import React, { useEffect, useState } from "react";
import { withBase, Loading } from "components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "stores/Common/user";
import * as menuActions from "stores/Common/menu";
import * as codeActions from "stores/Common/code";
import { GET_USER, GET_MENU, GET_CODE, SIGNOUT } from "stores/apis";
import { TOKEN, USER, MENU, CODE, TOKEN_TYPE } from "variables/constants";
import { removeStorage } from "variables/common";
import Container from "./Container";
import { handleRoutes, handleMenus } from "routes.js";
import Navbar from "./Navbar";
import Menu from "./Menu";
import queryString from "query-string";
import Dashboard from "views/DashBoard/DashBoard";

const AppLayout = (props) => {
  const { user, menu, routes } = props;
  const [loading, setLoading] = useState(true);
  const [menuItem, setMenuItem] = useState(null);

  const checkLoginUser = async () => {
    const param = queryString.parse(props.location.search);

    if (param.token && param.tokenType) {
      sessionStorage.setItem(TOKEN, param.token);
      sessionStorage.setItem(TOKEN_TYPE, param.tokenType);
    } else {
      const token = sessionStorage.getItem(TOKEN);
      if (!token) {
        return null;
      }
    }

    const { HttpRequest, UserActions } = props;
    const { url, method } = GET_USER;
    const data = await HttpRequest.request(url, method);

    if (!HttpRequest.hasError(data)) {
      sessionStorage.setItem(USER, JSON.stringify(data));
      UserActions.login(data);
      return {
        userId: data.userId,
        userNm: data.userNm,
        deptCd: data.deptCd,
        deptNm: data.deptNm,
        cmpnCd: data.cmpnCd,        
      };
    }

    return null;
  };

  const handleClickLogout = async () => {
    const { HttpRequest, history } = props;
    const { url, method } = SIGNOUT;
    const data = await HttpRequest.request(url, method);

    if (!HttpRequest.hasError(data)) {
      removeStorage();
      history.push("/login");
    }
  };

  const getMenuToRoutes = async (userData) => {
    const { HttpRequest, MenuActions } = props;
    const { url, method } = GET_MENU;
    const data = await HttpRequest.request(url, method, userData);

    if (HttpRequest.hasError(data, true)) {
      return false;
    }

    const routes = handleRoutes(data);
    if (!routes) return false;

    const menus = handleMenus(routes);
    if (!menus) return false;

    setMenuItem(menus[0]);
    MenuActions.saveRoutes(routes);
    MenuActions.save(menus);
    localStorage.setItem(MENU, JSON.stringify(routes));
    return true;
  };

  const getCode = async () => {
    // 1. 코드 버전 확인 (API)
    // 2. 버전이 같은 경우 localStorage 조회
    // // localStorage.getItem("code");
    // 2. 버전이 다른 경우 코드 정보 조회
    // 3. 코드 정보 저장

    const { HttpRequest, CodeActions } = props;
    const { url, method } = GET_CODE;
    const data = await HttpRequest.request(url, method);

    if (HttpRequest.hasError(data)) {
      return null;
    }

    localStorage.setItem(CODE, JSON.stringify(data));
    CodeActions.save(data);
    return data;
  };

  useEffect(() => {
    async function fetch() {
      let userData = null;
      if (process.env.REACT_APP_CHECK_LOGIN_YN === "Y") {
        userData = await checkLoginUser();
        if (!userData) {
          const { history } = props;
          history.push("/login");
          return; // if not return, execute below code and memory leack.
        }
      }

      if (!(await getMenuToRoutes(userData))) {
        pushErrorPage("/404", "Menu 초기화 오류");
        return;
      }
      if (!(await getCode())) {
        pushErrorPage("/404", "Code 초기화 오류");
        return;
      }
      setLoading(false);
    }
    fetch();
  }, []);

  const pushErrorPage = (pathname, message) => {
    const { history } = props;
    history.push({
      pathname: pathname,
      state: { detail: message },
    });
    history.push({
      pathname: pathname,
      state: { detail: message },
    });
  };

  const handleMenuChange = (menuItem) => {
    setMenuItem(menuItem);
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Navbar user={user} onClickLogout={handleClickLogout} />
      {props.location.pathname === "/dashBoard" ? (
        <Dashboard />
      ) : (
        <>
          <Menu menu={menu} onChange={handleMenuChange} />
          <Container menuItem={menuItem} routes={routes} />
        </>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    user: {
      userId: state.user.get("userId"),
      userNm: state.user.get("userNm"),
      deptCd: state.user.get("deptCd"),
      deptNm: state.user.get("deptNm"),
      cmpnCd: state.user.get("cmpnCd"),      
      auths: state.user.get("auths"),
    },
    menu: state.menu.get("menu"),
    routes: state.menu.get("routes"),
  }),
  (dispatch) => ({
    UserActions: bindActionCreators(userActions, dispatch),
    MenuActions: bindActionCreators(menuActions, dispatch),
    CodeActions: bindActionCreators(codeActions, dispatch),
  })
)(withBase(AppLayout));
