import React, { useState } from "react";
import PropTypes from "prop-types";
import "./RptDatPrtRoute.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  withBase,
  ConditionBox,
  ControlBox,
  DatePickerField,
  CodeSelect,
  Button,
  PopupWrapper,
  CustomLoading,
} from "components";
import moment from "moment";

const API = {
  LIST: {
    url: "api/v1/calc/deliveryTxn/listtotfaresum",
    method: "post",
  },
  DOWN: {
    url: "api/v1/calc/deliveryTxn/downtotfaresum",
    method: "post",
  },
};

const RptDatPrtRoute = (props) => {
  const [layerType, setLayerType] = useState("");
  const [message, setMessage] = useState("");

  const handleClickPrint = async (param) => {
    const { HttpRequest, searching } = props;
    setLayerType("loading");
    setMessage("출력중 입니다.");

    const { url, method } = API.LIST;

    const response = await HttpRequest.request(url, method, param);
    
    if (HttpRequest.hasError(response, true)) {
      setLayerType("");
      return;
    }

    if (response.totFareSumSheetList) {
      const printerObj1 = document.getElementById("printerObj1");
     
      printerObj1.Init();

      for (let i = 0; i < response.totFareSumSheetList.length; i++) {
        printerObj1.AddData(response.totFareSumSheetList[i].rowData);
      }

      setLayerType("");
      printerObj1.Print();
    }
    setLayerType("");
  };

  const handleClickDown = async (param) => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="RptDatPrtRoute">
      <Formik
        initialValues={{
          btnType: "",
          sumDate: moment().add(-1, "days"),
          phydsCorpDiv: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (values.btnType === "print") {
            handleClickPrint({
              sumDate: moment(values.sumDate).format("YYYYMMDD"),
              phydsCorpDiv: values.phydsCorpDiv,
            });
          } else if (values.btnType === "excel") {
            handleClickDown({
              sumDate: moment(values.sumDate).format("YYYYMMDD"),
              phydsCorpDiv: values.phydsCorpDiv,
            });
          }
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          sumDate: Yup.date().required().nullable(),
          phydsCorpDiv: Yup.string().required(),
        })}
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit } = props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <ConditionBox>
                  <div className="HasTable">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <dl>
                              <dt>
                                발송일<i></i>
                                <span>
                                  {errors.sumDate &&
                                    touched.sumDate &&
                                    errors.sumDate}
                                </span>
                              </dt>
                              <dd>
                                <div className="HasDate">
                                  <DatePickerField
                                    name="sumDate"
                                    date={values.sumDate}
                                  />
                                </div>
                              </dd>
                            </dl>
                            <dl>
                              <dt>
                                법인<i></i>
                                <span>
                                  {errors.phydsCorpDiv &&
                                    touched.phydsCorpDiv &&
                                    errors.phydsCorpDiv}
                                </span>
                              </dt>
                              <dd>
                                <CodeSelect
                                  codeId="PHYDS_CORP_DIV"
                                  name="phydsCorpDiv"
                                  onChange={handleChange}
                                  onBlur={handleChange}
                                  placeholder={"법인 선택"}
                                  value={values.phydsCorpDiv}
                                />
                              </dd>
                            </dl>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ConditionBox>
                <ControlBox>
                  <span className="info_text">
                    정산 프로그램을 통한 각각의 집계현황이며, 확인 및 출력이
                    가능합니다.
                  </span>
                </ControlBox>

                <div className="tb_area">
                  <article className="grid_data">
                    <div className="thead">
                      <table>
                        <colgroup>
                          {["100%"].map((i, key) => {
                            return <col width={i} key={key} />;
                          })}
                        </colgroup>
                        <tbody>
                          <tr>
                            {["총운임 집계표"].map((i, key) => {
                              return <th key={key}>{i}</th>;
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="tbody NoreSize">
                      <table>
                        <colgroup>
                          {["50%", "*"].map((i, key) => {
                            return <col width={i} key={key} />;
                          })}
                        </colgroup>
                        <tbody>
                          <tr>
                            <td className="text">총운임 집계표</td>
                            <td>
                              <Button
                                className="print"
                                onClick={() => {
                                  values.btnType = "print";
                                  handleSubmit();
                                }}
                              >
                                출력하기
                              </Button>
                              <Button
                                className="excel"
                                onClick={() => {
                                  values.btnType = "excel";
                                  handleSubmit();
                                }}
                              >
                                엑셀 다운로드
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </article>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <PopupWrapper
        layerType={layerType}
        title={""}
        loading={<CustomLoading layerType={layerType}>{message}</CustomLoading>}
      />
    </div>
  );
};

RptDatPrtRoute.propTypes = {};

export default withBase(RptDatPrtRoute);
