import React from "react";
import PropTypes from "prop-types";
import "./SetlTxnCorrectionContent.css";
import SetlTxnCorrectionContentPlcfbTable from "./SetlTxnCorrectionContentPlcfbTable";
import SetlTxnCorrectionContentCarTable from "./SetlTxnCorrectionContentCarTable";
import SetlTxnCorrectionContentFeeTable from "./SetlTxnCorrectionContentFeeTable";

const SetlTxnCorrectionContent = (props) => {
  const { plcfbDatas, carDatas, feeDatas, total } = props;

  return (
    <div className="SetlTxnCorrectionContent">
      {plcfbDatas && plcfbDatas.length > 0 ? (
        <SetlTxnCorrectionContentPlcfbTable plcfbDatas={plcfbDatas} />
      ) : (
        <></>
      )}
      {carDatas && carDatas.length > 0 ? (
        <SetlTxnCorrectionContentCarTable carDatas={carDatas} />
      ) : (
        <></>
      )}
      {feeDatas && feeDatas.length > 0 ? (
        <SetlTxnCorrectionContentFeeTable feeDatas={feeDatas} total={total} />
      ) : (
        <></>
      )}
    </div>
  );
};

SetlTxnCorrectionContent.propTypes = {};

export default SetlTxnCorrectionContent;
