import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withBase } from "components";

const API = {
  LIST: {
    url: "api/v1/calc/deliveryTxn/listtot",
    method: "post",
  },
};

const RptDatPrtTerminalTable = (props) => {
  const { param } = props;
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState();
  const styles = {
    fontSize: '12px'
  }

  useEffect(() => {
    if (param) list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setTotalData("");
      searching(false);
      return;
    }

    if (
      !response.trmLoadSumSheetList ||
      response.trmLoadSumSheetList.length < 2
    ) {
      setDatas([]);
      setTotalData("");
    } else {
      setDatas(response.trmLoadSumSheetList.slice(0, -1));
      setTotalData(response.trmLoadSumSheetList.slice(-1));
    }
    searching(false);
  };

  return (
    <article className="grid_data first">
      <div className="TableArea">
        <div className="thead HasTD">
          <table>
            <colgroup>
              {[
                "*",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                <th rowSpan="2">구분</th>
                {["군포", "고촌", "양산", "칠곡", "장성", "화성", "인천", "평택", "영영"].map(
                  (i, key) => {
                    return (
                      <th key={key} colSpan="2">
                        {i}
                      </th>
                    );
                  }
                )}
              </tr>
              <tr>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
                <td>하차</td>
                <td>상차</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbody">
          <table>
            <colgroup>
              {[
                "*",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={19}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        <span className="string">{item.region}</span>
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm1unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm1loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm2unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm2loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm3unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm3loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm4unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm4loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm5unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm5loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm6unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm6loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm8unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm8loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm9unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm9loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm7unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm7loadfare, "amount")}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="tfoot">
          <table>
            <colgroup>
              {[
                "*",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
                "5.4%",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!totalData || totalData.length === 0 ? (
                <></>
              ) : (
                totalData.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        <span className="string">{item.region}</span>
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm1unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm1loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm2unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm2loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm3unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm3loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm4unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm4loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm5unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm5loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm6unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm6loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm8unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm8loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm9unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm9loadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm7unloadfare, "amount")}
                      </td>
                      <td className="amount" style={styles}>
                        {props.formatter(item.trm7loadfare, "amount")}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
};

RptDatPrtTerminalTable.propTypes = {};

export default withBase(RptDatPrtTerminalTable);
