import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "./CustomInput.css";

const CustomInput = forwardRef((props, ref) => {
  return <input {...props} className="CustomInput" ref={ref} />;
});

CustomInput.propTypes = {};

export default CustomInput;
