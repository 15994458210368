import React from "react";
import "./Navbar.css";
import logo from "assets/images/logo.svg";
import Logo from "./Logo";
import NavbarLinks from "./NavbarLinks";

const Navbar = (props) => {
  const { user, onClickLogout } = props;
  return ( 
    <div className="Navbar">
      <Logo src={logo} link="/calc/deliveryTxn" />
      <NavbarLinks user={user} onClickLogout={onClickLogout} />
    </div>
  );
};

export default Navbar;
