import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./DsblTxnDetails.css";
import { withBase, ControlBox, Button, Pagination } from "components";
import moment from "moment";
import DsblTxnDetailsConditionBox from "./DsblTxnDetailsConditionBox";

const API = {
  LIST: {
    url: "api/v1/report/dsblTxn/listfare",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/dsblTxn/downfarefee",
    method: "post",
  },
};

const DsblTxnDetails = (props) => {
  const [param, setParam] = useState({
    setlDateFrom: moment().add(-1, "days").format("YYYYMMDD"),
    setlDateTo: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }

    setTotal(response.totalRowCnt);
    setDatas(response.fareFeeTxnList);
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="DsblTxnDetails">
      <DsblTxnDetailsConditionBox
        onClickSearch={handleClickSearch}
        {...props}
      />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>

      <div className="DsblTxnDetailsTable">
        <article className="grid_data">
          
          <div className="thead">
            <table>
              <colgroup>
                {[
                  "5%",
                  "*",
                  "5%",
                  "5%",
                  "10%",
                  "5.5%",
                  "3%",
                  "5%",
                  "5.5%",
                  "7%",
                  "5%",
                  "3%",
                  "3%",
                  "10%",
                  "5%",
                  "3%",
                  "4%",
                  "4%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {[
                    "발송일",
                    "영업소명(노선명)",
                    "수수료",
                    "차량번호",
                    "법인명",
                    "법인계좌",
                    "은행명",
                    "예금주명",
                    "계좌번호",
                    "통장기재",
                    "주민번호",
                    "구분",
                    "처리결과",
                    "사유",
                    "수수료종류",
                    "톤수",
                    "수정자",
                    "송금일",
                  ].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="holdTable">
            <div className="tbody HasTH">
              <table>
                <colgroup>
                  {[
                    "5%",
                    "*",
                    "5%",
                    "5%",
                    "10%",
                    "5.5%",
                    "3%",
                    "5%",
                    "5.5%",
                    "7%",
                    "5%",
                    "3%",
                    "3%",
                    "10%",
                    "5%",
                    "3%",
                    "4%",
                    "4%",
                  ].map((i, key) => {
                    return <col width={i} key={key} />;
                  })}
                </colgroup>
                <tbody>
                  {!datas || datas.length === 0 ? (
                    <tr>
                      <td className="NoData" colSpan={18}>
                        조회 결과가 없습니다.
                      </td>
                    </tr>
                  ) : (
                    datas.map((item, key) => {
                      return (
                        <tr key={key}>
                          <th>{props.formatter(item.dspDate, "date")}</th>
                          <td className="tal">{item.plcfbName}</td>
                          <td className="amount">
                            {props.formatter(item.fee, "amount")}
                          </td>
                          <td>{item.carNo}</td>
                          <td className="tal">{item.corpName}</td>
                          <td>
                            {props.formatter(item.corpAcnt, "account", false)}
                          </td>
                          <td>{item.bankName}</td>
                          <td>{props.formatter(item.dposr, "name", false)}</td>
                          <td>
                            {props.formatter(item.acntNo, "account", false)}
                          </td>
                          <td>{item.bnkbkSttm}</td>
                          <td>{props.formatter(item.custNo, "custNo")}</td>
                          <td>{item.degreeDiv}</td>
                          <td>{item.otpaySttusNm}</td>
                          <td className="tal">{item.remarks}</td>
                          <td>{item.setlItemName}</td>
                          <td>{item.carTon}</td>
                          <td>{item.amdrName}</td>
                          <td>{item.amdDate}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>

      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
    </div>
  );
};

DsblTxnDetails.propTypes = {};

export default withBase(DsblTxnDetails);
