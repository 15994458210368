let properties = {};

if (process.env.NODE_ENV === "development") {
  properties = {
    // contextRoot: "http://localhost:3000",
    contextRoot: "http://localhost/ksetl",
    applicationName: "K-BILLING",
    homepage: "/",
  };
} else if (process.env.NODE_ENV === "production") {
  properties = {
    contextRoot: "ksetl",
    applicationName: "K-BILLING",
    homepage: "/",
  };
} else {
  throw new Error("process.env.NODE_ENV를 설정하지 않았습니다.");
}

export { properties };
