import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./SmsTrmTxn.css";
import {
  withBase,
  Button,
  CodeSelect,
  LayerPopup,
  PopupWrapper,
  CustomLoading,
  CustomMessage,
  Table,
  Pagination,
  LinearProgressWithLabel,
  CustomDatePicker,
} from "components";
import textBoxIcon from "assets/images/text_box.png";
import FileUploadIcon from "assets/images/icon_file_upload.png";
import { useDropzone } from "react-dropzone";
import SmsTrmTxnUploadList from "./SmsTrmTxnUploadList";
import { properties } from "variables/properties";
import axios from "axios";
import { useSelector } from "react-redux";

const API = {
  LIST: {
    url: "api/v1/sms/smsTrmTxn/list",
    method: "post",
  },
  READ: {
    url: "api/v1/sms/smsTrmTxn/read",
    method: "post",
  },
  CREATE: {
    url: "api/v1/sms/smsTrmTxn/create",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/sms/smsTrmTxn/update",
    method: "post",
  },
  DOWN: {
    url: "api/v1/sms/smsTrmTxn/down",
    method: "post",
  },
};

const SmsTrmTxn = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    smsType: "",
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [smsTitle, setSmsTitle] = useState("");
  const [layerType, setLayerType] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [targetDate, setTargetDate] = useState(new Date());

  const [step, setStep] = useState(1);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: ({}, ".xlsx"),
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 314572800,
    onDrop: (acceptedFiles, rejectedFiles) => {
      console.log(acceptedFiles, rejectedFiles);
      if (acceptedFiles && acceptedFiles.length > 0) {
        setFiles([...acceptedFiles]);
        setStep(2);
      }
    },
  });

  useEffect(() => {
    list();
  }, [param]);

  useEffect(() => {
    if (files.length === 0) setStep(1);
  }, [files]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.smsTrmTxnList);
    searching(false);
  };

  const handleChange = (e) => {
    setParam({
      smsType: e.target.value,
      pageNo: 1,
    });
  };

  const handleClickCreate = async () => {
    if (param.smsType === "") {
      props.toast.error("SMS유형 선택 후 사용 가능합니다.");
      return;
    }

    setLayerType("loading");
    const { HttpRequest } = props;
    const { url, method } = API.CREATE;

    const response = await HttpRequest.request(url, method, {
      ...param,
      targetDate: props.formatter(
        props.formatter(targetDate, "date"),
        "toValue",
      ),
    });
    if (HttpRequest.hasError(response, true)) {
      setMessage("문자메세지 생성중 오류가 발생했습니다.");
      setLayerType("message");
      return;
    }
    console.log(response);

    setParam({
      ...param,
      pageNo: 1,
    });
    setMessage("문자메세지 생성이 완료되었습니다.");
    setLayerType("message");
  };

  const handleClickSend = async () => {
    if (window.confirm("전송을 하시겠습니까?")) {
      setLayerType("loading");
      const { HttpRequest } = props;
      const { url, method } = API.UPDATE;

      const response = await HttpRequest.request(url, method, param);
      if (HttpRequest.hasError(response, true)) {
        setMessage("문자메세지 전송중 오류가 발생했습니다.");
        setLayerType("message");
        return;
      }
      console.log(response);

      setParam({
        ...param,
        pageNo: 1,
      });
      setMessage("문자메세지 전송이 완료되었습니다.");
      setLayerType("message");
    }
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  const handleClickClose = () => {
    setLayerType("");
  };

  const handleClickRemoveFile = (file) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFiles([...acceptedFiles]);
  };

  const handleClickFileUpload = () => {
    const formData = new FormData();
    formData.append("name", "파일업로드");
    formData.append("fileTypeCd", "FT004");
    files.forEach((file) => {
      formData.append("files", file);
    });

    let url = "api/v1/comn/file/upload";
    if (properties && properties.contextRoot) {
      url = `${properties.contextRoot}/${url}`;
    }

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded} kb of ${total} kb | ${percent}%`);

        if (percent < 100) {
          setUploadPercent(percent);
        }
      },
    };

    axios
      .post(url, formData, options)
      .then((res) => {
        setUploadPercent(100);
        setTimeout(() => {
          setUploadPercent(0);
        }, 1000);
        if (res instanceof Error) {
          console.log("Error", res);
          setMessage(
            <>
              파일 업로드를 실패 하였습니다.
              <br />
              다시 업로드 해주세요.
            </>,
          );
          setLayerType("message");
        } else {
          console.log(res);
          setStep(3);
          setMessage(<>파일 업로드 및 전송이 완료 됐습니다.</>);
          setLayerType("message");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setMessage("파일 업로드 예외 발생했습니다.");
        setLayerType("message");
      });
  };

  return (
    <div className="SmsTrmTxn">
      <form
        className={
          "SmsSelect " +
          (cretFlag ? (param.smsType === "05" ? "other" : "basic") : "other")
        }
      >
        <div className="Type">
          <dl className="SelectArea">
            <dt>SMS유형</dt>
            <dd>
              <CodeSelect
                codeId="SMS_TYPE"
                name="smsType"
                onChange={(e, item) => {
                  if (item) setSmsTitle(item.name);
                  handleChange(e);
                }}
                onBlur={handleChange}
                placeholder={"SMS유형 선택"}
                value={param.smsType}
              />
            </dd>
          </dl>
          {cretFlag ? (
            param.smsType === "05" ? (
              <h4>
                <span>파일첨부</span> 버튼을 눌러 파일을 첨부해 주세요.(파일은
                1개만 올라갑니다.)
                <br />
                <br />
                파일 업로드가 완료되면 바로 <span>전송</span>됩니다.
              </h4>
            ) : (
              <>
                {param.smsType === "01" && (
                  <div className="baseDate">
                    기준일 :
                    <CustomDatePicker
                      name="targetDate"
                      onChange={(e) => {
                        setTargetDate(e);
                      }}
                      date={targetDate}
                    />
                  </div>
                )}
                <div>
                  <Button className="data_gnr" onClick={handleClickCreate}>
                    데이터 생성
                  </Button>
                  <h4>
                    SMS유형 선택 후 <span>데이터 생성</span> 버튼을 눌러주세요.
                  </h4>
                </div>
              </>
            )
          ) : (
            <div></div>
          )}
        </div>
      </form>
      {param.smsType ? (
        param.smsType === "05" ? (
          cretFlag && (
            <>
              <div className="UploadArea">
                <div
                  className={
                    step === 1
                      ? "drop"
                      : step === 2
                      ? "drop SecondStep"
                      : "drop ThirdStep"
                  }
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <img src={FileUploadIcon} className="icon" alt="fileUpload" />
                  <Button className="add_file" onClick={open}>
                    파일첨부
                  </Button>
                  {step === 1 ? (
                    <></>
                  ) : step === 2 ? (
                    <>
                      <Button
                        className="fee_file_upload"
                        onClick={handleClickFileUpload}
                      >
                        파일 업로드
                      </Button>
                    </>
                  ) : (
                    <>
                      <h4>파일 업로드 및 전송이 완료 됐습니다.</h4>
                    </>
                  )}
                </div>
                {step === 2 ? (
                  <SmsTrmTxnUploadList
                    files={files}
                    onClickRemoveFile={handleClickRemoveFile}
                  />
                ) : null}
                {uploadPercent > 0 && (
                  <LinearProgressWithLabel value={uploadPercent} />
                )}
              </div>
            </>
          )
        ) : (
          <div className="SmsTrmTxnTable">
            <div className="topArea">
              <h3>{smsTitle}</h3>
              {cretFlag && (
                <Button className="send" onClick={handleClickSend}>
                  전송하기
                </Button>
              )}
              <Button className="excel" onClick={handleClickDown}>
                엑셀 다운로드
              </Button>
            </div>
            <article className="grid_data">
              <div className="TableArea">
                <Table
                  widths={["5.4%", "12.1%", "*"]}
                  columns={["순번", "전화번호", "메세지 내용"]}
                  datas={
                    datas &&
                    datas.map((item, key) => {
                      return (
                        <>
                          <td>{item.rowNum}</td>
                          <td>
                            {props.formatter(item.rcvTelNo, "phone", false)}
                          </td>
                          <td className="msg">
                            {item.smsChr}
                            <div>
                              <i>
                                <img src={textBoxIcon} />
                              </i>
                              {item.smsChr}
                            </div>
                          </td>
                        </>
                      );
                    })
                  }
                  tbodyClass="ScrollNone"
                />
              </div>
            </article>

            {datas && datas.length > 0 && (
              <Pagination
                page={param.pageNo}
                total={total}
                row={15}
                pageCount={10}
                fetchData={(page) => {
                  setParam({
                    ...param,
                    pageNo: page,
                  });
                }}
              />
            )}
          </div>
        )
      ) : (
        <></>
      )}

      <PopupWrapper
        layerType={layerType}
        title={""}
        loading={
          <CustomLoading layerType={layerType}>
            문자메세지 생성중입니다.
          </CustomLoading>
        }
      />
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={""}
          message={
            <CustomMessage onClose={handleClickClose}>{message}</CustomMessage>
          }
        />
      </LayerPopup>
    </div>
  );
};

SmsTrmTxn.propTypes = {};

export default withBase(SmsTrmTxn);
