import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./FareFeeTxn.css";
import {
  withBase,
  Button,
  LayerPopup,
  ControlBox,
  Pagination,
} from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import FareFeeTxnConditionBox from "./FareFeeTxnConditionBox";
import FareFeeTxnTable from "./FareFeeTxnTable";
import FareFeeTxnPopupWrapper from "./FareFeeTxnPopupWrapper";
import FareFeeTxnPopupIndividual from "./FareFeeTxnPopupIndividual";
import FareFeeTxnPopupBatch from "./FareFeeTxnPopupBatch";
import FareFeeTxnPopupDown from "./FareFeeTxnPopupDown";

const API = {
  LIST: {
    url: "api/v1/report/fareFeeTxn/list",
    method: "post",
  },
  DOWNOTPAYREQ: {
    url: "api/v1/report/fareFeeTxn/downotpayreq",
    method: "post",
  },
  DOWNFAREFEE: {
    url: "api/v1/report/fareFeeTxn/downfarefee",
    method: "post",
  },
  CREATE: {
    url: "api/v1/report/fareFeeTxn/createotpay",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/report/fareFeeTxn/updateotpay",
    method: "post",
  },
  DELETE: {
    url: "api/v1/report/fareFeeTxn/deleteotpay",
    method: "post",
  },
  DATELIST: {
    url: "api/v1/report/fareFeeTxn/listposbldate",
    method: "post",
  },
};

const FareFeeTxn = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    dspDate: moment().add(-1, "days").format("YYYYMMDD"),
    dspSDate: moment().add(-1, "days").format("YYYYMMDD"),
    dspEDate: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);
  const [batchDatas, setBatchDatas] = useState([]);
  const [dateItem, setDateItem] = useState([]);
  const [batchAllDat, setBatchAllDat] = useState([]);
  const [batchAllChk, setBatchAllChk] = useState(false);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      setBatchDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.fareFeeTxnList);
    setBatchDatas([]);

    let checkDat = [];
    response.fareFeeTxnList &&
      response.fareFeeTxnList.forEach((item) => {
        if (
          item.otpaySttus === "01" ||
          item.otpaySttus === "02" ||
          item.otpaySttus === "03"
        ) {
          checkDat.push(item.rowNum);
        }
      });

    setBatchAllDat(checkDat);
    searching(false);
  };
  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    return true;
  };
  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDownOtpayReq = async (param) => {
    const { url } = API.DOWNOTPAYREQ;

    await props.downExcel.request(url, param);

    setLayerType("");
  };

  const handleClickDownFareFee = async () => {
    const { url } = API.DOWNFAREFEE;

    await props.downExcel.request(url, param);
  };

  const onChangeAllChk = (checked) => {
    setBatchAllChk(checked);

    if (checked) {
      setBatchDatas(batchAllDat);
    } else {
      setBatchDatas([]);
    }
  };

  const handleClickCheckBox = (index, check) => {
    check
      ? setBatchDatas([...batchDatas, index])
      : setBatchDatas(batchDatas.filter((item) => item !== index));
  };
  const handleDoubleClick = (index) => {
    setLayerType("individual");
    setViewData(datas[index]);
  };
  const handleClickDown = async () => {
    const { HttpRequest } = props;

    const { url, method } = API.DATELIST;

    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setDateItem([]);
      return;
    }

    if (!response.fareFeeTxnList || response.fareFeeTxnList.length === 0) {
      setDateItem([]);
    } else {
      setDateItem(
        response.fareFeeTxnList.map((item) => {
          return {
            code: item.dspDate,
            name: props.formatter(item.dspDate, "date"),
          };
        })
      );
    }

    setLayerType("down");
  };
  const handleClickBatch = () => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    setLayerType("batch");
    setViewData(datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1));
  };
  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const handleClickSave = async (saveType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (saveType) {
      case "batch":
        ret = await edit(API.CREATE, { fareFeeTxnList: data });
        msg = "일괄송금 저장되었습니다.";
        if (ret) setBatchDatas([]);
        break;
      case "individual":
        ret = await edit(API.CREATE, { fareFeeTxnList: [data] });
        msg = "개별송금 저장되었습니다.";
        break;
      case "update":
        ret = await edit(API.UPDATE, data);
        msg = "수정저장되었습니다.";
        break;
      case "delete":
        if (window.confirm("해당 송금정보를 삭제 하시겠습니까?")) {
          ret = await edit(API.DELETE, data);
          msg = "삭제되었습니다.";
        }
        break;
      default:
        console.log(saveType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };
  return (
    <div className="FareFeeTxn">
      <FareFeeTxnConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        {cretFlag && (
          <Button className="withdrawl_register" onClick={handleClickBatch}>
            일괄송금 등록
          </Button>
        )}
        <Button className="withdrawl_rquest" onClick={handleClickDown}>
          송금요청서
        </Button>
        <Button className="excel" onClick={handleClickDownFareFee}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <FareFeeTxnTable
        datas={datas}
        batchDatas={batchDatas}
        onChange={handleClickCheckBox}
        onDoubleClick={handleDoubleClick}
        batchAllChk={batchAllChk}
        onChangeAllChk={onChangeAllChk}
        formatter={props.formatter}
        cretFlag={cretFlag}
      />
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <FareFeeTxnPopupWrapper
          layerType={layerType}
          title={"운송수수료"}
          className={"FareFeeTxnPopup"}
          batchTable={
            <FareFeeTxnPopupBatch
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          individualTable={
            <FareFeeTxnPopupIndividual
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          downTable={
            <FareFeeTxnPopupDown
              onClose={handleClickClose}
              onDown={handleClickDownOtpayReq}
              dateItem={dateItem}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

FareFeeTxn.propTypes = {};

export default withBase(FareFeeTxn);
