import React from "react";
import PropTypes from "prop-types";
import "./DpstTxn.css";
import { CustomTab } from "components";
import DpstTxnList from "./DpstTxnList";
import DpstTxnDetails from "./DpstTxnDetails";

const DpstTxn = (props) => {
  return (
    <div className="DpstTxn">
      <CustomTab
        tabs={[
          {
            name: "예치내역",
            component: <DpstTxnList />,
          },
          {
            name: "상세보기",
            component: <DpstTxnDetails />,
          },
        ]}
      />
    </div>
  );
};

DpstTxn.propTypes = {};

export default DpstTxn;
