import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./DeliveryTxn.css";
import axios from "axios";
import {
  withBase,
  Button,
  LinearProgressWithLabel,
  LayerPopup,
  PopupWrapper,
  CustomLoading,
  CustomMessage,
} from "components";
import FileUploadIcon from "assets/images/icon_file_upload.png";
import { useDropzone } from "react-dropzone";
import DeliveryTxnStep from "./DeliveryTxnStep";
import DeliveryTxnUploadList from "./DeliveryTxnUploadList";
import { properties } from "variables/properties";
import moment from "moment";

const API = {
  CALC: {
    url: "api/v1/calc/deliveryTxn/calc",
    method: "post",
  },
};

const DeliveryTxn = (props) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const calcDay = moment().add(-1, "days").format("YYYYMMDD");

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: ({}, ".dbf,.xlsx"),
    noClick: true,
    noKeyboard: true,
    //maxSize: 314572800,
    maxSize : 524288000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      console.log(acceptedFiles, rejectedFiles);
      if (acceptedFiles && acceptedFiles.length > 0) {
        setFiles([...acceptedFiles]);
        setStep(2);
      }
    },
  });

  useEffect(() => {
    if (files.length === 0) setStep(1);
  }, [files]);

  // function of function
  const handleClickRemoveFile = (file) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFiles([...acceptedFiles]);
  };

  const chkFileName = () => {
    let ret = false;
    let dbfFiles = [];

    if (files.length === 0) {
      setMessage("업로드할 파일이 없습니다.");
      setLayerType("message");
      return true;
    }

    for (let i = 0; files.length > i; i++) {
      let fileName = files[i].name;

      if (fileName.slice(-3).toLowerCase() === "dbf") {
        if (fileName.length < 8) {
          ret = true;
          break;
        } else if (fileName.length !== 15) {
          ret = true;
          break;
        }

        dbfFiles.push(fileName.slice(3, -4));
      }
    }

    if (ret) {
      setMessage("생성 파일 예시를 확인하세요.");
      setLayerType("message");
      return ret;
    }

    if (Array.from(new Set(dbfFiles)).length > 1) ret = true;

    if (ret) {
      setMessage("업로드 dbf 파일의 날자가 동일하지 않습니다.");
      setLayerType("message");
      return ret;
    }

    return ret;
  };

  const handleClickFileUpload = async () => {
    setIsUploading(true);
    await handleFileUpload();
    setIsUploading(false);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("name", "파일업로드");
    formData.append("fileTypeCd", "FT001");

    if (chkFileName()) return;

    files.forEach((file) => {
      formData.append("files", file);
    });

    let url = "api/v1/comn/file/upload";
    if (properties && properties.contextRoot) {
      url = `${properties.contextRoot}/${url}`;
    }

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded} kb of ${total} kb | ${percent}%`);

        if (percent < 100) {
          setUploadPercent(percent);
        }
      },
    };

    await axios
      .post(url, formData, options)
      .then((res) => {
        setUploadPercent(100);
        setTimeout(() => {
          setUploadPercent(0);
        }, 1000);
        if (res instanceof Error) {
          console.log(res.response);
          if (res.response.status === 409) {
            let data = res.response.data;
            if (data.message) {
              setMessage(data.message);
            } else {
              let indexS = data.indexOf("<body>");
              let indexE = data.indexOf("</body>");

              if (indexS > 0 && indexE > 0) {
                setMessage(data.slice(indexS + 6, indexE));
              } else {
                setMessage(
                  <>
                    파일 업로드를 실패 하였습니다.
                    <br />
                    다시 업로드 해주세요.
                  </>,
                );
              }
            }
          } else {
            setMessage(
              <>
                파일 업로드를 실패 하였습니다.
                <br />
                다시 업로드 해주세요.
              </>,
            );
          }
          setLayerType("message");
        } else {
          console.log(res);
          setStep(3);
          setMessage(
            <>
              파일 업로드가 완료되었습니다.
              <br />
              수수료계산을 해주세요.
            </>,
          );
        }
      })
      .catch((error) => {
        console.log(error.response);
        setMessage("파일 업로드 예외 발생했습니다.");
        setLayerType("message");
      });
  };

  const handleClickCalcFee = async (param) => {
    setLayerType("loading");
    const { HttpRequest } = props;
    const { url, method } = API.CALC;

    const response = await HttpRequest.request(url, method, param, true);
    if (HttpRequest.hasError(response, false)) {
      console.log(response);
      if (response.status === 405) {
        setLayerType("message");
        setMessage(response.message);
        setMessageType("confirm");
      } else if (response.status === 409) {
        setLayerType("message");

        let data = response.data;
        if (response.message) {
          setMessage(response.message);
        } else {
          let indexS = data.indexOf("<body>");
          let indexE = data.indexOf("</body>");

          if (indexS > 0 && indexE > 0) {
            setMessage(data.slice(indexS + 6, indexE));
          } else {
            setMessage("수수료계산중 오류가 발생했습니다.");
          }
        }
      } else {
        setMessage("수수료계산중 오류가 발생했습니다.");
        setLayerType("message");
        setMessageType("");
      }
      return;
    }
    console.log(response);
    setMessage("수수료계산이 완료되었습니다.");
    setLayerType("message");
    setMessageType("");
  };

  const handleClickStep = (clickStep) => {
    setStep(clickStep);
  };

  const handleClickClose = () => {
    setLayerType("");
  };

  const handleClickConfirm = () => {
    handleClickCalcFee({ errIgnoreYn: "Y" });
  };

  return (
    <div className="DeliveryTxn">
      <div className="FileUploadProcess">
        <DeliveryTxnStep step={step} clickStep={handleClickStep} />
        <form className="UploadArea">
          <div
            className={
              step === 1
                ? "drop"
                : step === 2
                ? "drop SecondStep"
                : "drop ThirdStep"
            }
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <img src={FileUploadIcon} className="icon" alt="fileUpload" />
            <Button className="add_file" onClick={open}>
              파일첨부
            </Button>
            {step === 1 ? (
              <h4>첨부파일을 마우스로 끌어 넣으세요.</h4>
            ) : step === 2 ? (
              <Button
                className="fee_file_upload"
                onClick={handleClickFileUpload}
                disabled={isUploading}
              >
                파일 업로드
              </Button>
            ) : (
              <>
                <Button
                  className="fee_clclt"
                  onClick={() => {
                    handleClickCalcFee({});
                  }}
                >
                  수수료 계산
                </Button>
                <h4>
                  파일 업로드가 완료 됐습니다. 파일을 더 추가하거나 수수료 계산
                  버튼을 눌러 계속 진행해주세요.
                </h4>
              </>
            )}
          </div>
          {step === 2 ? (
            <DeliveryTxnUploadList
              files={files}
              onClickRemoveFile={handleClickRemoveFile}
            />
          ) : null}
          {uploadPercent > 0 && (
            <LinearProgressWithLabel value={uploadPercent} />
          )}
        </form>
        <div className="Example">
          <h5>생성 파일 예시</h5>
          <span>
            - 경동물류 원본파일명 = 01_{calcDay}.dbf / 경동물류 삭제파일명 = 03_
            {calcDay}.dbf
            <br />- 합동물류 원본파일명 = 02_{calcDay}.dbf / 합동물류 삭제파일명
            = 04_{calcDay}.dbf
          </span>
        </div>
      </div>
      <PopupWrapper
        layerType={layerType}
        title={""}
        loading={
          <CustomLoading layerType={layerType}>
            수수료계산중입니다.
          </CustomLoading>
        }
      />
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={""}
          message={
            <CustomMessage
              messageType={messageType}
              onClose={handleClickClose}
              onConfirm={handleClickConfirm}
            >
              {message}
            </CustomMessage>
          }
        />
      </LayerPopup>
    </div>
  );
};

DeliveryTxn.propTypes = {};

export default withBase(DeliveryTxn);
