import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
}));

const Loading = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <CircularProgress size={30} thickness={4} value="loading" /> */}
      <Typography variant="overline" display="block" gutterBottom>
        Loading...
      </Typography>
    </div>
  );
};

Loading.propTypes = {};

export default Loading;
