import React from "react";
import PropTypes from "prop-types";
import "./LayerPopup.css";
import PopupClose from "assets/images/popup_close.png";

const LayerPopup = (props) => {
  const { layerType, children, onClose } = props;

  return layerType === "loading" ? (
    <></>
  ) : (
    <div
      className="LayerPopup"
      style={{ display: layerType ? "block" : "none" }}
    >
      <div className="PopupArea">
        <div className="PopupClose">
          <img src={PopupClose} onClick={onClose} alt="close" />
        </div>
        {children}
      </div>
      <div className="PopupBG"></div>
    </div>
  );
};

LayerPopup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayerPopup;
