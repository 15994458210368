import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./CacheAutocomplete.css";
import ReactAutocomplete from "react-autocomplete";
import { connect } from "react-redux";
import withBase from "../Hoc/withBase";

const API = {
  GET: {
    url: "api/v1/comn/cache/info",
    method: "get",
  },
};

const CacheAutocomplete = (props) => {
  const {
    cache,
    name,
    onMap,
    setFieldValue,
    value,
    placeholder,
    callBack,
  } = props;
  const [items, setItems] = useState([]);
  const getCache = async () => {
    const { HttpRequest } = props;
    const { url, method } = API.GET;
    const response = await HttpRequest.request(`${url}/${cache}`, method);
    if (HttpRequest.hasError(response, true)) {
      return [];
    }
    return response;
  };
  useEffect(() => {
    async function fetch() {
      const data = await getCache();
      if (data) {
        setItems(data.map(onMap));
      }
    }
    cache ? fetch() : setItems([]);
  }, [cache]);
  const handleFieldValue = (value) => {
    if (setFieldValue) setFieldValue(name, value);
    const item = items.find((element) => element.name === value);
    if (callBack) callBack(item ? item : "");
  };
  return (
    <div className="Autocomplete">
      <ReactAutocomplete
        items={items ? items : []}
        shouldItemRender={(item, value) =>
          value && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        }
        getItemValue={(item) => item.name}
        renderItem={(item, highlighted) => (
          <div
            className="CacheAutocomplete"
            key={item.code}
            style={{ background: highlighted ? "lightgray" : "white" }}
          >
            {item.name}
          </div>
        )}
        renderMenu={(items, value, style) => {
          return (
            <div
              style={{ minWidth: style.minWidth }}
              className="renderMenu"
              children={items}
            />
          );
        }}
        value={value}
        onChange={(e) => handleFieldValue(e.target.value)}
        onSelect={(value) => handleFieldValue(value)}
        inputProps={{ placeholder: placeholder }}
        // wrapperProps={{ style: AutoCompleteBox }}
      />
    </div>
  );
};

CacheAutocomplete.propTypes = {};

export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({})
)(withBase(CacheAutocomplete));
