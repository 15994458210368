import React, { useState } from "react";
import PropTypes from "prop-types";
import "./RptDatPrt.css";
import { CustomTab } from "components";
import Tab1 from "./RptDatPrtRoute";
import Tab2 from "./RptDatPrtSmry";
import Tab3 from "./RptDatPrtTerminal";

const RptDatPrt = (props) => {
  const [tabs, setTabs] = useState([
    {
      name: "총운임 집계표",
      path: "./RptDatPrtRoute",
      component: <Tab1 />,
    },
    {
      name: "노선 집계표",
      path: "./RptDatPrtSmry",
      component: <Tab2 />,
    },
    {
      name: "터미널 집계표",
      path: "./RptDatPrtTerminal",
      component: <Tab3 />,
    },
  ]);

  return (
    <div className="RptDatPrt">
      <CustomTab tabs={tabs} />
    </div>
  );
};

RptDatPrt.propTypes = {};

export default RptDatPrt;
