import React from "react";
import PropTypes from "prop-types";
import "./PauseReqListPopupWrapper.css";
import { Title } from "components";

const PauseReqListPopupWrapper = (props) => {
  const {
    layerType,
    className,
    title,
    batchTable,
    individualTable,
    downTable,
  } = props;
  const determineView = () => {
    let ret = null;
    switch (layerType) {
      case "pause":
        ret = batchTable;
        break;
      case "deposit":
        ret = batchTable;
        break;
      case "down":
        ret = downTable;
        break;
      default:
        break;
    }
    return ret;
  };
  return (
    <div className={className ? className : "Popup"}>
      <Title
        title={title}
        upperMenus={[
          title,
          layerType === "pause"
            ? "일괄정지"
            :layerType === "deposit"
            ? "일괄예치"
            : layerType === "individual"
            ? "개별정지"
            : "송금요청서",
        ]}
      />
      {determineView()}
    </div>
  );
};

PauseReqListPopupWrapper.propTypes = {};

export default PauseReqListPopupWrapper;
