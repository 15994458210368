import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Table.css";

const Table = (props) => {
  const { columns, widths, datas, onDoubleClick, tbodyClass } = props;
  return (
    <article className="grid_data">
      <div className="thead">
        <table>
          <colgroup>
            {widths.map((i, key) => {
              return <col width={i} key={key} />;
            })}
          </colgroup>
          <tbody>
            <tr>
              {columns.map((i, key) => {
                return <th key={key}>{i}</th>;
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className={"tbody " + tbodyClass}>
        <table>
          <colgroup>
            {widths.map((i, key) => {
              return <col width={i} key={key} />;
            })}
          </colgroup>
          <tbody>
            {!datas || datas.length === 0 ? (
              <tr>
                <td className="NoData" colSpan={columns.length}>
                  조회 결과가 없습니다.
                </td>
              </tr>
            ) : (
              datas.map((item, key) => {
                return (
                  <tr
                    key={key}
                    onClick={() =>
                      onDoubleClick ? onDoubleClick(key) : ""
                    }
                    className={onDoubleClick ? "hand" : ""}
                  >
                    {item}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </article>
  );
};

Table.propTypes = {};

export default Table;
