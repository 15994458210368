import React from "react";
import PropTypes from "prop-types";
import { Button } from "components";
import moment from "moment";

const CarInfoViewTable = (props) => {
  const { viewData, onClose, onEdit, formatter, cretFlag } = props;
  let data = [];
  if (viewData) {
    data = [
      {
        name: "차량구분",
        value: viewData.carDivNm,
      },
      {
        name: "영업소명",
        value: viewData.plcfbName ? viewData.plcfbName : "영업소 없음",
      },
      {
        name: "법인명",
        value: viewData.corpCdNm,
      },
      {
        name: "차량명",
        value: viewData.carName,
      },
      {
        name: "차량번호",
        value: viewData.carNo,
      },
      {
        name: "톤수",
        value: viewData.carTon + " 톤",
      },
      {
        name: "예금주",
        value: formatter(viewData.depositor, "name", true),
      },
      {
        name: "주민등록번호",
        value: formatter(viewData.custNo, "custNo"),
      },
      {
        name: "사업자번호",
        value: formatter(viewData.bizno, "bizno", true),
      },
      {
        name: "전화번호",
        value: formatter(viewData.cntcNo, "phone", true),
      },
      {
        name: "시작일자",
        value: formatter(viewData.startDate, "date"),
      },
      {
        name: "종료일자",
        value: formatter(viewData.finishDate, "date"),
      },
      {
        name: "계좌번호",
        value: viewData.acntBankName,
        value2: formatter(viewData.acntNo, "account", true),
      },
      {
        name: "운행여부",
        value: viewData.mvmnYn === "Y" ? "운행중" : "미운행",
        class: viewData.mvmnYn === "Y" ? "TextColorRed" : "",
      },
    ];
  }
  return (
    <>
      <article className="popup_grid">
        <table>
          <colgroup>
            <col width="200" />
            <col width="*" />
          </colgroup>
          <tbody>
            {data &&
              data.map((item, key) => {
                return (
                  <tr key={key}>
                    <th>{item.name}</th>
                    <td className={item.class ? item.class : ""}>
                      {item.value} {item.value2}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </article>
      <div className="PopupButton">
        {cretFlag && (
          <Button className="insert" onClick={onEdit}>
            수정
          </Button>
        )}
        <Button className="close" onClick={onClose}>
          닫기
        </Button>
      </div>
    </>
  );
};

CarInfoViewTable.propTypes = {};

export default CarInfoViewTable;
