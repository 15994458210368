import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, ConditionBox, DatePickerFieldYm } from "components";
import moment from "moment";

const SetlSumTxnCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            setlDate: moment().add(-1, "days"),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onClickSearch({
              setlDate: moment(values.setlDate).format("YYYYMM"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            setlDate: Yup.date().required().nullable(),
          })}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              발송월<i></i>
                              <span>
                                {errors.setlDate &&
                                  touched.setlDate &&
                                  errors.setlDate}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerFieldYm
                                  name="setlDate"
                                  date={values.setlDate}
                                />
                              </div>
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

SetlSumTxnCondition.propTypes = {};

export default SetlSumTxnCondition;
