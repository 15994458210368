import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withBase } from "components";
import Dot from "assets/images/icon_dot.png";

const API = {
  LIST: {
    url: "api/v1/calc/deliveryTxn/listfare",
    method: "post",
  },
};

const RptDatPrtTerminalFareTable = (props) => {
  const { param } = props;
  const [datas, setDatas] = useState([]);
  const styles = {
    fontSize: '15px'
  }

  useEffect(() => {
    if (param) list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    //console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      searching(false);
      return;
    }

    setDatas(response.trmLoadSumSheetList);
    searching(false);
  };

  const replaceStr = (str) => {
    // ie에서 replaceAll 오류로 for문으로 작성
    const replaceArr = ["&gt;", "&lt;", "&amp;", "&quot;"];
    const changeArr = [">", "<", "&", '"'];

    if (str) {
      for (let i = 0; replaceArr.length > i; i++) {
        for (let j = 0; str.length > j; j++) {
          if (str.indexOf(replaceArr[i]) === -1) break;
          str = str.replace(replaceArr[i], changeArr[i]);
        }
      }
    }

    return str;
  };

  return (
    <article className="grid_data second">
      <div className="TableArea">
        <div className="thead">
          <table>
            <colgroup>
              {["100%"].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <h3>
                    터미널 - 터미널 운임현황
                    <br />
                    터미널 ↔ 터미널&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {datas &&
                      datas[0] &&
                      props.formatter(datas[0].ttfare, "amount")}
                  </h3>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbody HasTH NoreSize">
          <table>
            <colgroup>
              {[
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
                "7%",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={16}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas.map((item, key) => {
                  return (
                    <tr key={key}>
                      <th>{replaceStr(item.trm1)}</th>
                      <td className="amount" style={styles}>
                        {props.formatter(item.fare1, "amount")}
                      </td>
                      <th>{replaceStr(item.trm2)}</th>
                      <td className="amount" style={styles}>
                        {props.formatter(item.fare2, "amount")}
                      </td>
                      <th>{replaceStr(item.trm3)}</th>
                      <td className="amount" style={styles}>
                        {props.formatter(item.fare3, "amount")}
                      </td>
                      <th>{replaceStr(item.trm4)}</th>
                      <td className="amount" style={styles}>
                        {props.formatter(item.fare4, "amount")}
                      </td>
                      <th>{replaceStr(item.trm5)}</th>
                      <td className="amount" style={styles}>
                        {props.formatter(item.fare5, "amount")}
                      </td>
                      <th>{replaceStr(item.trm6)}</th>
                      <td className="amount" style={styles}>
                        {item.trm6 ? props.formatter(item.fare6, "amount") : ""}
                      </td>
                      <th>{replaceStr(item.trm7)}</th>
                      <td className="amount" style={styles}>
                        {item.trm7 ? props.formatter(item.fare7, "amount") : ""}
                      </td>
                      <th>{replaceStr(item.trm8)}</th>
                      <td className="amount" style={styles}>
                        {item.trm8 ? props.formatter(item.fare8, "amount") : ""}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
      {datas && datas[0] && (
        <ul className="TotalInfo">
          <li>
            총운임 &nbsp;: <span>{props.formatter(datas[0].totfare, "amount")}</span>
          </li>
          <li>
            총건수 &nbsp;: <span>{props.formatter(datas[0].totcnt, "amount")}</span>
          </li>
        </ul>
      )}
    </article>
  );
};

RptDatPrtTerminalFareTable.propTypes = {};

export default withBase(RptDatPrtTerminalFareTable);
