import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, ConditionBox, CodeSelect, DatePickerField } from "components";
import moment from "moment";

const RptDatPrtTerminalConditionBox = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            transDate: moment().add(-1, "days"),
            phydsCorpDiv: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { transDate, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              transDate: moment(transDate).format("YYYYMMDD"),
              now: moment(),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            transDate: Yup.date().required().nullable(),
            phydsCorpDiv: Yup.string().required(),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              발송일<i></i>
                              <span>
                                {errors.transDate &&
                                  touched.transDate &&
                                  errors.transDate}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerField
                                  name="transDate"
                                  date={values.transDate}
                                />
                              </div>
                            </dd>
                          </dl>
                          <dl>
                            <dt>
                              법인<i></i>
                              <span>
                                {errors.phydsCorpDiv &&
                                  touched.phydsCorpDiv &&
                                  errors.phydsCorpDiv}
                              </span>
                            </dt>
                            <dd>
                              <CodeSelect
                                codeId="PHYDS_CORP_DIV"
                                name="phydsCorpDiv"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"법인 선택"}
                                value={values.phydsCorpDiv}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

RptDatPrtTerminalConditionBox.propTypes = {};

export default RptDatPrtTerminalConditionBox;
