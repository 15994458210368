import { createAction, handleActions } from "redux-actions";

// import { Map, fromJS } from "immutable";
import { fromJS } from "immutable";
// import { pender } from "redux-pender";
// import * as api from "../apis";

// action types
const INITIALIZE = "code/INITIALIZE";
const SAVE = "code/SAVE";
// const FIND_GROUP_CODE = "code/FIND_GROUP_CODE";
// const FIND_DETAIL_CODE = "code/FIND_DETAIL_CODE";

// action creators
export const initialize = createAction(INITIALIZE);
export const save = createAction(SAVE);
// export const findGroupCode = createAction(FIND_GROUP_CODE);
// export const findDetailCode = createAction(FIND_DETAIL_CODE);

// initial state
const initialState = fromJS({
  code: {
    // findGroupCode: (code, grpCd) => {
    //   return code.find((item) => {
    //     return item.cdGrpId === grpCd;
    //   });
    // },
    // findDetailCode: (code, grpCd, dtlCd) => {
    //   const groupCode = this.findGroupCode(code, grpCd);
    //   return groupCode.cdDtlInfoList.find((item) => {
    //     return item.cdDtlId === dtlCd;
    //   });
    // },
  },
});

// reducer
export default handleActions(
  {
    [INITIALIZE]: () => initialState,
    [SAVE]: (state, action) => {
      return state.set("code", action.payload);
    },
    // [FIND_GROUP_CODE]: (state, action) => {
    //   return state.code;
    // },
    // [FIND_DETAIL_CODE]: (state, action) => {
    //   return state.code;
    // },
  },
  initialState
);
