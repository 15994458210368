import React from "react";
import PropTypes from "prop-types";
import "./Select.css";

const Select = (props) => {
  const { items, date, onChange, name, placeholder } = props;
  const className = date ? "select date" : "select";

  return (
    <select
      name={name}
      className={className}
      onChange={(e) => {
        let item;
        if (e && e.target.value) {
          item = items[e.target.value];
        }
        onChange(e, item);
      }}
    >
      {placeholder && (
        <option value={""} key={-1}>
          {placeholder}
        </option>
      )}
      {items &&
        items.map((item, key) => {
          return (
            <option value={key} key={key}>
              {item.name}
            </option>
          );
        })}
    </select>
  );
};

Select.propTypes = {
  items: PropTypes.array.isRequired,
  date: PropTypes.bool,
};

export default Select;
