import React from "react";
import PropTypes from "prop-types";
import "./PauseAdmListPopupIndividual.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  DatePickerField,
  CustomInput,
  FormatInput,
  CodeSelect,
} from "components";
import { connect } from "react-redux";
import { findDetailName } from "variables/common";
import moment from "moment";

const PauseAdmListPopupIndividual = (props) => {
  const {
    code,
    layerType,
    viewData,
    onClose,
    onSave,
    formatter,
    cretFlag,
  } = props;

  return (
    <Formik
      initialValues={{
        otpayDate2: viewData ? moment(viewData.otpayDate2) : moment(),
        bankCd2: viewData
          ? viewData.bankName2
            ? findDetailName(code, "ACNT_BANK_CD", viewData.bankName2).code
            : ""
          : "",
        bankName2: viewData ? viewData.bankName2 : "",
        acntNo2: viewData ? formatter(viewData.acntNo2, "account") : "",
        dposr2: viewData ? viewData.dposr2 : "",
        remarks2: viewData ? viewData.remarks2 : "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        let data = {
          ...viewData,
          otpayDate2: moment(values.otpayDate2).format("YYYYMMDD"),
          bankName2: values.bankName2,
          acntNo2: formatter(values.acntNo2, "toValue"),
          dposr2: values.dposr2,
          remarks2: values.remarks2,
        };
        let type =
          viewData.otpaySttus === "11" ||
          viewData.otpaySttus === "12" ||
          viewData.otpaySttus === "13"
            ? "update"
            : layerType;
        onSave(type, data);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        otpayDate2: Yup.date().required().nullable(),
        bankCd2: Yup.string().required(),
        acntNo2: Yup.string().required(),
        dposr2: Yup.string().required().max(20),
        remarks2: Yup.string().required().max(50),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <Form>
            <div className="PauseAdmListPopupIndividual">
              <h5>필수 입력</h5>
              <article className="popup_grid first">
                <div className="first">
                  <table>
                    <colgroup>
                      <col width="12.4%" />
                      <col width="12.7%" />
                      <col width="11.2%" />
                      <col width="11.3%" />
                      <col width="10.5%" />
                      <col width="15.1%" />
                      <col width="13.9%" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>발송일</th>
                        <th>영업소명</th>
                        <th>수수료</th>
                        <th>차량번호</th>
                        <th>예금주명</th>
                        <th>계좌번호</th>
                        <th>통장기재</th>
                        <th>구분</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{formatter(viewData.dspDate, "date")}</td>
                        <td>{viewData.plcfbName}</td>
                        <td className="amount">
                          {formatter(viewData.fee, "amount")}
                        </td>
                        <td>{viewData.carNo}</td>
                        <td>{formatter(viewData.dposr, "name", false)}</td>
                        <td>{formatter(viewData.acntNo, "account", false)}</td>
                        <td>{viewData.bnkbkSttm}</td>
                        <td>{viewData.degreeDiv}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="second">
                  <table>
                    <colgroup>
                      <col width="22.6%" />
                      <col width="14.7%" />
                      <col width="15.3%" />
                      <col width="11.5%" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          송금일자<i></i>
                          <span>
                            {errors.otpayDate2 &&
                              touched.otpayDate2 &&
                              errors.otpayDate2}
                          </span>
                        </th>
                        <th>
                          입금은행<i></i>
                          <span>
                            {errors.bankCd2 &&
                              touched.bankCd2 &&
                              errors.bankCd2}
                          </span>
                        </th>
                        <th>
                          입금계좌<i></i>
                          <span>
                            {errors.acntNo2 &&
                              touched.acntNo2 &&
                              errors.acntNo2}
                          </span>
                        </th>
                        <th>
                          입금예금주<i></i>
                          <span>
                            {errors.dposr2 && touched.dposr2 && errors.dposr2}
                          </span>
                        </th>
                        <th>
                          사유기재<i></i>
                          <span>
                            {errors.remarks2 &&
                              touched.remarks2 &&
                              errors.remarks2}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cretFlag ? (
                        <>
                          <tr>
                            <td>
                              {viewData && viewData.otpayDate2 ? (
                                formatter(values.otpayDate2, "date")
                              ) : (
                                <DatePickerField
                                  name="otpayDate2"
                                  date={values.otpayDate2}
                                />
                              )}
                            </td>
                            <td>
                              <CodeSelect
                                codeId="ACNT_BANK_CD"
                                name="bankCd2"
                                onChange={(e, item) => {
                                  item
                                    ? (values.bankName2 = item.name)
                                    : (values.bankName2 = "");
                                  handleChange(e);
                                }}
                                placeholder={"은행 선택"}
                                value={values.bankCd2}
                              />
                            </td>
                            <td>
                              <FormatInput
                                formatType="account"
                                name="acntNo2"
                                placeholder="입금계좌 입력"
                                onChange={handleChange}
                                value={values.acntNo2}
                              />
                            </td>
                            <td>
                              <CustomInput
                                name="dposr2"
                                type="text"
                                placeholder="입금예금주 입력"
                                onChange={handleChange}
                                value={values.dposr2}
                              />
                            </td>
                            <td>
                              <CustomInput
                                name="remarks2"
                                type="text"
                                placeholder="사유 입력"
                                onChange={handleChange}
                                value={values.remarks2}
                              />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <td>{formatter(viewData.otpayDate2, "date")}</td>
                            <td>{viewData.bankName2}</td>
                            <td>
                              {formatter(viewData.acntNo2, "account", false)}
                            </td>
                            <td>{formatter(viewData.dposr2, "name", false)}</td>
                            <td className="tal">{viewData.remarks2}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </article>
              <div className="PopupButton">
                {cretFlag && (
                  <Button
                    type="submit"
                    className="insert"
                    disabled={isSubmitting}
                  >
                    저장
                  </Button>
                )}
                {viewData.otpaySttus === "11" ||
                viewData.otpaySttus === "12" ||
                viewData.otpaySttus === "13" ? (
                  cretFlag && (
                    <Button
                      className="delete"
                      onClick={() => onSave("delete", viewData)}
                    >
                      삭제
                    </Button>
                  )
                ) : (
                  <></>
                )}
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

PauseAdmListPopupIndividual.propTypes = {};

export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({}),
)(PauseAdmListPopupIndividual);
