import React from "react";
import PropTypes from "prop-types";
import "./Title.css";

const Title = (props) => {
  const { title, children, upperMenus } = props;
  return (
    title && (
      <article className="title">
        <h3 className="page_tit">
          {title}
          {upperMenus &&
            upperMenus.map((item, key) => {
              const className = key === 0 ? "dep1" : "dep2";
              return (
                <span className={className} key={key}>
                  {item}
                </span>
              );
            })}
        </h3>
        {children && <div className="add_content">{children}</div>}
      </article>
    )
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
  upperMenus: PropTypes.array,
};

export default Title;
