import React from "react";
import "./Dashboard.css";
import PmonRgnChrt from "./PmonRgnChrt";
import MbySetlSumChrt from "./MbySetlSumChrt";
import TdayTerminalChrt from "./TdayTerminalChrt";

function Dashboard() {
  return (
    <div className="container">
      <MbySetlSumChrt title={"월별 정산 집계(총운임)"} />
      <TdayTerminalChrt />
      <PmonRgnChrt />
    </div>
  );
}

export default Dashboard;
