import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Menu.css";

const navIconPrefix = "images/";

const MenuItem = React.memo((props) => {
  const { menuItem, index, clicked, onClick } = props;
  const on = `${navIconPrefix}${menuItem.id}_on.png`;
  const off = `${navIconPrefix}${menuItem.id}_off.png`;
  // console.assert(menuItem.path, "menusItem path is null : " + menuItem.name);
  return (
    <li
      onClick={() => {
        onClick(index, menuItem);
      }}
    >
      <Link to={menuItem.path} className={clicked ? "on" : ""} replace>
        <img src={on} className="on" alt={"menu" + index} />
        <img src={off} className="off" alt={"menu" + index} />
        {menuItem.name}
      </Link>
    </li>
  );
});

const Menu = (props) => {
  const { menu, onChange } = props;
  const [clickIndex, setClickIndex] = useState(0);
  const oneLevelMenu = menu.views;
  const handleMenuItemClick = useCallback((index, item) => {
    setClickIndex(index);
    onChange(menu.views[index]);
  }, []);
  useEffect(() => {
    onChange(menu.views[0]);
  }, []);
  return (
    <nav className="Nav">
      <ul>
        {oneLevelMenu &&
          oneLevelMenu.map((menuItem, index) => {
            return (
              <MenuItem
                menuItem={menuItem}
                key={index}
                index={index}
                onClick={handleMenuItemClick}
                clicked={clickIndex === index ? true : false}
              />
            );
          })}
      </ul>
    </nav>
  );
};

Menu.propTypes = {};

export default Menu;
