import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  ConditionBox,
  CustomInput,
  DatePickerField,
  CacheAutocomplete,
  CacheSelect,
  CodeSelect,
  FormatInput,
} from "components";
import moment from "moment";

const FareFeeTxnConditionBox = (props) => {
  const { onClickSearch, formatter } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            //regDate: moment(),
            pauseName: "",
            carNo: "",
            dposr: "",
            corpName: "",
            otpaySttus: "",
            setlItem: "",
            fee: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { regDate, fee, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              //regDate: moment(regDate).format("YYYYMMDD"),
              fee: formatter(fee, "toValue"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            //regDate: Yup.date().required().nullable(),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          {/* <dl>
                            <dt>
                              정지일
                              <span>
                                {errors.regDate &&
                                  touched.regDate &&
                                  errors.regDate}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerField
                                  name="regDate"
                                  date={values.regDate}
                                />
                              </div>
                            </dd>
                          </dl> */}
                          <dl>
                            <dt>영업소</dt>
                            <dd>
                              <CacheAutocomplete
                                cache="plcfbInfo"
                                onMap={(item, key) => {
                                  return {
                                    code: item.plcfbCd,
                                    name: item.plcfbName,
                                  };
                                }}
                                name="pauseName"
                                value={values.pauseName}
                                setFieldValue={setFieldValue}
                                placeholder="영업소/노선명 입력"
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>법인명</dt>
                            <dd>
                              <CacheSelect
                                cache="corpInfo"
                                onMap={(item, key) => {
                                  return {
                                    code: item.corpName,
                                    name: item.corpName,
                                  };
                                }}
                                name="corpName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="법인명 선택"
                                value={values.corpName}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>처리결과</dt>
                            <dd>
                              <CodeSelect
                                codeId="OTPAY_STTUS_REMOVE"
                                name="status"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"처리결과 선택"}
                                value={values.status}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

FareFeeTxnConditionBox.propTypes = {};

export default FareFeeTxnConditionBox;
