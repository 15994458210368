import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CodeSelect, Button, ConditionBox } from "components";

const FeeInfoBasicCondition = (props) => {
  const { phydsCorpDiv } = props.match.params;
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            phydsCorpDiv: phydsCorpDiv,
            routeDiv: "",
            sbrLgubun: "",
            ebrLgubun: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            console.log(values);
            onClickSearch(values);
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            phydsCorpDiv: Yup.string().required(),
            routeDiv: Yup.string().required(),
            // sbrLgubun: Yup.string().required(),
            // ebrLgubun: Yup.string().required(),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              물류법인<i></i>
                              <span>
                                {errors.phydsCorpDiv &&
                                  touched.phydsCorpDiv &&
                                  errors.phydsCorpDiv}
                              </span>
                            </dt>
                            <dd>
                              <CodeSelect
                                codeId="PHYDS_CORP_DIV"
                                name="phydsCorpDiv"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"법인 선택"}
                                value={values.phydsCorpDiv}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>
                              노선 1구분<i></i>
                              <span>
                                {errors.routeDiv &&
                                  touched.routeDiv &&
                                  errors.routeDiv}
                              </span>
                            </dt>
                            <dd>
                              <CodeSelect
                                codeId="ROUTE_DIV"
                                name="routeDiv"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"노선1구분 선택"}
                                value={values.routeDiv}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>발송지구역</dt>
                            <dd>
                              <CodeSelect
                                codeId="SBR_LGUBUN"
                                name="sbrLgubun"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"발송지구역 선택"}
                                value={values.sbrLgubun}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>도착지구역</dt>
                            <dd>
                              <CodeSelect
                                codeId="EBR_LGUBUN"
                                name="ebrLgubun"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"도착지구역 선택"}
                                value={values.ebrLgubun}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

FeeInfoBasicCondition.propTypes = {};

export default FeeInfoBasicCondition;
