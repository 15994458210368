import React, { useState } from "react";
import PropTypes from "prop-types";
import "./RmtFeeSmryCorp.css";
import { CustomTab } from "components";
import RmtFeeSmryCorpList from "./RmtFeeSmryCorpList";
import RmtFeeSmryCorpDetails from "./RmtFeeSmryCorpDetails";

const RmtFeeSmryCorp = (props) => {
  return (
    <div className="RmtFeeSmryCorp">
      <CustomTab
        tabs={[
          {
            name: "법인별운송수수료집계표",
            component: <RmtFeeSmryCorpList />,
          },
          {
            name: "상세보기",
            component: <RmtFeeSmryCorpDetails />,
          },
        ]}
      />
    </div>
  );
};

RmtFeeSmryCorp.propTypes = {};

export default RmtFeeSmryCorp;
