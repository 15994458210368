import React from "react";
import PropTypes from "prop-types";
import "./PlcfbListEnterPopup.css";
import { withBase, Button } from "components";

const PlcfbListEnterPopup = (props) => {
  const { viewData, onClose, formatter } = props;

  return (
    <div className="PlcfbListEnterPopup">
      <article className="popup_grid">
        <table className="ScrollNone HasTH">
          <colgroup>
            <col width="7%" />
            <col width="*" />
            <col width="6%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
          </colgroup>
          <thead>
            <tr>
              <th>일자</th>
              <th>영업소명</th>
              <th>소장명</th>
              <th>영업소정산금액</th>
              <th>영업소수수료</th>
              <th>노선차량수수료</th>
              <th>본사입금액</th>
              <th>본사출금액</th>
              <th>개별출금액</th>
              <th>기타출금액</th>
              <th>미수잔액</th>
              <th>한도금액</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            {viewData &&
              viewData.map((item, key) => {
                return (
                  <>
                    <tr key={key}>
                      <th>
                        {item.setlDate === "합계"
                          ? item.setlDate
                          : props.formatter(item.setlDate, "date")}
                      </th>
                      <td className="tal">{item.plcfbName}</td>
                      <td className="tal">
                        {props.formatter(item.managerName, "name", false)}
                      </td>
                      <td className="amount">
                        {props.formatter(item.dlvrPayment, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.mktFee, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.routecarFee, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.hoffDspAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.hoffOtpayAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.hoffOtpayAmt2, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.hoffOtpayAmt3, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.natmpBlnc, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.limitAmt, "amount")}
                      </td>
                      <td className="tal">{item.remarks}</td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </article>
      <div className="PopupButton">
        <Button className="close" onClick={onClose}>
          닫기
        </Button>
      </div>
    </div>
  );
};

PlcfbListEnterPopup.propTypes = {};

export default withBase(PlcfbListEnterPopup);
