import React from "react";
import PropTypes from "prop-types";
import "./AddressSearch.css";
import PopupClose from "assets/images/popup_close.png";

const AddressSearch = (props) => {
  const { layerType, children, onClose } = props;

  return (
    <div
      className="AddressSearch"
      style={{ display: layerType ? "block" : "none" }}
    >
      <div className="SearchArea">
        <div className="PopupClose">
          <img src={PopupClose} onClick={onClose} alt="close" />
        </div>
        <h3 className="page_tit">주소 검색</h3>
        <h5>도로명, 건물명 또는 지번 주소 중 하나를 검색해주세요.</h5>
        {children}
      </div>
      <div className="PopupBG"></div>
    </div>
  );
};

AddressSearch.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AddressSearch;
