import { createAction, handleActions } from "redux-actions";

// import { Map, fromJS } from "immutable";
import { fromJS } from "immutable";
// import { pender } from "redux-pender";
// import * as api from "../apis";

// action types
const INITIALIZE = "user/INITIALIZE";
const LOGIN = "user/LOGIN";
const LOGOUT = "user/LOGOUT";
const CRETFLAG = "user/cretFlag";

// action creators
export const initialize = createAction(INITIALIZE);
export const login = createAction(LOGIN);
export const logout = createAction(LOGOUT);
export const cretFlag = createAction(CRETFLAG);

// initial state
const initialState = fromJS({
  userId: "",
  userNm: "",
  deptCd: "",
  deptNm: "",
  cmpnCd: "",  
  auths: [],
  login: false,
  cretFlag: false,
});

// reducer
export default handleActions(
  {
    [INITIALIZE]: () => initialState,
    [LOGIN]: (state, action) => {
      const { userId, userNm, deptCd, deptNm, cmpnCd, authorities } = action.payload;
      let auths = [];
      if (authorities) {
        auths = authorities.map((i) => i.authority);
      }
      return state
        .set("userId", userId)
        .set("userNm", userNm)
        .set("deptCd", deptCd)
        .set("deptNm", deptNm)
        .set("cmpnCd", cmpnCd)        
        .set("auths", auths)
        .set("login", true)
        .set("cretFlag", false);
    },
    [CRETFLAG]: (state, action) => {
      const { cretFlag } = action.payload;
      return state.set("cretFlag", cretFlag);
    },
    [LOGOUT]: () => initialState,
  },
  initialState
);
