import defaultAxios from "axios";
import { useState, useEffect } from "react";
import { properties } from "variables/properties";

export const useAxios = (
  initialOpts,
  initialFetch = 0,
  axiosInstance = defaultAxios
) => {
  const [opts, setOpts] = useState({
    ...initialOpts,
    url:
      properties && properties.contextRoot
        ? `${properties.contextRoot}/${initialOpts.url}`
        : initialOpts.url,
  });
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null,
  });
  const [trigger, setTrigger] = useState(initialFetch);
  const refetch = (data = undefined) => {
    if (data) {
      setOpts({
        ...opts,
        data,
      });
    }
    setState({
      ...state,
      loading: true,
    });
    setTrigger(Date.now());
  };
  useEffect(() => {
    if (trigger === 0 || !opts.url) {
      return;
    }
    axiosInstance(opts)
      .then((data) => {
        setState({
          ...state,
          loading: false,
          data,
        });
      })
      .catch((error) => {
        setState({ ...state, loading: false, error });
      });
  }, [trigger]);
  return { ...state, refetch };
};
