import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Button } from "components";

const NotFound = (props) => {
  const { location, history } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%",
        flexDirection: "column",
      }}
    >
      <div>
        <Typography variant="h4" display="block" gutterBottom>
          404 NotFound
        </Typography>
      </div>
      <div>
        <Typography variant="body1" display="block" gutterBottom>
          {location && location.state && location.state.detail}
        </Typography>
      </div>
      <div>
        <Button onClick={() => {
          history.push("/");
        }}>Go Main</Button>
      </div>
    </div>
  );
};

NotFound.propTypes = {};

export default NotFound;
