import React from "react";
import { Bar } from "react-chartjs-2";

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    mode: "label",
    callbacks: {
      label: function (tooltipItem, data) {
        var value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        if (parseInt(value) >= 1000) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            " : " +
            value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        } else {
          return data.datasets[tooltipItem.datasetIndex].label + " : " + value;
        }
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback(value) {
            return Number(value).toLocaleString("ko-KR");
          },
        },
      },
    ],
  },
};

export default function TrmQtyChrt(props) {
  const { chrtData } = props;
  const chart = React.useRef();

  const data = {
    labels: chrtData.labels,
    datasets: [
      {
        label: "경동",
        fill: false,
        backgroundColor: "#3E64F4",
        borderColor: "#3E64F4",
        hoverBackgroundColor: "#3E64F4",
        hoverBorderColor: "#3E64F4",
        data: chrtData.kdCnt,
      },
      {
        label: "합동",
        fill: false,
        borderColor: "#FBBA0A",
        backgroundColor: "#FBBA0A",
        hoverBackgroundColor: "#FBBA0A",
        hoverBorderColor: "#FBBA0A",
        data: chrtData.hdCnt,
      },
    ],
  };

  return (
    <div className="chart">
      <Bar ref={chart} data={data} options={options} />
    </div>
  );
}
