import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  ConditionBox,
  DatePickerFieldYm,
  CodeSelect,
  FormatInput,
} from "components";
import moment from "moment";

const SmsTrmHstCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            trmDate: moment().add(-1, "days"),
            trmResult: "",
            rcvTelNo: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { trmDate, rcvTelNo, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              trmDate: moment(trmDate).format("YYYYMM"),
              rcvTelNo: props.formatter(rcvTelNo, "toValue"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            trmDate: Yup.date().required().nullable(),
          })}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              전송월<i></i>
                              <span>
                                {errors.trmDate &&
                                  touched.trmDate &&
                                  errors.trmDate}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerFieldYm
                                  name="trmDate"
                                  date={values.trmDate}
                                />
                              </div>
                            </dd>
                          </dl>
                          <dl>
                            <dt>전송결과</dt>
                            <dd>
                              <CodeSelect
                                codeId="TRM_RESULT"
                                name="trmResult"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"구분 선택"}
                                value={values.trmResult}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>전화번호</dt>
                            <dd>
                              <FormatInput
                                formatType="phone"
                                name="rcvTelNo"
                                type="text"
                                placeholder="전화번호 입력"
                                onChange={handleChange}
                                value={values.rcvTelNo}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

SmsTrmHstCondition.propTypes = {};

export default SmsTrmHstCondition;
