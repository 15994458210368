import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./AcruDclaTxn.css";
import { withBase, Button, ControlBox, Table, Pagination } from "components";
import moment from "moment";
import AcruDclaTxnConditionBox from "./AcruDclaTxnConditionBox";

const API = {
  LIST: {
    url: "api/v1/report/acruDclaTxn/list",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/acruDclaTxn/down",
    method: "post",
  },
};

const AcruDclaTxn = (props) => {
  const [param, setParam] = useState();
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (param) list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.acruDclaTxnList);
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="AcruDclaTxn">
      <AcruDclaTxnConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <article className="grid_data">
        <div className="TableArea">
          <Table
            widths={[
              "10%",
              "10.2%",
              "9.8%",
              "9%",
              "10%",
              "10%",
              "10%",
              "10%",
              "11.5%",
              "*",
            ]}
            columns={[
              "계약년월",
              "차량번호",
              "운송년월",
              "배차횟수",
              "운송료",
              "사업자번호",
              "위탁년월",
              "위탁금액",
              "영업소명",
              "구분",
            ]}
            datas={
              datas &&
              datas.map((item, key) => {
                return (
                  <>
                    <th>{props.formatter(item.contYm, "dateYm")}</th>
                    <td>{item.carNo}</td>
                    <td>{props.formatter(item.carryYm, "dateYm")}</td>
                    <td className="amount">
                      {props.formatter(item.dsptTmscnt, "amount")}
                    </td>
                    <td className="amount">
                      {props.formatter(item.carryChage, "amount")}
                    </td>
                    <td>{props.formatter(item.bizno, "bizno", false)}</td>
                    <td>{props.formatter(item.csgnYm, "dateYm")}</td>
                    <td className="amount">
                      {props.formatter(item.csgnAmt, "amount")}
                    </td>
                    <td className="tal">{item.plcfbName}</td>
                    <td>{item.carDivName}</td>
                  </>
                );
              })
            }
            tbodyClass="HasTH"
          />
        </div>
      </article>

      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
    </div>
  );
};

AcruDclaTxn.propTypes = {};

export default withBase(AcruDclaTxn);
