import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

const data = {
  labels: [
    "10월",
    "11월",
    "12월",
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
  ],
  datasets: [
    {
      label: "총액 (영업소입금액+미수금+기타)",
      fill: false,
      borderColor: "rgba(62,100,244)",
      backgroundColor: "rgba(62,100,244)",
      hoverBackgroundColor: "rgba(62,100,244)",
      hoverBorderColor: "rgba(62,100,244)",
      data: [65, 60, 80, 81, 56, 55, 40, 50, 40, 50, 40, 30, 20],
    },
    {
      label: "영업소입금액",
      fill: false,
      borderColor: "rgba(251,186,10)",
      backgroundColor: "rgba(251,186,10)",
      hoverBackgroundColor: "rgba(251,186,10)",
      hoverBorderColor: "rgba(251,186,10)",
      data: [65, 60, 80, 81, 56, 55, 40, 50, 40, 50, 40, 30, 20],
    },
  ],
};

const options = {
  legend: {
    position: "bottom",
    display: true,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 1000,
        },
      },
    ],
  },
};

const plugins = [
  {
    afterDraw: (chartInstance, easing) => {
      const datasets = chartInstance.chart.config.data.datasets;

      if (datasets) {
        const ctx = chartInstance.chart.ctx;
        ctx.font = "9px Verdana";
        ctx.fillStyle = "#333";
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";

        datasets.forEach(function (dataset) {
          console.log(dataset);
          const dataArray = dataset.data;
          dataset._meta[0].data.forEach(function (bar, index) {
            ctx.fillText(dataArray[index], bar._view.x, bar._view.y);
          });
        });
      }
    },
  },
];

export default function MbySetlSumChrt(props) {
  const { title } = props;
  const chart = React.useRef();
  return (
    <div className="chart">
      <h3>{title}</h3>
      <Bar ref={chart} data={data} options={options} plugins={plugins} />
    </div>
  );
}
