import React from "react";
import PropTypes from "prop-types";
import "./ControlBox.css";

const ControlBox = (props) => {
  const {InputTitle, left, leftbtn, children } = props;
  return (
    <div className="controls">
      <div className="left">
        {InputTitle}
        {left}
        {leftbtn}
      </div>
      <div className="right">{children}</div>
    </div>
  );
};

ControlBox.propTypes = {
  children: PropTypes.node,
  left: PropTypes.object,
};

export default ControlBox;
