import React from "react";
import PropTypes from "prop-types";
import "./PauseReqListPopupDown.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Select } from "components";

const PauseReqListPopupDown = (props) => {
  const { dateItem, onClose, onDown } = props;

  return (
    <div className="PauseReqListPopupDown">
      <Formik
        initialValues={{
          dspDate: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onDown(values);
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          dspDate: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <Form>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="50%" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        날자<i></i>
                        <span>
                          {errors.dspDate && touched.dspDate && errors.dspDate}
                        </span>
                      </th>
                      <th>다운로드</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          items={dateItem}
                          name="dspDate"
                          onChange={(e, item) => (values.dspDate = item.code)}
                          value={values.dspDate}
                          placeholder="날자 선택"
                        />
                      </td>
                      <td>
                        <Button
                          type="submit"
                          className="withdrawl_rquest"
                          disabled={isSubmitting}
                        >
                          송금요청서
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="PopupButton">
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

PauseReqListPopupDown.propTypes = {};

export default PauseReqListPopupDown;
