import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./IndvSmsMobile.css";
import { withBase } from "components";
import mLogo from "assets/images/m_logo.png";

const API = {
  LIST: {
    url: "api/v1/sms/smsTrmTxn/listcar",
    method: "post",
  },
};

const IndvSmsMobile = (props) => {
  const { setlDate, plcfbCd } = props.match.params;
  const [datas, setDatas] = useState([]);
  const [plcfbName, setPlcfbName] = useState("");
  const [dposr, setDposr] = useState("");
  const [sumFee, setSumFee] = useState(0);

  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;

    const response = await HttpRequest.request(url, method, {
      setlDate: setlDate,
      plcfbCd: plcfbCd,
    });
    if (HttpRequest.hasError(response, true)) {
      setPlcfbName("");
      setDposr("");
      setSumFee(0);
      setDatas([]);
      searching(false);
      return;
    }
    setDatas(response.smsTrmTxnList);

    if (!response.smsTrmTxnList || response.smsTrmTxnList.length === 0) {
      setPlcfbName("");
      setDposr("");
      setSumFee(0);
    } else {
      setPlcfbName(response.smsTrmTxnList[0].plcfbName);
      setDposr(response.smsTrmTxnList[0].dposr);
      setSumFee(response.smsTrmTxnList[0].sumFee);
    }
    searching(false);
  };

  return (
    <div className="IndvSmsMobile">
      <div className="MobileForm">
        <div className="mHeader">
          <img src={mLogo} />
        </div>
        <div className="mContents">
          <h2>
            <span>{dposr}</span> 님의
            <br />
            <span>차량 수수료 현황</span>입니다.
          </h2>
          <div className="info">
            <dl>
              <dt>차량명</dt>
              <dd>{plcfbName}</dd>
            </dl>
            <dl>
              <dt>총 수수료</dt>
              <dd>{props.formatter(sumFee, "amount")}</dd>
            </dl>
          </div>
          <div className="IndvSmsMobileTable">
            <article className="grid_data">
              <div className="thead">
                <table>
                  <colgroup>
                    {["50%", "50%"].map((i, key) => {
                      return <col width={i} key={key} />;
                    })}
                  </colgroup>
                  <tbody>
                    <tr>
                      {["날짜", "수수료"].map((i, key) => {
                        return <th key={key}>{i}</th>;
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="tbody ScrollNone ">
                <table>
                  <colgroup>
                    {["50%", "50%"].map((i, key) => {
                      return <col width={i} key={key} />;
                    })}
                  </colgroup>
                  <tbody>
                    {!datas || datas.length === 0 ? (
                      <tr>
                        <td className="NoData" colSpan={2}>
                          조회 결과가 없습니다.
                        </td>
                      </tr>
                    ) : (
                      datas.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{props.formatter(item.setlDate, "date")}</td>
                            <td className="amount">
                              {props.formatter(item.fee, "amount")}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

IndvSmsMobile.propTypes = {};

export default withBase(IndvSmsMobile);
