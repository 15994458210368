import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./CorpInfo.css";
import { connect } from "react-redux";
import {
  withBase,
  ControlBox,
  CodeSelect,
  Button,
  Table,
  Pagination,
  LayerPopup,
  PopupWrapper,
} from "components";
import { useSelector } from "react-redux";
import CorpInfoViewTable from "./CorpInfoViewTable";
import CorpInfoEditTable from "./CorpInfoEditTable";

const API = {
  LIST: {
    url: "api/v1/comn/corpInfo/list",
    method: "post",
  },
  READ: {
    url: "api/v1/comn/corpInfo/read",
    method: "post",
  },
  CREATE: {
    url: "api/v1/comn/corpInfo/create",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/comn/corpInfo/update",
    method: "post",
  },
  DOWN: {
    url: "api/v1/comn/corpInfo/down",
    method: "post",
  },
};

const CorpInfo = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({ pageNo: 1 });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.corpInfoList);
    searching(false);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    return true;
  };

  const handleChangeSearch = (corpDiv) => {
    setParam({
      corpDiv: corpDiv,
      pageNo: 1,
    });
  };

  const handleDoubleClick = (index) => {
    setLayerType("view");
    setViewData(datas[index]);
  };
  const handleClickNew = () => {
    setLayerType("new");
  };
  const handleClickEdit = () => {
    setLayerType("edit");
  };
  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };

  const handleClickSave = async (layerType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "new":
        ret = await edit(API.CREATE, data);
        msg = "신규등록되었습니다.";
        break;
      case "edit":
        ret = await edit(API.UPDATE, data);
        msg = "수정저장되었습니다.";
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="CorpInfo">
      <ControlBox
        InputTitle={<h6>법인구분</h6>}
        left={
          <CodeSelect
            codeId="CORP_DIV"
            name="corpDiv"
            placeholder={"법인구분 선택"}
            onChange={(e) => {
              handleChangeSearch(e.target.value);
            }}
          />
        }
      >
        {cretFlag && (
          <Button className="insert" onClick={handleClickNew}>
            신규등록
          </Button>
        )}
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <article className="grid_data">
        <div className="TableArea">
          <Table
            widths={["5%", "*%", "8%", "8%", "10%", "15%", "15%", "8%", "8%"]}
            columns={[
              "순번",
              "법인명",
              "법인구분",
              "계좌은행",
              "계좌번호",
              "기초잔액",
              "사업자번호",
              "지입차량여부",
              "영업여부",
            ]}
            datas={
              datas &&
              datas.map((item, key) => {
                return (
                  <>
                    <td>{item.rowNum}</td>
                    <td className="tal">{item.corpName}</td>
                    <td>{item.corpDivNm}</td>
                    <td>{item.acntBankName}</td>
                    <td>{props.formatter(item.acntNo, "account", false)}</td>
                    <td className="amount">
                      {props.formatter(item.firstBlnc, "amount")}
                    </td>
                    <td>{props.formatter(item.bizno, "bizno", false)}</td>
                    <td>{item.ladvhFlag === 1 ? "지입차량" : ""}</td>
                    {item.mktYn === "Y" ? (
                      <td className="TextColorRed">영업중</td>
                    ) : (
                      <td>영업중지</td>
                    )}
                  </>
                );
              })
            }
            onDoubleClick={handleDoubleClick}
          />
        </div>
      </article>
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"법인관리"}
          viewTable={
            <CorpInfoViewTable
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onEdit={handleClickEdit}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          editTable={
            <CorpInfoEditTable
              viewData={viewData}
              layerType={layerType}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

CorpInfo.propTypes = {};

export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({}),
)(withBase(CorpInfo));
