import React from "react";
import { Route } from "react-router-dom";
import RoleMgt from "./RoleMgt";
import RoleMgtEdit from "./RoleMgtEdit";

const RoleMgtRoute = () => {
  return (
    <>
      <Route exact={true} path="/comn/roleMgt" component={RoleMgt} />
      <Route
        exact={true}
        path="/comn/roleMgt/:roleId"
        component={RoleMgtEdit}
      />
      <Route
        exact={true}
        path="/comn/roleMgt/:roleId/:roleName"
        component={RoleMgtEdit}
      />
    </>
  );
};

export default RoleMgtRoute;
