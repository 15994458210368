import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AddressSearch from "./AddressSearch";
import {
  Pagination,
  Button,
  CustomInput,
  useAxios,
  useInput,
} from "components";
import DelIcon from "assets/images/icon_del.png";

//sql 예약어
const sqlArray = new Array(
  "OR",
  "SELECT",
  "INSERT",
  "DELETE",
  "UPDATE",
  "CREATE",
  "DROP",
  "EXEC",
  "UNION",
  "FETCH",
  "DECLARE",
  "TRUNCATE"
);

const checkKeyword = (keyword) => {
  if (keyword.length > 0) {
    //특수문자 제거
    const expText = /[%=><]/;
    if (expText.test(keyword.value)) {
      alert("특수문자를 입력 할수 없습니다.");
      keyword = keyword.split(expText).join("");
      return false;
    }

    //특정문자열(sql예약어의 앞뒤공백포함) 제거
    let regex;
    for (var i = 0; i < sqlArray.length; i++) {
      regex = new RegExp(sqlArray[i], "gi");

      if (regex.test(keyword)) {
        alert(
          '"' + sqlArray[i] + '"와(과) 같은 특정문자로 검색할 수 없습니다.'
        );
        keyword = keyword.replace(regex, "");
        return false;
      }
    }
  }
  return true;
};

const API = {
  LIST: {
    url: "api/v1/comn/addr/list",
    method: "post",
  },
};

const countPerPage = 5;

const Address = (props) => {
  const { open, handleOpen, setAddress } = props;
  const { url, method } = API.LIST;
  const { value, onChange } = useInput();
  const input = useRef();
  const [initial, setInitial] = useState(true);
  const { loading, error, data, refetch } = useAxios({
    url: url,
    method: method,
    data: {},
  });
  useEffect(() => {
    if (open) {
      input.current.value = "";
      setInitial(true);
    }
  }, [open]);
  const handleSearch = () => {
    fetchData(1);
  };
  const fetchData = (page) => {
    setInitial(false);
    checkKeyword(value);
    refetch({
      countPerPage: countPerPage,
      currentPage: page,
      keyword: value,
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div className="Content zipCode">
      <AddressSearch
        layerType={open ? "search" : ""}
        onClose={() => handleOpen(false)}
      >
        <dl>
          <dt>
            <CustomInput
              type="text"
              placeholder="주소 입력"
              onChange={onChange}
              onKeyPress={handleKeyPress}
              ref={input}
            />
            <span
              style={{ cursor: "pointer" }}
              className="del"
              onClick={(value) => {
                input.current.value = "";
              }}
            >
              <img src={DelIcon} alt="delete" />
            </span>
          </dt>
          <dd>
            <Button onClick={handleSearch}>검색</Button>
          </dd>
        </dl>
        {!initial && data ? (
          <Result
            data={data.data}
            fetchData={fetchData}
            setAddress={setAddress}
            handleOpen={handleOpen}
          />
        ) : (
          <Information />
        )}
      </AddressSearch>
    </div>
  );
};

Address.propTypes = {};

function Information() {
  return (
    <div className="TipArea">
      <h4>주소 검색 Tip</h4>
      <table>
        <colgroup>
          <col width="184" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>건 물 명 : 건물 명칭 입력</th>
            <td>예) 코엑스</td>
          </tr>
          <tr>
            <th>도 로 명 : 도로명 + 건물번호</th>
            <td>예) 영동대로 502</td>
          </tr>
          <tr>
            <th>지번주소 : 동 (읍/면/리/가)</th>
            <td>예) 삼성동 168-6</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Result(props) {
  const { data, fetchData, setAddress, handleOpen } = props;
  const goToJusoSite = () => {
    const url = "http://www.juso.go.kr";
    window.open(url, "_blank");
  };
  // const handleClickItem = (e) => {
  //   console.log(e.target);
  //   const key = e.target.getAttribute("key");
  //   console.log(key);
  // };
  const handleClickItem = (item) => {
    console.log(item);
    setAddress(item);
    handleOpen(false);
  };
  return (
    <>
      <div className="ResultArea">
        <div className="HasScroll">
          <table>
            <colgroup>
              <col width="285" />
              <col width="*" />
            </colgroup>
            <tbody>
              {data.results.juso && data.results.juso.length > 0 ? (
                data.results.juso.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // handleClickItem(index);
                        handleClickItem(item);
                      }}
                    >
                      <th>
                        {item.roadAddr}
                        <div className="lotNum">{item.jibunAddr}</div>
                      </th>
                      <td>{item.zipNo}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>검색결과가 존재하지 않습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {data.results.juso && data.results.juso.length > 0 && (
          <Pagination
            page={parseInt(data.results.common.currentPage)}
            total={parseInt(data.results.common.totalCount)}
            row={parseInt(data.results.common.countPerPage)}
            pageCount={countPerPage}
            fetchData={fetchData}
          />
        )}
      </div>
      <div className="help">
        혹시 도로명 주소를 찾기가 어려우신가요?
        <br />
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={goToJusoSite}
        >
          {"www.juso.go.kr "}
        </span>
        에서 보다 상세한 도로명 주소를 확인하실 수 있습니다.
      </div>
    </>
  );
}

export default Address;
