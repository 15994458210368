import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Prev.css";
import PagingPrevArrow from "assets/images/prev.png";

const Prev = (props) => {
  const { onClick, visible } = props;
  return (
    visible && (
      <>
        <span href="#" className="prev" onClick={onClick}>
          <img src={PagingPrevArrow} alt="" />
          이전
        </span>
        <span className="line">&nbsp;</span>
      </>
    )
  );
};

Prev.propTypes = {};

export default Prev;
