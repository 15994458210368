import React from "react";
import PropTypes from "prop-types";
import "./NavbarLinks.css";
import { Link, withRouter } from "react-router-dom";
import { Button } from "components";
import CenterIcon from "assets/images/icon_center.png";
import OptionIcon from "assets/images/icon_option.png";
import ProfileIcon from "assets/images/icon_profile.png";
import ArrowDownIcon from "assets/images/icon_arrow_down.png";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import LogoutIcon from "assets/images/icon_logout.png";

const NavbarLinks = (props) => {
  const { user, onClickLogout } = props;
  const dashBoardRole = ["11", "12", "13"]; // 11 : 관리자, 12 : 정산담당, 13 : 재무담당

  console.log(user);
  return (
    <div className="NavbarLinks">
      <ul>
        <li>
          <span className="logout" onClick={onClickLogout}>
            {/* <PowerSettingsNewIcon
              style={{ color: "white" }}
              className="logout"
              fontSize="large"
            /> */}
            <img src={LogoutIcon} />
          </span>
        </li>
      </ul>
      <div className="user">
        <div className="dropdown">
          <div>
            <img src={ProfileIcon} alt="profile" />
            <div className="name_id">
              <span className="name">{user ? user.userId : ""}</span>
              <span className="id roboto">
                {user ? `${user.userNm}(${user.deptNm})` : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ul>
        {/* <li className="help">
          <a href="#">
            <img src={CenterIcon} alt="center" />
          </a>
        </li> */}
        <li className="option">
          {user.auths &&
            dashBoardRole.includes(user.auths[0]) &&
            (props.location.pathname === "/dashBoard" ? (
              <Link to={"/"}>
                <Button className="calc">정산업무</Button>
              </Link>
            ) : (
              <Link to={"/dashBoard"}>
                <Button className="dashboard">DashBoard</Button>
              </Link>
            ))}
        </li>
      </ul>
    </div>
  );
};

NavbarLinks.propTypes = {};

export default withRouter(NavbarLinks);
