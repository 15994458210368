import React from "react";
import PropTypes from "prop-types";
import "./PauseRmvListPopupBatch.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, DatePickerField, CustomInput, CustomRadioButton } from "components";
import moment from "moment";

const PauseRmvListPopupBatch = (props) => {
  const { layerType, viewData, onClose, onSave, formatter, cretFlag } = props;

  const getSum = (list, type) => {
    if(list && list.length !== 0)
      return type === "remain" ? props.formatter(list.reduce((acc, curr) => curr.manualYn !== "Y" ? acc + curr.fee : acc, 0), "amount") : props.formatter(list.reduce((acc, curr) => acc + curr.fee, 0), "amount");
  };

  return (
    <Formik
      initialValues={{
        updateType : "",
        otpayDate: moment(),
        remarks: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        let data = viewData.map((item) => ({
          ...item,
          updateType : "pause",
          reqDate: moment(values.otpayDate).format("YYYYMMDD"), 
          cancelMemo: values.remarks,
        }));
        onSave("pauseReq", data);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        otpayDate: Yup.date().required().nullable(),
        remarks: Yup.string().required().max(50),
        // updateType: Yup.string().required().nullable()
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <Form>
            <div className="PauseRmvListPopupBatch">
              <h5>필수 입력</h5>
              <article className="popup_grid first">
                <div className="first">
                  <table>
                    <colgroup>
                      <col width="11.4%" />
                      <col width="9%" />
                      <col width="11.7%" />
                      <col width="10.2%" />
                      <col width="10.3%" />
                      <col width="9.5%" />
                      <col width="13.1%" />
                      <col width="11.9%" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>발송일</th>
                        <th>상태</th>
                        <th>영업소명</th>
                        <th>수수료</th>
                        <th>차량번호</th>
                        <th>예금주명</th>
                        <th>계좌번호</th>
                        <th>통장기재</th>
                        <th>구분</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewData &&
                        viewData.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{formatter(item.dspDate, "date")}</td>
                              <td>{item.manualYn === "Y" ? "송금 완료" : "송금 전"}</td>
                              <td>{item.plcfbName}</td>
                              <td className="amount">
                                {formatter(item.fee, "amount")}
                              </td>
                              <td>{item.carNo}</td>
                              <td>{formatter(item.dposr, "name", false)}</td>
                              <td>
                                {formatter(item.acntNo, "account", false)}
                              </td>
                              <td>{item.bnkbkSttm}</td>
                              <td>{item.degreeDiv}</td>
                            </tr>
                          );
                        })}
                         {viewData && viewData.length !== 0 ? (
                          <tr>
                            <td colSpan="3">총금액(잔여)<i></i></td>
                            <td className="amount">{getSum(viewData, "total")}원</td>
                            <td className="amount2">({getSum(viewData, "remain")}원)</td>
                            <td colSpan="4"></td>
                          </tr>
                         ) : ( 
                          <tr>
                            <td className="NoData" colSpan={8}>
                              정지 대상 건이 없습니다.
                            </td>
                          </tr>
                          )}
                    </tbody>
                  </table>
                </div>
                {layerType === "pause" ? (
                <div className="second">
                  <table>
                    <colgroup>
                      <col width="200" />
                      <col width="455" />
                      <col width="200" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                        해제 일자<i></i>
                          <span>
                            {errors.otpayDate &&
                              touched.otpayDate &&
                              errors.otpayDate}
                          </span>
                        </th>
                        <td>
                        {formatter(moment().format("YYYY.MM.DD"), "date")}
                        </td>
                        <th>해제요청 메모<i></i>
                          <span>
                            {errors.remarks &&
                              touched.remarks &&
                              errors.remarks}
                          </span>
                        </th>
                        <td>
                          <CustomInput
                            name="remarks"
                            type="text"
                            placeholder="사유 입력"
                            onChange={handleChange}
                            value={values.remarks}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>해제 유형<i></i>
                          <span>
                            {errors.updateType &&
                              touched.updateType &&
                              errors.updateType}
                          </span>
                        </th>
                        <td colSpan="3" className="has_radio">
                          <CustomRadioButton
                            name="updateType"
                            onChange={handleChange}
                            checked={
                              // values.updateType === "pause" ? true : false
                              true
                            }
                            value="pause"
                          />{" "}
                          {/* <span>정지해제 + 송금요청</span> */}
                          <span>정지해제</span>
                          {/* <CustomRadioButton
                            name="updateType"
                            onChange={handleChange}
                            checked={
                              values.updateType === "send" ? true : false
                            }
                            value="send"
                          />{" "}
                          <span>송금요청만</span> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                ) : ""}
              </article>
              <div className="PopupButton">
                {cretFlag && layerType === "pause" ? (
                  <Button
                    type="submit"
                    className="insert"
                    disabled={isSubmitting}
                  >
                    저장
                  </Button>
                ) : ""}
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

PauseRmvListPopupBatch.propTypes = {};

export default PauseRmvListPopupBatch;
