import React from "react";
import PropTypes from "prop-types";
import "./ConditionBox.css";

const ConditionBox = (props) => {
  const { children } = props;
  return (
    <div className="condition_search">
      <div>{children}</div>
    </div>
  );
};

ConditionBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConditionBox;
