import React, { useState } from "react";
import PropTypes from "prop-types";
import "./PauseReqListTable.css";
import { CheckBox as Checkbox } from "components";

const PauseReqListTable = (props) => {
  const {
    datas,
    batchDatas,
    onChange,
    onDoubleClick,
    batchAllChk,
    onChangeAllChk,
    formatter,
    cretFlag,
  } = props;

  return (
    <article className="grid_data">
      <div className="thead">
        <table>
          <colgroup>
            {[
              "2.5%",
              "3%",
              "4.5%",
              "*",
              "5%",
              "5%",
              "10%",
              "5%",
              "3%",
              "5%",
              "5%",
              "7%",
              "5%",
              "3%",
              "10%",
              "5%",
              "3%",
              "4%",
              "4%",
            ].map((i, key) => {
              return <col width={i} key={key} />;
            })}
          </colgroup>
          <tbody>
            <tr>
              <th>
                선택
                {cretFlag && (
                  <>
                    {" "}
                    <Checkbox
                      name="batchAllChk"
                      checked={batchAllChk}
                      onChange={(e) => onChangeAllChk(e.target.checked)}
                    />
                  </>
                )}
              </th>
              <th>상태</th>
              <th>발송일</th>
              <th>영업소명</th>
              <th>수수료</th>
              <th>차량번호</th>
              <th>법인명</th>
              <th>법인계좌</th>
              <th>은행명</th>
              <th>예금주명</th>
              <th>계좌번호</th>
              <th>통장기재</th>
              <th>주민번호</th>
              <th>구분</th>
              <th>사유</th>
              <th>수수료종류</th>
              <th>톤수</th>
              <th>요청자</th>
              <th>수정일</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="holdTable">
        <div className="tbody">
          <table>
            <colgroup>
              {[
                "2.5%",
                "3%",
                "4.5%",
                "*",
                "5%",
                "5%",
                "10%",
                "5%",
                "3%",
                "5%",
                "5%",
                "7%",
                "5%",
                "3%",
                "10%",
                "5%",
                "3%",
                "4%",
                "4%",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={19}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas &&
                datas.map((item, key) => {
                  let chkBox = false;
                  let layer = false;

                  if (
                    item.otpaySttus === "불능" ||
                    item.otpaySttus === "정지요청" ||
                    item.otpaySttus === "예치요청"
                  ) {
                    chkBox = true;
                    layer = true;
                  } 

                  return (
                    <>
                      <tr key={key}>
                        <td>
                          {chkBox ? (
                            <Checkbox
                              name="batchChk"
                              checked={
                                batchDatas.indexOf(item.rowNum) > -1
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                onChange(
                                  item.rowNum,
                                  window.event.target.checked,
                                )
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.manualYn === "Y" ? (item.otpaySttus === "정지" ? "정지해제" : item.otpaySttus) : (item.otpaySttus)}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {formatter(item.dspDate, "date")}
                        </td>
                        <td
                          /* className={"tal " + (layer ? "hand" : "")} 
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.plcfbName}{item.cmsReturnCode === "0012" ? " (오류요청)" : ""}
                        </td>
                        <td
                          /* className={"amount " + (layer ? "hand" : "")}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {formatter(item.fee, "amount")}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.carNo}
                        </td>
                        <td
                          /* className={"tal " + (layer ? "hand" : "")}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.corpName}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {formatter(item.corpAcnt, "account", false)}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.bankName}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {formatter(item.dposr, "name", false)}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {formatter(item.acntNo, "account", false)}
                        </td>
                        <td
                         /*  className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.bnkbkSttm}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {formatter(item.custNo, "custNo")}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.degreeDiv}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.reqMemo}
                        </td>
                        <td
                         /*  className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.setlItem}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.carTon}
                        </td>
                        <td
                         /*  className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.amdrName}
                        </td>
                        <td
                          /* className={layer ? "hand" : ""}
                          onDoubleClick={() =>
                            layer ? onDoubleClick(key) : ""
                          } */
                        >
                          {item.amdDate}
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

      </div>
      
     
    </article>
  );
};

PauseReqListTable.propTypes = {};

export default PauseReqListTable;
