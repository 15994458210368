import React from "react";
import { Bar } from "react-chartjs-2";

const data = {
  datasets: [
    {
      label: "Sales",
      type: "line",
      data: [
        51,
        65,
        40,
        49,
        60,
        37,
        40,
        51,
        65,
        40,
        49,
        60,
        37,
        40,
        51,
        65,
        40,
      ],
      fill: false,
      borderColor: "#EC932F",
      backgroundColor: "#EC932F",
      pointBorderColor: "#EC932F",
      pointBackgroundColor: "#EC932F",
      pointHoverBackgroundColor: "#EC932F",
      pointHoverBorderColor: "#EC932F",
      yAxisID: "y-axis-2",
    },
    {
      type: "bar",
      label: "Visitor",
      data: [
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        350,
        400,
        95,
      ],
      fill: false,
      backgroundColor: "#3E64F4",
      borderColor: "#3E64F4",
      hoverBackgroundColor: "#71B37C",
      hoverBorderColor: "#71B37C",
      yAxisID: "y-axis-1",
    },
    {
      type: "bar",
      label: "Visitor",
      data: [
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        350,
        400,
        95,
      ],
      fill: false,
      backgroundColor: "#3E64F4",
      borderColor: "#3E64F4",
      hoverBackgroundColor: "#71B37C",
      hoverBorderColor: "#71B37C",
      yAxisID: "y-axis-1",
    },
    {
      type: "bar",
      label: "Visitor",
      data: [
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        350,
        400,
        95,
      ],
      fill: false,
      backgroundColor: "#3E64F4",
      borderColor: "#3E64F4",
      hoverBackgroundColor: "#71B37C",
      hoverBorderColor: "#71B37C",
      yAxisID: "y-axis-1",
    },
    {
      type: "bar",
      label: "Visitor",
      data: [
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        200,
        185,
        590,
        621,
        250,
        400,
        95,
        350,
        400,
        95,
      ],
      fill: false,
      backgroundColor: "#3E64F4",
      borderColor: "#3E64F4",
      hoverBackgroundColor: "#71B37C",
      hoverBorderColor: "#71B37C",
      yAxisID: "y-axis-1",
    },
  ],
};

const options = {
  legend: {
    position: "bottom",
  },
  responsive: true,
  tooltips: {
    mode: "label",
  },
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        // labels: {
        //   show: true,
        // },
        labels: [
          "강원",
          "경기",
          "경남",
          "경북",
          "광주",
          "대구",
          "대전",
          "부산",
          "서울",
          "세종",
          "울산",
          "인천",
          "전남",
          "전북",
          "제주",
          "충남",
          "충북",
        ],
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-1",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-2",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
    ],
  },
};

const plugins = [
  {
    // afterDraw: (chartInstance, easing) => {
    //   const ctx = chartInstance.chart.ctx;
    //   ctx.fillText("This text drawn by a plugin", 100, 100);
    // },
  },
];

export default function () {
  return (
    <div className="chart">
      <h3>지역별 9월 운임</h3>
      <Bar data={data} options={options} plugins={plugins} />
    </div>
  );
}
