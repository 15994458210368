import { TOKEN, TOKEN_TYPE, USER, MENU, CODE } from "./constants";

const findGroupCode = (code, grpCd) => {
  const groupCode = code.find((item) => {
    return item.cdGrpId === grpCd;
  });
  if (!groupCode) return [];
  return groupCode.cdDtlInfoList.map((item, key) => {
    return {
      code: item.cdDtlId,
      name: item.cdDtlNm,
      text: item.cdDtlSbst,
      orderBy: item.indcOdrg,
      strVal1: item.expnsnStrVal1,
      strVal2: item.expnsnStrVal2,
      strVal3: item.expnsnStrVal3,
    };
  });
};

const findDetailCode = (code, grpCd, dtlCd) => {
  const groupCode = findGroupCode(code, grpCd);
  return groupCode.find((item) => {
    return item.code === dtlCd;
  });
};

const findDetailName = (code, grpCd, dtlNm) => {
  const groupCode = findGroupCode(code, grpCd);
  return groupCode.find((item) => {
    return item.name === dtlNm;
  });
};

const removeStorage = () => {
  sessionStorage.removeItem(TOKEN);
  sessionStorage.removeItem(TOKEN_TYPE);
  sessionStorage.removeItem(USER);
  localStorage.removeItem(MENU);
  localStorage.removeItem(CODE);
};

export { findGroupCode, findDetailCode, findDetailName, removeStorage };
