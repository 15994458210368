
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PauseReqList.css";
import {
  withBase,
  Button,
  LayerPopup,
  ControlBox,
  Pagination,
} from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import PauseReqListConditionBox from "./PauseReqListConditionBox";
import PauseReqListTable from "./PauseReqListTable";
import PauseReqListPopupWrapper from "./PauseReqListPopupWrapper";
import PauseReqListPopupIndividual from "./PauseReqListPopupIndividual";
import PauseReqListPopupBatch from "./PauseReqListPopupBatch";
import PauseReqListPopupDown from "./PauseReqListPopupDown";

const API = {
  LIST: {
    url: "api/v1/finnc/finnc/listPause",
    method: "post",
  },
  CREATE: {
    url: "api/v1/finnc/finnc/createPauseReq",
    method: "post",
  },
  CANCEL: {
    url: "api/v1/finnc/finnc/updatePauseReqCancel",
    method: "post",
  },
  ERROR: {
    url: "api/v1/finnc/finnc/updateAcntError",
    method: "post",
  },
};

const PauseReqList = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    dspDate: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);
  const [batchDatas, setBatchDatas] = useState([]);
  const [dateItem, setDateItem] = useState([]);
  const [batchAllDat, setBatchAllDat] = useState([]);
  const [batchAllChk, setBatchAllChk] = useState(false);
  const [states, setStates] = useState([]);

  useEffect(() => {
    list();
    //readStates();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    const response = await HttpRequest.request(url, method, param);
    //const list = response.fareFeeTxnList;
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      setBatchDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.fareFeeTxnList);
    setBatchDatas([]);

    let checkDat = [];
    response.fareFeeTxnList &&
    response.fareFeeTxnList.forEach((item) => {
        if (item.otpaySttus === "불능" || item.otpaySttus.indexOf("요청") > 0) {
          checkDat.push(item.rowNum);
        }
      });

    setBatchAllDat(checkDat);
    searching(false);
  };

  const readStates = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const {url, method } = API.READSTATES;
    const response = await HttpRequest.request(url, method, {});

    if (HttpRequest.hasError(response, true)) {
    
      return;
    }

    setStates(response.stateMap);
    searching(false);

  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    return true;
  };
  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDownOtpayReq = async (param) => {
    const { url } = API.DOWNOTPAYREQ;

    await props.downExcel.request(url, param);

    setLayerType("");
  };

  const handleClickDownFareFee = async () => {
    const { url } = API.DOWNFAREFEE;

    await props.downExcel.request(url, param);
  };

  const onChangeAllChk = (checked) => {
    setBatchAllChk(checked);

    if (checked) {
      setBatchDatas(batchAllDat);
    } else {
      setBatchDatas([]);
    }
  };

  const handleClickCheckBox = (index, check) => {
    check
      ? setBatchDatas([...batchDatas, index])
      : setBatchDatas(batchDatas.filter((item) => item !== index));
  };
  const handleDoubleClick = (index) => {
    setLayerType("individual");
    setViewData(datas[index]);
  };
  const handleClickDown = async () => {
    const { HttpRequest } = props;

    const { url, method } = API.DATELIST;

    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setDateItem([]);
      return;
    }

    if (!response.PauseReqListList || response.PauseReqListList.length === 0) {
      setDateItem([]);
    } else {
      setDateItem(
        response.PauseReqListList.map((item) => {
          return {
            code: item.dspDate,
            name: props.formatter(item.dspDate, "date"),
          };
        })
      );
    }

    setLayerType("down");
  };
  const handleClickPause = (layerType) => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    let data = [];
    data = datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1);
    for (let i = 0; i < data.length; i++) {
      if(data[i].otpaySttus !== "불능"){
        props.toast.error("이미 요청된 건은 중복 요청 불가합니다.");
        return;
      }
      if(layerType === "deposit") 
        if(data[i].setlItem !== "용차수수료"){
        props.toast.error("용차수수료만 예치가 가능합니다.");
        return;
      }
    }
    setLayerType(layerType); 
    setViewData(datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1));
  };

  const handleClickAcnt = () => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    let data = [];
    data = datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1);
    for (let i = 0; i < data.length; i++) {
      if(data[i].otpaySttus !== "불능"){
        props.toast.error("불능건만 계좌오류요청/취소가 가능합니다.");
        return;
      }
        /* 
          if(data[i].cmsReturnCode === "0012"){
            props.toast.error("이미 계좌오류요청 상태입니다.");
            return;
          } 
        */
    }
    handleClickSave("acnt", datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1));
  };

  /*
  const handleClickDeposit = () => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    let data = [];
    data = datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1);
    for (let i = 0; i < data.length; i++) {
      if(data[i].statusName !== "불능"){
        props.toast.error("이미 요청된 건은 중복 요청 불가합니다.");
        return;
      }
    }
    setLayerType("deposit");
    setViewData(datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1));
  };
  */

  const handleClickPauseCancel = () => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    let data = [];
    data = datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1);
    for (let i = 0; i < data.length; i++) {
      if(data[i].otpaySttus !== "정지요청" && data[i].otpaySttus !== "예치요청"){
        props.toast.error("정지/예치 요청된 건만 요청 취소가 가능합니다.");
        return;
      }
    }
    handleClickSave("cancel", data);
  };


  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const handleClickSave = async (saveType, data) => {
    console.log(data);
    let ret = false;
    let msg = "";
    switch (saveType) {
      case "pause":
        ret = await edit(API.CREATE, { fareFeeTxnList: data });
        msg = ((layerType === "pause") ? "정지" : "예치") + " 요청 처리되었습니다.";
        if (ret) setBatchDatas([]);
        break;
      case "cancel":
        ret = await edit(API.CANCEL, { fareFeeTxnList: data });
        msg = "정지/예치 요청 취소되었습니다.";
        break;
      case "acnt":
          ret = await edit(API.ERROR, { fareFeeTxnList: data });
          msg = "계좌오류요청/취소가 처리되었습니다.";
          break;
      default:
        console.log(saveType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

    }
    if (msg) props.toast.error(msg);
  };
  return (
    <div className="PauseReqList">
      <PauseReqListConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        {/* <Button className="withdrawl_register" onClick={() => handleClickPause("pause")}>
            정지요청
        </Button>
        <Button className="withdrawl_register" onClick={() => handleClickPause("deposit")}>
            예치요청
        </Button>
        <Button className="withdrawl_register" onClick={handleClickPauseCancel}>
            요청취소
        </Button> */}
        <Button className="insert" onClick={() => handleClickAcnt()}>
            계좌오류요청/취소
        </Button>
      </ControlBox>
      <PauseReqListTable
        datas={datas}
        batchDatas={batchDatas}
        onChange={handleClickCheckBox}
        onDoubleClick={handleDoubleClick}
        batchAllChk={batchAllChk}
        onChangeAllChk={onChangeAllChk}
        formatter={props.formatter}
        cretFlag={cretFlag}
      />
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PauseReqListPopupWrapper
          layerType={layerType}
          title={"송금 "+ (layerType === "pause" ? "정지":"예치")+" 요청"}
          className={"PauseReqListPopup"}
          batchTable={
            <PauseReqListPopupBatch
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          individualTable={
            <PauseReqListPopupIndividual
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          downTable={
            <PauseReqListPopupDown
              onClose={handleClickClose}
              onDown={handleClickDownOtpayReq}
              dateItem={dateItem}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

PauseReqList.propTypes = {};

export default withBase(PauseReqList);
