import React from "react";
import PropTypes from "prop-types";
import "./DeliveryTxnHeader.css";
import ProcessStepArrowIcon from "assets/images/step_right_arrow.png";

const DeliveryTxnHeader = (props) => {
  const { step, clickStep } = props;

  const handleClickStep = (e, step) => {
    e.preventDefault();
    clickStep(step);
  };

  return (
    <div className="StepProcess">
      <ul>
        <li className={step === 1 ? "on" : ""}>
          <a href="#" onClick={(e) => handleClickStep(e, 1)}>
            <span className="roboto">1</span>
            <h3>원본파일 업로드</h3>
            <h4>수수료 계산을 위한 파일 업로드</h4>
          </a>
        </li>
        <li className="arrow">
          <img src={ProcessStepArrowIcon} />
        </li>
        <li className={step === 2 ? "on" : ""}>
          <a href="#" onClick={(e) => handleClickStep(e, 2)}>
            <span className="roboto">2</span>
            <h3>집계표 출력</h3>
            <h4>
              정산 프로그램을 통한 각각의 집계현황 <br />
              (총 운임 및 노선집계표) 확인 및 출력 가능
            </h4>
          </a>
        </li>
        <li className="arrow">
          <img src={ProcessStepArrowIcon} />
        </li>
        <li className={step === 3 ? "on" : ""}>
          <a href="#" onClick={(e) => handleClickStep(e, 3)}>
            <span className="roboto">3</span>
            <h3>자료정리</h3>
            <h4>집계표 출력 완료 후 자료정리 실행</h4>
          </a>
        </li>
        <li className="arrow">
          <img src={ProcessStepArrowIcon} />
        </li>
        <li className={step === 4 ? "on" : ""}>
          <a href="#" onClick={(e) => handleClickStep(e, 4)}>
            <span>4</span>
            <h3>FTAS 정산</h3>
            <h4>자료 정리 완료 후 FTAS 프로그램 실행 정산완료</h4>
          </a>
        </li>
      </ul>
    </div>
  );
};

DeliveryTxnHeader.propTypes = {};

export default DeliveryTxnHeader;
