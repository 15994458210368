import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./DashBoard.css";
import { withBase, Title } from "components";
import moment from "moment";
import AddBtn from "assets/images/icon_add.png";
import Notice from "./Notice";
import SetlSumChrt from "./SetlSumChrt";
import TrmQtyChrt from "./TrmQtyChrt";
import TrmFareChrt from "./TrmFareChrt";
import RegionFareChrt from "./RegionFareChrt";
import RegionQtyChrt from "./RegionQtyChrt";
import IconTotal from "assets/images/icon_total.png";
import IconUp from "assets/images/icon_up.png";
import IconDown from "assets/images/icon_down.png";
import IconSame from "assets/images/icon_same.png";

const API = {
  LIST: {
    url: "api/v1/comn/dashboard/list",
    method: "post",
  },
};

const DashBoard = (props) => {
  const toMonth = moment().add(-1, "days").format("M");
  const [noticeData, setNoticeData] = useState([]);
  const [setlSumChrtData, setSetlSumChrtData] = useState({});
  const [trmChrtData, setTrmChrtData] = useState({});
  const [regionChrtData, setRegionChrtData] = useState({});

  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setNoticeData([]);
      setSetlSumChrtData({});
      setTrmChrtData({});
      setRegionChrtData({});
      searching(false);
      return;
    }

    // 공지사항
    setNoticeData(response.dashboardList1);

    if (!response.dashboardList2 || response.dashboardList2[0] === null) {
      setSetlSumChrtData({});
    } else {
      // 월별 정산집계(총운임) : 1년간의 월별 총입금대상금액/영업소입금액
      const list2 = response.dashboardList2[0];

      let setlChrt = {
        labels: list2.term.split(","),
        kdCnt: list2.kdCnt.split(","),
        hdCnt: list2.hdCnt.split(","),
      };

      setSetlSumChrtData(setlChrt);
    }

    if (!response.dashboardList3 || response.dashboardList3[0] === null) {
      setTrmChrtData({});
    } else {
      // 당일 터미널별 수량/운임
      const list3 = response.dashboardList3[0];

      let trmChrt = {
        labels: list3.term.split(","),
        kdCnt: list3.kdCnt.split(","),
        hdCnt: list3.hdCnt.split(","),
        kdFare: list3.kdFare.split(","),
        hdFare: list3.hdFare.split(","),
      };

      setTrmChrtData(trmChrt);
    }

    if (!response.dashboardList4 || response.dashboardList4[0] === null) {
      setRegionChrtData({});
    } else {
      // 지역별 월운임/수량 : 전월간 금액 비교
      const list4 = response.dashboardList4[0];

      let regionChrt = {
        // 차트
        labels: list4.region.split(","),
        kyungDongDspFare: list4.kyungDongDspFare.split(","),
        kyungDongArvrFare: list4.kyungDongArvrFare.split(","),
        hapDongDspFare: list4.hapDongDspFare.split(","),
        hapDongArvrFare: list4.hapDongArvrFare.split(","),
        regionTotFare: list4.regionTotFare.split(","),
        kyungDongDspCnt: list4.kyungDongDspCnt.split(","),
        kyungDongArvrCnt: list4.kyungDongArvrCnt.split(","),
        hapDongDspCnt: list4.hapDongDspCnt.split(","),
        hapDongArvrCnt: list4.hapDongArvrCnt.split(","),
        regionTotCnt: list4.regionTotCnt.split(","),

        // 운임
        kyungDongTotFare: list4.kyungDongTotFare,
        kyungDongFareDiff: list4.kyungDongFareDiff,
        hapDongTotFare: list4.hapDongTotFare,
        hapDongFareDiff: list4.hapDongFareDiff,
        totFare: list4.totFare,
        totFareDiff: list4.totFareDiff,

        // 수량
        kyungDongTotCnt: list4.kyungDongTotCnt,
        kyungDongCntDiff: list4.kyungDongCntDiff,
        hapDongTotCnt: list4.hapDongTotCnt,
        hapDongCntDiff: list4.hapDongCntDiff,
        totCnt: list4.totCnt,
        totCntDiff: list4.totCntDiff,
      };

      setRegionChrtData(regionChrt);
    }
    searching(false);
  };

  return (
    <div className="Content DashBoard">
      <Notice noticeData={noticeData} formatter={props.formatter} />
      <article className="GraphArea">
        <div className="GraphData w50 first">
          <div className="titleArea">
            <h3>월별 정산 집계(총운임)</h3>
            {/* <a href="#">
              <img src={AddBtn} />
            </a> */}
          </div>
          <div className="Graph">
            <SetlSumChrt chrtData={setlSumChrtData} />
            <ul className="sortation">
              <li>
                <span className="blue"></span>영업소입금예정금액
              </li>
              <li>
                <span className="green"></span>영업소입금액
              </li>
            </ul>
          </div>
        </div>
        <div className="GraphData w50">
          <div className="GraphData w50 first">
            <div className="titleArea">
              <h3>당일 터미널별 수량</h3>
            </div>
            <div className="Graph">
              <TrmQtyChrt chrtData={trmChrtData} />
            </div>
          </div>
          <div className="GraphData w50">
            <div className="titleArea">
              <h3>당일 터미널별 운임</h3>
              {/* <a href="#">
                <img src={AddBtn} />
              </a> */}
            </div>
            <div className="Graph">
              <TrmFareChrt chrtData={trmChrtData} />
            </div>
          </div>
          <ul className="sortation">
            <li>
              <span className="blue"></span>경동
            </li>
            <li>
              <span className="yellow"></span>합동
            </li>
          </ul>
        </div>
      </article>
      <article className="GraphArea">
        <div className="GraphData">
          <div className="titleArea">
            <h3>지역별 {toMonth}월 운임</h3>
          </div>
          <div className="Graph leftArea">
            <RegionFareChrt chrtData={regionChrtData} />
          </div>
          <div className="TotalFare">
            <table>
              <colgroup>
                <col width="62%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    경동택배 운임총합
                    <span>
                      {props.formatter(
                        regionChrtData.kyungDongTotFare,
                        "amount"
                      )}
                      원
                    </span>
                  </th>
                  {regionChrtData.kyungDongFareDiff > 0 ? (
                    <td className="up">
                      <img src={IconUp} />
                      {props.formatter(
                        regionChrtData.kyungDongFareDiff,
                        "amount"
                      )}
                      원
                    </td>
                  ) : regionChrtData.kyungDongFareDiff < 0 ? (
                    <td className="down">
                      <img src={IconDown} />
                      {props.formatter(
                        regionChrtData.kyungDongFareDiff,
                        "amount"
                      )}
                      원
                    </td>
                  ) : (
                    <td className="same">
                      <img src={IconSame} />
                      동일
                    </td>
                  )}
                </tr>
                <tr>
                  <th>
                    합동택배 운임총합
                    <span>
                      {props.formatter(regionChrtData.hapDongTotFare, "amount")}
                      원
                    </span>
                  </th>
                  {regionChrtData.hapDongFareDiff > 0 ? (
                    <td className="up">
                      <img src={IconUp} />
                      {props.formatter(
                        regionChrtData.hapDongFareDiff,
                        "amount"
                      )}
                      원
                    </td>
                  ) : regionChrtData.hapDongFareDiff < 0 ? (
                    <td className="down">
                      <img src={IconDown} />
                      {props.formatter(
                        regionChrtData.hapDongFareDiff,
                        "amount"
                      )}
                      원
                    </td>
                  ) : (
                    <td className="same">
                      <img src={IconSame} />
                      동일
                    </td>
                  )}
                </tr>
                <tr>
                  <th>
                    전체 운임총합
                    <span>
                      {props.formatter(regionChrtData.totFare, "amount")}원
                    </span>
                  </th>
                  {regionChrtData.totFareDiff > 0 ? (
                    <td className="up">
                      <img src={IconUp} />
                      {props.formatter(regionChrtData.totFareDiff, "amount")}원
                    </td>
                  ) : regionChrtData.totFareDiff < 0 ? (
                    <td className="down">
                      <img src={IconDown} />
                      {props.formatter(regionChrtData.totFareDiff, "amount")}원
                    </td>
                  ) : (
                    <td className="same">
                      <img src={IconSame} />
                      동일
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ul className="sortation">
          <li>
            <span className="red"></span>경동발송
          </li>
          <li>
            <span className="blue"></span>경동도착
          </li>
          <li>
            <span className="green"></span>합동발송
          </li>
          <li>
            <span className="yellow"></span>합동도착
          </li>
          <li>
            <span className="total">
              <img src={IconTotal} />
            </span>
            합계
          </li>
        </ul>
      </article>
      <article className="GraphArea">
        <div className="GraphData">
          <div className="titleArea">
            <h3>지역별 {toMonth}월 수량</h3>
          </div>
          <div className="Graph leftArea">
            <RegionQtyChrt chrtData={regionChrtData} />
          </div>
          <div className="TotalFare">
            <table>
              <colgroup>
                <col width="62%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    경동택배 수량총합
                    <span>
                      {props.formatter(
                        regionChrtData.kyungDongTotCnt,
                        "amount"
                      )}
                      건
                    </span>
                  </th>
                  {regionChrtData.kyungDongCntDiff > 0 ? (
                    <td className="up">
                      <img src={IconUp} />
                      {props.formatter(
                        regionChrtData.kyungDongCntDiff,
                        "amount"
                      )}
                      건
                    </td>
                  ) : regionChrtData.kyungDongCntDiff < 0 ? (
                    <td className="down">
                      <img src={IconDown} />
                      {props.formatter(
                        regionChrtData.kyungDongCntDiff,
                        "amount"
                      )}
                      건
                    </td>
                  ) : (
                    <td className="same">
                      <img src={IconSame} />
                      동일
                    </td>
                  )}
                </tr>
                <tr>
                  <th>
                    합동택배 수량총합
                    <span>
                      {props.formatter(regionChrtData.hapDongTotCnt, "amount")}
                      건
                    </span>
                  </th>
                  {regionChrtData.hapDongCntDiff > 0 ? (
                    <td className="up">
                      <img src={IconUp} />
                      {props.formatter(regionChrtData.hapDongCntDiff, "amount")}
                      건
                    </td>
                  ) : regionChrtData.hapDongCntDiff < 0 ? (
                    <td className="down">
                      <img src={IconDown} />
                      {props.formatter(regionChrtData.hapDongCntDiff, "amount")}
                      건
                    </td>
                  ) : (
                    <td className="same">
                      <img src={IconSame} />
                      동일
                    </td>
                  )}
                </tr>
                <tr>
                  <th>
                    전체 수량총합
                    <span>
                      {props.formatter(regionChrtData.totCnt, "amount")}건
                    </span>
                  </th>
                  {regionChrtData.totCntDiff > 0 ? (
                    <td className="up">
                      <img src={IconUp} />
                      {props.formatter(regionChrtData.totCntDiff, "amount")}건
                    </td>
                  ) : regionChrtData.totCntDiff < 0 ? (
                    <td className="down">
                      <img src={IconDown} />
                      {props.formatter(regionChrtData.totCntDiff, "amount")}건
                    </td>
                  ) : (
                    <td className="same">
                      <img src={IconSame} />
                      동일
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ul className="sortation">
          <li>
            <span className="red"></span>경동발송
          </li>
          <li>
            <span className="blue"></span>경동도착
          </li>
          <li>
            <span className="green"></span>합동발송
          </li>
          <li>
            <span className="yellow"></span>합동도착
          </li>
          <li>
            <span className="total">
              <img src={IconTotal} />
            </span>
            합계
          </li>
        </ul>
      </article>
    </div>
  );
};

DashBoard.propTypes = {};

export default withBase(DashBoard);
