import React, { useState, useEffect, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./SideNavigation.css";

const lnbIconPrefix = "images/lnb_icon/";

const SubMenuItem = React.memo((props) => {
  const { subMenuItem, index, clicked, onClick } = props;
  const on = `${lnbIconPrefix}${subMenuItem.id}_on.png`;
  const off = `${lnbIconPrefix}${subMenuItem.id}_off.png`;
  console.log(on, off);
  return (
    <li>
      <Link
        to={subMenuItem.path}
        className={clicked ? "on" : ""}
        onClick={() => {
          onClick(index, subMenuItem);
        }}
        replace
      >
        <img
          src={off}
          alt="submenu"
          className="off"
        />
        <img
          src={on}
          alt="submenu"
          className="on"
        />
        {subMenuItem.name}
      </Link>
    </li>
  );
});

const SideNavigation = (props) => {
  const { menuItem, onClick } = props;
  const [clickIndex, setClickIndex] = useState(0);
  useEffect(() => {
    if (menuItem && menuItem.views) {
      setClickIndex(0);
    }
  }, [menuItem]);
  useEffect(() => {
    let subMenuItem = menuItem.views[clickIndex];
    if (!subMenuItem) {
      subMenuItem = menuItem.views[0];
    }
    onClick(subMenuItem);
    const { history } = props;
    history.push(subMenuItem.path);
  }, [menuItem, clickIndex]);
  const handleSubMenuItemClick = useCallback((index, subMenuItem) => {
    setClickIndex(index);
    onClick(subMenuItem);
  }, []);
  return (
    <aside className="SideNavigation">
      <article>
        <h4>{menuItem && menuItem.name}</h4>
        <ul>
          {menuItem &&
            menuItem.views &&
            menuItem.views.map((item, index) => {
              return (
                <SubMenuItem
                  subMenuItem={item}
                  key={index}
                  index={index}
                  onClick={handleSubMenuItemClick}
                  clicked={clickIndex === index ? true : false}
                />
              );
            })}
        </ul>
      </article>
    </aside>
  );
};

SideNavigation.propTypes = {};

export default withRouter(SideNavigation);
