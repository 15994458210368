import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import {
  CacheSelect,
  CodeSelect,
  Button,
  ConditionBox,
  CustomInput,
  CacheAutocomplete,
} from "components";

const UserInfoCondition = (props) => {
  const { onClickSearch } = props;
  const [grpIdPosDept, setGrpIdPosDeptCd] = useState("");
  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            roleId: "",
            posCmpnCd: "",
            posDeptCd: "",
            posDeptNm: "",
            userId: "",
            userNm: "",
            delYn: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            console.log(values);
            onClickSearch(values);
            setSubmitting(false);
          }}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>사용자권한</dt>
                            <dd>
                              <CacheSelect
                                cache="roleInfo"
                                onMap={(item, key) => {
                                  return {
                                    code: item.roleId,
                                    name: item.roleNm,
                                  };
                                }}
                                name="roleId"
                                onChange={handleChange}
                                placeholder="권한 선택"
                                value={values.roleId}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>소속구분</dt>
                            <dd>
                              <CodeSelect
                                codeId="POS_DIV"
                                name="posCmpnCd"
                                onChange={(e, item) => {
                                  values.posDeptCd = "";
                                  values.posDeptNm = "";
                                  setGrpIdPosDeptCd(item ? item.strVal1 : "");
                                  handleChange(e);
                                }}
                                placeholder="소속구분 선택"
                                value={values.posCmpnCd}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>소속</dt>
                            <dd>
                              {values.posCmpnCd === "02" ? (
                                <CacheAutocomplete
                                  cache="plcfbInfo"
                                  onMap={(item, key) => {
                                    return {
                                      code: item.plcfbCd,
                                      name: item.plcfbName,
                                    };
                                  }}
                                  name="posDeptNm"
                                  value={values.posDeptNm}
                                  callBack={(item) => {
                                    setFieldValue(
                                      "posDeptCd",
                                      item ? item.code : ""
                                    );
                                  }}
                                  setFieldValue={setFieldValue}
                                  placeholder="소속 선택"
                                />
                              ) : (
                                <CodeSelect
                                  codeId={grpIdPosDept}
                                  name="posDeptCd"
                                  onChange={handleChange}
                                  placeholder="소속 선택"
                                  value={values.posDeptCd}
                                />
                              )}
                            </dd>
                          </dl>
                          <dl>
                            <dt>아이디</dt>
                            <dd>
                              <CustomInput
                                name="userId"
                                type="text"
                                placeholder="아이디 입력"
                                onChange={handleChange}
                                value={values.userId}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>이름</dt>
                            <dd>
                              <CustomInput
                                name="userNm"
                                type="text"
                                placeholder="이름 입력"
                                onChange={handleChange}
                                value={values.userNm}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>사용여부</dt>
                            <dd>
                              <CodeSelect
                                codeId="DEL_YN"
                                name="delYn"
                                onChange={handleChange}
                                placeholder="사용여부 선택"
                                value={values.delYn}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

UserInfoCondition.propTypes = {};

export default UserInfoCondition;
