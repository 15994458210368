import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./SetlTxn.css";
import {
  withBase,
  Button,
  ControlBox,
  LayerPopup,
  PopupWrapper,
  CustomLoading,
  CustomMessage,
} from "components";
import DateOn from "assets/images/dateOn.png";
import DateLine from "assets/images/dateLine.png";
import DataUploadIcon from "assets/images/icon_data_armt.png";
import SetlTxnCorrectionContent from "./SetlTxnCorrectionContent";

const API = {
  CREATE: {
    url: "api/v1/calc/deliveryTxn/createVerify",
    method: "post",
  },
  LIST: {
    url: "api/v1/calc/deliveryTxn/listVerify",
    method: "post",
  },
  DATELIST: {
    url: "api/v1/calc/deliveryTxn/listCretDate",
    method: "post",
  },
};

const SetlTxn = (props) => {
  const [param, setParam] = useState({ pageNo: 1 });
  const [searchFlg, setSearchFlg] = useState(true);
  const [plcfbDatas, setPlcfbDatas] = useState();
  const [carDatas, setCarDatas] = useState();
  const [feeDatas, setFeeDatas] = useState();
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [message, setMessage] = useState("");
  const [dateList, setDateList] = useState([]);

  useEffect(() => {
    listDate();
  }, []);

  useEffect(() => {
    list();
  }, [param]);

  const listDate = async () => {
    const { HttpRequest } = props;
    const { url, method } = API.DATELIST;

    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setDateList([]);
      return;
    }
    setDateList(response.setlTxnList);
  };

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    setTotal(0);
    setPlcfbDatas();
    setCarDatas();
    setFeeDatas();

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      return;
    }
    setSearchFlg(false);
    setTotal(response.totalRowCnt);
    setPlcfbDatas(response.vrfBatchWrkRsltTxnList1);
    setCarDatas(response.vrfBatchWrkRsltTxnList2);
    setFeeDatas(response.vrfBatchWrkRsltTxnList3);
    searching(false);
  };

  const handleClickDataArmt = async () => {
    if (dateList.length === 0) {
      props.toast.error("자료정리 대상이 존재하지 않습니다.");
      return;
    }
    setLayerType("loading");
    setTotal(0);
    setPlcfbDatas();
    setCarDatas();
    setFeeDatas();

    const { HttpRequest } = props;
    const { url, method } = API.CREATE;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setMessage("자료정리중 오류가 발생했습니다.");
      setLayerType("message");
      return;
    }
    setSearchFlg(false);
    setTotal(response.totalRowCnt);
    setPlcfbDatas(response.vrfBatchWrkRsltTxnList1);
    setCarDatas(response.vrfBatchWrkRsltTxnList2);
    setFeeDatas(response.vrfBatchWrkRsltTxnList3);
    console.log(response);
    setMessage(
      response.vrfBatchWrkRsltTxnList1 ||
        response.vrfBatchWrkRsltTxnList2 ||
        response.vrfBatchWrkRsltTxnList3 ? (
        <>
          자료 정리 중 추가 확인 및 수정이 필요한 <br /> 내용이 발견되었습니다.
        </>
      ) : (
        <>자료정리가 완료되었습니다.</>
      )
    );
    setLayerType("message");
    listDate();
  };

  const handleClickClose = () => {
    setLayerType("");
  };

  return (
    <div className="SetlTxn">
      {searchFlg ? (
        <></>
      ) : plcfbDatas || carDatas || feeDatas ? (
        <>
          <ControlBox
            left={
              <article className="text_info">
                <p>
                  {dateList &&
                    dateList.length !== 0 &&
                    props.formatter(dateList[0].cretDate, "date")}{" "}
                  수정이 완료되면 자료정리 <span>재실행</span> 해주세요.
                </p>
              </article>
            }
          >
            <Button className="data_armt" onClick={handleClickDataArmt}>
              자료정리
            </Button>
          </ControlBox>
          <SetlTxnCorrectionContent
            plcfbDatas={plcfbDatas}
            carDatas={carDatas}
            feeDatas={feeDatas}
            total={total}
          />
        </>
      ) : (
        <form className="UploadArea">
          <div className="drop">
            {dateList && dateList.length === 0 ? (
              <img src={DataUploadIcon} className="icon" />
            ) : (
              <ul className="dateList">
                {dateList &&
                  dateList.map((item, key) => {
                    return key === 0 ? (
                      <>
                        <li className="on">
                          <img src={DateOn} className="on" />
                          {props.formatter(item.cretDate, "date")}
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="line">
                          <img src={DateLine} />
                        </li>
                        <li>
                          <img src={DateOn} className="on" />
                          {props.formatter(item.cretDate, "date")}
                        </li>
                      </>
                    );
                  })}
              </ul>
            )}
            <Button className="data_armt" onClick={handleClickDataArmt}>
              자료정리
            </Button>
            <h4>
              보고자료 출력이 완료되면 <span>자료정리 실행</span> 버튼을
              눌러주세요.
            </h4>
          </div>
        </form>
      )}

      <PopupWrapper
        layerType={layerType}
        title={""}
        loading={
          <CustomLoading layerType={layerType}>자료정리중입니다.</CustomLoading>
        }
      />
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={""}
          message={
            <CustomMessage onClose={handleClickClose}>{message}</CustomMessage>
          }
        />
      </LayerPopup>
    </div>
  );
};

SetlTxn.propTypes = {};

export default withBase(SetlTxn);
