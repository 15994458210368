import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./DpstTxnList.css";
import {
  withBase,
  Button,
  ControlBox,
  LayerPopup,
  PopupWrapper,
} from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import WonIcon from "assets/images/icon_won.png";
import IconTotalPlus from "assets/images/icon_plus.png";
import DpstTxnListConditionBox from "./DpstTxnListConditionBox";
import DpstTxnEnterPopup from "./DpstTxnEnterPopup";

const API = {
  LIST: {
    url: "api/v1/report/dpstTxn/list",
    method: "post",
  },
  LISTETC: {
    url: "api/v1/report/dpstTxn/listetc",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/dpstTxn/downodpsttxn",
    method: "post",
  },
  CREATE: {
    url: "api/v1/report/dpstTxn/createetc",
    method: "post",
  },
  DELETE: {
    url: "api/v1/report/dpstTxn/deleteetc",
    method: "post",
  },
};

const DpstTxnList = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    setlDate: moment().add(-1, "days").format("YYYYMM"),
  });
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState();
  const [etcDatas, setEtcDatas] = useState([]);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);
  const [acntBlnc, setAcntBlnc] = useState(0);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setTotalData("");
      setAcntBlnc(0);
      searching(false);
      return;
    }

    if (
      !response.corpAcntSumTxnList ||
      response.corpAcntSumTxnList.length === 0
    ) {
      setDatas([]);
      setTotalData("");
      setAcntBlnc(0);
    } else {
      const resList = response.corpAcntSumTxnList;
      setDatas(resList.slice(0, -1));
      setTotalData(resList.slice(-1));
      setAcntBlnc(resList[resList.length - 1].acntBlnc);
    }
    searching(false);
  };
  const listEtc = async (etcParam) => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LISTETC;
    console.log(etcParam);
    const response = await HttpRequest.request(url, method, etcParam);
    if (HttpRequest.hasError(response, true)) {
      setEtcDatas([]);
      searching(false);
      return;
    }
    setEtcDatas(response.corpAcntSumTxnList);
    searching(false);
  };
  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    list();
    return true;
  };
  const handleClickSearch = (condition) => {
    setParam(condition);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  const handleDoubleClick = (index) => {
    const clickData = datas[index];
    setLayerType("input");
    setViewData(clickData);
    listEtc(clickData);
  };

  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };

  const handleClickDel = async (data) => {
    let ret = false;
    let msg = "";
    console.log(data);

    if (window.confirm("해당 정보를 삭제 하시겠습니까?")) {
      ret = await edit(API.DELETE, { corpAcntSumTxnList: [data] });
      msg = "삭제되었습니다.";
    }

    if (ret) {
      let etcData = etcDatas.filter((item) => {
        return item.seq !== data.seq;
      });

      setEtcDatas(etcData);

      if (msg) props.toast.error(msg);
    }
  };
  const handleClickSave = async (saveType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (saveType) {
      case "create":
        ret = await edit(API.CREATE, { corpAcntSumTxnList: data });
        msg = "수기입력 저장되었습니다.";
        break;
      default:
        console.log(saveType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };
  return (
    <div className="DpstTxnList">
      <DpstTxnListConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <div className="Bankbook">
          <img src={WonIcon} alt="" />
          통장잔액 :{" "}
          <span className="roboto">{props.formatter(acntBlnc, "amount")}</span>
          원
        </div>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <article className="grid_data">
        
        <div className="thead HasTD">
          <table>
            <colgroup>
              {[
                "14.7%",
                "12.5%",
                "12.5%",
                "12.5%",
                "12.5%",
                "12.5%",
                "12.5%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                <th rowSpan="2">발송일</th>
                <th colSpan="2">자동입력</th>
                <th colSpan="4">수기입력</th>
                <th rowSpan="2">계좌잔액</th>
              </tr>
              <tr>
                {[
                  "예치금",
                  "예치해지금",
                  "예수금",
                  "예금이자",
                  "예수금 반제",
                  "출금",
                ].map((i, key) => {
                  return <td key={key}>{i}</td>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="holdTable">
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {[
                  "14.7%",
                  "12.5%",
                  "12.5%",
                  "12.5%",
                  "12.5%",
                  "12.5%",
                  "12.5%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={8}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    let layer = true;

                    if (item.setlDate === " 이월잔고") layer = false;
                    return (
                      <tr key={key}>
                        <th>
                          {layer
                            ? props.formatter(item.setlDate, "date")
                            : item.setlDate}
                        </th>
                        <td className="amount">
                          {props.formatter(item.dpsAmt, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.indivOtpayAmt, "amount")}
                        </td>
                        <td
                          className={"amount " + (layer ? "hand" : "")}
                          onDoubleClick={() =>
                            layer ? handleDoubleClick(key) : ""
                          }
                        >
                          {props.formatter(item.despAmt, "amount")}
                        </td>
                        <td
                          className={"amount " + (layer ? "hand" : "")}
                          onDoubleClick={() =>
                            layer ? handleDoubleClick(key) : ""
                          }
                        >
                          {props.formatter(item.despIntr, "amount")}
                        </td>
                        <td
                          className={"amount " + (layer ? "hand" : "")}
                          onDoubleClick={() =>
                            layer ? handleDoubleClick(key) : ""
                          }
                        >
                          {props.formatter(item.rfndAmt, "amount")}
                        </td>
                        <td
                          className={"amount " + (layer ? "hand" : "")}
                          onDoubleClick={() =>
                            layer ? handleDoubleClick(key) : ""
                          }
                        >
                          {props.formatter(item.otpay, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.cfwdBlnc, "amount")}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="tfoot">
          <table>
            <colgroup>
              {[
                "14.7%",
                "12.5%",
                "12.5%",
                "12.5%",
                "12.5%",
                "12.5%",
                "12.5%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!totalData || totalData.length === 0 ? (
                <></>
              ) : (
                totalData.map((item, key) => {
                  return (
                    <tr key={key}>
                      <th>
                        <img src={IconTotalPlus} alt="" />
                        합계
                      </th>
                      <td className="amount">
                        {props.formatter(item.dpsAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.indivOtpayAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.despAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.rfndAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.despIntr, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.otpay, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.cfwdBlnc, "amount")}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        
      </article>
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"예치내역"}
          className={"DpstTxnPopup"}
          editTable={
            <DpstTxnEnterPopup
              layerType={layerType}
              viewData={viewData}
              etcDatas={etcDatas}
              onClose={handleClickClose}
              onSave={handleClickSave}
              onDel={handleClickDel}
              cretFlag={cretFlag}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

DpstTxnList.propTypes = {};

export default withBase(DpstTxnList);
