import {
  DeliveryTxn,
  RptDatPrt,
  SetlTxn,
  FtasSetl,
  DeliveryTxnStats,
  SetlTxnStats,
  Finnc,
  CmsList,
  PauseReqList,
  PauseRmvList,
  PauseAdmList,
  SetlSumTxn,
  DailyFeeSum,
  SalesSumTxn,
  FareFeeTxn,
  TrmSumSheet,
  TrmFee,
  RmtFeeSmryCorp,
  DpstTxn,
  DsblTxn,
  AcntDpsTxn,
  ApdOtpayTxn,
  AcruDclaTxn,
  ReadyMoneyTxn,
  SmsTrmTxn,
  IndvSmsTrmTxn,
  SmsTrmHst,
  PlcfbAbookTxn,
  PlcfbList,
  Notification,
  RoleMgt,
  CorpInfo,
  PlcfbInfo,
  UserInfo,
  CarInfo,
  FeeInfo,
  DashBoard,
} from "views";

const componentMapping = {
  DeliveryTxn: DeliveryTxn,
  RptDatPrt: RptDatPrt,
  SetlTxn: SetlTxn,
  FtasSetl: FtasSetl,
  DeliveryTxnStats: DeliveryTxnStats,
  SetlTxnStats: SetlTxnStats,
  Finnc: Finnc,
  CmsList : CmsList,
  PauseReqList : PauseReqList,
  PauseRmvList : PauseRmvList,
  PauseAdmList : PauseAdmList,
  SetlSumTxn: SetlSumTxn,
  SalesSumTxn: SalesSumTxn,
  DailyFeeSum: DailyFeeSum,
  FareFeeTxn: FareFeeTxn,
  TrmSumSheet: TrmSumSheet,
  TrmFee: TrmFee,
  RmtFeeSmryCorp: RmtFeeSmryCorp,
  DpstTxn: DpstTxn,
  DsblTxn: DsblTxn,
  AcntDpsTxn: AcntDpsTxn,
  ApdOtpayTxn: ApdOtpayTxn,
  AcruDclaTxn: AcruDclaTxn,
  ReadyMoneyTxn: ReadyMoneyTxn,
  SmsTrmTxn: SmsTrmTxn,
  IndvSmsTrmTxn: IndvSmsTrmTxn,
  SmsTrmHst: SmsTrmHst,
  PlcfbAbookTxn: PlcfbAbookTxn,
  PlcfbList: PlcfbList,
  Notification: Notification,
  RoleMgt: RoleMgt,
  CorpInfo: CorpInfo,
  PlcfbInfo: PlcfbInfo,
  UserInfo: UserInfo,
  CarInfo: CarInfo,
  FeeInfo: FeeInfo,
  DashBoard: DashBoard,
};

const iconMapping = {
  //   AttachMoneyIcon: {
  //     icon: AttachMoneyIcon,
  //   },
  //   SettingApplicationsIcon: {
  //     icon: SettingApplicationsIcon,
  //   },
};

const handleRoutes = (data) => {
  if (!data) return [];

  console.log(data);
  const routes = data
    .filter((item) => {
      const { menuYn } = item;
      return menuYn === "Y";
    })
    .map((item) => {
      const {
        riId,
        riPtrn,
        riNm,
        upRiId,
        componentName,
        iconName,
        cretFlag,
      } = item;
      return {
        id: riId,
        path: riPtrn,
        name: riNm,
        parentId: upRiId,
        component: componentMapping[componentName],
        iconName: iconMapping[iconName],
        cretFlag: cretFlag,
      };
    });

  if (Array.isArray(routes) && routes.length > 0) {
    return routes;
  }

  console.assert(Array.isArray(routes) && routes.length > 0, "menus is empty");

  return null;
};

const handleMenus = (routes) => {
  if (routes) {
    const idMapping = routes.reduce((acc, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});
    let root;
    routes.forEach((el) => {
      if (!el.parentId) {
        root = el;
        return;
      }
      const parentEl = routes[idMapping[el.parentId]];
      if (parentEl) {
        parentEl.state = parentEl.name;
        parentEl.views = [...(parentEl.views || []), el];
      } else {
        console.log(el.parentId);
      }
    });

    if (root) {
      return root.views;
    }
  }

  return null;
};

export { handleRoutes, handleMenus };
