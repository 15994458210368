import React from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Logo.css";

const Logo = (props) => {
  const { src, link } = props;
  return (
    <h1 className="Logo">
      {/* <Link to={link}> */}
      <img src={src} alt="logo" />
      {/* </Link> */}
    </h1>
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Logo;
