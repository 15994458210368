import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PlcfbList.css";
import {
  withBase,
  ControlBox,
  Button,
  LayerPopup,
  PopupWrapper,
  Pagination,
} from "components";
import PlcfbListConditionBox from "./PlcfbListConditionBox";
import PlcfbListTable from "./PlcfbListTable";
import PlcfbListEnterPopup from "./PlcfbListEnterPopup";
import moment from "moment";

const API = {
  LIST: {
    url: "api/v1/plcfb/plcfbList/list",
    method: "post",
  },
  LISTDAILY: {
    url: "api/v1/plcfb/plcfbList/listdaily",
    method: "post",
  },
  DOWN: {
    url: "api/v1/plcfb/plcfbList/downlistDaily",
    method: "post",
  },
  DOWNMONTH: {
    url: "api/v1/plcfb/plcfbList/downlistMonth",
    method: "post",
  },
};

const PlcfbList = (props) => {
  const [param, setParam] = useState({
    setlDate: moment().add(-1, "days").format("YYYYMM"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState(null);
  const [viewData, setViewData] = useState();

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.plcfbAbookTxnList);
    searching(false);
  };

  const popupList = async (popupParam) => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LISTDAILY;
    console.log(popupParam);
    const response = await HttpRequest.request(url, method, popupParam);
    if (HttpRequest.hasError(response, true)) {
      setViewData(null);
      searching(false);
      return;
    }
    setViewData(response.plcfbAbookTxnList);
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam({ ...condition, pageNo: 1 });
  };

  const handleClickDownMon = async () => {
    const { searching, downExcel } = props;

    searching(true, "엑셀 다운로드중입니다.");
    const { url } = API.DOWNMONTH;

    await downExcel.request(url, param);
    searching(false);
  };

  const handleClickDown = async () => {
    const { searching, downExcel, toast } = props;

    if (!(param.plcfbName || param.carName || param.managerName)) {
      toast.error(
        "영업소, 차량명, 소장명 셋중 하나는 입력한 조회 데이터만 일별 다운로드 가능합니다.",
      );
      return;
    }

    searching(true, "엑셀 다운로드중입니다.");
    const { url } = API.DOWN;

    await downExcel.request(url, param);
    searching(false);
  };

  const handleDoubleClick = (index) => {
    setLayerType("view");
    popupList(datas[index]);
  };

  const handleClickClose = () => {
    setLayerType(null);
    setViewData();
  };

  return (
    <div className="PlcfbList">
      <PlcfbListConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="excelMon" onClick={handleClickDownMon}>
          월간 엑셀 다운로드
        </Button>
        <Button className="excel" onClick={handleClickDown}>
          일별 엑셀 다운로드
        </Button>
      </ControlBox>
      <PlcfbListTable
        datas={datas}
        onDoubleClick={handleDoubleClick}
        formatter={props.formatter}
      />
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"영업소리스트"}
          className={"PlcfbListPopup"}
          viewTable={
            <PlcfbListEnterPopup
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

PlcfbList.propTypes = {};

export default withBase(PlcfbList);
