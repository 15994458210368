import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, ConditionBox, DatePickerField, CodeSelect } from "components";
import moment from "moment";

const ReadyMoneyTxnCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            rdymnyDateFrom: moment().add(-1, "days"),
            rdymnyDateTo: moment().add(-1, "days"),
            acntNo: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { rdymnyDateFrom, rdymnyDateTo, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              rdymnyDateFrom: moment(values.rdymnyDateFrom).format("YYYYMMDD"),
              rdymnyDateTo: moment(values.rdymnyDateTo).format("YYYYMMDD"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            rdymnyDateFrom: Yup.date().required().nullable(),
            rdymnyDateTo: Yup.date()
              .required()
              .nullable()
              .when("rdymnyDateFrom", (rdymnyDateFrom, schema) =>
                rdymnyDateFrom
                  ? schema.min(rdymnyDateFrom, "기간을 확인하세요.")
                  : schema
              ),
            acntNo: Yup.string().required(),
          })}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              조회기간<i></i>
                              <span>
                                {errors.rdymnyDateFrom && touched.rdymnyDateFrom
                                  ? errors.rdymnyDateFrom
                                  : errors.rdymnyDateTo && touched.rdymnyDateTo
                                  ? errors.rdymnyDateTo
                                  : ""}
                              </span>
                            </dt>
                            <dd className="Double">
                              <DatePickerField
                                name="rdymnyDateFrom"
                                date={values.rdymnyDateFrom}
                              />
                              ~{" "}
                              <DatePickerField
                                name="rdymnyDateTo"
                                date={values.rdymnyDateTo}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>
                              계좌<i></i>
                              <span>
                                {errors.acntNo &&
                                  touched.acntNo &&
                                  errors.acntNo}
                              </span>
                            </dt>
                            <dd>
                              <CodeSelect
                                codeId="RDYMNY_ACNT_NO"
                                name="acntNo"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"계좌 선택"}
                                value={values.acntNo}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

ReadyMoneyTxnCondition.propTypes = {};

export default ReadyMoneyTxnCondition;
