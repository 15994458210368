import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./FeeInfoHD.css";
import { Button, ControlBox, CheckBox as Checkbox, withBase } from "components";
import { useSelector } from "react-redux";
import DetailArrowIcon from "assets/images/right_arrow_black.png";

const API = {
  LIST: {
    url: "api/v1/comn/feeInfo/list",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/comn/feeInfo/update",
    method: "post",
  },
};

const FeeInfoHD = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({ phydsCorpDiv: "02" });
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      searching(false);
      return;
    }
    setDatas(response.feeInfoBasList);
    searching(false);
  };

  const handleClickCheckBox = (feeCd, value) => {
    setDatas(
      datas.map((item) => (item.feeCd === feeCd ? { ...item, ...value } : item))
    );
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    return true;
  };

  const handleClickSave = async () => {
    let ret = false;
    let msg = "";

    ret = await edit(API.UPDATE, { feeInfoBasList: datas });
    msg = "수정저장되었습니다.";

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };

  const replaceStr = (str) => {
    // ie에서 replaceAll 오류로 for문으로 작성
    const replaceArr = ["&gt;", "&lt;", "&amp;", "&quot;"];
    const changeArr = [">", "<", "&", '"'];

    if (str) {
      for (let i = 0; replaceArr.length > i; i++) {
        for (let j = 0; str.length > j; j++) {
          if (str.indexOf(replaceArr[i]) === -1) break;
          str = str.replace(replaceArr[i], changeArr[i]);
        }
      }
    }

    return str;
  };

  return (
    <div className="FeeInfoHD">
      {cretFlag && (
        <ControlBox>
          <Button className="save" onClick={handleClickSave}>
            저장
          </Button>
        </ControlBox>
      )}
      <article className="grid_data">
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {[
                  "8.1%",
                  "8.1%",
                  "8.1%",
                  "8.1%",
                  "*",
                  "5.4%",
                  "5.4%",
                  "7.4%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {[
                    "구분",
                    "수수료(대)",
                    "수수료(중)",
                    "수수료(소)",
                    "내용",
                    "작업순서",
                    "사용여부",
                    "상세보기",
                  ].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody NoreSize">
            <table>
              <colgroup>
                {[
                  "8.1%",
                  "8.1%",
                  "8.1%",
                  "8.1%",
                  "*",
                  "5.4%",
                  "5.4%",
                  "7.4%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={8}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.phydsCorpName}</td>
                        <td>{item.feeLarge}</td>
                        <td>{item.feeMiddle}</td>
                        <td>{item.feeSmall}</td>
                        <td className="cnt">{replaceStr(item.feeText)}</td>
                        <td className="roboto">{item.jobOdrg}</td>
                        <td>
                          <Checkbox
                            checked={item.useYn === "Y" ? true : false}
                            onChange={() =>
                              handleClickCheckBox(item.feeCd, {
                                useYn: window.event.target.checked ? "Y" : "N",
                              })
                            }
                            disabled={!cretFlag}
                          />
                        </td>
                        <td>
                          <Link
                            to={
                              "/comn/feeInfo/" +
                              (item.feeCd === "KDC01" ? "basic" : "special") +
                              "/" +
                              param.phydsCorpDiv +
                              "/" +
                              item.feeCd
                            }
                            className="detail_btn"
                          >
                            <span>상세보기</span>
                            <img src={DetailArrowIcon} alt="" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  );
};

FeeInfoHD.propTypes = {};

export default withBase(FeeInfoHD);
