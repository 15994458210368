import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CustomInput, Button } from "components";
import moment from "moment";

const SetlTxnPopupBasicFee = (props) => {
  const { layerType, viewData, onClose, onSave } = props;

  return (
    <>
      <Formik
        initialValues={{
          charang: viewData ? viewData.charang : "",
          chulgo: viewData ? viewData.chulgo : "",
          cretDate: viewData ? viewData.cretDate : "",
          ibche: viewData ? viewData.ibche : "",
          junsan: viewData ? viewData.junsan : "",
          junsan2: viewData ? viewData.junsan2 : "",
          kita02: viewData ? viewData.kita02 : "",
          no0s: viewData ? viewData.no0s : "",
          no1s: viewData ? viewData.no1s : "",
          no13s: viewData ? viewData.no13s : "",
          no14c: viewData ? viewData.no14c : "",
          no14h: viewData ? viewData.no14h : "",
          no14s: viewData ? viewData.no14s : "",
          no19s: viewData ? viewData.no19s : "",
          no1s: viewData ? viewData.no1s : "",
          no21s: viewData ? viewData.no21s : "",
          no22s: viewData ? viewData.no22s : "",
          no23s: viewData ? viewData.no23s : "",
          no2s: viewData ? viewData.no2s : "",
          rcode: viewData ? viewData.rcode : "",
          readName: viewData ? viewData.readName : "",
          spa1: viewData ? viewData.spa1 : "",
          spa3: viewData ? viewData.spa3 : "",
          spa5: viewData ? viewData.spa5 : "",
          spa6: viewData ? viewData.spa6 : "",
          spa7: viewData ? viewData.spa7 : "",
          srcfileCd: viewData ? viewData.srcfileCd : "",
          susu: viewData ? viewData.susu : "",
          t1Gubun: viewData ? viewData.t1Gubun : "",
          t2Gubun: viewData ? viewData.t2Gubun : "",
          temi: viewData ? viewData.temi : "",
          yunke: viewData ? viewData.yunke : "",
          yunno: viewData ? viewData.yunno : "",
          yunno2: viewData ? viewData.yunno2 : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const { readName, ...rest } = values;
          onSave(layerType, { ...rest });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          no19s: Yup.number().required(),
          temi: Yup.number().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="200" />
                    <col width="*" />
                    <col width="200" />
                    <col width="*" />
                  </colgroup>
                    <tbody>
                    <tr>
                        <th>생성일</th>
                        <td colSpan="3">
                            {moment(values.cretDate).format("YYYY.MM.DD")}
                        </td>
                    </tr>
                    <tr>
                        <th>검증결과</th>
                        <td>{values.readName}</td>
                        <th>no1s</th>
                        <td>{values.no1s}</td>
                    </tr>
                    <tr>
                        <th>
                            temi<i></i>
                            <span>
                          {errors.temi && touched.temi && errors.temi}
                        </span>
                        </th>
                        <td>
                            <CustomInput
                                name="temi"
                                type="number"
                                placeholder="temi 입력"
                                onChange={handleChange}
                                value={values.temi}
                            />
                        </td>
                        <th>junsan2</th>
                        <td>
                            <CustomInput
                                name="junsan2"
                                type="number"
                                placeholder="junsan2 입력"
                                onChange={handleChange}
                                value={values.junsan2}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>spa1</th>
                        <td>
                            <CustomInput
                                name="spa1"
                                type="number"
                                placeholder="spa1 입력"
                                onChange={handleChange}
                                value={values.spa1}
                            />
                        </td>
                        <th>spa3</th>
                        <td>{values.spa3}</td>
                    </tr>
                    <tr>
                        <th>spa5</th>
                        <td>
                            <CustomInput
                                name="spa5"
                                type="number"
                                placeholder="spa5 입력"
                                onChange={handleChange}
                                value={values.spa5}
                            />
                        </td>
                        <th>spa6</th>
                        <td>
                            <CustomInput
                                name="spa6"
                                type="number"
                                placeholder="spa6 입력"
                                onChange={handleChange}
                                value={values.spa6}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>spa7</th>
                        <td>
                            <CustomInput
                                name="spa7"
                                type="number"
                                placeholder="spa7 입력"
                                onChange={handleChange}
                                value={values.spa7}
                            />
                        </td>
                        <th>-</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>charang</th>
                        <td>
                            <CustomInput
                                name="charang"
                                type="number"
                                placeholder="charang 입력"
                                onChange={handleChange}
                                value={values.charang}
                            />
                        </td>
                        <th>yunno</th>
                        <td>
                            <CustomInput
                                name="yunno"
                                type="number"
                                placeholder="yunno 입력"
                                onChange={handleChange}
                                value={values.yunno}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>yunke</th>
                        <td>
                            <CustomInput
                                name="yunke"
                                type="number"
                                placeholder="yunke 입력"
                                onChange={handleChange}
                                value={values.yunke}
                            />
                        </td>
                        <th>yunno2</th>
                        <td>
                            <CustomInput
                                name="yunno2"
                                type="number"
                                placeholder="yunno2 입력"
                                onChange={handleChange}
                                value={values.yunno2}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>junsan</th>
                        <td>
                            <CustomInput
                                name="junsan"
                                type="number"
                                placeholder="junsan 입력"
                                onChange={handleChange}
                                value={values.junsan}
                            />
                        </td>
                        <th>susu</th>
                        <td>{values.susu}</td>
                    </tr>
                    <tr>
                        <th>chulgo</th>
                        <td>{values.chulgo}</td>
                        <th>
                            no19s<i></i>
                            <span>
                          {errors.no19s && touched.no19s && errors.no19s}
                        </span>
                        </th>
                        <td>
                            <CustomInput
                                name="no19s"
                                type="number"
                                placeholder="no19s 입력"
                                onChange={handleChange}
                                value={values.no19s}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>no14s</th>
                        <td>{values.no14s}</td>
                        <th>ibche</th>
                        <td>{values.ibche}</td>
                    </tr>
                    <tr>
                        <th>no14h</th>
                        <td>{values.no14h}</td>
                        <th>no13s</th>
                        <td>{values.no13s}</td>
                    </tr>
                    <tr>
                        <th>no14c</th>
                        <td>{values.no14c}</td>
                        <th>no21s</th>
                        <td>
                            <CustomInput
                                name="no21s"
                                type="text"
                                placeholder="no21s 입력"
                                onChange={handleChange}
                                value={values.no21s}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>t1_gubun</th>
                        <td>{values.t1Gubun}</td>
                        <th>no22s</th>
                        <td>
                            <CustomInput
                                name="no22s"
                                type="text"
                                placeholder="no22s 입력"
                                onChange={handleChange}
                                value={values.no22s}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>t2_gubun</th>
                        <td>{values.t2Gubun}</td>
                        <th>no23s</th>
                        <td>
                            <CustomInput
                                name="no23s"
                                type="text"
                                placeholder="no23s 입력"
                                onChange={handleChange}
                                value={values.no23s}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>no0s</th>
                        <td>{values.no0s}</td>
                        <th>rcode</th>
                        <td>{values.rcode}</td>
                    </tr>
                    <tr>
                        <th>no2s</th>
                        <td>{values.no2s}</td>
                        <th>kita02</th>
                        <td>{values.kita02}</td>
                    </tr>
                    </tbody>
                </table>
              </article>
                <div className="PopupButton">
                    <Button
                        type="submit"
                        className="insert"
                        disabled={isSubmitting}
                    >
                        저장
                    </Button>
                    <Button className="close" onClick={onClose}>
                        닫기
                    </Button>
                </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

SetlTxnPopupBasicFee.propTypes = {};

export default SetlTxnPopupBasicFee;
