import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DpstTxnEnterPopup.css";
import { withBase, Button, CustomInput, FormatInput } from "components";

const DpstTxnEnterPopup = (props) => {
  const {
    viewData,
    etcDatas,
    onClose,
    onSave,
    onDel,
    formatter,
    cretFlag,
  } = props;
  const [addRowSeq, setAddRowSeq] = useState(0);
  const [addRows, setAddRows] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleClickAddRow = () => {
    let newRow = {
      id: addRowSeq,
      repaDate: viewData.setlDate,
      corpCd: viewData.corpCd,
      acntDiv: viewData.acntDiv,
      despAmt: 0,
      rfndAmt: 0,
      despIntr: 0,
      otpay: 0,
      etcOtpay: 0,
      amdrId: "",
      remarks: "",
    };

    setAddRowSeq(addRowSeq + 1);
    setAddRows([...addRows, newRow]);
  };

  const handleClickDelRow = (id) => {
    let delRows = addRows.filter((row) => {
      return row.id !== id;
    });

    setAddRows(delRows);
  };

  const handleChange = (id) => (e) => {
    const {
      target: { name, value },
    } = e;

    const tempRows = addRows.map((row) => {
      if (row.id === id) {
        row[name] = value;
      }
      return row;
    });

    setAddRows(tempRows);
  };

  const handleClickSave = () => {
    if (addRows.length === 0) {
      props.toast.error("저장할 내용이 없습니다.");
      return;
    }

    setIsSaving(true);

    let msg = "";

    for (let i = 0; i < addRows.length; i++) {
      if (Number(addRows[i].despAmt) < 0) msg = "예수금을 확인하세요.";
      if (Number(addRows[i].rfndAmt) < 0) msg = "반제를 확인하세요.";
      if (Number(addRows[i].despIntr) < 0) msg = "예금이자를 확인하세요.";
      if (Number(addRows[i].etcOtpay) < 0) msg = "송금을 확인하세요.";

      if (msg) break;

      if (
        Number(addRows[i].despAmt) +
          Number(addRows[i].rfndAmt) +
          Number(addRows[i].despIntr) +
          Number(addRows[i].etcOtpay) <=
        0
      ) {
        msg = "모든 금액이 0인 데이터가 존재합니다.";
      }

      if (msg) break;
    }

    if (msg) {
      props.toast.error(msg);
    } else {
      let createRows = addRows.map((item) => {
        return {
          ...item,
          despAmt: formatter(item.despAmt, "toValue"),
          rfndAmt: formatter(item.rfndAmt, "toValue"),
          despIntr: formatter(item.despIntr, "toValue"),
          etcOtpay: formatter(item.etcOtpay, "toValue"),
        };
      });

      onSave("create", createRows);
    }

    setIsSaving(false);
  };

  return (
    <div className="DpstTxnEnterPopup">
      <article className="popup_grid">
        <table>
          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
            <col width="*" />
          </colgroup>
          <thead>
            <tr>
              <th>발송일</th>
              <th>예치금</th>
              <th>예치해지금</th>
              <th>계좌잔액</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatter(viewData.setlDate, "date")}</td>
              <td className="amount">{formatter(viewData.dpsAmt, "amount")}</td>
              <td className="amount">
                {formatter(viewData.indivOtpayAmt, "amount")}
              </td>
              <td className="amount">
                {formatter(viewData.cfwdBlnc, "amount")}
              </td>
            </tr>
          </tbody>
        </table>
        {cretFlag && (
          <div className="btn_area">
            <Button className="add" onClick={handleClickAddRow}>
              추가
            </Button>
          </div>
        )}
        <div className="secondTB">
          <table>
            <colgroup>
              <col width="14.8%" />
              <col width="14.4%" />
              <col width="14.6%" />
              <col width="14.6%" />
              <col width="14.6%" />
              <col width="*" />
              <col width="11.9%" />
            </colgroup>
            <thead>
              <tr>
                <th>예수금</th>
                <th>예금이자</th>
                <th>예수금 반제</th>
                <th>송금(소득세)</th>
                <th className="RegID">등록자아이디</th>
                <th>비고</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {etcDatas &&
                etcDatas.map((item, key) => {
                  return (
                    <>
                      <tr key={key}>
                        <td className="amount">
                          {formatter(item.despAmt, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.despIntr, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.rfndAmt, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.etcOtpay, "amount")}
                        </td>
                        <td>{item.amdrId}</td>
                        <td className="tal">{item.remarks}</td>
                        <td>
                          {cretFlag && (
                            <Button
                              className="delete"
                              onClick={() => onDel(etcDatas[key])}
                            >
                              삭제
                            </Button>
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
            <tfoot>
              {addRows.length !== 0 &&
                addRows.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <FormatInput
                          formatType="amount"
                          name="despAmt"
                          placeholder="입력"
                          onChange={handleChange(item.id)}
                          value={item.despAmt}
                        />
                      </td>
                      <td>
                        <FormatInput
                          formatType="amount"
                          name="despIntr"
                          placeholder="입력"
                          onChange={handleChange(item.id)}
                          value={item.despIntr}
                        />
                      </td>
                      <td>
                        <FormatInput
                          formatType="amount"
                          name="rfndAmt"
                          placeholder="입력"
                          onChange={handleChange(item.id)}
                          value={item.rfndAmt}
                        />
                      </td>
                      <td>
                        <FormatInput
                          formatType="amount"
                          name="etcOtpay"
                          placeholder="입력"
                          onChange={handleChange(item.id)}
                          value={item.etcOtpay}
                        />
                      </td>
                      <td>{item.amdrId}</td>
                      <td>
                        <CustomInput
                          name="remarks"
                          type="text"
                          placeholder="비고입력"
                          onChange={handleChange(item.id)}
                          value={item.remarks}
                          maxLength={50}
                        />
                      </td>
                      <td>
                        <Button
                          className="delete"
                          onClick={() => handleClickDelRow(item.id)}
                        >
                          삭제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tfoot>
          </table>
        </div>
      </article>
      <div className="PopupButton">
        {cretFlag && (
          <Button
            className="insert"
            disabled={isSaving}
            onClick={handleClickSave}
          >
            저장
          </Button>
        )}
        <Button className="close" onClick={onClose}>
          닫기
        </Button>
      </div>
    </div>
  );
};

DpstTxnEnterPopup.propTypes = {};

export default withBase(DpstTxnEnterPopup);
