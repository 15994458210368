import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

const data = {
  labels: ["군포", "고촌", "양산", "칠곡", "장성", "평택", "영영"],
  datasets: [
    {
      label: "My First dataset",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 60, 80, 81, 56, 55, 40],
    },
    {
      label: "My First dataset",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 60, 80, 81, 56, 55, 40],
    },
  ],
};

const options = {
  legend: {
    position: "bottom",
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    ],
  },
};

const plugins = [
  {
    // afterDraw: (chartInstance, easing) => {
    //   const ctx = chartInstance.chart.ctx;
    //   ctx.fillText("This text drawn by a plugin", 100, 100);
    // },
  },
];

export default function TdayTerminalChrt() {
  const chart = React.useRef();
  return (
    <div className="chart">
      <h3>당일 터미널별 건수</h3>
      <Bar ref={chart} data={data} options={options} plugins={plugins} />
    </div>
  );
}
