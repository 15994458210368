import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PlcfbInfo.css";
import {
  withBase,
  Button,
  Table,
  Pagination,
  ControlBox,
  LayerPopup,
  PopupWrapper,
} from "components";
import { useSelector } from "react-redux";
import PlcfbInfoConditionBox from "./PlcfbInfoConditionBox";
import PlcfbInfoViewTable from "./PlcfbInfoViewTable";
import PlcfbInfoEditTable from "./PlcfbInfoEditTable";

const API = {
  LIST: {
    url: "api/v1/comn/plcfbInfo/list",
    method: "post",
  },
  CREATE: {
    url: "api/v1/comn/plcfbInfo/create",
    method: "post",
  },
  SIMPLE: {
    url: "api/v1/comn/plcfbInfo/updateSimple",
    method: "post",
  },
  NAMECHANGE: {
    url: "api/v1/comn/plcfbInfo/updateNameChange",
    method: "post",
  },
  DOWN: {
    url: "api/v1/comn/plcfbInfo/down",
    method: "post",
  },
  REFRESH: {
    url: "api/v1/comn/cache/refresh/plcfbInfo",
    method: "get",
  },
};

const PlcfbInfo = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({ pageNo: 1 });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.plcfbInfoList);
    searching(false);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    refresh();
    return true;
  };

  const refresh = async () => {
    const { HttpRequest } = props;

    const response = await HttpRequest.request(
      API.REFRESH.url,
      API.REFRESH.method,
    );
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...param,
      ...condition,
      pageNo: 1,
    });
  };
  const handleDoubleClick = (index) => {
    setLayerType("view");
    setViewData(datas[index]);
  };
  const handleClickNew = () => {
    setLayerType("new");
  };
  const handleClickEdit = () => {
    setLayerType("edit");
  };
  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const isDuplicate = (data) => {
    let arr = [];

    if (data) {
      if (data.dpsNote1) arr.push(data.dpsNote1);
      if (data.dpsNote2) arr.push(data.dpsNote2);
      if (data.dpsNote3) arr.push(data.dpsNote3);
      if (data.dpsNote4) arr.push(data.dpsNote4);
      if (data.dpsNote5) arr.push(data.dpsNote5);
    }

    const isDup = arr.some((item) => {
      return arr.indexOf(item) !== arr.lastIndexOf(item);
    });

    if (isDup) {
      props.toast.error("중복된 입금적요가 있습니다.");
    }

    return isDup;
  };

  const handleClickSave = async (layerType, updateType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "new":
        if (!isDuplicate(data)) {
          ret = await edit(API.CREATE, data);
          msg = "신규등록되었습니다.";
        }
        break;
      case "edit":
        if (!isDuplicate(data)) {
          if (updateType === "SIMPLE") {
            ret = await edit(API.SIMPLE, data);
          } else {
            ret = await edit(API.NAMECHANGE, data);
          }
          msg = "수정저장되었습니다.";
        }
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="PlcfbInfo">
      <PlcfbInfoConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        {cretFlag && (
          <Button className="insert" onClick={handleClickNew}>
            신규등록
          </Button>
        )}
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <article className="grid_data">
        <div className="TableArea">
          <Table
            widths={[
              "4.7%",
              "7.2%",
              "8.0%",
              "8.3%",
              "7.4%",
              "8.7%",
              "10.1%",
              "6.7%",
              "8.0%",
              "7.7%",
              "7.8%",
              "7.4%",
              "*",
            ]}
            columns={[
              "순번",
              "영업소코드",
              "영업소명",
              "사업자번호",
              "소장명",
              "주민번호",
              "계좌번호",
              "계좌은행",
              "연락처",
              "한도액",
              "기초잔액",
              "물류법인",
              "영업여부",
            ]}
            datas={
              datas &&
              datas.map((item, key) => {
                return (
                  <>
                    <td>{item.rowNum}</td>
                    <td>{item.orgnCd}</td>
                    <td className="tal">{item.plcfbName}</td>
                    <td>{props.formatter(item.bizno, "bizno", false)}</td>
                    <td>{props.formatter(item.managerName, "name", false)}</td>
                    <td>{props.formatter(item.custNo, "custNo")}</td>
                    <td>{props.formatter(item.acntNo, "account", false)}</td>
                    <td>{item.acntBankName}</td>
                    <td>{props.formatter(item.cntcNo, "phone", false)}</td>
                    <td className="amount">
                      {props.formatter(item.limitAmt, "amount")}
                    </td>
                    <td className="amount">
                      {props.formatter(item.firstBlnc, "amount")}
                    </td>
                    <td>{item.phydsCorpName}</td>
                    {item.mktYn === "Y" ? (
                      <td className="TextColorRed">영업중</td>
                    ) : (
                      <td>영업중지</td>
                    )}
                  </>
                );
              })
            }
            onDoubleClick={handleDoubleClick}
          />
        </div>
      </article>

      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"영업소관리"}
          className={"PlcfbInfoPopup"}
          viewTable={
            <PlcfbInfoViewTable
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onEdit={handleClickEdit}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          editTable={
            <PlcfbInfoEditTable
              viewData={viewData}
              layerType={layerType}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

PlcfbInfo.propTypes = {};

export default withBase(PlcfbInfo);
