import React from "react";
import PropTypes from "prop-types";
import { withBase, Button } from "components";

const NotificationViewTable = (props) => {
  const {
    layerType,
    viewData,
    onClose,
    onEdit,
    onSave,
    formatter,
    cretFlag,
  } = props;
  let data = [];
  if (viewData) {
    data = [
      {
        name: "제목",
        value: viewData.ntfTitle,
      },
      {
        name: "공지기간",
        value:
          formatter(viewData.pstngStartDate, "date") +
          " ~ " +
          formatter(viewData.pstngFinishDate, "date"),
      },
      {
        name: "등록일자",
        value: formatter(viewData.regDt, "date"),
      },
      {
        name: "내용",
        value: <div className="TextContent">{viewData.ntfSbst}</div>,
      },
    ];
  }
  return (
    <>
      <article className="popup_grid">
        <table>
          <colgroup>
            <col width="200" />
            <col width="*" />
          </colgroup>
          <tbody>
            {data &&
              data.map((item, key) => {
                return (
                  <tr key={key}>
                    <th>{item.name}</th>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </article>
      <div className="PopupButton">
        {layerType === "dashboard" || !cretFlag ? (
          <></>
        ) : (
          <>
            <Button className="insert" onClick={onEdit}>
              수정
            </Button>
            <Button
              className="delete"
              onClick={() => {
                onSave("del", {
                  regSeq: viewData.regSeq,
                });
              }}
            >
              삭제
            </Button>
          </>
        )}
        <Button className="close" onClick={onClose}>
          닫기
        </Button>
      </div>
    </>
  );
};

NotificationViewTable.propTypes = {};

export default withBase(NotificationViewTable);
