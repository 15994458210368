import React from "react";
import PropTypes from "prop-types";
import "./CustomInput.css";
import numeral from "numeral";

const FormatInput = (props) => {
  const {
    formatType,
    placeholder,
    value,
    onChange,
    onBlur,
    disabled,
    name,
  } = props;

  const phoneNum = (phoneNum, masking = false) => {
    phoneNum = phoneNum.replace(/[-]/g, "");
    if (masking) {
      return phoneNum.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        "$1-****-$3"
      );
    } else {
      return phoneNum.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        "$1-$2-$3"
      );
    }
  };

  const custNo = (custNo, masking = false) => {
    custNo = custNo.replace(/[-]/g, "");
    if (masking) {
      return custNo.replace(/([0-9]{6})([0-9]{1})([0-9]+)/, "$1-$2******");
    } else {
      return custNo.replace(/([0-9]{6})([0-9]{1})/, "$1-$2");
    }
  };

  const bizno = (bizno, masking = false) => {
    bizno = bizno.replace(/[-]/g, "");
    if (masking) {
      return bizno.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-*****");
    } else {
      return bizno.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
    }
  };

  const account = (account, masking = false) => {
    account = account.replace(/[-]/g, "");
    if (masking) {
      return account.replace(/(\d{3})(\d{4})(\d{4})(\d{2})/, "$1-****-****-$4");
    } else {
      return account.replace(/(\d{3})(\d{4})(\d{4})(\d{2})/, "$1-$2-$3-$4");
    }
  };

  const fee = (fee) => {
    let len = fee.length;
    let index = fee.indexOf(".");

    if (len === 0 || index === 0) {
      return 0;
    } else if (index === len - 1) {
      return fee;
    } else if (index > 0) {
      let str1 = fee.slice(0, index + 1);
      let str2 = fee.slice(index);
      str2 = str2.replace(/[.]/g, "");
      return str2 ? str1.concat(str2) : str1;
    } else {
      return Number(fee);
    }
  };

  const formatter = (value, type) => {
    let newValue = "";
    switch (type) {
      case "amt": // 금액 null 허용
        newValue = value ? numeral(value).format("0,0") : value;
        break;
      case "amount": // 금액
        newValue = numeral(value).format("0,0");
        break;
      case "fee": // 수수료율
        newValue = fee(value);
        break;
      case "phone": // 전화번호
        newValue = phoneNum(value);
        break;
      case "custNo": // 주민등록번호
        newValue = custNo(value, true);
        break;
      case "bizno": // 사업자번호
        newValue = bizno(value);
        break;
      case "account": // 농협 계좌번호
        newValue = account(value);
        break;
      default:
        break;
    }

    return newValue;
  };

  const formatLength = (type) => {
    let newValue = 13;
    switch (type) {
      case "amt": // 금액
        newValue = 17;
        break;
      case "amount": // 금액
        newValue = 14;
        break;
      case "fee": // 수수료율
        newValue = 7;
        break;
      case "phone": // 전화번호
        newValue = 13;
        break;
      case "custNo": // 주민번호
        newValue = 14;
        break;
      case "bizno": // 사업자번호
        newValue = 12;
        break;
      case "account": // 농협 계좌번호
        newValue = 16;
        break;
      default:
        break;
    }

    return newValue;
  };

  return (
    <input
      type="text"
      name={name}
      className="CustomInput"
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onChange={(e) => {
        e.target.value = formatter(e.target.value, formatType);
        onChange(e);
      }}
      onKeyPress={(e) => {
        if (
          e.which &&
          (e.which <= 47 || e.which >= 58) &&
          e.which !== 8 &&
          !(formatType === "fee" && e.which === 46)
        ) {
          e.preventDefault();
        }
      }}
      disabled={disabled}
      maxLength={formatLength(formatType)}
    />
  );
};

FormatInput.propTypes = {};

export default FormatInput;
