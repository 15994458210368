import React from "react";
import PropTypes from "prop-types";
import "./CustomMessage.css";
import { Button } from "components";

const CustomMessage = (props) => {
  const { children, onClose, onConfirm, messageType } = props;

  return (
    <div className="CustomMessage">
      <div className="PopupMessage">{children}</div>
      <div className="PopupButton">
        {messageType === "confirm" ? (
          <>
            <Button className="update" onClick={onConfirm}>
              계속
            </Button>
            <Button className="confirm" onClick={onClose}>
              취소
            </Button>
          </>
        ) : (
          <Button className="confirm" onClick={onClose}>
            확인
          </Button>
        )}
      </div>
    </div>
  );
};

CustomMessage.propTypes = {};

export default CustomMessage;
