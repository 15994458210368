import queryString from "query-string";
import { properties } from "variables/properties";

const GET_USER = {
  method: "get",
  url: "api/v1/auth/me",
};
const SIGNIN = {
  method: "post",
  url: "api/v1/auth/signin",
};
const SIGNUP = {
  method: "post",
  url: "api/v1/auth/signup",
};
const SIGNOUT = {
  method: "post",
  url: "api/v1/auth/signout",
};
const GET_MENU = {
  method: "post",
  url: "api/v1/comn/menu/list",
};
const GET_CODE = {
  method: "get",
  url: "api/v1/comn/cache/info/cdGrpInfo",
};

export { GET_USER, SIGNIN, SIGNUP, SIGNOUT, GET_MENU, GET_CODE };
