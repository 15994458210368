import { createAction, handleActions } from "redux-actions";

// import { Map, fromJS } from "immutable";
import { fromJS } from "immutable";
// import { pender } from "redux-pender";
// import * as api from "../apis";

// action types
const INITIALIZE = "menu/INITIALIZE";
const SAVE = "menu/SAVE";
const SAVE_ROUTES = "menu/SAVE_ROUTES";

// action creators
export const initialize = createAction(INITIALIZE);
export const save = createAction(SAVE);
export const saveRoutes = createAction(SAVE_ROUTES);

// initial state
const initialState = fromJS({
  menu: {},
  routes: [],
});

// reducer
export default handleActions(
  {
    [INITIALIZE]: () => initialState,
    [SAVE]: (state, action) => {
      return state.set("menu", {
        id: "root",
        views: action.payload,
      });
    },
    [SAVE_ROUTES]: (state, action) => {
      return state.set("routes", action.payload);
    },
  },
  initialState
);
