import React, { useState } from "react";
import PropTypes from "prop-types";
import "./RptDatPrtTerminal.css";
import { withBase, Title, ControlBox, Button } from "components";
import RptDatPrtTerminalConditionBox from "./RptDatPrtTerminalConditionBox";
import RptDatPrtTerminalTable from "./RptDatPrtTerminalTable";
import RptDatPrtTerminalFareTable from "./RptDatPrtTerminalFareTable";
import moment from "moment";
import { useSelector } from "react-redux";

const API = {
  DOWN: {
    url: "api/v1/calc/deliveryTxn/downtrmsum",
    method: "post",
  },
};

const RptDatPrtTerminal = (props) => {
  const code = useSelector((state) => state.code.get("code"));
  const [param, setParam] = useState();

  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
    });
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    if (param === undefined) {
      props.toast.error("조회후 사용가능합니다.");
      return;
    }

    await props.downExcel.request(url, param);
  };

  const handleClickPrint = () => {
    if (param === undefined) {
      props.toast.error("조회후 사용가능합니다.");
      return;
    }

    window.print();
  };

  return (
    <div className="RptDatPrtTerminal">
      <RptDatPrtTerminalConditionBox
        onClickSearch={handleClickSearch}
        {...props}
      />
      <ControlBox>
        <Button className="print" onClick={handleClickPrint}>
          출력하기
        </Button>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      {/* <Title title="터미널 운임 현황" /> */}
      <div className="controls second">
        <div className="left">
          전산실 발송일자 :{" "}
          {param === undefined
            ? moment().format("YYYY년 MM월 DD일")
            : moment(param.transDate).format("YYYY년 MM월 DD일")}
        </div>
        <h1 className="page_tit">
          {param &&
            param.phydsCorpDiv &&
            props.findDetailCode(code, "PHYDS_CORP_DIV", param.phydsCorpDiv)
              .cdDtlNm}
          택배 터미널 운임 현황
        </h1>
        <div className="right">
          출력시각 :{" "}
          {param === undefined
            ? moment().format("YYYY년 MM월 DD일 HH:mm:ss")
            : moment(param.now).format("YYYY년 MM월 DD일 HH:mm:ss")}
        </div>
      </div>
      <RptDatPrtTerminalTable param={param} />
      <RptDatPrtTerminalFareTable param={param} />
    </div>
  );
};

RptDatPrtTerminal.propTypes = {};

export default withBase(RptDatPrtTerminal);
