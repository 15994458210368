import React from "react";
import PropTypes from "prop-types";
import "./AcntDpsTxn.css";
import { CustomTab } from "components";
import AcntDpsTxnList from "./AcntDpsTxnList"; 
import AcntDpsTxnUpload from "./AcntDpsTxnUpload";
import AcntAbookTxnImpossList from "./AcntAbookTxnImpossList";

const AcntDpsTxn = () => {
  return (
    <div className="AcntDpsTxn">
      <CustomTab
        tabs={[
          {
            name: "입금리스트",
            component: <AcntDpsTxnList />,
          },
          {
            name: "파일업로드",
            component: <AcntDpsTxnUpload />,
          },
          {
            name: "입금자 불분명 리스트",
            component: <AcntAbookTxnImpossList />, 
          },
        ]}
      />
    </div>
  );
};

AcntDpsTxn.propTypes = {};

export default AcntDpsTxn;
