import React from "react";
import PropTypes from "prop-types";

const FinncStep = (props) => {
  const { step, clickStep } = props;
  const className = `Step${step}on`;

  const handleClickStep = (e, step) => {
    e.preventDefault();
    clickStep(step);
  };

  return (
    <>
      <ul className="upload_step">
        <li className={step === 1 ? "on" : ""}>
          <a href="#" onClick={(e) => handleClickStep(e, 1)}>
            1. 출금요청서 결과내역 파일 첨부
          </a>
        </li>
        <li className={step === 2 ? "on" : ""}>
          <a href="#" onClick={(e) => handleClickStep(e, 2)}>
            2. 파일 업로드 버튼 클릭
          </a>
        </li>
        <li className={step === 3 ? "on" : ""}>
          <a href="#" onClick={(e) => handleClickStep(e, 3)}>
            3. 파일 업로드 완료 후 재무집계표 생성 버튼 클릭
          </a>
        </li>
      </ul>
      <div className={`StepBar ${className}`}>&nbsp;</div>
    </>
  );
};

FinncStep.propTypes = {};

export default FinncStep;
