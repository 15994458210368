import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./SmsTrmHst.css";
import { withBase, Table, Pagination } from "components";
import moment from "moment";
import SmsTrmHstConditionBox from "./SmsTrmHstConditionBox";
import textBoxIcon from "assets/images/text_box.png";

const API = {
  LIST: {
    url: "api/v1/sms/smsTrmHst/list",
    method: "post",
  },
};

const SmsTrmHst = (props) => {
  const [param, setParam] = useState({
    trmDate: moment().add(-1, "days").format("YYYYMM"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [monthCnt, setMonthCnt] = useState(0);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      setMonthCnt(0);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.smsTrmTxnList);

    if (!response.smsTrmTxnList || response.smsTrmTxnList.length === 0) {
      setMonthCnt(0);
    } else {
      setMonthCnt(response.smsTrmTxnList[0].monthCnt);
    }
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  return (
    <div className="SmsTrmHst">
      <SmsTrmHstConditionBox onClickSearch={handleClickSearch} {...props} />
      <div className="SmsTrmHstTable">
        <div className="text">
          <span>
            월간 전송 총건수 : {props.formatter(monthCnt, "amount")} 건
          </span>
        </div>
        <article className="grid_data">
          <div className="TableArea">
            <Table
              widths={["5.4%", "8.1%", "12.1%", "54.9%", "12.1%", "*"]}
              columns={[
                "순번",
                "전송아이디",
                "전화번호",
                "메세지 내용",
                "전송시간",
                "전송결과",
              ]}
              datas={
                datas &&
                datas.map((item, key) => {
                  return (
                    <>
                      <td>{item.rowNum}</td>
                      <td>{item.amdrId}</td>
                      <td>{props.formatter(item.rcvTelNo, "phone", false)}</td>
                      <td className="msg">
                        {item.smsChr}
                        <div>
                          <i>
                            <img src={textBoxIcon} />
                          </i>
                          {item.smsChr}
                        </div>
                      </td>
                      <td>{props.formatter(item.realTrmDt, "datetime")}</td>
                      <td
                        className={
                          item.trmResultName === "미전송"
                            ? "notSend"
                            : item.trmResultName === "실패"
                            ? "fail"
                            : ""
                        }
                      >
                        {item.trmResultName}
                      </td>
                    </>
                  );
                })
              }
              tbodyClass="ScrollNone"
            />
          </div>
        </article>

        {datas && datas.length > 0 && (
          <Pagination
            page={param.pageNo}
            total={total}
            row={15}
            pageCount={10}
            fetchData={(page) => {
              setParam({
                ...param,
                pageNo: page,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

SmsTrmHst.propTypes = {};

export default withBase(SmsTrmHst);
