import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./CodeSelect.css";
import { connect } from "react-redux";
import { findGroupCode } from "variables/common";

const CodeSelect = (props) => {
  const { code, codeId, name, onChange, onBlur, placeholder, value } = props;
  const [items, setItems] = useState([]);
  useEffect(() => {
    codeId ? setItems(findGroupCode(code, codeId)) : setItems([]);
  }, [codeId]);
  return (
    <select
      name={name}
      className="select"
      onChange={(e) => {
        let item;
        if (e && e.target.value) {
          item = items.find((element) => element.code === e.target.value);
        }
        onChange(e, item);
      }}
      onBlur={onBlur}
      value={value}
    >
      {placeholder && (
        <option value={""} key={-1}>
          {placeholder}
        </option>
      )}
      {items &&
        items.map((item, key) => {
          return (
            <option value={item.code} key={key}>
              {item.name}
            </option>
          );
        })}
    </select>
  );
};

CodeSelect.propTypes = {};

export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({})
)(CodeSelect);
