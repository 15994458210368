import React from "react";
import { Route } from "react-router-dom";
import FeeInfo from "./FeeInfo";
import FeeInfoBasic from "./FeeInfoBasic";
import FeeInfoSpecial from "./FeeInfoSpecial";

const FeeInfoRoute = () => {
  return (
    <>
      <Route exact={true} path="/comn/FeeInfo" component={FeeInfo} />
      <Route
        exact={true}
        path="/comn/FeeInfo/basic/:phydsCorpDiv/:feeCd"
        component={FeeInfoBasic}
      />
      <Route
        exact={true}
        path="/comn/FeeInfo/special/:phydsCorpDiv/:feeCd"
        component={FeeInfoSpecial}
      />
    </>
  );
};

export default FeeInfoRoute;
