import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ApdOtpayTxn.css";
import { withBase, Button, ControlBox, Table, Pagination } from "components";
import moment from "moment";
import ApdOtpayTxnConditionBox from "./ApdOtpayTxnConditionBox";

const API = {
  LIST: {
    url: "api/v1/report/apdOtpayTxn/list",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/apdOtpayTxn/down",
    method: "post",
  },
};

const ApdOtpayTxn = (props) => {
  const [param, setParam] = useState({
    otpayDate: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.apdOtpayTxnList);
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="ApdOtpayTxn">
      <ApdOtpayTxnConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <article className="grid_data">
        <div className="TableArea">
          <Table
            widths={["15.6%", "16.3%", "17.6%", "16.3%", "*", "16.3%"]}
            columns={[
              "거래일자",
              "수수료",
              "영업소명",
              "소장명",
              "계좌번호",
              "거래기록사항",
            ]}
            datas={
              datas &&
              datas.map((item, key) => {
                return (
                  <>
                    <th>{props.formatter(item.otpayDate, "date")}</th>
                    <td className="amount">
                      {props.formatter(item.otpayAmt, "amount")}
                    </td>
                    <td className="tal">{item.plcfbName}</td>
                    <td>{props.formatter(item.managerName, "name", false)}</td>
                    <td>{props.formatter(item.dpsAcnt, "account", false)}</td>
                    <td className="tal">{item.bnkbkSttm}</td>
                  </>
                );
              })
            }
            tbodyClass="HasTH"
          />
        </div>
      </article>
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
    </div>
  );
};

ApdOtpayTxn.propTypes = {};

export default withBase(ApdOtpayTxn);
