import React from "react";
import PropTypes from "prop-types";
import { Button } from "components";

const CorpInfoViewTable = (props) => {
  const { viewData, onClose, onEdit, formatter, cretFlag } = props;
  let data = [];
  if (viewData) {
    data = [
      {
        name: "법인구분",
        value: <td>{viewData.corpDivNm}</td>,
      },
      {
        name: "법인명",
        value: <td>{viewData.corpName}</td>,
      },
      {
        name: "사업자번호",
        value: <td>{formatter(viewData.bizno, "bizno", false)}</td>,
      },
      {
        name: "예금주",
        value: <td>{viewData.acntName}</td>,
      },
      {
        name: "계좌번호",
        value: (
          <td>
            {viewData.acntBankName}{" "}
            {formatter(viewData.acntNo, "account", false)}
          </td>
        ),
      },
      {
        name: "기초잔액",
        value: <td>{formatter(viewData.firstBlnc, "amount")}</td>,
      },
      {
        name: "지입차량여부",
        value: <td>{viewData.ladvhFlag === 1 ? "지입차량" : ""}</td>,
      },
      {
        name: "영업여부",
        value:
          viewData.mktYn === "Y" ? (
            <td className="TextColorRed">영업중</td>
          ) : (
            <td>영업중지</td>
          ),
      },
    ];
  }
  return (
    <>
      <article className="popup_grid">
        <table>
          <colgroup>
            <col width="200" />
            <col width="*" />
          </colgroup>
          <tbody>
            {data &&
              data.map((item, key) => {
                return (
                  <tr key={key}>
                    <th>{item.name}</th>
                    {item.value}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </article>
      <div className="PopupButton">
        {cretFlag && (
          <Button className="insert" onClick={onEdit}>
            수정
          </Button>
        )}
        <Button className="close" onClick={onClose}>
          닫기
        </Button>
      </div>
    </>
  );
};

CorpInfoViewTable.propTypes = {};

export default CorpInfoViewTable;
