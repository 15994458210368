import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  HashRouter,
  MemoryRouter,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
import configure from "stores/configure";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";
import { TOKEN, TOKEN_TYPE, AUTHORIZATION } from "variables/constants";

import AppLayout from "layouts/AppLayout";
import { Login, NotFound } from "views";
import Sample from "views/Sample/Sample";
import Dashboard2 from "views/Sample/Dashboard";
// import Dashboard2 from "views/DashBoard/DashBoard";
import Mobile from "views/Sms/IndvSmsMobile/IndvSmsMobile";

import { setLocale } from "yup";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
registerLocale("ko", ko);

const GlobalStyle = createGlobalStyle``;

// dotenv
// dotenv.config();
console.log("env", process.env);

// redux
const store = configure();

// axios
axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem(TOKEN);
    const tokenType = sessionStorage.getItem(TOKEN_TYPE);
    if (token) {
      config.headers[AUTHORIZATION] = `${tokenType} ${token}`;
    }
    return config;
  },
  (request) => {
    return request;
  },
  (error) => {
    return error;
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error;
  }
);

// yup
setLocale({
  mixed: {
    required: "필수값 입니다.",
  },
  string: {
    min: "최소 길이는 ${min}이상 입니다.",
    max: "최대 길이는 ${max}이하 입니다.",
    email: "잘못된 이메일 형식입니다.",
    required: "필수 값입니다.",
  },
});

// ReactDOM.render(<AppLayout />, document.getElementById("root"));
ReactDOM.render(
  <>
    <GlobalStyle />
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/404" component={NotFound} />
          <Route
            exact={true}
            path="/mobile/:setlDate/:plcfbCd"
            component={Mobile}
          />
          <Route path="/sample" component={Sample} />
          {/* <Route path="/dashboard" component={Dashboard} /> */}
          <Route path="/dashboard2" component={Dashboard2} />
          <Route path="/" component={AppLayout} />
          <Redirect from="*" to="/" />
        </Switch>
      </HashRouter>
      <ToastContainer />
    </Provider>
  </>,
  document.getElementById("root")
);
