import React from "react";
import PropTypes from "prop-types";
import "./CustomLoading.css";
import LoadingImg from "assets/images/loading.gif";

const CustomLoading = (props) => {
  const { layerType, children, onClose } = props;

  return (
    <div
      className="CustomLoading"
      style={{ display: layerType ? "block" : "none" }}
    >
      <div className="LoadingArea">
        <img src={LoadingImg} alt="Loading" />
        <h5> {children}</h5>
      </div>
      <div className="LoadingBG"></div>
    </div>
  );
};

CustomLoading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomLoading;
