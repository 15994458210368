import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const SetlTxnStatsTable = (props) => {
  const { columns, widths, datas } = props;
  return (
    <article className="grid_data">
      <div className="">
        <div className="thead">
          <table>
            <colgroup>
              {widths.map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                {columns.map((i, key) => {
                  return <th key={key}>{i}</th>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbody">
          <table>
            <colgroup>
              {widths.map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={columns.length}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas.map((item, key) => {
                  return <tr key={key}>{item}</tr>;
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
};

SetlTxnStatsTable.propTypes = {};

export default SetlTxnStatsTable;
