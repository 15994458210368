import React from "react";
import PropTypes from "prop-types";
import "./CustomCheckBox.css";

const CustomCheckBox = (props) => {
  const { checked } = props;
  const className = checked ? "checkbox checked" : "checkbox";
  return (
    <span className={className}>
      <input type="checkbox" />
    </span>
  );
};

CustomCheckBox.propTypes = {
  checked: PropTypes.bool,
};

export default CustomCheckBox;
