import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PlcfbAbookTxnDepositList.css";
import { withBase, ControlBox, Button } from "components";
import moment from "moment";

const API = {
  LIST: {
    url: "api/v1/plcfb/plcfbAbookTxn/listdps",
    method: "post",
  },
  DOWN: {
    url: "api/v1/plcfb/plcfbAbookTxn/downdps",
    method: "post",
  },
};

const PlcfbAbookTxnDepositList = (props) => {
  const { searchData } = props;
  const [param, setParam] = useState();
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (searchData) {
      const searchParam = {
        dealDate: searchData.dspYm,
        plcfbCd: searchData.plcfbCd,
        managerName: searchData.managerName,
        plcfbSeqNo: searchData.plcfbSeqNo,
      };
      setParam(searchParam);
      list(searchParam);
    }
  }, [searchData]);

  const list = async (searchParam) => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(searchParam);
    const response = await HttpRequest.request(url, method, searchParam);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      searching(false);
      return;
    }
    setDatas(response.acntDpsTxnList);
    searching(false);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    if (param === undefined) {
      props.toast.error("조회후 사용가능합니다.");
      return;
    }

    await props.downExcel.request(url, param);
  };

  return (
    <article className="grid_data">
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <div className="TableArea">
        <div className="thead">
          <table>
            <colgroup>
              {["10%", "10%", "10%", "20%", "20%", "*"].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                {[
                  "일자",
                  "시간",
                  "금액",
                  "거래기록사항",
                  "영업소명",
                  "소장명",
                ].map((i, key) => {
                  return <th key={key}>{i}</th>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbody ScrollNone HasTH">
          <table>
            <colgroup>
              {["10%", "10%", "10%", "20%", "20%", "*"].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={6}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas.map((item, key) => {
                  return (
                    <tr key={key}>
                      <th>{props.formatter(item.dealDate, "date")}</th>
                      <td>{props.formatter(item.dealTime, "time")}</td>
                      <td className="amount">
                        {props.formatter(item.dealAmt, "amount", false)}
                      </td>
                      <td className="tal">{item.notes}</td>
                      <td className="tal">{item.plcfbName}</td>
                      <td>
                        {props.formatter(item.managerName, "name", false)}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
};

PlcfbAbookTxnDepositList.propTypes = {};

export default withBase(PlcfbAbookTxnDepositList);
