import React from "react";
import PropTypes from "prop-types";
import { Button } from "components";

const CorpInfoViewTable = (props) => {
  const { viewData, onClose, onEdit, formatter, cretFlag } = props;
  let data = [];
  if (viewData) {
    data = [
      {
        name1: <th>영업소코드</th>,
        value1: <td>{viewData.orgnCd}</td>,
        name2: <th>영업소명</th>,
        value2: <td>{viewData.plcfbName}</td>,
      },
      {
        name1: <th>법인구분</th>,
        value1: <td>{viewData.phydsCorpName}</td>,
        name2: <th>영업소구분</th>,
        value2: <td>{viewData.setlCorpName}</td>,
      },
      {
        name1: <th>연계권</th>,
        value1: <td>{viewData.lnkDsrtName}</td>,
        name2: <th>전화번호</th>,
        value2: <td>{formatter(viewData.cntcNo, "phone", false)}</td>,
      },
      {
        name1: <th>지역</th>,
        value1: <td>{viewData.regionName}</td>,
        name2: <th>연락처1</th>,
        value2: <td>{formatter(viewData.apdCntcNo1, "phone", false)}</td>,
      },
      {
        name1: <th>영업소장명</th>,
        value1: <td>{formatter(viewData.managerName, "name", false)}</td>,
        name2: <th>연락처2</th>,
        value2: <td>{formatter(viewData.apdCntcNo2, "phone", false)}</td>,
      },
      {
        name1: <th>주민등록번호</th>,
        value1: <td>{formatter(viewData.custNo, "custNo")}</td>,
        name2: <th>연락처3</th>,
        value2: <td>{formatter(viewData.apdCntcNo3, "phone", false)}</td>,
      },
      {
        name1: <th>사업자번호</th>,
        value1: <td>{formatter(viewData.bizno, "bizno", false)}</td>,
        name2: <th>연락처4</th>,
        value2: <td>{formatter(viewData.apdCntcNo4, "phone", false)}</td>,
      },
      {
        name1: <th>시작일자</th>,
        value1: <td>{formatter(viewData.startDate, "date")}</td>,
        name2: <th>연락처5</th>,
        value2: <td>{formatter(viewData.apdCntcNo5, "phone", false)}</td>,
      },
      {
        name1: <th>종료일자</th>,
        value1: <td>{formatter(viewData.finishDate, "date")}</td>,
        name2: <th>입금적요1</th>,
        value2: <td>{viewData.dpsNote1}</td>,
      },
      {
        name1: <th>예금주</th>,
        value1: <td>{formatter(viewData.dposr, "name", false)}</td>,
        name2: <th>입금적요2</th>,
        value2: <td>{viewData.dpsNote2}</td>,
      },
      {
        name1: <th>계좌번호</th>,
        value1: (
          <td>
            {viewData.acntBankName}{" "}
            {formatter(viewData.acntNo, "account", false)}
          </td>
        ),
        name2: <th>입금적요3</th>,
        value2: <td>{viewData.dpsNote3}</td>,
      },
      {
        name1: <th>한도액</th>,
        value1: <td>{formatter(viewData.limitAmt, "amount")}</td>,
        name2: <th>입금적요4</th>,
        value2: <td>{viewData.dpsNote4}</td>,
      },
      {
        name1: <th>기초잔액</th>,
        value1: <td>{formatter(viewData.firstBlnc, "amount")}</td>,
        name2: <th>입금적요5</th>,
        value2: <td>{viewData.dpsNote5}</td>,
      },
      {
        name1: <th>노선차</th>,
        value1: <td>{formatter(viewData.nosunType1, "amount")}</td>,
        name2: <th>연계차</th>,
        value2: <td>{formatter(viewData.nosunType2, "amount")}</td>,
      },
      {
        name1: <th>비고</th>,
        value1: <td colSpan="3">{viewData.remarks}</td>,
        name2: null,
        value2: null,
      },
      {
        name1: <th>우편번호</th>,
        value1: <td colSpan="3">{viewData.zipCd}</td>,
        name2: null,
        value2: null,
      },
      {
        name1: <th>주소</th>,
        value1: <td colSpan="3">{viewData.adr}</td>,
        name2: null,
        value2: null,
      },
      {
        name1: <th>상세주소</th>,
        value1: <td colSpan="3">{viewData.adrEtc}</td>,
        name2: null,
        value2: null,
      },
      {
        name1: <th>영업여부</th>,
        value1:
          viewData.mktYn === "Y" ? (
            <td colSpan="3" className="TextColorRed">
              영업중
            </td>
          ) : (
            <td>영업중지</td>
          ),
        name2: null,
        value2: null,
      },
    ];
  }
  return (
    <>
      <article className="popup_grid">
        <table>
          <colgroup>
            <col width="200" />
            <col width="*" />
            <col width="200" />
            <col width="*" />
          </colgroup>
          <tbody>
            {data &&
              data.map((item, key) => {
                return (
                  <tr key={key}>
                    {item.name1}
                    {item.value1}
                    {item.name2}
                    {item.value2}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </article>
      <div className="PopupButton">
        {cretFlag && (
          <Button className="insert" onClick={onEdit}>
            수정
          </Button>
        )}
        <Button className="close" onClick={onClose}>
          닫기
        </Button>
      </div>
    </>
  );
};

CorpInfoViewTable.propTypes = {};

export default CorpInfoViewTable;
