import React, { useState } from "react";
import PropTypes from "prop-types";
import "./PauseRmvListTable.css";
import { CheckBox as Checkbox } from "components";

const PauseRmvListTable = (props) => {
  const {
    datas,
    batchDatas,
    onChange,
    onDoubleClick,
    batchAllChk,
    onChangeAllChk,
    formatter,
    cretFlag,
  } = props;

  return (
    <article className="grid_data">
      <div className="thead">
        <table>
          <colgroup>
            {[
              "5%",
              "8%",
              "5%",
              "*%",
              "8%",
              "6%",
              "6%",
              "10%",
              "6%",
              "6%",
              "6%",
              "6%",
              "6%",
            ].map((i, key) => {
              return <col width={i} key={key} />;
            })}
          </colgroup>
          <tbody>
            <tr>
              <th>
                선택
                {cretFlag && (
                  <>
                    {" "}
                    <Checkbox
                      name="batchAllChk"
                      checked={batchAllChk}
                      onChange={(e) => onChangeAllChk(e.target.checked)}
                    />
                  </>
                )}
              </th>
              <th>정지일</th>
              <th>상태</th>
              <th>영업소명/노선명</th>
              <th>법인명</th>
              <th>전체</th>
              <th>정지</th>
              <th>해제</th>
              <th>정지금액</th>
              <th>요청인</th>
              <th>발송일</th>
              <th>요청일</th>
              <th>해제일</th>
              <th>송금일</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="holdTable">
        <div className="tbody">
          <table>
            <colgroup>
              {[
                "5%",
                "8%",
                "5%",
                "*%",
                "8%",
                "6%",
                "6%",
                "10%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={19}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas &&
                datas.map((item, key) => {
                  let chkBox = false;
                  let layer = false;

                  if (
                    item.status === "01" || item.status === "04" || item.status === "05"
                  ) {
                    chkBox = true;
                    layer = true;
                  } 

                  return (
                    <>
                      <tr key={key}>
                        <td>
                          {chkBox ? (
                            <Checkbox
                              name="batchChk"
                              checked={
                                batchDatas.indexOf(item.pauseSeq) > -1
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                onChange(
                                  item.pauseSeq,
                                  window.event.target.checked,
                                )
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {formatter(item.regDate, "date")}
                        </td>
                        <td>
                          {item.status === "01" ? "정지" : item.status === "02" ? "해제요청" : item.status === "03" ? "해제완료" : item.status === "04" ? "잔액남음" : "송금요청"}
                        </td>
                        <td>
                          {item.pauseName}
                        </td>
                        <td>
                          {item.corpName}
                        </td>
                        <td class="hand" onDoubleClick={() => onDoubleClick(item.pauseSeq)}>
                          {formatter(item.totalCount,"amount")} 건
                        </td>
                        <td>
                        {formatter(item.ncount,"amount")} 건
                        </td>
                        <td>
                        {formatter(item.ycount,"amount")} 건
                        </td>
                        <td>
                        {formatter(item.fee,"amount")} 원
                        </td>
                        <td>
                          {item.cancelReqUser}
                        </td>
                        <td>
                          {formatter(item.remitDt, "date")}
                        </td>
                        <td>
                          {formatter(item.cancelReqDate, "date")}
                        </td>
                        <td>
                          {formatter(item.cancelDate, "date")}
                        </td>
                        <td>
                          {formatter(item.manualDt, "date")}
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

      </div>
      
     
    </article>
  );
};

PauseRmvListTable.propTypes = {};

export default PauseRmvListTable;
