import React, { useState } from "react";
import PropTypes from "prop-types";
import { withBase, LayerPopup, PopupWrapper } from "components";
import PlcfbInfoEditTable from "views/Comn/PlcfbInfo/PlcfbInfoEditTable";

const API = {
  CREATE: {
    url: "api/v1/comn/plcfbInfo/create",
    method: "post",
  },
};

const SetlTxnCorrectionContentPlcfbTable = (props) => {
  const { plcfbDatas } = props;
  const [layerType, setLayerType] = useState("");
  const [plcfbName, setPlcfbName] = useState(null);

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    return true;
  };

  const handleDoubleClick = (index) => {
    setLayerType("new");
    setPlcfbName(plcfbDatas[index].reviewDatName5);
  };
  const handleClickClose = () => {
    setLayerType("");
    setPlcfbName(null);
  };
  const isDuplicate = (data) => {
    let arr = [];

    if (data) {
      if (data.dpsNote1) arr.push(data.dpsNote1);
      if (data.dpsNote2) arr.push(data.dpsNote2);
      if (data.dpsNote3) arr.push(data.dpsNote3);
      if (data.dpsNote4) arr.push(data.dpsNote4);
      if (data.dpsNote5) arr.push(data.dpsNote5);
    }

    const isDup = arr.some((item) => {
      return arr.indexOf(item) !== arr.lastIndexOf(item);
    });

    if (isDup) {
      // alert("중복된 입금적요가 있습니다.");
      props.toast.error("중복된 입금적요가 있습니다.");
    }

    return isDup;
  };

  const handleClickSave = async (layerType, updateType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "new":
        if (!isDuplicate(data)) {
          ret = await edit(API.CREATE, data);
          msg = "신규등록되었습니다.";
        }
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");
      setPlcfbName(null);

      if (msg) props.toast.error(msg);
    }
  };

  return (
    <div className="PlcfbInfo">
      <article className="grid_data">
        <h4 className="title">신규발송영업소 검증</h4>
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {["25.3%", "25.5%", "25.2%", "*"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {["수탁번호", "영업소명", "정산가능여부", "사유"].map(
                    (i, key) => {
                      return <th key={key}>{i}</th>;
                    }
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody">
            <table>
              <colgroup>
                {["25.3%", "25.5%", "25.2%", "*"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {plcfbDatas &&
                  plcfbDatas.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        onDoubleClick={() => handleDoubleClick(key)}
                        className="hand"
                      >
                        <td>{item.reviewDatName4}</td>
                        <td className="tal">{item.reviewDatName5}</td>
                        <td className="impossible">{item.reviewDatName1}</td>
                        <td className="tal">{item.reviewDatName2}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </article>
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"영업소관리"}
          className={"PlcfbInfoPopup"}
          editTable={
            <PlcfbInfoEditTable
              plcfbName={plcfbName}
              layerType={layerType}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

SetlTxnCorrectionContentPlcfbTable.propTypes = {};

export default withBase(SetlTxnCorrectionContentPlcfbTable);
