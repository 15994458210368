import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "stores/Common/user";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import "./Container.css";
import SideNavigation from "./SideNavigation";
import { Title } from "components";

const ContainerRouter = React.memo((props) => {
  const { routes } = props;
  return (
    <Switch>
      {routes &&
        routes
          .filter((item) => {
            return item.id !== "ROOT" && item.path && item.component;
          })
          .map((item, index) => {
            return (
              <Route path={item.path} component={item.component} key={index} />
            );
          })}
      {/* <Redirect to="/plcfb/plcfbAbookTxn" /> */}
    </Switch>
  );
});

const Container = (props) => {
  const { menuItem, routes } = props;
  const [title, setTitle] = useState("");
  const handleSubMenuClick = (subMenuItem) => {
    setTitle(subMenuItem.name);
    props.UserActions.cretFlag({ cretFlag: subMenuItem.cretFlag === "1" ? true : false });
  };
  useEffect(() => {
    if (menuItem && Array.isArray(menuItem.views)) {
      setTitle(menuItem.views[0].name);
    }
  }, [menuItem]);
  return (
    <div className="Container">
      <div>
        <SideNavigation menuItem={menuItem} onClick={handleSubMenuClick} />
        <div className="Content">
          <Title title={title} upperMenus={[menuItem.name, title]} />
          <ContainerRouter routes={routes} />
        </div>
      </div>
    </div>
  );
};

Container.propTypes = {};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    UserActions: bindActionCreators(userActions, dispatch),
  })
)(Container);
