import React, { useState } from "react";
import PropTypes from "prop-types";
import "views/Comn/CarInfo/CarInfo.css";
import { withBase, LayerPopup, PopupWrapper } from "components";
import CarInfoEditTable from "views/Comn/CarInfo/CarInfoEditTable";

const API = {
  CREATE: {
    url: "api/v1/comn/carInfo/create",
    method: "post",
  },
};

const SetlTxnCorrectionContentCarTable = (props) => {
  const { carDatas } = props;
  const [layerType, setLayerType] = useState("");

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    return true;
  };

  const handleDoubleClick = (index) => {
    setLayerType("new");
  };
  const handleClickClose = () => {
    setLayerType("");
  };
  const handleClickSave = async (layerType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "new":
        ret = await edit(API.CREATE, data);
        msg = "신규등록되었습니다.";
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");

      if (msg) props.toast.error(msg);
    }
  };

  return (
    <>
      <article className="grid_data">
        <h4 className="title">신규차량 검증</h4>
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {["25.3%", "25.5%", "25.2%", "*"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {["수탁번호", "차량명", "정산가능여부", "사유"].map(
                    (i, key) => {
                      return <th key={key}>{i}</th>;
                    }
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody ScrollNone ">
            <table>
              <colgroup>
                {["25.3%", "25.5%", "25.2%", "*"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {carDatas &&
                  carDatas.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        onDoubleClick={() => handleDoubleClick(key)}
                        className="hand"
                      >
                        <td>{item.reviewDatName4}</td>
                        <td className="tal">{item.reviewDatName5}</td>
                        <td className="impossible">{item.reviewDatName1}</td>
                        <td className="tal">{item.reviewDatName2}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </article>
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"차량관리"}
          editTable={
            <CarInfoEditTable
              layerType={layerType}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
            />
          }
        />
      </LayerPopup>
    </>
  );
};

SetlTxnCorrectionContentCarTable.propTypes = {};

export default withBase(SetlTxnCorrectionContentCarTable);
