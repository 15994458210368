import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  CustomInput,
  FormatInput,
  DatePickerField,
  Button,
  CodeSelect,
  CacheSelect,
  CheckBox as Checkbox,
  CacheAutocomplete,
} from "components";
import moment from "moment";

const CarInfoEditTable = (props) => {
  const { layerType, viewData, onClose, onSave, formatter } = props;

  return (
    <>
      <Formik
        initialValues={{
          // 연계차량, 노선차량 - 영업소코드 필수
          // 경동차주 - N000000
          // 외부차량 - K000000
          plcfbCd: viewData ? viewData.plcfbCd : "",
          plcfbName: viewData ? viewData.plcfbName : "",
          carSeqNo: viewData ? viewData.carSeqNo : "",
          startDate: viewData ? moment(viewData.startDate) : moment(),
          finishDate: viewData
            ? viewData.finishDate
              ? moment(viewData.finishDate)
              : ""
            : "",
          carName: viewData ? viewData.carName : "",
          carNo: viewData ? viewData.carNo : "",
          carDiv: viewData ? viewData.carDiv : "",
          carDivNm: viewData ? viewData.carDivNm : "",
          carTon: viewData ? viewData.carTon : "",
          acntNo: viewData ? formatter(viewData.acntNo, "account") : "",
          acntBankCd: viewData ? viewData.acntBankCd : "",
          depositor: viewData ? viewData.depositor : "",
          custNo: viewData ? formatter(viewData.custNo, "custNo") : "",
          bizno: viewData ? formatter(viewData.bizno, "bizno") : "",
          cntcNo: viewData ? formatter(viewData.cntcNo, "phone") : "",
          corpCd: viewData ? viewData.corpCd : "",
          mvmnYn: viewData ? (viewData.mvmnYn === "Y" ? true : false) : true,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const {
            plcfbName,
            carDivNm,
            acntNo,
            custNo,
            bizno,
            cntcNo,
            mvmnYn,
            ...rest
          } = values;
          onSave(layerType, {
            ...rest,
            acntNo: formatter(acntNo, "toValue"),
            custNo: formatter(custNo, "toValue"),
            bizno: formatter(bizno, "toValue"),
            cntcNo: formatter(cntcNo, "toValue"),
            mvmnYn: mvmnYn ? "Y" : "N",
          });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          plcfbCd: Yup.string().required(),
          startDate: Yup.date().required().nullable(),
          finishDate: Yup.date()
            .nullable()
            .when("startDate", (startDate, schema) =>
              startDate ? schema.min(startDate, "기간을 확인하세요.") : schema,
            ),
          carName: Yup.string().required().max(50),
          carNo: Yup.string().required().max(20),
          carDiv: Yup.string().required(),
          carTon: Yup.number().required(),
          acntNo: Yup.string().required(),
          acntBankCd: Yup.string().required(),
          depositor: Yup.string().required().max(20),
          custNo: Yup.string().required(),
          bizno: Yup.string().required(),
          cntcNo: Yup.string().required(),
          corpCd: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <Form>
              <h5>필수 입력</h5>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="200" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        차량구분<i></i>
                        <span>
                          {errors.carDiv && touched.carDiv && errors.carDiv}
                        </span>
                      </th>
                      <td>
                        {layerType === "new" ? (
                          <>
                            <CodeSelect
                              codeId="CAR_DIV"
                              name="carDiv"
                              onChange={(e, item) => {
                                if (item) {
                                  if (item.code === "03") {
                                    values.plcfbCd = "K000000";
                                    values.carName = "";
                                  } else if (item.code === "04") {
                                    values.plcfbCd = "N000000";
                                    values.carName = "";
                                  } else {
                                    values.plcfbCd = "";
                                    values.plcfbName = "";
                                    values.carName = " ";
                                  }
                                }
                                handleChange(e);
                              }}
                              placeholder="차량구분 선택"
                              value={values.carDiv}
                            />{" "}
                            <span className="info_text">
                              ※ 노선 및 외부차량은 계좌등록 필수
                            </span>
                          </>
                        ) : (
                          <> {values.carDivNm}</>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        영업소명<i></i>
                        <span>
                          {errors.plcfbCd && touched.plcfbCd && errors.plcfbCd}
                        </span>
                      </th>
                      <td>
                        {layerType === "new" ? (
                          values.carDiv === "03" || values.carDiv === "04" ? (
                            "영업소없음"
                          ) : (
                            <>
                              <CacheAutocomplete
                                cache="plcfbInfo"
                                onMap={(item, key) => {
                                  return {
                                    code: item.plcfbCd,
                                    name: item.plcfbName,
                                    acntBankCd: item.acntBankCd,
                                    acntNo: formatter(item.acntNo, "account"),
                                    bizno: formatter(item.bizno, "bizno"),
                                    custNo: formatter(item.custNo, "custNo"),
                                    depositor: item.managerName,
                                  };
                                }}
                                name="plcfbName"
                                value={values.plcfbName}
                                callBack={(item) => {
                                  if (item) {
                                    setFieldValue("plcfbCd", item.code);
                                    setFieldValue(
                                      "acntBankCd",
                                      item.acntBankCd,
                                    );
                                    setFieldValue("acntNo", item.acntNo);
                                    setFieldValue("bizno", item.bizno);
                                    setFieldValue("custNo", item.custNo);
                                    setFieldValue("depositor", item.depositor);
                                  }
                                }}
                                setFieldValue={setFieldValue}
                                placeholder="영업소명 입력"
                              />
                            </>
                          )
                        ) : (
                          <>
                            {values.plcfbName
                              ? values.plcfbName
                              : "영업소 없음"}
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        법인명<i></i>
                        <span>
                          {errors.corpCd && touched.corpCd && errors.corpCd}
                        </span>
                      </th>
                      <td>
                        <CacheSelect
                          cache="corpInfo"
                          onMap={(item, key) => {
                            return {
                              code: item.corpCd,
                              name: item.corpName,
                            };
                          }}
                          name="corpCd"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="법인 선택"
                          value={values.corpCd}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        차량명<i></i>
                        <span>
                          {errors.carName && touched.carName && errors.carName}
                        </span>
                      </th>
                      <td>
                        {values.carDiv === "03" || values.carDiv === "04" ? (
                          <>
                            <CustomInput
                              name="carName"
                              type="text"
                              placeholder="차량명 입력"
                              onChange={handleChange}
                              value={values.carName}
                            />
                          </>
                        ) : (
                          <>
                            {values.carName}
                            <span className="info_text">
                              {" "}
                              ※ 저장시 자동설정
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        차량번호<i></i>
                        <span>
                          {errors.carNo && touched.carNo && errors.carNo}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="carNo"
                          type="text"
                          placeholder="차량번호 입력"
                          onChange={handleChange}
                          value={values.carNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        톤수<i></i>
                        <span>
                          {errors.carTon && touched.carTon && errors.carTon}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="carTon"
                          type="text"
                          placeholder="톤수 입력"
                          onChange={handleChange}
                          value={values.carTon}
                        />{" "}
                        톤
                      </td>
                    </tr>
                    <tr>
                      <th>
                        예금주<i></i>
                        <span>
                          {errors.depositor &&
                            touched.depositor &&
                            errors.depositor}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="depositor"
                          type="text"
                          placeholder="예금주 입력"
                          onChange={handleChange}
                          value={values.depositor}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        주민등록번호<i></i>
                        <span>
                          {errors.custNo && touched.custNo && errors.custNo}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="custNo"
                          name="custNo"
                          type="text"
                          placeholder="주민번호 입력"
                          onChange={handleChange}
                          value={values.custNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        사업자번호<i></i>
                        <span>
                          {errors.bizno && touched.bizno && errors.bizno}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="bizno"
                          name="bizno"
                          type="text"
                          placeholder="사업자번호 입력"
                          onChange={handleChange}
                          value={values.bizno}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        전화번호<i></i>
                        <span>
                          {errors.cntcNo && touched.cntcNo && errors.cntcNo}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="cntcNo"
                          type="text"
                          placeholder="전화번호 입력"
                          onChange={handleChange}
                          value={values.cntcNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        시작일자<i></i>
                        <span>
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </span>
                      </th>
                      <td>
                        <DatePickerField
                          name="startDate"
                          date={values.startDate}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        종료일자
                        <span>
                          {errors.finishDate &&
                            touched.finishDate &&
                            errors.finishDate}
                        </span>
                      </th>
                      <td>
                        <DatePickerField
                          name="finishDate"
                          date={values.finishDate}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        계좌번호<i></i>
                        <span>
                          {errors.acntBankCd && touched.acntBankCd
                            ? errors.acntBankCd
                            : errors.acntNo && touched.acntNo
                            ? errors.acntNo
                            : ""}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="ACNT_BANK_CD"
                          name="acntBankCd"
                          onChange={handleChange}
                          onBlur={handleChange}
                          placeholder={"은행 선택"}
                          value={values.acntBankCd}
                        />{" "}
                        <FormatInput
                          formatType="account"
                          name="acntNo"
                          type="text"
                          placeholder="계좌번호 입력"
                          onChange={handleChange}
                          value={values.acntNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>운행여부</th>
                      <td>
                        <Checkbox
                          name="mvmnYn"
                          onChange={handleChange}
                          checked={values.mvmnYn}
                        />{" "}
                        운행중
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="PopupButton">
                <Button
                  type="submit"
                  className="insert"
                  disabled={isSubmitting}
                >
                  저장
                </Button>
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

CarInfoEditTable.propTypes = {};

export default CarInfoEditTable;
