import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import {
  Button,
  ConditionBox,
  CustomInput,
  FormatInput,
  CodeSelect,
} from "components";

const PlcfbInfoConditionBox = (props) => {
  const { onClickSearch, formatter } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            orgnCd: "",
            plcfbName: "",
            bizno: "",
            managerName: "",
            custNo: "",
            mktYn: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { bizno, custNo, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              custNo: formatter(custNo, "toValue"),
              bizno: formatter(bizno, "toValue"),
            });
            setSubmitting(false);
          }}
        >
          {(props) => {
            const { values, isSubmitting, handleChange, handleSubmit } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>영업소 코드</dt>
                            <dd>
                              <CustomInput
                                name="orgnCd"
                                type="text"
                                placeholder="영업소코드 입력"
                                onChange={handleChange}
                                value={values.orgnCd}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>영업소명</dt>
                            <dd>
                              <CustomInput
                                name="plcfbName"
                                type="text"
                                placeholder="영업소명 입력"
                                onChange={handleChange}
                                value={values.plcfbName}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>사업자번호</dt>
                            <dd>
                              <FormatInput
                                formatType="bizno"
                                name="bizno"
                                type="text"
                                placeholder="사업자번호 입력"
                                onChange={handleChange}
                                value={values.bizno}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>소장명</dt>
                            <dd>
                              <CustomInput
                                name="managerName"
                                type="text"
                                placeholder="소장명 입력"
                                onChange={handleChange}
                                value={values.managerName}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>주민번호</dt>
                            <dd>
                              <FormatInput
                                formatType="custNo"
                                name="custNo"
                                type="text"
                                placeholder="주민번호 입력"
                                onChange={handleChange}
                                value={values.custNo}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>영업여부</dt>
                            <dd>
                              <CodeSelect
                                codeId="MKT_YN"
                                name="mktYn"
                                onChange={handleChange}
                                placeholder="영업여부 선택"
                                value={values.mktYn}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

PlcfbInfoConditionBox.propTypes = {};

export default PlcfbInfoConditionBox;
