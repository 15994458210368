import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./DailyFeeSumDetails.css";
import { withBase, ControlBox, Button, Pagination } from "components";
import moment from "moment";
import DailyFeeSumDetailsConditionBox from "./DailyFeeSumDetailsConditionBox";

const API = {
  LIST: {
    url: "api/v1/report/dailyFeeSum/listdtl",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/dailyFeeSum/downdtl",
    method: "post",
  },
};

const DailyFeeSumDetails = (props) => {
  const [param, setParam] = useState({
    cretDate: moment().add(-1, "days").format("YYYYMMDD"),
  });
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      searching(false);
      return;
    }

    setDatas(response.dailyFeeSumList);
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam(condition);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="DailyFeeSumDetails">
      <DailyFeeSumDetailsConditionBox
        onClickSearch={handleClickSearch}
        {...props}
      />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>

      <div className="DailyFeeSumDetailsTable">
        <article className="grid_data">
          
        
          <div className="thead HasTD">
            <table>
              <colgroup>
                {[
                  "5%",
                  "4%",
                  "*",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                  "6%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {[
                    "날자",
                    "법인",
                    "구분",
                    "발송",
                    "도착",
                    "회사",
                    "하역1",
                    "하역2",
                    "노선",
                    "발송연계노선",
                    "도착연계노선",
                    "백변",
                    "고변",
                    "용변",
                    "도선료",
                    "합계",
                  ].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div class="holdTable">
            <div className="tbody HasTH">
              <table>
                <colgroup>
                  {[
                    "5%",
                    "4%",
                    "*",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                    "6%",
                  ].map((i, key) => {
                    return <col width={i} key={key} />;
                  })}
                </colgroup>
                <tbody>
                  {!datas || datas.length === 0 ? (
                    <tr>
                      <td className="NoData" colSpan={16}>
                        조회 결과가 없습니다.
                      </td>
                    </tr>
                  ) : (
                    datas.map((item, key) => {
                      return (
                        <tr key={key}>
                          <th>{props.formatter(item.cretDate, "date")}</th>
                          <td>{item.phydsCorpName}</td>
                          <td className="tal">{item.feeName}</td>
                          <td className="amount">
                            {props.formatter(item.susu, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.chulgo, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.temi, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.yunke, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.junsan, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.charang, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.yunno, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.yunno2, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.spa3, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.spa4, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.spa1, "amount")}
                          </td>
                          <td className="amount">
                            {props.formatter(item.no18s, "amount")}
                          </td>
                          <td className="amount TextColorRed">
                            {props.formatter(item.totSum, "amount")}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

DailyFeeSumDetails.propTypes = {};

export default withBase(DailyFeeSumDetails);
