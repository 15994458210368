import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { 
  Button, 
  ConditionBox, 
  DatePickerField,
  CustomInput
} from "components";
import moment from "moment";

const AcntAbookTxnImpossListCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            dealDate: moment().add(-1, "days"),
            dealEndDate: moment().add(-1, "days"),
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { dealDate, dealEndDate, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              dealDate: moment(values.dealDate).format("YYYYMMDD"),
              dealEndDate: moment(values.dealEndDate).format("YYYYMMDD"),
            }); 
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            dealDate: Yup.date().required().nullable(),
            dealEndDate: Yup.date() 
              .required()
              .nullable()
              .when("dealDate", (dealDate, schema) =>
                dealDate
                  ? schema.min(dealDate, "기간을 확인하세요.")
                  : schema
              ),
          })}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              touched,
              errors,
              handleSubmit,
              handleChange,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              기간<i></i>
                              <span>
                                {errors.dealDate && touched.dealDate
                                  ? errors.dealDate
                                  : errors.dealEndDate && touched.dealEndDate
                                  ? errors.dealEndDate
                                  : ""}
                              </span>
                            </dt>
                            <dd className="Double">
                              <DatePickerField
                                name="dealDate"
                                date={values.dealDate}
                              />
                              ~{" "}
                              <DatePickerField
                                name="dealEndDate"
                                date={values.dealEndDate}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>거래기록사항</dt>
                            <dd>
                              <CustomInput
                                name="notes"
                                type="text"
                                placeholder="거래기록사항"
                                onChange={handleChange}
                                value={values.notes}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

AcntAbookTxnImpossListCondition.propTypes = {};

export default AcntAbookTxnImpossListCondition;
