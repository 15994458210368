import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, ConditionBox, DatePickerField, CacheSelect } from "components";
import moment from "moment";

const RmtFeeSmryCorpListCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            setlStartDate: moment().add(-1, "days"),
            setlEndDate: moment().add(-1, "days"),
            corpCd: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { setlStartDate, setlEndDate, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              setlStartDate: moment(values.setlStartDate).format("YYYYMMDD"),
              setlEndDate: moment(values.setlEndDate).format("YYYYMMDD"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            setlStartDate: Yup.date().required().nullable(),
            setlEndDate: Yup.date()
              .required()
              .nullable()
              .when("setlStartDate", (setlStartDate, schema) =>
                setlStartDate
                  ? schema.min(setlStartDate, "기간을 확인하세요.")
                  : schema
              ),
          })}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              발송기간<i></i>
                              <span>
                                {errors.setlStartDate && touched.setlStartDate
                                  ? errors.setlStartDate
                                  : errors.setlEndDate && touched.setlEndDate
                                  ? errors.setlEndDate
                                  : ""}
                              </span>
                            </dt>
                            <dd className="Double">
                              <DatePickerField
                                name="setlStartDate"
                                date={values.setlStartDate}
                              />
                              ~{" "}
                              <DatePickerField
                                name="setlEndDate"
                                date={values.setlEndDate}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>법인명</dt>
                            <dd>
                              <CacheSelect
                                cache={"corpInfo"}
                                onMap={(item, key) => {
                                  return {
                                    code: item.corpCd,
                                    name: item.corpName,
                                  };
                                }}
                                name="corpCd"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="법인 선택"
                                value={values.corpCd}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

RmtFeeSmryCorpListCondition.propTypes = {};

export default RmtFeeSmryCorpListCondition;
