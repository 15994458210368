import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Finnc.css";
import axios from "axios";
import {
  withBase,
  Button,
  LinearProgressWithLabel,
  LayerPopup,
  PopupWrapper,
  CustomLoading,
  CustomMessage,
} from "components";
import FileUploadIcon from "assets/images/icon_file_upload.png";
import { useDropzone } from "react-dropzone";
import FinncStep from "./FinncStep";
import FinncUploadList from "./FinncUploadList";
import { properties } from "variables/properties";

const API = {
  CALC: {
    url: "api/v1/finnc/finnc/createFinnc",
    method: "post",
  },
};

const Finnc = (props) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState("");

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: ({}, ".xlsx"),
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 314572800,
    onDrop: (acceptedFiles, rejectedFiles) => {
      console.log(acceptedFiles, rejectedFiles);
      if (acceptedFiles && acceptedFiles.length > 0) {
        setFiles([...acceptedFiles]);
        setStep(2);
      }
    },
  });

  useEffect(() => {
    if (files.length === 0) setStep(1);
  }, [files]);

  // function of function
  const handleClickRemoveFile = (file) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFiles([...acceptedFiles]);
  };

  const handleClickFileUpload = async () => {
    props.searching(true, "파일업로드 및 CMS 데이터 반영중입니다.");
    setIsUploading(true);
    await handleFileUpload();
    setIsUploading(false);
    props.searching(false);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("name", "파일업로드");
    formData.append("fileTypeCd", "FT003");

    if (files.length === 0) {
      setMessage("업로드할 파일이 없습니다.");
      setLayerType("message");
      return;
    }

    files.forEach((file) => {
      formData.append("files", file);
    });

    let url = "api/v1/comn/file/upload";
    if (properties && properties.contextRoot) {
      url = `${properties.contextRoot}/${url}`;
    }

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded} kb of ${total} kb | ${percent}%`);

        if (percent < 100) {
          setUploadPercent(percent);
        }
      },
    };

    await axios
      .post(url, formData, options)
      .then((res) => {
        setUploadPercent(100);
        setTimeout(() => {
          setUploadPercent(0);
        }, 1000);
        if (res instanceof Error) {
          console.log(res.response);
          if (res.response.status === 409) {
            let data = res.response.data;
            if (data.message) {
              setMessage(data.message);
            } else {
              let indexS = data.indexOf("<body>");
              let indexE = data.indexOf("</body>");

              if (indexS > 0 && indexE > 0) {
                setMessage(data.slice(indexS + 6, indexE));
              } else {
                setMessage(
                  <>
                    파일 업로드를 실패 하였습니다.
                    <br />
                    다시 업로드 해주세요.
                  </>,
                );
              }
            }
          } else {
            setMessage(
              <>
                파일 업로드를 실패 하였습니다.
                <br />
                다시 업로드 해주세요.
              </>,
            );
          }
          setLayerType("message");
        } else {
          console.log(res);
          setStep(3);
          setMessage(
            <>
              파일 업로드가 완료되었습니다.
              <br />
              재무집계표를 생성해주세요.
            </>,
          );
          setLayerType("message");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setMessage("파일 업로드 예외 발생했습니다.");
        setLayerType("message");
      });
  };

  const handleClickCalcFee = async () => {
    setLayerType("loading");
    const { HttpRequest } = props;
    const { url, method } = API.CALC;

    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setMessage("재무집계표 생성중 오류가 발생했습니다.");
      setLayerType("message");
      return;
    }
    console.log(response);
    setMessage("재무집계표 생성이 완료되었습니다.");
    setLayerType("message");
  };

  const handleClickStep = (clickStep) => {
    setStep(clickStep);
  };

  const handleClickClose = () => {
    setLayerType("");
  };

  return (
    <div className="Finnc">
      <div className="FileUploadProcess">
        <FinncStep step={step} clickStep={handleClickStep} />
        <form className="UploadArea">
          <div
            className={
              step === 1
                ? "drop"
                : step === 2
                ? "drop SecondStep"
                : "drop ThirdStep"
            }
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <img src={FileUploadIcon} className="icon" alt="fileUpload" />
            <Button className="add_file" onClick={open}>
              파일첨부
            </Button>
            {step === 1 ? (
              <h4>첨부파일을 마우스로 끌어 넣으세요.</h4>
            ) : step === 2 ? (
              <Button
                className="fee_file_upload"
                onClick={handleClickFileUpload}
                disabled={isUploading}
              >
                파일 업로드
              </Button>
            ) : (
              <>
                <Button className="fee_clclt" onClick={handleClickCalcFee}>
                  재무집계표 생성
                </Button>
                <h4>
                  파일 업로드가 완료 됐습니다. 파일을 더 추가하거나 재무집계표
                  생성 버튼을 눌러 계속 진행해주세요.
                </h4>
              </>
            )}
          </div>
          {step === 2 ? (
            <FinncUploadList
              files={files}
              onClickRemoveFile={handleClickRemoveFile}
            />
          ) : null}
          {uploadPercent > 0 && (
            <LinearProgressWithLabel value={uploadPercent} />
          )}
        </form>
      </div>

      <PopupWrapper
        layerType={layerType}
        title={""}
        loading={
          <CustomLoading layerType={layerType}>
            재무집계표 생성중입니다.
          </CustomLoading>
        }
      />
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={""}
          message={
            <CustomMessage onClose={handleClickClose}>{message}</CustomMessage>
          }
        />
      </LayerPopup>
    </div>
  );
};

Finnc.propTypes = {};

export default withBase(Finnc);
