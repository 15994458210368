import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./DeliveryTxnStats.css";
import { connect } from "react-redux";
import { withBase, Pagination } from "components";
import moment from "moment";
import DeliveryTxnStatsConditionBox from "./DeliveryTxnStatsConditionBox";
import DeliveryTxnStatsTable from "./DeliveryTxnStatsTable";

const API = {
  LIST: {
    url: "api/v1/calc/deliveryTxnStats/list",
    method: "post",
  },
};

const DeliveryTxnStats = (props) => {
  const [param, setParam] = useState({
    cretDate: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [total, setTotal] = useState(0);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.deliveryTxnList);
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };
  return (
    <div className="DeliveryTxnStats">
      <DeliveryTxnStatsConditionBox
        onClickSearch={handleClickSearch}
        {...props}
      />
      <DeliveryTxnStatsTable
        widths={[
          "3.8%",
          "4.1%",
          "4.1%",
          "4.1%",
          "4.1%",
          "5%",
          "4.1%",
          "6.6%",
          "4.4%",
          "4.6%",
          "4.1%",
          "4.1%",
          "4.1%",
          "4.1%",
          "3.8%",
          "3.8%",
          "4.1%",
          "3.8%",
          "4%",
          "3.8%",
          "3.8%",
          "3%",
          "3%",
          "3%",
          "*",
        ]}
        columns={[
          "발송일",
          "원천구분",
          "n01s",
          "n019s",
          "n013s",
          "carno1",
          "노선1구분",
          "n021s",
          "n022s",
          "no23s",
          "지원susu1",
          "t1_gubun",
          "t2_gubun",
          "no0s",
          "sbr_gubun",
          "rcode",
          "innib",
          "sbr_lgubun",
          "no2s",
          "ebr_gubun",
          "gubun",
          "innid",
          "ebr_lgubun",
          "sbr_rank",
          "ebr_rank",
        ]}
        datas={
          datas &&
          datas.map((item, key) => {
            return (
              <>
                <td>{item.cretDate}</td>
                <td>{item.srcfileName}</td>
                <td>{item.no1s}</td>
                <td>{item.no19s}</td>
                <td>{item.no13s}</td>
                <td>{item.carno1}</td>
                <td>{item.routeDiv1}</td>
                <td>{item.no21s}</td>
                <td>{item.no22s}</td>
                <td>{item.no23s}</td>
                <td>{item.suprtFee1}</td>
                <td>{item.t1Gubun}</td>
                <td>{item.t2Gubun}</td>
                <td>{item.no0s}</td>
                <td>{item.sbrGubun}</td>
                <td>{item.rcode}</td>
                <td>{item.innib}</td>
                <td>{item.sbrLgubun}</td>
                <td>{item.no2s}</td>
                <td>{item.ebrGubun}</td>
                <td>{item.gubun}</td>
                <td>{item.innid}</td>
                <td>{item.ebrLgubun}</td>
                <td>{item.sbrRank}</td>
                <td>{item.ebrRank}</td>
              </>
            );
          })
        }
      />
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
    </div>
  );
};

DeliveryTxnStats.propTypes = {};

export default withBase(DeliveryTxnStats);
