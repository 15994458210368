import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  CustomInput,
  DatePickerField,
  Button,
  CustomTextArea,
  withBase,
} from "components";
import moment from "moment";

const NotificationEditTable = (props) => {
  const { layerType, viewData, onClose, onSave } = props;

  return (
    <>
      <Formik
        initialValues={{
          regSeq: viewData ? viewData.regSeq : "",
          ntfTitle: viewData ? viewData.ntfTitle : "",
          pstngStartDate: viewData ? moment(viewData.pstngStartDate) : moment(),
          pstngFinishDate: viewData
            ? moment(viewData.pstngFinishDate)
            : moment().add(7, "days"),
          ntfSbst: viewData ? viewData.ntfSbst : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSave(layerType, { ...values });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          ntfTitle: Yup.string().required().max(100),
          pstngStartDate: Yup.date().required().nullable(),
          pstngFinishDate: Yup.date()
            .required()
            .nullable()
            .when("pstngStartDate", (pstngStartDate, schema) =>
              pstngStartDate
                ? schema.min(pstngStartDate, "기간을 확인하세요.")
                : schema
            ),
          ntfSbst: Yup.string().required().max(2000),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form>
              <h5>필수 입력</h5>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="200" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        제목<i></i>
                        <span>
                          {errors.ntfTitle &&
                            touched.ntfTitle &&
                            errors.ntfTitle}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="ntfTitle"
                          type="text"
                          placeholder="제목 입력"
                          onChange={handleChange}
                          value={values.ntfTitle}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        공지기간<i></i>
                        <span>
                          {errors.pstngStartDate && touched.pstngStartDate
                            ? errors.pstngStartDate
                            : errors.pstngFinishDate && touched.pstngFinishDate
                            ? errors.pstngFinishDate
                            : ""}
                        </span>
                      </th>
                      <td>
                        <div className="PopupDate">
                          <ul>
                            <li>
                              <DatePickerField
                                name="pstngStartDate"
                                date={values.pstngStartDate}
                              />
                            </li>
                          </ul>
                          <div className="termIcon">~ </div>
                          <ul>
                            <li>
                              <DatePickerField
                                name="pstngFinishDate"
                                date={values.pstngFinishDate}
                              />
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        내용<i></i>
                        <span>
                          {errors.ntfSbst && touched.ntfSbst && errors.ntfSbst}
                        </span>
                      </th>
                      <td>
                        <CustomTextArea
                          name="ntfSbst"
                          placeholder="내용 입력"
                          onChange={handleChange}
                          value={values.ntfSbst}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="PopupButton">
                <Button
                  type="submit"
                  className="insert"
                  disabled={isSubmitting}
                >
                  저장
                </Button>
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

NotificationEditTable.propTypes = {};

export default withBase(NotificationEditTable);
