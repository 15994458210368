import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./AcntDpsTxnList.css";
import {
  withBase,
  Button,
  ControlBox,
  LayerPopup,
  PopupWrapper,
} from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import AcntDpsTxnListConditionBox from "./AcntDpsTxnListConditionBox";
import AcntDpsTxnListTable from "./AcntDpsTxnListTable";
import AcntDpsTxnListPopup from "./AcntDpsTxnListPopup";

const API = {
  LIST: {
    url: "api/v1/report/acntDpsTxn/list",
    method: "post",
  },
  CREATE: {
    url: "api/v1/report/acntDpsTxn/create",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/report/acntDpsTxn/update",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/acntDpsTxn/down",
    method: "post",
  },
};

const AcntDpsTxnList = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    dealDate: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalData, setTotalData] = useState();
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setTotalData("");
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.acntDpsTxnList);

    !response.acntDpsTxnList || response.acntDpsTxnList.length === 0
      ? setTotalData("")
      : setTotalData(response.acntDpsTxnList[0]);

    searching(false);
  };
  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    return true;
  };
  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDpsChk = async () => {
    const { searching } = props;
    searching(true, "입금확인중입니다.");

    let ret = false;
    let msg = "";

    ret = await edit(API.CREATE, {});
    msg = "입금확인 작업이 완료되었습니다.";

    if (ret) {
      if (msg) props.toast.error(msg);
    }
    searching(false);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  const handleDoubleClick = (index) => {
    setLayerType("edit");
    setViewData(datas[index]);
  };
  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const handleClickSave = async (layerType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "edit":
        ret = await edit(API.UPDATE, data);
        msg = "수정저장되었습니다.";
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };
  return (
    <div className="AcntDpsTxnList">
      <AcntDpsTxnListConditionBox
        onClickSearch={handleClickSearch}
        {...props}
      />
      <ControlBox>
        {/* {cretFlag && (
          <Button className="insert" onClick={handleClickDpsChk}>
            입금확인
          </Button>
        )} */}
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <AcntDpsTxnListTable
        datas={datas}
        totalData={totalData}
        page={param.pageNo}
        total={total}
        row={15}
        pageCount={10}
        fetchData={(page) => {
          setParam({
            ...param,
            pageNo: page,
          });
        }}
        onDoubleClick={handleDoubleClick}
        formatter={props.formatter}
      />
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"입금리스트"}
          editTable={
            <AcntDpsTxnListPopup
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

AcntDpsTxnList.propTypes = {};

export default withBase(AcntDpsTxnList);
