import React from "react";
import PropTypes from "prop-types";
import "./IndvSmsTrmTxn.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withBase, Button, CustomTextArea, FormatInput } from "components";
import { useSelector } from "react-redux";

const API = {
  CREATE: {
    url: "api/v1/sms/indvSmsTrmTxn/create",
    method: "post",
  },
};

const IndvSmsTrmTxn = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;

    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    return true;
  };

  const handleClickSave = async (data) => {
    let ret = false;
    let msg = "";

    ret = await edit(API.CREATE, data);
    msg = "문자메세지 전송되었습니다.";

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };
  return (
    <div className="IndvSmsTrmTxn">
      <Formik
        initialValues={{
          smsChr: "",
          rcvTelNo: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          const { rcvTelNo, ...rest } = values;
          setSubmitting(true);
          handleClickSave({
            ...rest,
            rcvTelNo: props.formatter(rcvTelNo, "toValue"),
          });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          smsChr: Yup.string().required().max(200),
          rcvTelNo: Yup.string().required().min(12),
        })}
      >
        {(props) => {
          const {
            values,
            isSubmitting,
            touched,
            errors,
            handleChange,
            handleSubmit,
          } = props;
          return (
            <Form onSubmit={handleSubmit} className="EnterMessage">
              <div className="textArea">
                <CustomTextArea
                  name="smsChr"
                  placeholder="메세지를 입력해 주세요."
                  onChange={handleChange}
                  value={values.smsChr}
                />
              </div>
              <span>
                {errors.smsChr &&
                  touched.smsChr &&
                  "메세지 내용은 " + errors.smsChr}
              </span>
              <dl>
                <dt>
                  받는사람<i></i>
                </dt>
                <dd>
                  <FormatInput
                    formatType="phone"
                    name="rcvTelNo"
                    type="text"
                    placeholder="전화번호 입력"
                    onChange={handleChange}
                    value={values.rcvTelNo}
                  />
                </dd>
                <span>
                  {errors.rcvTelNo &&
                    touched.rcvTelNo &&
                    "받는사람은 " + errors.rcvTelNo}
                </span>
              </dl>
              {cretFlag && (
                <Button type="submit" className="send" disabled={isSubmitting}>
                  전송하기
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

IndvSmsTrmTxn.propTypes = {};

export default withBase(IndvSmsTrmTxn);
