import React, { useState } from "react";
import PropTypes from "prop-types";
import "./CheckBox.css";

const CheckBox = (props) => {
  const { name, onChange, checked, disabled } = props;
  return (
    <span className={checked ? "checkbox checked" : "checkbox"}>
      <input
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    </span>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
};

export default CheckBox;
