import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./RoleMgtEdit.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  withBase,
  ControlBox,
  Button,
  CustomInput,
  CheckBox as Checkbox,
} from "components";

const API = {
  LIST: {
    url: "api/v1/comn/roleMgt/list",
    method: "post",
  },
  CREATE: {
    url: "api/v1/comn/roleMgt/create",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/comn/roleMgt/update",
    method: "post",
  },
  REFRESH: {
    url: "api/v1/comn/cache/refresh/roleInfo",
    method: "get",
  },
};

const RoleMgtEdit = (props) => {
  const { roleId, roleName } = props.match.params;
  const [datas, setDatas] = useState([]);
  let upperMenu = "";

  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;

    let param = roleId === "new" ? { roleId: "temp" } : { roleId: roleId };
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      searching(false);
      return;
    }
    setDatas(response.roleRiHstList);
    searching(false);
  };

  const handleClickCheckBox = (riId, value) => {
    setDatas(
      datas.map((item) => (item.riId === riId ? { ...item, ...value } : item))
    );
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    if (roleId === "new") {
      const { url, method } = API.REFRESH;

      const response = await HttpRequest.request(url, method);
      if (HttpRequest.hasError(response, true)) {
        return false;
      }
    }
    return true;
  };

  const handleClickSave = async (roleIdName) => {
    let ret = false;
    let msg = "";

    let data = datas.map((item) => ({ ...item, roleIdName: roleIdName }));
    setDatas(data);

    switch (roleId) {
      case "new":
        ret = await edit(API.CREATE, { roleRiHstList: data });
        msg = "신규등록되었습니다.";
        break;
      default:
        ret = await edit(API.UPDATE, { roleRiHstList: data });
        msg = "수정저장되었습니다.";
        break;
    }
    if (ret) {
      props.history.push("/comn/roleMgt");

      if (msg) props.toast.error(msg);
    }
  };

  return (
    <div className="RoleMgtEdit">
      <Formik
        initialValues={{
          roleIdName: roleName,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleClickSave(values.roleIdName);
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          roleIdName: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form>
              <ControlBox
                InputTitle={
                  <>
                    <h6>
                      권한명
                      <i></i>
                      <span>
                        {errors.roleIdName &&
                          touched.roleIdName &&
                          errors.roleIdName}
                      </span>
                    </h6>
                  </>
                }
                left={
                  <>
                    <CustomInput
                      name="roleIdName"
                      type="text"
                      placeholder="권한명 입력"
                      onChange={handleChange}
                      value={values.roleIdName}
                    />
                  </>
                }
              >
                <Button
                  type="submit"
                  className="insert"
                  disabled={isSubmitting}
                >
                  저장
                </Button>
                <Link to="/comn/roleMgt">
                  <Button className="prev">이전화면</Button>
                </Link>
              </ControlBox>
            </Form>
          );
        }}
      </Formik>
      <article className="grid_data">
        <div>
          <div className="thead">
            <table>
              <colgroup>
                {["*", "8.6%", "14%"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {["메뉴", "조회", "등록/수정/삭제"].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody NoreSize HasRowsTH">
            <table>
              <colgroup>
                {["*", "8.6%", "14%"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {datas.map((item, key) => {
                  let gridTr =
                    upperMenu === item.riIdUpName ? (
                      <tr key={key}>
                        <td className="cnt">{item.riIdName}</td>
                        <td>
                          <Checkbox
                            checked={item.readFlag === "1" ? true : false}
                            onChange={() =>
                              handleClickCheckBox(item.riId, {
                                readFlag: window.event.target.checked
                                  ? "1"
                                  : "0",
                              })
                            }
                          />
                        </td>
                        <td>
                          <Checkbox
                            checked={item.cretFlag === "1" ? true : false}
                            onChange={() =>
                              handleClickCheckBox(item.riId, {
                                cretFlag: window.event.target.checked
                                  ? "1"
                                  : "0",
                              })
                            }
                          />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr key={key}>
                          <th>{item.riIdUpName}</th>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="cnt">{item.riIdName}</td>
                          <td>
                            <Checkbox
                              checked={item.readFlag === "1" ? true : false}
                              onChange={() =>
                                handleClickCheckBox(item.riId, {
                                  readFlag: window.event.target.checked
                                    ? "1"
                                    : "0",
                                })
                              }
                            />
                          </td>
                          <td>
                            <Checkbox
                              checked={item.cretFlag === "1" ? true : false}
                              onChange={() =>
                                handleClickCheckBox(item.riId, {
                                  cretFlag: window.event.target.checked
                                    ? "1"
                                    : "0",
                                })
                              }
                            />
                          </td>
                        </tr>
                      </>
                    );
                  upperMenu = item.riIdUpName;
                  return gridTr;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  );
};

RoleMgtEdit.propTypes = {};

export default withBase(RoleMgtEdit);
