import React from "react";
import PropTypes from "prop-types";
import "./CustomRadioButton.css";

const CustomRadioButton = (props) => {
  const { name, onChange, checked, value } = props;
  const className = checked ? "radio checked" : "radio";
  return (
    <span className={className}>
      <input type="radio" name={name} onChange={onChange} value={value} />
    </span>
  );
};

CustomRadioButton.propTypes = {
  checked: PropTypes.bool,
};

export default CustomRadioButton;
