import React from "react";
import PropTypes from "prop-types";
import "./TrmSumSheetTable.css";
import IconTotalPlus from "assets/images/icon_plus.png";

const TrmSumSheetTable = (props) => {
  const { datas, totalData, formatter } = props;
  return (
    <div className="TrmSumSheetTable">
      <article className="grid_data">
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {[
                  "6.4%",
                  "9.8%",
                  "9.9%",
                  "9.6%",
                  "10%",
                  "9.7%",
                  "9.9%",
                  "9.7%",
                  "12.5%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {[
                    "구분",
                    "경동 수량",
                    "합동 수량",
                    "총 수량",
                    "총 건수",
                    "경동 운임",
                    "합동 운임",
                    "운임 합계",
                    "수량당 운임",
                    "평균 운임비",
                  ].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {[
                  "6.4%",
                  "9.8%",
                  "9.9%",
                  "9.6%",
                  "10%",
                  "9.7%",
                  "9.9%",
                  "9.7%",
                  "12.5%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={10}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas &&
                  datas.map((item, key) => {
                    return (
                      <>
                        <tr key={key}>
                          <th>{item.division}</th>
                          <td className="amount">
                            {formatter(item.kyungdongQnty, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.hapDongQnty, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.totalQnty, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.totalCnt, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.kyungdongFare, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.hapDongFare, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.totalFare, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.farePerQnty, "amount")}
                          </td>
                          <td>{item.avgFare}</td>
                        </tr>
                      </>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="tfoot">
            <table>
              <colgroup>
                {[
                  "6.4%",
                  "9.8%",
                  "9.9%",
                  "9.6%",
                  "10%",
                  "9.7%",
                  "9.9%",
                  "9.7%",
                  "12.5%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!totalData || totalData.length === 0 ? (
                  <></>
                ) : (
                  totalData.map((item, key) => {
                    return (
                      <tr key={key}>
                        <th>
                          <img src={IconTotalPlus} alt="" />
                          {item.division}
                        </th>
                        <td className="amount">
                          {formatter(item.kyungdongQnty, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.hapDongQnty, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.totalQnty, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.totalCnt, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.kyungdongFare, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.hapDongFare, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.totalFare, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.farePerQnty, "amount")}
                        </td>
                        <td>{item.avgFare}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  );
};

TrmSumSheetTable.propTypes = {};

export default TrmSumSheetTable;
