import React from "react";
import PropTypes from "prop-types";
import "./PlcfbListTable.css";

const Table = (props) => {
  const { datas, onDoubleClick, formatter } = props;

  return (
    <article className="grid_data">
      <div className="thead">
        <table>
          <colgroup>
            {[
              "5%",
              "*",
              "5%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
            ].map((i, key) => {
              return <col width={i} key={key} />;
            })}
          </colgroup>
          <tbody>
            <tr>
              {[
                "월",
                "영업소명",
                "소장명",
                "영업소정산금액",
                "영업소수수료",
                "노선차량수수료",
                "본사입금액",
                "본사출금액",
                "개별출금액",
                "기타출금액",
                "미수잔액",
                "한도금액",
                "비고",
              ].map((i, key) => {
                return <th key={key}>{i}</th>;
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="tbody ScrollNone HasTH">
        <table>
          <colgroup>
            {[
              "5%",
              "*",
              "5%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
              "8%",
            ].map((i, key) => {
              return <col width={i} key={key} />;
            })}
          </colgroup>
          <tbody>
            {!datas || datas.length === 0 ? (
              <tr>
                <td className="NoData" colSpan={13}>
                  조회 결과가 없습니다.
                </td>
              </tr>
            ) : (
              datas.map((item, key) => {
                return (
                  <tr
                    key={key}
                    onDoubleClick={() => onDoubleClick(key)}
                    className={"hand " + (item.mktYn === "N" ? "nouse" : "")}
                  >
                    <th>{formatter(item.setlDate, "dateYm")}</th>
                    <td className="tal">{item.plcfbName}</td>
                    <td className="tal">
                      {formatter(item.managerName, "name", false)}
                    </td>
                    <td className="amount">
                      {formatter(item.dlvrPayment, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.mktFee, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.routecarFee, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.hoffDspAmt, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.hoffOtpayAmt, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.hoffOtpayAmt2, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.hoffOtpayAmt3, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.natmpBlnc, "amount")}
                    </td>
                    <td className="amount">
                      {formatter(item.limitAmt, "amount")}
                    </td>
                    <td className="tal">{item.remarks}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </article>
  );
};

Table.propTypes = {};

export default Table;
