import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./SetlSumTxn.css";
import {
  withBase,
  Button,
  ControlBox,
  LayerPopup,
  PopupWrapper,
} from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import SetlSumTxnConditionBox from "./SetlSumTxnConditionBox";
import SetlSumTxnTable from "./SetlSumTxnTable";
import SetlSumTxnEnterPopup from "./SetlSumTxnEnterPopup";

const API = {
  LIST: {
    url: "api/v1/report/setlSum/list",
    method: "post",
  },
  LISTETC: {
    url: "api/v1/report/setlSum/listetc",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/setlSum/down",
    method: "post",
  },
  CREATE: {
    url: "api/v1/report/setlSum/createetc",
    method: "post",
  },
  DELETE: {
    url: "api/v1/report/setlSum/deleteetc",
    method: "post",
  },
};

const SetlSumTxn = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    setlDate: moment().add(-1, "days").format("YYYYMM"),
  });
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState();
  const [etcDatas, setEtcDatas] = useState([]);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setTotalData("");
      searching(false);
      return;
    }

    if (
      !response.corpAcntSumTxnList ||
      response.corpAcntSumTxnList.length === 0
    ) {
      setDatas([]);
      setTotalData("");
    } else {
      setDatas(response.corpAcntSumTxnList.slice(0, -1));
      setTotalData(response.corpAcntSumTxnList.slice(-1));
    }
    searching(false);
  };
  const listEtc = async (etcParam) => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LISTETC;
    console.log(etcParam);
    const response = await HttpRequest.request(url, method, etcParam);
    if (HttpRequest.hasError(response, true)) {
      setEtcDatas([]);
      searching(false);
      return;
    }
    setEtcDatas(response.corpAcntSumTxnList);
    searching(false);
  };
  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    list();
    return true;
  };
  const handleClickSearch = (condition) => {
    setParam(condition);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  const handleDoubleClick = (index) => {
    const clickData = datas[index];
    setLayerType("input");
    setViewData(clickData);
    listEtc(clickData);
  };

  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };

  const handleClickDel = async (data) => {
    let ret = false;
    let msg = "";
    console.log(data);

    if (window.confirm("해당 정보를 삭제 하시겠습니까?")) {
      ret = await edit(API.DELETE, { corpAcntSumTxnList: [data] });
      msg = "삭제되었습니다.";
    }

    if (ret) {
      let etcData = etcDatas.filter((item) => {
        return item.seq !== data.seq;
      });

      setEtcDatas(etcData);

      if (msg) props.toast.error(msg);
    }
  };
  const handleClickSave = async (saveType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (saveType) {
      case "create":
        ret = await edit(API.CREATE, { corpAcntSumTxnList: data });
        msg = "수기입력 저장되었습니다.";
        break;
      default:
        console.log(saveType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };
  return (
    <div className="SetlSumTxn">
      <SetlSumTxnConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <SetlSumTxnTable
        datas={datas}
        totalData={totalData}
        onDoubleClick={handleDoubleClick}
        formatter={props.formatter}
      />
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"정산집계표"}
          className={"SetlSumTxnPopup"}
          editTable={
            <SetlSumTxnEnterPopup
              layerType={layerType}
              viewData={viewData}
              etcDatas={etcDatas}
              onClose={handleClickClose}
              onSave={handleClickSave}
              onDel={handleClickDel}
              cretFlag={cretFlag}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

SetlSumTxn.propTypes = {};

export default withBase(SetlSumTxn);
