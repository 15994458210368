import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./CarInfo.css";
import { connect } from "react-redux";
import {
  withBase,
  ControlBox,
  Button,
  Table,
  Pagination,
  LayerPopup,
  PopupWrapper,
} from "components";
import { useSelector } from "react-redux";
import CarInfoConditionBox from "./CarInfoConditionBox";
import CarInfoViewTable from "./CarInfoViewTable";
import CarInfoEditTable from "./CarInfoEditTable";

const API = {
  LIST: {
    url: "api/v1/comn/carInfo/list",
    method: "post",
  },
  READ: {
    url: "api/v1/comn/carInfo/read",
    method: "post",
  },
  CREATE: {
    url: "api/v1/comn/carInfo/create",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/comn/carInfo/update",
    method: "post",
  },
  DOWN: {
    url: "api/v1/comn/carInfo/down",
    method: "post",
  },
};

const CarInfo = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({ pageNo: 1 });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.carInfoList);
    searching(false);
  };
  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    return true;
  };
  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };
  const handleDoubleClick = (index) => {
    setLayerType("view");
    setViewData(datas[index]);
  };
  const handleClickNew = () => {
    setLayerType("new");
  };
  const handleClickEdit = () => {
    setLayerType("edit");
  };
  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const handleClickSave = async (layerType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "new":
        ret = await edit(API.CREATE, data);
        msg = "신규등록되었습니다.";
        break;
      case "edit":
        ret = await edit(API.UPDATE, data);
        msg = "수정저장되었습니다.";
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="CarInfo">
      <CarInfoConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        {cretFlag && (
          <Button className="insert" onClick={handleClickNew}>
            신규등록
          </Button>
        )}
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <article className="grid_data">
        <div className="TableArea">
          <Table
            widths={[
              "4.1%",
              "9%",
              "8.3%",
              "8.3%",
              "8.8%",
              "8.8%",
              "6.1%",
              "10.2%",
              "6.9%",
              "6.9%",
              "8.3%",
              "8.8%",
              "*",
            ]}
            columns={[
              "순번",
              "영업소명",
              "소장명",
              "차량명",
              "차량구분",
              "차량번호",
              "톤수",
              "계좌번호",
              "계좌은행",
              "예금주",
              "연락처",
              "법인명",
              "운행여부",
            ]}
            datas={
              datas &&
              datas.map((item, key) => {
                return (
                  <>
                    <td className="roboto">{item.rowNum}</td>
                    <td className="tal">{item.plcfbName}</td>
                    <td>{props.formatter(item.managerName, "name", false)}</td>
                    <td className="tal">{item.carName}</td>
                    <td>{item.carDivNm}</td>
                    <td className="roboto">{item.carNo}</td>
                    <td className="roboto">{item.carTon}</td>
                    <td className="roboto">
                      {props.formatter(item.acntNo, "account", false)}
                    </td>
                    <td>{item.acntBankName}</td>
                    <td>{props.formatter(item.depositor, "name", false)}</td>
                    <td className="roboto">
                      {props.formatter(item.cntcNo, "phone", false)}
                    </td>
                    <td className="tal">{item.corpCdNm}</td>
                    {item.mvmnYn === "Y" ? (
                      <td className="TextColorRed">운행중</td>
                    ) : (
                      <td>미운행</td>
                    )}
                  </>
                );
              })
            }
            onDoubleClick={handleDoubleClick}
          />
        </div>
      </article>
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"차량관리"}
          viewTable={
            <CarInfoViewTable
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onEdit={handleClickEdit}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          editTable={
            <CarInfoEditTable
              viewData={viewData}
              layerType={layerType}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

CarInfo.propTypes = {};
export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({}),
)(withBase(CarInfo));
