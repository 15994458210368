import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withBase } from "components";
import DeliveryTxnHeader from "./DeliveryTxnHeader";
import DeliveryTxn from "./DeliveryTxn";
import RptDatPrt from "views/Calc/RptDatPrt/RptDatPrt";
import SetlTxn from "views/Calc/SetlTxn/SetlTxn";
import FtasSetl from "views/Calc/FtasSetl/FtasSetl";

const API = {
  LISTSTEP: {
    url: "api/v1/calc/deliveryTxn/liststep",
    method: "post",
  },
};

const DeliveryTxnStep = (props) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    const { HttpRequest } = props;
    const { url, method } = API.LISTSTEP;

    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setStep(0);
      return;
    }
    setStep(response);
  };

  const handleClickStep = (clickStep) => {
    setStep(clickStep);
  };

  return (
    <>
      <DeliveryTxnHeader step={step} clickStep={handleClickStep} />
      {step === 1 ? (
        <DeliveryTxn />
      ) : step === 2 ? (
        <RptDatPrt />
      ) : step === 3 ? (
        <SetlTxn />
      ) : step === 4 ? (
        <FtasSetl />
      ) : (
        <></>
      )}
    </>
  );
};

export default withBase(DeliveryTxnStep);
