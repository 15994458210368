import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { properties } from "variables/properties";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { Searching } from "components";

const withBase = (WrappedComponent) => {
  return class extends Component {
    state = { displayFlg: false, msg: "" };
    componentDidMount() {}
    componentWillUnmount() {}
    httpPost = async (url, data) => {
      try {
        const response = await axios.post(url, data);
        return response;
      } catch (e) {
        console.log(e);
      }
    };
    httpGet = async (url) => {
      try {
        const response = await axios.get(url);
        return response;
      } catch (e) {
        console.log(e);
      }
    };
    httpPut = async (url, data) => {
      try {
        const response = await axios.put(url, data);
        return response;
      } catch (e) {
        console.log(e);
      }
    };
    httpDelete = async (url) => {
      try {
        const response = await axios.delete(url);
        return response;
      } catch (e) {
        console.log(e);
      }
    };
    HttpRequest = {
      request: async (url, method, data, errRtnFlg) => {
        if (properties && properties.contextRoot) {
          url = `${properties.contextRoot}/${url}`;
        }
        let response = null;
        switch (method) {
          case "post":
            response = await this.httpPost(url, data);
            break;
          case "put":
            response = await this.httpPut(url, data);
            break;
          case "delete":
            response = await this.httpDelete(url, data);
            break;
          case "get":
          default:
            response = await this.httpGet(url);
            break;
        }
        if (response instanceof Error) {
          response = response.response;
        }
        if (!response) {
          toast.error("데이터 수신 실패");
          return;
        } else if (response.status !== 200) {
          if (errRtnFlg) {
            return response.data;
          } else {
            if (response.data) {
              toast.error(`${response.data.message}`);
              if (response.data.error === "Unauthorized") {
                this.props.history.push("/login");
              }
            } else {
              toast.error(`HTTP 상태 오류:${response.status}`);
            }
            return;
          }
        }
        return response.data;
      },
      hasError: (data, notify, message) => {
        if (!data) return true;
        if (data.hasOwnProperty("status") && data.hasOwnProperty("error")) {
          if (notify) {
            if (message) {
              toast.error(message);
            } else {
              toast.error(`[${data.error}] ${data.message}`);
            }
          }
          return true;
        }

        return false;
      },
    };
    findGroupCode = (code, grpCd) => {
      // return _.find(code, item => {
      //   return item.cdGrpId === grpCd;
      // });
      return code.find((item) => {
        return item.cdGrpId === grpCd;
      });
    };
    findDetailCode = (code, grpCd, dtlCd) => {
      // const groupCode = this.findGroupCode(code, grpCd);
      // return _.find(groupCode.cdDtlInfoList, item => {
      //   return item.cdDtlId === dtlCd;
      // });
      const groupCode = this.findGroupCode(code, grpCd);
      return groupCode.cdDtlInfoList.find((item) => {
        return item.cdDtlId === dtlCd;
      });
    };
    codeToName = (code, grpCd, dtlCd) => {
      const detailCode = this.findDetailCode(code, grpCd, dtlCd);
      return detailCode ? detailCode.cdDtlNm : "";
    };
    searchMenu = (element, id) => {
      if (element.id === id) {
        return element;
      } else if (element.views != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < element.views.length; i++) {
          result = this.searchMenu(element.views[i], id);
        }
        return result;
      }
      return null;
    };
    toDate = (time) => {
      if (!time) return "";
      return moment(time).format("YYYY.MM.DD");
    };
    toDateDash = (time) => {
      if (!time) return "";
      return moment(time).format("YYYY-MM-DD");
    };
    toDateYm = (time) => {
      if (!time) return "";
      return moment(time).format("YYYY.MM");
    };
    toDateTime = (time) => {
      if (!time) return "";
      return moment(time).format("YYYY.MM.DD HH:mm:ss");
    };
    toTime = (time) => {
      if (time.length === 4) {
        return time.replace(/([0-9]{2})([0-9]{2})/, "$1:$2");
      } else {
        return time.replace(/([0-9]{2})([0-9]{2})([0-9]{2})/, "$1:$2:$3");
      }
    };
    phoneNum = (phoneNum, masking = false) => {
      phoneNum = phoneNum.replace(/[-]/g, "");
      if (masking) {
        return phoneNum.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
          "$1-****-$3",
        );
      } else {
        return phoneNum.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
          "$1-$2-$3",
        );
      }
    };
    cardNum = (cardNum, masking = false) => {
      if (masking) {
        return cardNum.replace(
          /(\\d{4})(\\d{4})(\\d{4})(\\d{3,4})/,
          "$1-$2-$3-$4",
        );
      } else {
        return cardNum.replace(
          /(\\d{4})(\\d{4})(\\d{4})(\\d{3,4})/,
          "****-****-****-$4",
        );
      }
    };
    number = (formattedNumber) => {
      if (formattedNumber) {
        return Number(formattedNumber.replace(/[^0-9]/g, ""));
      }

      return 0;
    };
    toValue = (toValue) => {
      return toValue.replace(/[^0-9]/g, "");
    };
    bizno = (bizno, masking = false) => {
      bizno = bizno.replace(/[-]/g, "");
      if (masking) {
        return bizno.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-*****");
      } else {
        return bizno.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
      }
    };
    account = (account, masking = false) => {
      account = account.replace(/[-]/g, "");
      if (masking) {
        return account.replace(
          /(\d{3})(\d{4})(\d{4})(\d{2})/,
          "$1-****-****-$4",
        );
      } else {
        return account.replace(/(\d{3})(\d{4})(\d{4})(\d{2})/, "$1-$2-$3-$4");
      }
    };
    custNo = (custNo, masking = false) => {
      custNo = custNo.replace(/[-]/g, "");
      if (custNo.length > 7) custNo = custNo.slice(0, 7);
      if (masking) {
        return custNo.replace(/([0-9]{6})([0-9]{1})/, "$1-$2******");
      } else {
        return custNo.replace(/([0-9]{6})([0-9]{1})/, "$1-$2");
      }
    };
    toName = (name, masking = false) => {
      if (masking) {
        // ie 에서 replace(/(?<=.{2})./gi, "*") 오류나서 풀어서 작성
        let firstName = "";
        let maskName = "";
        let lastName = "";
        let chkPoint = name.indexOf("(");

        if (chkPoint < 0) {
          firstName = name;
        } else {
          firstName = name.slice(0, chkPoint);
          lastName = name.slice(chkPoint);
        }

        if (firstName.length === 0) {
          return name;
        } else if (firstName.length < 3) {
          firstName = firstName.slice(0, 1) + "*";
        } else {
          maskName = firstName.slice(2);
          firstName = firstName.slice(0, 2);

          let maskNameLen = maskName.length;
          maskName = maskName.replace(
            new RegExp(".(?=.{0," + maskNameLen + "})", "g"),
            "*",
          );
        }

        return firstName + maskName + lastName;
      } else {
        return name;
      }
    };
    formatter = (value, type, masking) => {
      if (!value) return value;

      let newValue = "";
      switch (type) {
        case "date": // 날짜
          newValue = this.toDate(value);
          break;
        case "dateDash": // 날짜
          newValue = this.toDateDash(value);
          break;
        case "dateYm": // 년월
          newValue = this.toDateYm(value);
          break;
        case "datetime": // 일시
          newValue = this.toDateTime(value);
          break;
        case "time": // 시간
          newValue = this.toTime(value);
          break;
        case "amount": // 금액
          newValue = numeral(value).format("0,0");
          break;
        case "phone": // 전화번호
          newValue = this.phoneNum(value, masking);
          break;
        case "number": // 1,000 -> 1000
          newValue = this.number(value);
          break;
        case "bizno": // 사업자번호
          newValue = this.bizno(value, masking);
          break;
        case "account": // 농협 계좌번호
          newValue = this.account(value, masking);
          break;
        case "custNo": // 주민번호
          newValue = this.custNo(value, true);
          break;
        case "toValue": // 0~9에 해당하는 값
          newValue = this.toValue(value);
          break;
        case "name": // 이름
          newValue = this.toName(value, masking);
          break;
        default:
          break;
      }

      return newValue;
    };
    downExcel = {
      request: async (url, data) => {
        if (properties && properties.contextRoot) {
          url = `${properties.contextRoot}/${url}`;
        }
        await axios
          .post(url, data, { responseType: "arraybuffer" })
          .then((res) => {
            let blob = new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let fileName;
            if(res.headers["content-disposition"] === undefined){
              fileName = "test.xlsx";
            }else{
              fileName =  res.headers["content-disposition"].split(
                "filename=",
              )[1];
            }
            fileName = decodeURI(fileName);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {
            toast.error("엑셀 다운로드 실패");
          });
      },
    };
    searching = (flg, msg = "") => {
      this.setState({
        displayFlg: flg,
        msg: msg,
      });
    };

    render() {
      const {
        HttpRequest,
        findGroupCode,
        findDetailCode,
        codeToName,
        searchMenu,
        toDate,
        toDateTime,
        formatter,
        downExcel,
        searching,
      } = this;
      return (
        <>
          <WrappedComponent
            {...this.props}
            HttpRequest={HttpRequest}
            toast={toast}
            findGroupCode={findGroupCode}
            findDetailCode={findDetailCode}
            codeToName={codeToName}
            searchMenu={searchMenu}
            toDate={toDate}
            toDateTime={toDateTime}
            formatter={formatter}
            downExcel={downExcel}
            searching={searching}
          />
          {this.state.displayFlg ? (
            <Searching searchState={this.state} />
          ) : (
            <></>
          )}
        </>
      );
    }
  };
};

export default withBase;
