import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./AcntAbookTxnImpossList.css";
import { withBase, Button, ControlBox, CustomInput } from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import AcntAbookTxnImpossListConditionBox from "./AcntAbookTxnImpossListConditionBox";

const API = {
  LIST: {
    url: "api/v1/report/acntDpsTxn/listimppay",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/report/acntDpsTxn/updateimppay",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/acntDpsTxn/downimppay",
    method: "post",
  },
};

const AcntAbookTxnImpossList = (props) => {
  const [datas, setDatas] = useState([]);
  const [param, setParam] = useState({
    dealDate: moment().add(-1, "days").format("YYYYMMDD"),
    dealEndDate: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [saveIndex, setSaveIndex] = useState([]);

  useEffect(() => {
    if (param) list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      searching(false);
      return;
    }
    
    if (!response.acntDpsTxnList) {
      setDatas([]);
      
    } else {
      setDatas(response.acntDpsTxnList);
      
    }
    searching(false);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    setSaveIndex([]);
    return true;
  };

  const handleClickSearch = (condition) => {    
    setParam(condition);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  const isSaveData = () => {
    let ret = true;

    if (saveIndex.length === 0) {
      props.toast.error("저장할 내용이 없습니다.");
      ret = false;
    }

    return ret;
  };

  const handleClickEdit = async () => {
    let ret = false;
    let msg = "";

    // if (isSaveData()) { <--이거하면 수정후 조회 시 이전값 출력
      let saveData = datas.filter((item, index) => saveIndex.includes(index));
      ret = await edit(API.UPDATE, { acntDpsTxnList: saveData });
      msg = "수정저장되었습니다.";
    // }

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };

  const handleChange = (index, rowNum, value) => {
     setDatas(
      datas.map((item) =>
        item.rowNum === rowNum ? { ...item, ...value } : item,
      ), 
    );

    setSaveIndex([...saveIndex, index]);
  };

  return (
    <div className="AcntAbookTxnImpossList">     
    <AcntAbookTxnImpossListConditionBox onClickSearch={handleClickSearch} {...props} /> 
    <ControlBox>
      <Button className="insert" onClick={handleClickEdit}>
        수정
      </Button> 
      <Button className="excel" onClick={handleClickDown}>
        엑셀 다운로드
      </Button>
    </ControlBox>
      
    <article className="grid_data">
      <div className="thead">
        <table>
          <colgroup>
            {["15%", "15%", "15%", "15%", "15%", "*"].map((i, key) => {
              return <col width={i} key={key} />;
            })}
          </colgroup>
          <tbody>
            <tr>
              {["일자", "시간", "금액", ,"거래기록사항", "입금확인수행시간", "비고"].map(
                (i, key) => {
                  return <th key={key}>{i}</th>;
                },
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="holdTable">
      {/* ScrollNone */}
        <div className="tbody HasTH ">
          <table>
            <colgroup>
              {["15%", "15%", "15%", "15%", "15%", "*"].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={6}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{props.formatter(item.dealDate, "date")}</td>
                      <td>{props.formatter(item.dealTime, "time")}</td>
                      <td className="amount">
                        {props.formatter(item.dealAmt, "amount", false)}
                      </td>
                      <td className="tal">{item.notes}</td>
                      <td>{props.formatter(item.dpsConfDt, "datetime")}</td>
                      <td>
                      <CustomInput
                          name="remarks"
                          type="text"
                          placeholder="비고 입력"
                          onChange={(e) =>
                            handleChange(key, item.rowNum, {
                            remarks: e.target.value,
                            })
                          }
                          value={item.remarks}
                          maxLength={20}
                      />  
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </article>
    </div>
  );
};

AcntAbookTxnImpossList.propTypes = {};

export default withBase(AcntAbookTxnImpossList);
