import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Button, ConditionBox, CustomInput } from "components";

const NotificationCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            ntfTitle: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onClickSearch(values);
            setSubmitting(false);
          }}
        >
          {(props) => {
            const { values, isSubmitting, handleChange, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="HasTable">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <dl>
                                        <dt>제목</dt>
                                        <dd>
                                            <CustomInput
                                                name="ntfTitle"
                                                type="text"
                                                placeholder="제목 입력"
                                                onChange={handleChange}
                                                value={values.ntfTitle}
                                            />
                                        </dd>
                                    </dl>
                                </td>
                                <td>
                                    <Button
                                        type="submit"
                                        className="inquire"
                                        disabled={isSubmitting}
                                    >
                                        조회하기
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>  
              </form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

NotificationCondition.propTypes = {};

export default NotificationCondition;
