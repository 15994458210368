import React from "react";
import PropTypes from "prop-types";
import "./Next.css";
import PagingNextArrow from "assets/images/next.png";

const Next = (props) => {
  const { onClick, visible } = props;
  return (
    visible && (
      <>
        <span className="line">&nbsp;</span>
        <span href="#" className="next" onClick={onClick}>
          다음
          <img src={PagingNextArrow} alt="" />
        </span>
      </>
    )
  );
};

Next.propTypes = {};

export default Next;
