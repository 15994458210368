import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./DsblTxnList.css";
import { withBase, Button, ControlBox } from "components";
import moment from "moment";
import IconTotalPlus from "assets/images/icon_plus.png";
import DsblTxnListConditionBox from "./DsblTxnListConditionBox";

const API = {
  LIST: {
    url: "api/v1/report/dsblTxn/list",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/dsblTxn/downdsbltxn",
    method: "post",
  },
};

const DsblTxnList = (props) => {
  const [param, setParam] = useState({
    setlDate: moment().add(-1, "days").format("YYYYMM"),
  });
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState();

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setTotalData("");
      searching(false);
      return;
    }

    if (
      !response.corpAcntSumTxnList ||
      response.corpAcntSumTxnList.length === 0
    ) {
      setDatas([]);
      setTotalData("");
    } else {
      setDatas(response.corpAcntSumTxnList.slice(0, -1));
      setTotalData(response.corpAcntSumTxnList.slice(-1));
    }
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam(condition);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };
  return (
    <div className="DsblTxnList">
      <DsblTxnListConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <article className="grid_data">
        <div className="thead">
          <table>
            <colgroup>
              {["20%", "20%", "20%", "20%", "*"].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                {["발송일", "정지", "불능", "송금액", "합계"].map(
                  (i, key) => {
                    return <th key={key}>{i}</th>;
                  }
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="holdTable">
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {["20%", "20%", "20%", "20%", "*"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={5}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    return (
                      <tr key={key}>
                        <th>
                          {item.setlDate === " 전월잔고"
                            ? item.setlDate
                            : props.formatter(item.setlDate, "date")}
                        </th>
                        <td className="amount">
                          {props.formatter(item.stopAmt, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.dsblAmt, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.indivOtpayAmt, "amount")}
                        </td>
                        <td className="amount TextColorRed">
                          {props.formatter(item.abookRmtTotAmt, "amount")}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>  
        <div className="tfoot">
          <table>
            <colgroup>
              {["20%", "20%", "20%", "20%", "*"].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!totalData || totalData.length === 0 ? (
                <></>
              ) : (
                totalData.map((item, key) => {
                  return (
                    <tr key={key}>
                      <th>
                        <img src={IconTotalPlus} alt="" />
                        합계
                      </th>
                      <td className="amount">
                        {props.formatter(item.stopAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.dsblAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.indivOtpayAmt, "amount")}
                      </td>
                      <td className="amount">
                        {props.formatter(item.abookRmtTotAmt, "amount")}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </article>
    </div>
  );
};

DsblTxnList.propTypes = {};

export default withBase(DsblTxnList);
