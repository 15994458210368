import React from "react";
import PropTypes from "prop-types";
import "./PauseReqListPopupBatch.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, DatePickerField, CustomInput } from "components";
import moment from "moment";

const PauseReqListPopupBatch = (props) => {
  const { layerType, viewData, onClose, onSave, formatter, cretFlag } = props;

  return (
    <Formik
      initialValues={{
        otpayDate: moment(),
        remarks: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        let data = viewData.map((item) => ({
          ...item,
          reqType : (layerType === "pause") ? "0" : "1",
          reqDate: moment(values.otpayDate).format("YYYYMMDD"),
          reqMemo: values.remarks,
        }));
        onSave("pause", data);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        otpayDate: Yup.date().required().nullable(),
        remarks: Yup.string().required().max(50),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <Form>
            <div className="PauseReqListPopupBatch">
              <h5>필수 입력</h5>
              <article className="popup_grid first">
                <div className="first">
                  <table>
                    <colgroup>
                      <col width="12.4%" />
                      <col width="12.7%" />
                      <col width="11.2%" />
                      <col width="11.3%" />
                      <col width="10.5%" />
                      <col width="15.1%" />
                      <col width="13.9%" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>발송일</th>
                        <th>영업소명</th>
                        <th>수수료</th>
                        <th>차량번호</th>
                        <th>예금주명</th>
                        <th>계좌번호</th>
                        <th>통장기재</th>
                        <th>구분</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewData &&
                        viewData.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{formatter(item.dspDate, "date")}</td>
                              <td>{item.plcfbName}</td>
                              <td className="amount">
                                {formatter(item.fee, "amount")}
                              </td>
                              <td>{item.carNo}</td>
                              <td>{formatter(item.dposr, "name", false)}</td>
                              <td>
                                {formatter(item.acntNo, "account", false)}
                              </td>
                              <td>{item.bnkbkSttm}</td>
                              <td>{item.degreeDiv}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="second">
                  <table>
                    <colgroup>
                      <col width="200" />
                      <col width="455" />
                      <col width="200" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                        {layerType === "pause" ? "정지":"예치"} 일자<i></i>
                          <span>
                            {errors.otpayDate &&
                              touched.otpayDate &&
                              errors.otpayDate}
                          </span>
                        </th>
                        <td>
                        {formatter(moment().format("YYYY.MM.DD"), "date")}
                        </td>
                        <th>
                          사유 기재<i></i>
                          <span>
                            {errors.remarks &&
                              touched.remarks &&
                              errors.remarks}
                          </span>
                        </th>
                        <td>
                          <CustomInput
                            name="remarks"
                            type="text"
                            placeholder="사유 입력"
                            onChange={handleChange}
                            value={values.remarks}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </article>
              <div className="PopupButton">
                {cretFlag && (
                  <Button
                    type="submit"
                    className="insert"
                    disabled={isSubmitting}
                  >
                    저장
                  </Button>
                )}
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

PauseReqListPopupBatch.propTypes = {};

export default PauseReqListPopupBatch;
