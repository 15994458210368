import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PauseAdmListPopupBatch.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, DatePickerField, CustomInput, CustomRadioButton, CheckBox as Checkbox } from "components";
import moment from "moment";

const PauseAdmListPopupBatch = (props) => {
  const { layerType, viewData, onClose, onSave, onChange, batchAllChk, onChangeAllChk, formatter, cretFlag, batchDatas, pauseSeq } = props;

  const getSum = (list, type) => {
    if(list && list.length !== 0)
      return type === "remain" ? props.formatter(list.reduce((acc, curr) => curr.manualYn !== "Y" ? acc + curr.fee : acc, 0), "amount") : props.formatter(list.reduce((acc, curr) => acc + curr.fee, 0), "amount");
  };

  return (
    <Formik
      initialValues={{
        btnType: "",
        manualDt : moment().format("YYYY.MM.DD"),
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        let data = viewData.filter((item) => batchDatas.indexOf(item.rowNum) > -1).map((item) => ({
          ...item,
          updateType : values.btnType,
          //개별송금처리
          manualDt : values.manualDt,
          manualYn : "Y",
        }));
        
        if (values.btnType === "pause") {
          if(data.length < 1){
            onSave("pauseNodata", data, pauseSeq);
          }else{
            onSave(values.btnType, data, pauseSeq);
          }
        } else {
          onSave(values.btnType, data, pauseSeq);
        }
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        manualDt: Yup.date().required().nullable(),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <Form>
            <div className="PauseAdmListPopupBatch">
              <h5>필수 입력</h5>
              <article className="popup_grid first">
                <div className="first">
                  <table>
                    <colgroup>
                      <col width="1%" />
                      <col width="9%" />
                      <col width="9.4%" />
                      <col width="9.7%" />
                      <col width="9.2%" />
                      <col width="9.3%" />
                      <col width="9.5%" />
                      <col width="11.1%" />
                      <col width="11.9%" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                        {cretFlag && layerType === "pause" ? (
                              <Checkbox
                                name="batchAllChk"
                                checked={batchAllChk}
                                onChange={(e) => onChangeAllChk(e.target.checked)}
                              />
                        ) : ""}
                        </th>
                        <th>발송일</th>
                        <th>상태</th>
                        <th>영업소명</th>
                        <th>수수료</th>
                        <th>차량번호</th>
                        <th>예금주명</th>
                        <th>계좌번호</th>
                        <th>통장기재</th>
                        <th>구분</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewData &&
                        viewData.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>
                              {cretFlag && layerType === "pause" ? (
                                    <Checkbox
                                      name="batchChk"
                                      checked={
                                        batchDatas.indexOf(item.rowNum) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        onChange(
                                          item.rowNum,
                                          window.event.target.checked,
                                        )}
                                    />
                              ) : ""}
                              </td>
                              <td>{formatter(item.dspDate, "date")}</td>
                              <td>{item.manualYn === "Y" ? "송금 완료" : "송금 전"}</td>
                              <td>{item.plcfbName}</td>
                              <td className="amount">
                                {formatter(item.fee, "amount")}
                              </td>
                              <td>{item.carNo}</td>
                              <td>{formatter(item.dposr, "name", false)}</td>
                              <td>
                                {formatter(item.acntNo, "account", false)}
                              </td>
                              <td>{item.bnkbkSttm}</td>
                              <td>{item.degreeDiv}</td>
                            </tr>
                         );
                        })} 
                        {viewData && viewData.length !== 0 ? (
                        <tr>
                          <td></td>
                          <td colSpan="3">총금액(잔여)<i></i></td>
                          <td className="amount">{getSum(viewData, "total")}원</td>
                          <td className="amount2">({getSum(viewData, "remain")}원)</td>
                          <td colSpan="4"></td>
                        </tr>
                        ) : ( 
                        <tr>
                          <td className="NoData" colSpan={10}>
                            정지 건이 남아있으나 리스트가 없을경우 송금 정지해제 요청에서 송금요청을 해주셔야 됩니다.
                          </td>
                        </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                {layerType === "pause" && viewData &&
                    viewData.filter((item) => item.rowNum === 1).map((item, key) => {
                      return (
                    <div className="second">
                      <table>
                        <colgroup>
                          <col width="200" />
                          <col width="455" />
                          <col width="200" />
                          <col width="*" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>
                            해제 요청 일자<i></i>
                            </th>
                            <td>{formatter(item.cancelReqDate, "date")}</td>
                            <th>해제요청 메모<i></i></th>
                            <td>
                            {item.cancelMemo}
                            </td>
                          </tr>
                          <tr>
                            <th>요청 내용<i></i></th>
                            <td>{item.status === "01" ? "정지" : item.status === "02" ? "해제요청" : item.status === "03" ? "해제완료" : item.status === "04" ? "잔액남음" : "송금요청"}</td>
                            <th>송금 일자 선택<i></i>
                              <span>
                                {errors.manualDt &&
                                touched.manualDt &&
                                errors.manualDt}
                              </span>
                            </th>
                            <td><DatePickerField
                                  name="manualDt"
                                  date={values.manualDt}
                                />
                            </td>
                          </tr>
                          <tr>
                          <th>정지 사유<i></i></th>
                            <td>{item.pauseMemo}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                );
              })} 
              </article>
              <div className="PopupButton">
                {cretFlag && layerType === "pause" ? (
                  <>
                  <Button type="submit" className="insert" 
                  onClick={() => {
                    values.btnType = "pause";
                  }} disabled={isSubmitting}>정지해제</Button>
                  {/* <Button type="submit" className="update" 
                  onClick={() => {
                    values.btnType = "send";
                  }} disabled={isSubmitting}>개별송금</Button> */}
                  </>
                ) : ""}
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

PauseAdmListPopupBatch.propTypes = {};

export default PauseAdmListPopupBatch;
