import React, { useState } from "react";
import PropTypes from "prop-types";
import "./SmsTrmTxnUploadList.css";
import { Button } from "components";
import FileUploadIcon2 from "assets/images/icon_upload_file.png";

const SmsTrmTxnUploadList = (props) => {
  const { files, onClickRemoveFile } = props;
  let totalSize = 0;
  const printFileSize = (size) => {
    let fileSize;
    let unit;
    if (size < 1024) {
      fileSize = size;
      unit = "b";
    } else if (size < 1024 * 1024) {
      fileSize = Math.round((size / 1024) * 100) / 100;
      unit = "kb";
    } else {
      fileSize = Math.round((size / 1024 / 1024) * 100) / 100;
      unit = "mb";
    }
    return `${fileSize} ${unit}`;
  };
  const fileItems = files.map((file, key) => {
    totalSize += file.size;
    return (
      <li key={key}>
        <img src={FileUploadIcon2} alt="uploadFile" />
        <span>{file.name}</span>
        {printFileSize(file.size)}
        <Button className="fee_file_del" onClick={onClickRemoveFile(file)}>
          <span>파일 삭제</span>
        </Button>
      </li>
    );
  });
  return (
    <div className="UploadList">
      <span className="file_sc">
        {`Total ${printFileSize(totalSize)}`} | {`${files.length} files`}
      </span>
      <ul className="file_name">{fileItems}</ul>
    </div>
  );
};

SmsTrmTxnUploadList.propTypes = {};

export default SmsTrmTxnUploadList;
