import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, ConditionBox, DatePickerField, CodeSelect } from "components";
import moment from "moment";

const DailyFeeSumDetailsCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            cretDate: moment().add(-1, "days"),
            phydsCorpDiv: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onClickSearch({
              cretDate: moment(values.cretDate).format("YYYYMMDD"),
              phydsCorpDiv: values.phydsCorpDiv,
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            cretDate: Yup.date().required().nullable(),
          })}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              발송일<i></i>
                              <span>
                                {errors.cretDate &&
                                  touched.cretDate &&
                                  errors.cretDate}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerField
                                  name="cretDate"
                                  date={values.cretDate}
                                />
                              </div>
                            </dd>
                          </dl>
                          <dl>
                            <dt>법인</dt>
                            <dd>
                              <CodeSelect
                                codeId="PHYDS_CORP_DIV"
                                name="phydsCorpDiv"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"법인 선택"}
                                value={values.phydsCorpDiv}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

DailyFeeSumDetailsCondition.propTypes = {};

export default DailyFeeSumDetailsCondition;
