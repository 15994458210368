import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./RmtFeeSmryCorpDetails.css";
import { withBase, Button, ControlBox } from "components";
import moment from "moment";
import RmtFeeSmryCorpDetailsConditionBox from "./RmtFeeSmryCorpDetailsConditionBox";
import RmtFeeSmryCorpDetailsTable from "./RmtFeeSmryCorpDetailsTable";

const API = {
  LIST: {
    url: "api/v1/report/rmtFeeSmryCorp/listdaily",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/rmtFeeSmryCorp/downdaily",
    method: "post",
  },
};

const RmtFeeSmryCorpDetails = (props) => {
  const [param, setParam] = useState();
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState();

  useEffect(() => {
    if (param) list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setTotalData("");
      searching(false);
      return;
    }

    if (
      !response.corpAcntSumTxnList ||
      response.corpAcntSumTxnList.length === 0
    ) {
      setDatas([]);
      setTotalData("");
    } else {
      setDatas(response.corpAcntSumTxnList.slice(0, -1));
      setTotalData(response.corpAcntSumTxnList.slice(-1));
    }
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam(condition);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };

  return (
    <div className="RmtFeeSmryCorpDetails">
      <RmtFeeSmryCorpDetailsConditionBox
        onClickSearch={handleClickSearch}
        {...props}
      />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <RmtFeeSmryCorpDetailsTable
        datas={datas}
        totalData={totalData}
        formatter={props.formatter}
      />
    </div>
  );
};

RmtFeeSmryCorpDetails.propTypes = {};

export default withBase(RmtFeeSmryCorpDetails);
