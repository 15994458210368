import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DailyFeeSum.css";
import { CustomTab } from "components";
import DailyFeeSumList from "./DailyFeeSumList";
import DailyFeeSumDetails from "./DailyFeeSumDetails";

const DailyFeeSum = (props) => {
  return (
    <div className="DailyFeeSum">
      <CustomTab
        tabs={[
          {
            name: "지원수수료",
            component: <DailyFeeSumList />,
          },
          {
            name: "상세보기",
            component: <DailyFeeSumDetails />,
          },
        ]}
      />
    </div>
  );
};

DailyFeeSum.propTypes = {};

export default DailyFeeSum;
