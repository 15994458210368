import React from "react";
import PropTypes from "prop-types";
import "./SetlSumTxnTable.css";
import IconTotalPlus from "assets/images/icon_plus.png";

const SetlSumTxnTable = (props) => {
  const { datas, totalData, onDoubleClick, formatter } = props;
  return (
    <div className="SetlSumTxnTable">
      <article className="grid_data">
        
        <div className="thead HasTD">
          <table>
            <colgroup>
              {[
                "6%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                <th rowSpan="2">발송일</th>
                <th colSpan="9">자동입력</th>
                <th colSpan="5">수기입력</th>
                <th rowSpan="2">계좌잔액</th>
              </tr>
              <tr>
                <td>총운임</td>
                <td>마이너스운임</td>
                <td>정지</td>
                <td>불능</td>
                <td>
                  자동 송금액
                  <br />
                  (정상+예치)
                </td>
                <td>개별 송금액</td>
                <td>송금 합계</td>
                <td>
                  영업소 입금액
                  <br />
                  (실계좌)
                </td>
                <td>영업소미수</td>
                <td>차입금</td>
                <td>예수금</td>
                <td>예금이자</td>
                <td>반제</td>
                <td>
                  출금
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="holdTable">  
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {[
                  "6%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "6.25%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={16}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas &&
                  datas.map((item, key) => {
                    let layer = false;

                    if (item.corpCd) layer = true;
                    return (
                      <>
                        <tr key={key}>
                          <th>
                            {layer
                              ? formatter(item.setlDate, "date")
                              : item.setlDate}
                          </th>
                          <td className="amount">
                            {formatter(item.carryChage, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.minusFare, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.stopAmt, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.dsblAmt, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.abookRmtAmt, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.indivOtpayAmt, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.abookRmtTotAmt, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.plcfbDpsAmt, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.plcfbNatmp, "amount")}
                          </td>
                          <td
                            className={"amount " + (layer ? "hand" : "")}
                            onDoubleClick={() =>
                              layer ? onDoubleClick(key) : ""
                            }
                          >
                            {formatter(item.loanAmt, "amount")}
                          </td>
                          <td
                            className={"amount " + (layer ? "hand" : "")}
                            onDoubleClick={() =>
                              layer ? onDoubleClick(key) : ""
                            }
                          >
                            {formatter(item.despAmt, "amount")}
                          </td>
                          <td
                            className={"amount " + (layer ? "hand" : "")}
                            onDoubleClick={() =>
                              layer ? onDoubleClick(key) : ""
                            }
                          >
                            {formatter(item.despIntr, "amount")}
                          </td>
                          <td
                            className={"amount " + (layer ? "hand" : "")}
                            onDoubleClick={() =>
                              layer ? onDoubleClick(key) : ""
                            }
                          >
                            {formatter(item.rfndAmt, "amount")}
                          </td>
                          <td
                            className={"amount " + (layer ? "hand" : "")}
                            onDoubleClick={() =>
                              layer ? onDoubleClick(key) : ""
                            }
                          >
                            {formatter(item.etcOtpay, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.acntBlnc, "amount")}
                          </td>
                        </tr>
                      </>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>  
        </div>                    
        <div className="tfoot">
          <table>
            <colgroup>
              {[
                "6%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "6.25%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!totalData || totalData.length === 0 ? (
                <></>
              ) : (
                totalData.map((item, key) => {
                  return (
                    <tr key={key}>
                      <th>
                        <img src={IconTotalPlus} alt="" />
                        합계
                      </th>
                      <td className="amount">
                        {formatter(item.carryChage, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.minusFare, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.stopAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.dsblAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.abookRmtAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.indivOtpayAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.abookRmtTotAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.plcfbDpsAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.plcfbNatmp, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.loanAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.despAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.despIntr, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.rfndAmt, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.etcOtpay, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.acntBlnc, "amount")}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>  
        
    </article>
  </div>
  );
};

SetlSumTxnTable.propTypes = {};

export default SetlSumTxnTable;
