
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PauseRmvList.css";
import {
  withBase,
  Button,
  LayerPopup,
  ControlBox,
  Pagination,
} from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import PauseRmvListConditionBox from "./PauseRmvListConditionBox";
import PauseRmvListTable from "./PauseRmvListTable";
import PauseRmvListPopupWrapper from "./PauseRmvListPopupWrapper";
import PauseRmvListPopupIndividual from "./PauseRmvListPopupIndividual";
import PauseRmvListPopupBatch from "./PauseRmvListPopupBatch";
import PauseRmvListPopupDown from "./PauseRmvListPopupDown";

const API = {
  LIST: {
    url: "api/v1/finnc/finnc/listPauseRmv",
    method: "post",
  },
  LISTPAUSE: {
    url: "api/v1/finnc/finnc/listPauseRmvPop",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/finnc/finnc/updatePauseUnReq",
    method: "post",
  },
  REMIT: {
    url: "api/v1/finnc/finnc/updatePauseRemit",
    method: "post",
  },
};

const PauseRmvList = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    //dspDate: moment().add(-1, "days").format("YYYYMMDD"),
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);
  const [batchDatas, setBatchDatas] = useState([]);
  const [dateItem, setDateItem] = useState([]);
  const [batchAllDat, setBatchAllDat] = useState([]);
  const [batchAllChk, setBatchAllChk] = useState(false);
  const [states, setStates] = useState([]);

  useEffect(() => {
    list();
    //readStates();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    const response = await HttpRequest.request(url, method, param);
    
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      setBatchDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.fareFeeTxnPauseList);
    setBatchDatas([]);

    
    let checkDat = [];
    response.fareFeeTxnPauseList &&
    response.fareFeeTxnPauseList.forEach((item) => {
        if (item.status === "01" || item.status === "04" || item.status === "05") {
          checkDat.push(item.pauseSeq);
        }
      });
    setBatchAllDat(checkDat);
    searching(false);
  };

  const readStates = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const {url, method } = API.READSTATES;
    const response = await HttpRequest.request(url, method, {});

    if (HttpRequest.hasError(response, true)) {
    
      return;
    }

    setStates(response.stateMap);
    searching(false);

  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    return true;
  };
  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDownOtpayReq = async (param) => {
    const { url } = API.DOWNOTPAYREQ;

    await props.downExcel.request(url, param);

    setLayerType("");
  };

  const handleClickDownFareFee = async () => {
    const { url } = API.DOWNFAREFEE;

    await props.downExcel.request(url, param);
  };

  const onChangeAllChk = (checked) => {
    setBatchAllChk(checked);

    if (checked) {
      setBatchDatas(batchAllDat);
    } else {
      setBatchDatas([]);
    }
  };

  const handleClickCheckBox = (index, check) => {
    check
      ? setBatchDatas([...batchDatas, index])
      : setBatchDatas(batchDatas.filter((item) => item !== index));
  };
  const handleDoubleClick = (index) => {
    setLayerType("pauseRead");
    pauseListPopup(datas.filter((item) => item.pauseSeq === index));
  };
  const handleClickDown = async () => {
    const { HttpRequest } = props;

    const { url, method } = API.DATELIST;

    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setDateItem([]);
      return;
    }

    if (!response.PauseRmvListList || response.PauseRmvListList.length === 0) {
      setDateItem([]);
    } else {
      setDateItem(
        response.PauseRmvListList.map((item) => {
          return {
            code: item.dspDate,
            name: props.formatter(item.dspDate, "date"),
          };
        })
      );
    }

    setLayerType("down");
  };

  const handleClickPause = () => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    setLayerType("pause");
    pauseListPopup(datas.filter((item) => batchDatas.indexOf(item.pauseSeq) > -1));
  };

  const pauseListPopup = async (popupParam) => {
    const { HttpRequest, searching } = props;
    searching(true);
    const { url, method } = API.LISTPAUSE;
    
    const response = await HttpRequest.request(url, method,  { fareFeeTxnPauseList: popupParam });
    if (HttpRequest.hasError(response, true)) {
      setViewData(null);
      searching(false);
      return;
    }
    setViewData(response.fareFeeTxnList);
    searching(false);
  };

  /*
  const handleClickDeposit = () => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    let data = [];
    data = datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1);
    for (let i = 0; i < data.length; i++) {
      if(data[i].statusName !== "불능"){
        props.toast.error("이미 요청된 건은 중복 요청 불가합니다.");
        return;
      }
    }
    setLayerType("deposit");
    setViewData(datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1));
  };
  */

  const handleClickPauseCancel = () => {
    if (batchDatas.length === 0) {
      props.toast.error("선택된 데이터가 없습니다.");
      return;
    }
    let data = [];
    data = datas.filter((item) => batchDatas.indexOf(item.rowNum) > -1);
    for (let i = 0; i < data.length; i++) {
      if(data[i].otpaySttus !== "정지요청" && data[i].otpaySttus !== "예치요청"){
        props.toast.error("정지/예치 요청된 건만 요청 취소가 가능합니다.");
        return;
      }
    }
    handleClickSave("cancel", data);
  };


  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const handleClickSave = async (saveType, data) => {
    console.log(data);
    let ret = false;
    let msg = "";
    switch (saveType) {
      case "pauseReq":
        ret = await edit(API.UPDATE, { fareFeeTxnList: data });
        msg = "해제 및 송금요청 처리되었습니다.";
        if (ret) setBatchDatas([]);
        break;
      default:
        console.log(saveType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

    }
    if (msg) props.toast.error(msg);
  };
  return (
    <div className="PauseRmvList">
      <PauseRmvListConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="withdrawl_register" onClick={handleClickPause}>
            해제요청
        </Button>
      </ControlBox>
      <PauseRmvListTable
        datas={datas}
        batchDatas={batchDatas}
        onChange={handleClickCheckBox}
        onDoubleClick={handleDoubleClick}
        batchAllChk={batchAllChk}
        onChangeAllChk={onChangeAllChk}
        formatter={props.formatter}
        cretFlag={cretFlag}
      />
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PauseRmvListPopupWrapper
          layerType={layerType}
          title={"송금 정지해제 요청"}
          className={"PauseRmvListPopup"}
          batchTable={
            <PauseRmvListPopupBatch
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          individualTable={
            <PauseRmvListPopupIndividual
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          downTable={
            <PauseRmvListPopupDown
              onClose={handleClickClose}
              onDown={handleClickDownOtpayReq}
              dateItem={dateItem}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

PauseRmvList.propTypes = {};

export default withBase(PauseRmvList);
