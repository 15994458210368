import React from "react";
import { Bar } from "react-chartjs-2";

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  responsive: true,
  elements: {
    line: {
      fill: false,
    },
  },
  tooltips: {
    mode: "label",
    callbacks: {
      label: function (tooltipItem, data) {
        var value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        if (parseInt(value) >= 1000) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            " : " +
            value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        } else {
          return data.datasets[tooltipItem.datasetIndex].label + " : " + value;
        }
      },
    },
  },
  scales: {
    yAxes: [
      {
        type: "linear",
        // display: true,
        position: "left",
        id: "y-axis-1",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
        ticks: {
          callback(value) {
            return Number(value).toLocaleString("ko-KR");
          },
        },
      },
      {
        type: "linear",
        // display: true,
        position: "right",
        id: "y-axis-2",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
        ticks: {
          callback(value) {
            return Number(value).toLocaleString("ko-KR");
          },
        },
      },
    ],
  },
};

export default function RegionQtyChrt(props) {
  const { chrtData } = props;
  const chart = React.useRef();

  const data = {
    labels: chrtData.labels,
    datasets: [
      {
        type: "line",
        label: "합계",
        fill: false,
        borderColor: "#B7B7B7",
        backgroundColor: "#B7B7B7",
        pointBorderColor: "#B7B7B7",
        pointBackgroundColor: "#B7B7B7",
        pointHoverBackgroundColor: "#B7B7B7",
        pointHoverBorderColor: "#B7B7B7",
        yAxisID: "y-axis-2",
        data: chrtData.regionTotCnt,
      },
      {
        type: "bar",
        label: "경동발송",
        fill: false,
        borderColor: "#FD6F4F",
        backgroundColor: "#FD6F4F",
        hoverBackgroundColor: "#FD6F4F",
        hoverBorderColor: "#FD6F4F",
        yAxisID: "y-axis-1",
        data: chrtData.kyungDongDspCnt,
      },
      {
        type: "bar",
        label: "경동도착",
        fill: false,
        backgroundColor: "#3E64F4",
        borderColor: "#3E64F4",
        hoverBackgroundColor: "#3E64F4",
        hoverBorderColor: "#3E64F4",
        yAxisID: "y-axis-1",
        data: chrtData.kyungDongArvrCnt,
      },
      {
        type: "bar",
        label: "합동발송",
        fill: false,
        borderColor: "#34BCA1",
        backgroundColor: "#34BCA1",
        hoverBackgroundColor: "#34BCA1",
        hoverBorderColor: "#34BCA1",
        yAxisID: "y-axis-1",
        data: chrtData.hapDongDspCnt,
      },
      {
        type: "bar",
        label: "합동도착",
        fill: false,
        borderColor: "#FBBA0A",
        backgroundColor: "#FBBA0A",
        hoverBackgroundColor: "#FBBA0A",
        hoverBorderColor: "#FBBA0A",
        yAxisID: "y-axis-1",
        data: chrtData.hapDongArvrCnt,
      },
    ],
  };

  return (
    <div className="chart">
      <Bar ref={chart} data={data} options={options} />
    </div>
  );
}
