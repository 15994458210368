import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PlcfbAbookTxnLedgerStatus.css";
import {
  withBase,
  ControlBox,
  Button,
  CustomInput,
  FormatInput,
} from "components";
import PlusIcon from "assets/images/icon_plus.png";

const API = {
  LIST: {
    url: "api/v1/plcfb/plcfbAbookTxn/listabook",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/plcfb/plcfbAbookTxn/updateabook",
    method: "post",
  },
  DOWN: {
    url: "api/v1/plcfb/plcfbAbookTxn/downabook",
    method: "post",
  },
};

const PlcfbAbookTxnLedgerStatus = (props) => {
  const { searchData, cretFlag } = props;
  const [param, setParam] = useState();
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState();
  const [saveIndex, setSaveIndex] = useState([]);

  useEffect(() => {
    if (searchData) {
      const searchParam = {
        setlDate: searchData.dspYm,
        plcfbCd: searchData.plcfbCd,
        abookSeq: searchData.plcfbSeqNo,
      };
      setParam(searchParam);
      list(searchParam);
    }
  }, [searchData]);

  const list = async (searchParam) => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(searchParam);
    const response = await HttpRequest.request(url, method, searchParam);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setTotalData("");
      setSaveIndex([]);
      searching(false);
      return;
    }
    setSaveIndex([]);

    if (!response.plcfbAbookTxnList || response.plcfbAbookTxnList.length < 2) {
      setDatas([]);
      setTotalData("");
    } else {
      setDatas(response.plcfbAbookTxnList.slice(0, -1));
      setTotalData(response.plcfbAbookTxnList.slice(-1));
    }
    searching(false);
  };

  const handleChange = (index, rowNum, value) => {
    setDatas(
      datas.map((item) =>
        item.rowNum === rowNum ? { ...item, ...value } : item,
      ),
    );

    setSaveIndex([...saveIndex, index]);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    setSaveIndex([]);
    return true;
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    if (param === undefined) {
      props.toast.error("조회후 사용가능합니다.");
      return;
    }

    await props.downExcel.request(url, param);
  };

  const isSaveData = () => {
    let ret = true;

    if (saveIndex.length === 0) {
      props.toast.error("저장할 내용이 없습니다.");
      ret = false;
    }

    return ret;
  };

  const handleClickSave = async (param) => {
    let ret = false;
    let msg = "";

    if (isSaveData()) {
      let saveData = datas.filter((item, index) => saveIndex.includes(index));
      saveData = saveData.map((item) => {
        return {
          ...item,
          hoffOtpayAmt3: props.formatter(String(item.hoffOtpayAmt3), "toValue"),
        };
      });
      ret = await edit(API.UPDATE, { plcfbAbookTxnList: saveData });
      msg = "수정저장되었습니다.";
      //window.location.reload();
      list(param);
    }

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };

  return (
    <div className="PlcfbAbookTxnLedgerStatus">
      <article className="grid_data">
        <ControlBox>
          {cretFlag && (
            <Button className="save" onClick={() => handleClickSave(param)}>
              저장
            </Button>
          )}
          <Button className="excel" onClick={handleClickDown}>
            엑셀 다운로드
          </Button>
        </ControlBox>
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {[
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {[
                    "날짜",
                    "영업소정산금액 \n (현불+착불)",
                    "영업소 수수료",
                    "노선차량 수수료",
                    "본사 입금액",
                    "본사 출금액",
                    "개별 출금액",
                    "기타 출금액",
                    "미수 잔액",
                    "비고(출금)",
                  ].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {[
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={9}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {item.rowNum
                            ? props.formatter(item.setlDate, "date")
                            : item.setlDate}
                        </td>
                        <td className="amount">
                          {props.formatter(item.dlvrPayment, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.mktFee, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.routecarFee, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.hoffDspAmt1, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.hoffOtpayAmt1, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.hoffOtpayAmt2, "amount")}
                        </td>
                        
                          {item.rowNum && cretFlag ? (
                            <td className="amount">
                              <FormatInput
                                formatType="amount"
                                name="hoffOtpayAmt3"
                                placeholder="기타출금액 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    hoffOtpayAmt3: e.target.value,
                                  })
                                }
                                value={props.formatter(
                                  item.hoffOtpayAmt3,
                                  "amount",
                                )}
                              />
                            </td>
                          ) : item.rowNum ? (
                            <td className="amount">
                              {props.formatter(item.hoffOtpayAmt3, "amount")}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        
                        <td className="amount">
                          {props.formatter(item.natmpBlnc, "amount")}
                        </td>
                        {item.rowNum && cretFlag ? (
                          <td>
                            <CustomInput
                              name="remarks"
                              type="text"
                              placeholder="비고 입력"
                              onChange={(e) =>
                                handleChange(key, item.rowNum, {
                                  remarks: e.target.value,
                                })
                              }
                              value={item.remarks}
                              maxLength={100}
                            />
                          </td>
                        ) : item.rowNum ? (
                          <td>{item.remarks}</td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="tfoot HasTH">
            <table>
              <colgroup>
                {[
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "10%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!totalData || totalData.length === 0 ? (
                  <></>
                ) : (
                  totalData.map((item, key) => {
                    return (
                      <tr className="total" key={key}>
                        <td>
                          <img src={PlusIcon} alt="plus" />{" "}
                          <span className="string">합계</span>
                        </td>
                        <td className="amount">
                          {props.formatter(item.dlvrPayment, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.mktFee, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.routecarFee, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.hoffDspAmt1, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.hoffOtpayAmt1, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.hoffOtpayAmt2, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.hoffOtpayAmt3, "amount")}
                        </td>
                        <td className="amount">
                          {props.formatter(item.natmpBlnc, "amount")}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  );
};

PlcfbAbookTxnLedgerStatus.propTypes = {};

export default withBase(PlcfbAbookTxnLedgerStatus);
