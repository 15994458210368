import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  ConditionBox,
  CacheAutocomplete,
  DatePickerFieldYm,
  CustomInput,
} from "components";
import moment from "moment";

const PlcfbAbookTxnConditionBox = (props) => {
  const {onClickSearch, cretFlag, deptCd, deptNm, cmpnCd} = props;
  
  const addMonth = (date, months) => {
   

    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            authFlag: cretFlag ? 1 : 0,
            dspYm: moment().add(-1, "days"),
            plcfbCd: cretFlag ? "" : deptCd,
            plcfbNm: cretFlag ? "" : deptNm,
            carName: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { dspYm, plcfbNm, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              dspYm: moment(dspYm).format("YYYYMM"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            dspYm: Yup.date().required().nullable(),
            plcfbCd: Yup.lazy((value, object) => {
              if (
                object.parent.carName &&
                !(
                  object.parent.carName === null ||
                  object.parent.carName === undefined ||
                  object.parent.carName === ""
                )
              ) {
                return Yup.string();
              }
              return Yup.string().required();
            }),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              발송월<i></i>
                              <span>
                                {errors.dspYm && touched.dspYm && errors.dspYm}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerFieldYm
                                  name="dspYm"
                                  date={values.dspYm}
                                  minDate={addMonth(new Date(), -13)}
                                />
                              </div>
                            </dd>
                          </dl>
                          <dl>
                            <dt>
                              영업소<i></i>
                              <span>
                                {errors.plcfbCd &&
                                  touched.plcfbCd &&
                                  errors.plcfbCd}
                              </span>
                            </dt>
                            <dd>
                            {cmpnCd==="01" ? (
                                <CacheAutocomplete
                                  cache="plcfbInfo"
                                  onMap={(item, key) => {
                                    return {
                                      code: item.plcfbCd,
                                      name: item.plcfbName,
                                    };
                                  }}
                                  name="plcfbNm"
                                  value={values.plcfbNm}
                                  callBack={(item) => {
                                    setFieldValue(
                                      "plcfbCd",
                                      item ? item.code : ""
                                    );
                                  }}
                                  setFieldValue={setFieldValue}
                                  placeholder="영업소 입력"
                                />
                                ) : (
                                  <CustomInput
                                    name="plcfbNm"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.plcfbNm}
                                    disabled={true}
                                  />
                                )}
                            </dd>
                          </dl>
                          {cmpnCd==="01" && (
                            <dl>
                              <dt>차량명</dt>
                              <dd>
                                <CustomInput
                                  name="carName"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.carName}
                                />
                              </dd>
                            </dl>
                         )}
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

PlcfbAbookTxnConditionBox.propTypes = {};

export default PlcfbAbookTxnConditionBox;
