import React from "react";
import PropTypes from "prop-types";
import "./PauseAdmListPopupWrapper.css";
import { Title } from "components";

const PauseAdmListPopupWrapper = (props) => {
  const {
    layerType,
    className,
    title,
    batchTable,
    individualTable,
    downTable,
  } = props;
  const determineView = () => {
    let ret = null;
    switch (layerType) {
      case "pause":
        ret = batchTable;
        break;
      case "pauseRead":
        ret = batchTable;
      break;
      case "down":
        ret = downTable;
        break;
      default:
        break;
    }
    return ret;
  };
  return (
    <div className={className ? className : "Popup"}>
      <Title
        title={title}
        upperMenus={[
          title,
          layerType === "pause"
            ? "일괄해제"
            :layerType === "pauseRead"
            ? "전체리스트"
            : layerType === "individual"
            ? "개별해제"
            : "송금요청서",
        ]}
      />
      {determineView()}
    </div>
  );
};

PauseAdmListPopupWrapper.propTypes = {};

export default PauseAdmListPopupWrapper;
