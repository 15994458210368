import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  CustomInput,
  FormatInput,
  DatePickerField,
  Button,
  CodeSelect,
  CustomRadioButton,
  CheckBox as Checkbox,
  Address,
} from "components";
import moment from "moment";

const PlcfInfoEditTable = (props) => {
  const { layerType, viewData, onClose, onSave, plcfbName, formatter } = props;
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState({
    zipNo: viewData ? viewData.zipCd : "",
    roadAddr: viewData ? viewData.adr : "",
  });

  return (
    <>
      <Formik
        initialValues={{
          updateType: "SIMPLE",
          plcfbCd: viewData ? viewData.plcfbCd : "",
          plcfbSeqNo: viewData ? viewData.plcfbSeqNo : "",
          orgnCd: viewData ? viewData.orgnCd : "",
          startDate: viewData ? moment(viewData.startDate) : moment(),
          finishDate: viewData
            ? viewData.finishDate
              ? moment(viewData.finishDate)
              : ""
            : "",
          plcfbName: viewData ? viewData.plcfbName : plcfbName ? plcfbName : "",
          managerName: viewData ? viewData.managerName : "",
          bizno: viewData ? formatter(viewData.bizno, "bizno") : "",
          custNo: viewData ? formatter(viewData.custNo, "custNo") : "",
          cntcNo: viewData ? formatter(viewData.cntcNo, "phone") : "",
          acntNo: viewData ? formatter(viewData.acntNo, "account") : "",
          acntBankCd: viewData ? viewData.acntBankCd : "",
          dposr: viewData ? viewData.dposr : "",
          limitAmt: viewData ? formatter(viewData.limitAmt, "amount") : "",
          firstBlnc: viewData ? formatter(viewData.firstBlnc, "amount") : "",
          nosunType1: viewData ? viewData.nosunType1 : "",
          nosunType2: viewData ? viewData.nosunType2 : "",
          lnkDsrtDiv: viewData ? viewData.lnkDsrtDiv : "",
          regionDiv: viewData ? viewData.regionDiv : "",
          phydsCorpDiv: viewData ? viewData.phydsCorpDiv : "",
          setlCorpDiv: viewData ? viewData.setlCorpDiv : "",
          dpsNote1: viewData ? viewData.dpsNote1 : "",
          dpsNote2: viewData ? viewData.dpsNote2 : "",
          dpsNote3: viewData ? viewData.dpsNote3 : "",
          dpsNote4: viewData ? viewData.dpsNote4 : "",
          dpsNote5: viewData ? viewData.dpsNote5 : "",
          apdCntcNo1: viewData ? formatter(viewData.apdCntcNo1, "phone") : "",
          apdCntcNo2: viewData ? formatter(viewData.apdCntcNo2, "phone") : "",
          apdCntcNo3: viewData ? formatter(viewData.apdCntcNo3, "phone") : "",
          apdCntcNo4: viewData ? formatter(viewData.apdCntcNo4, "phone") : "",
          apdCntcNo5: viewData ? formatter(viewData.apdCntcNo5, "phone") : "",
          // zipCd: viewData ? viewData.zipCd : "",
          // adr: viewData ? viewData.adr : "",
          adrEtc: viewData ? viewData.adrEtc : "",
          remarks: viewData ? viewData.remarks : "",
          mktYn: viewData ? (viewData.mktYn === "Y" ? true : false) : true,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const {
            updateType,
            bizno,
            custNo,
            cntcNo,
            acntNo,
            limitAmt,
            firstBlnc,
            apdCntcNo1,
            apdCntcNo2,
            apdCntcNo3,
            apdCntcNo4,
            apdCntcNo5,
            mktYn,
            ...rest
          } = values;
          onSave(layerType, updateType, {
            ...rest,
            bizno: formatter(bizno, "toValue"),
            custNo: formatter(custNo, "toValue"),
            cntcNo: formatter(cntcNo, "toValue"),
            acntNo: formatter(acntNo, "toValue"),
            limitAmt: formatter(limitAmt, "toValue"),
            firstBlnc: formatter(firstBlnc, "toValue"),
            apdCntcNo1: formatter(apdCntcNo1, "toValue"),
            apdCntcNo2: formatter(apdCntcNo2, "toValue"),
            apdCntcNo3: formatter(apdCntcNo3, "toValue"),
            apdCntcNo4: formatter(apdCntcNo4, "toValue"),
            apdCntcNo5: formatter(apdCntcNo5, "toValue"),
            zipCd: address.zipNo,
            adr: address.roadAddr,
            mktYn: mktYn ? "Y" : "N",
          });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          orgnCd: Yup.string().required().max(10),
          startDate: Yup.date().required().nullable(),
          finishDate: Yup.date()
            .nullable()
            .when("startDate", (startDate, schema) =>
              startDate ? schema.min(startDate, "기간을 확인하세요.") : schema
            ),
          plcfbName: Yup.string().required().max(50),
          managerName: Yup.string().required().max(20),
          bizno: Yup.string().required(),
          custNo: Yup.string().required(),
          acntNo: Yup.string().required(),
          acntBankCd: Yup.string().required(),
          dposr: Yup.string().required().max(20),
          limitAmt: Yup.string().required(),
          firstBlnc: Yup.string().required(),
          lnkDsrtDiv: Yup.string().required(),
          regionDiv: Yup.string().required(),
          phydsCorpDiv: Yup.string().required(),
          setlCorpDiv: Yup.string().required(),
          dpsNote1: Yup.string().required().max(20),
          dpsNote2: Yup.string().max(20),
          dpsNote3: Yup.string().max(20),
          dpsNote4: Yup.string().max(20),
          dpsNote5: Yup.string().max(20),
          cntcNo: Yup.string().required(),
          apdCntcNo1: Yup.string().required(),
          adrEtc: Yup.string().max(100),
          remarks: Yup.string().max(50),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form>
              <h5>필수 입력</h5>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="200" />
                    <col width="*" />
                    <col width="200" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    {layerType === "edit" ? (
                      <tr>
                        <th>수정형식</th>
                        <td colSpan="3" className="has_radio">
                          <CustomRadioButton
                            name="updateType"
                            onChange={handleChange}
                            checked={
                              values.updateType === "SIMPLE" ? true : false
                            }
                            value="SIMPLE"
                          />{" "}
                          <span>단순수정</span>
                          <CustomRadioButton
                            name="updateType"
                            onChange={handleChange}
                            checked={
                              values.updateType === "NAMECHANGE" ? true : false
                            }
                            value="NAMECHANGE"
                          />{" "}
                          <span>명의변경</span>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <th>
                        영업소코드<i></i>
                        <span>
                          {errors.orgnCd && touched.orgnCd && errors.orgnCd}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="orgnCd"
                          type="text"
                          placeholder="영업소코드 입력"
                          onChange={handleChange}
                          value={values.orgnCd}
                        />
                      </td>
                      <th>
                        영업소명<i></i>
                        <span>
                          {errors.plcfbName &&
                            touched.plcfbName &&
                            errors.plcfbName}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="plcfbName"
                          type="text"
                          placeholder="영업소명 입력"
                          onChange={handleChange}
                          value={values.plcfbName}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        법인구분<i></i>
                        <span>
                          {errors.phydsCorpDiv &&
                            touched.phydsCorpDiv &&
                            errors.phydsCorpDiv}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="PHYDS_CORP_DIV"
                          name="phydsCorpDiv"
                          onChange={handleChange}
                          onBlur={handleChange}
                          placeholder={"법인 선택"}
                          value={values.phydsCorpDiv}
                        />
                      </td>
                      <th>
                        영업소구분<i></i>
                        <span>
                          {errors.setlCorpDiv &&
                            touched.setlCorpDiv &&
                            errors.setlCorpDiv}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="SETL_CORP_DIV"
                          name="setlCorpDiv"
                          onChange={handleChange}
                          onBlur={handleChange}
                          placeholder={"영업소 선택"}
                          value={values.setlCorpDiv}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        연계권<i></i>
                        <span>
                          {errors.lnkDsrtDiv &&
                            touched.lnkDsrtDiv &&
                            errors.lnkDsrtDiv}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="LNK_DSRT_DIV"
                          name="lnkDsrtDiv"
                          onChange={handleChange}
                          onBlur={handleChange}
                          placeholder={"연계권 선택"}
                          value={values.lnkDsrtDiv} 
                        />
                      </td>
                      <th>
                        전화번호<i></i>
                        <span>
                          {errors.cntcNo && touched.cntcNo && errors.cntcNo}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="cntcNo"
                          type="text"
                          placeholder="전화번호 입력"
                          onChange={handleChange}
                          value={values.cntcNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        지역<i></i>
                        <span>
                          {errors.regionDiv &&
                            touched.regionDiv &&
                            errors.regionDiv}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="REGION_DIV"
                          name="regionDiv"
                          onChange={handleChange}
                          onBlur={handleChange}
                          placeholder={"지역 선택"}
                          value={values.regionDiv}
                        />
                      </td>
                      <th>
                        연락처1<i></i>
                        <span>
                          {errors.apdCntcNo1 &&
                            touched.apdCntcNo1 &&
                            errors.apdCntcNo1}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="apdCntcNo1"
                          type="text"
                          placeholder="연락처 입력"
                          onChange={handleChange}
                          value={values.apdCntcNo1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        영업소장명<i></i>
                        <span>
                          {errors.managerName &&
                            touched.managerName &&
                            errors.managerName}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="managerName"
                          type="text"
                          placeholder="영업소장명 입력"
                          onChange={handleChange}
                          value={values.managerName}
                        />
                      </td>
                      <th>연락처2</th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="apdCntcNo2"
                          type="text"
                          placeholder="연락처 입력"
                          onChange={handleChange}
                          value={values.apdCntcNo2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        주민등록번호<i></i>
                        <span>
                          {errors.custNo && touched.custNo && errors.custNo}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="custNo"
                          name="custNo"
                          type="text"
                          placeholder="주민등록번호 입력"
                          onChange={handleChange}
                          value={values.custNo}
                        />
                      </td>
                      <th>연락처3</th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="apdCntcNo3"
                          type="text"
                          placeholder="연락처 입력"
                          onChange={handleChange}
                          value={values.apdCntcNo3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        사업자번호<i></i>
                        <span>
                          {errors.bizno && touched.bizno && errors.bizno}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="bizno"
                          name="bizno"
                          type="text"
                          placeholder="사업자번호 입력"
                          onChange={handleChange}
                          value={values.bizno}
                        />
                      </td>
                      <th>연락처4</th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="apdCntcNo4"
                          type="text"
                          placeholder="연락처 입력"
                          onChange={handleChange}
                          value={values.apdCntcNo4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        시작일자<i></i>
                        <span>
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </span>
                      </th>
                      <td>
                        <DatePickerField
                          name="startDate"
                          date={values.startDate}
                        />
                      </td>
                      <th>연락처5</th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="apdCntcNo5"
                          type="text"
                          placeholder="연락처 입력"
                          onChange={handleChange}
                          value={values.apdCntcNo5}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        종료일자
                        <span>
                          {errors.finishDate &&
                            touched.finishDate &&
                            errors.finishDate}
                        </span>
                      </th>
                      <td>
                        <DatePickerField
                          name="finishDate"
                          date={values.finishDate}
                        />
                      </td>
                      <th>
                        입금적요1<i></i>
                        <span>
                          {errors.dpsNote1 &&
                            touched.dpsNote1 &&
                            errors.dpsNote1}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="dpsNote1"
                          type="text"
                          placeholder="입금적요 입력"
                          onChange={handleChange}
                          value={values.dpsNote1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        예금주<i></i>
                        <span>
                          {errors.dposr && touched.dposr && errors.dposr}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="dposr"
                          type="text"
                          placeholder="예금주 입력"
                          onChange={handleChange}
                          value={values.dposr}
                        />
                      </td>
                      <th>입금적요2</th>
                      <td>
                        <CustomInput
                          name="dpsNote2"
                          type="text"
                          placeholder="입금적요 입력"
                          onChange={handleChange}
                          value={values.dpsNote2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        계좌번호<i></i>
                        <span>
                          {errors.acntBankCd && touched.acntBankCd
                            ? errors.acntBankCd
                            : errors.acntNo && touched.acntNo
                            ? errors.acntNo
                            : ""}
                        </span>
                      </th>
                      <td>
                        <div className="PopupDate">
                          <ul>
                            <li>
                              <CodeSelect
                                codeId="ACNT_BANK_CD"
                                name="acntBankCd"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"은행 선택"}
                                value={values.acntBankCd}
                              />
                            </li>
                            <li>
                              <FormatInput
                                formatType="account"
                                name="acntNo"
                                type="text"
                                placeholder="계좌번호 입력"
                                onChange={handleChange}
                                value={values.acntNo}
                              />
                            </li>
                          </ul>
                        </div>
                      </td>
                      <th>입금적요3</th>
                      <td>
                        <CustomInput
                          name="dpsNote3"
                          type="text"
                          placeholder="입금적요 입력"
                          onChange={handleChange}
                          value={values.dpsNote3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        한도액<i></i>
                        <span>
                          {errors.limitAmt &&
                            touched.limitAmt &&
                            errors.limitAmt}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="amount"
                          name="limitAmt"
                          type="text"
                          placeholder="한도액 입력"
                          onChange={handleChange}
                          value={values.limitAmt}
                        />
                      </td>
                      <th>입금적요4</th>
                      <td>
                        <CustomInput
                          name="dpsNote4"
                          type="text"
                          placeholder="입금적요 입력"
                          onChange={handleChange}
                          value={values.dpsNote4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        기초잔액<i></i>
                        <span>
                          {errors.firstBlnc &&
                            touched.firstBlnc &&
                            errors.firstBlnc}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="amount"
                          name="firstBlnc"
                          type="text"
                          placeholder="기초잔액 입력"
                          onChange={handleChange}
                          value={values.firstBlnc}
                        />
                      </td>
                      <th>입금적요5</th>
                      <td>
                        <CustomInput
                          name="dpsNote5"
                          type="text"
                          placeholder="입금적요 입력"
                          onChange={handleChange}
                          value={values.dpsNote5}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        노선차
                      </th>
                      <td>
                        <FormatInput
                          formatType="fee"
                          name="nosunType1"
                          type="text"
                          placeholder="노선차 입력"
                          onChange={handleChange}
                          value={values.nosunType1}
                        />
                      </td>
                      <th>연계차</th>
                      <td>
                        <FormatInput
                          formatType="fee"
                          name="nosunType2"
                          type="text"
                          placeholder="연계차 입력"
                          onChange={handleChange}
                          value={values.nosunType2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>비고</th>
                      <td colSpan="3">
                        <CustomInput
                          name="remarks"
                          type="text"
                          placeholder="비고 입력"
                          onChange={handleChange}
                          value={values.remarks}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>우편번호</th>
                      <td colSpan="3" className="zipNo">
                        <CustomInput
                          name="zipCd"
                          type="text"
                          value={address.zipNo}
                          disabled={true}
                        />
                        <Button
                          className="insert"
                          onClick={() => setOpen(true)}
                        >
                          우편번호
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td colSpan="3">
                        <CustomInput
                          name="adr"
                          type="text"
                          value={address.roadAddr}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>상세주소</th>
                      <td colSpan="3">
                        <CustomInput
                          name="adrEtc"
                          type="text"
                          placeholder="상세주소 입력"
                          onChange={handleChange}
                          value={values.adrEtc}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>영업여부</th>
                      <td colSpan="3">
                        <Checkbox
                          name="mktYn"
                          onChange={handleChange}
                          checked={values.mktYn}
                        />{" "}
                        영업중
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="PopupButton">
                <Button
                  type="submit"
                  className="insert"
                  disabled={isSubmitting}
                >
                  저장
                </Button>
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Address open={open} handleOpen={setOpen} setAddress={setAddress} />
    </>
  );
};

PlcfInfoEditTable.propTypes = {};

export default PlcfInfoEditTable;
