import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PlcfbAbookTxn.css";
import {
  withBase,
  ControlBox,
  Button,
  CustomInput,
  FormatInput,
  CustomTab,
  CustomDatePicker,
} from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import PlcfbAbookTxnConditionBox from "./PlcfbAbookTxnConditionBox";
import PlcfbAbookTxnLedgerStatus from "./PlcfbAbookTxnLedgerStatus";
import PlcfbAbookTxnDepositList from "./PlcfbAbookTxnDepositList";
import PlcfbAbookTxnWithdrawalList from "./PlcfbAbookTxnWithdrawalList";
import PlcfbAbookTxnImpossList from "./PlcfbAbookTxnImpossList";

const API = {
  LIST: {
    url: "api/v1/plcfb/plcfbAbookTxn/list",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/plcfb/plcfbAbookTxn/update",
    method: "post",
  },
  READCOMM: {
    url: "api/v1/plcfb/plcfbAbookTxn/readcomm",
    method: "post",
  },
  UPDATECOMM: {
    url: "api/v1/plcfb/plcfbAbookTxn/updatecomm",
    method: "post",
  },
  READSTATES: {
    url: "api/v1/plcfb/plcfbAbookTxn/readstates",
    method: "post",
  },
};

const PlcfbAbookTxn = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const deptCd = useSelector((state) => state.user.get("deptCd"));
  const deptNm = useSelector((state) => state.user.get("deptNm"));
  const auths = useSelector((state) => state.user.get("auths"));
  const cmpnCd = useSelector((state) => state.user.get("cmpnCd"));  
  const [searchData, setSearchData] = useState(null);
  const [param, setParam] = useState();
  const [datas, setDatas] = useState([]);
  const [states, setStates] = useState([]);
  const [saveIndex, setSaveIndex] = useState([]);
  const [minDpsBlnc, setMinDpsBlnc] = useState(0);
  const [baseDate, setBaseDate] = useState(new Date());
  const [viewBaseDate, setViewBaseDate] = useState(new Date());
  const [setlDate, setSetlDate] = useState();
  const [plcfbCd, setPlcfbCd] = useState();
  const [abookSeq, setAbookSeq] = useState();

  useEffect(() => {
    if (cretFlag) baseDateSearch();
    if (!cretFlag && auths && auths[0] === "14") {
      setParam({
        dspYm: moment().add(-1, "days").format("YYYYMM"),
        plcfbCd: deptCd,
        authFlag: cretFlag ? 1 : 0,
      });
    }
  }, []);

  useEffect(() => {
    if (param) 
      list(param);
      readStates();
  }, [param]);

  const readStates = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const {url, method } = API.READSTATES;
    const response = await HttpRequest.request(url, method, {});

    if (HttpRequest.hasError(response, true)) {
    
      return;
    }

    setStates(response.stateMap);

    searching(false);

  }

  const list = async (params) => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(params);
    const response = await HttpRequest.request(url, method, params);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setViewBaseDate(new Date());
      setMinDpsBlnc(0);
      searching(false);
      return;
    }
    setDatas(response.plcfbInfoList);
    
    if (!response.plcfbInfoList || response.plcfbInfoList.length === 0) {
      setSearchData("");
      setViewBaseDate(new Date());
      setMinDpsBlnc(0);
    } else {
      setSearchData(response.plcfbInfoList[0]);
      setViewBaseDate(response.plcfbInfoList[0].baseDate);
      setMinDpsBlnc(response.plcfbInfoList[0].minDpsBlnc);
    }

    setStates(response.stateMap);
    console.log(response.stateMap);
    searching(false);
  };

  const baseDateSearch = async () => {
    const { HttpRequest } = props;

    const { url, method } = API.READCOMM;
    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setBaseDate(new Date());
      return;
    }
    setBaseDate(moment(response.baseDate));
  };

  const handleChange = (index, rowNum, value) => {
    setDatas(
      datas.map((item) =>
        item.rowNum === rowNum ? { ...item, ...value } : item,
      ),
    );

    setSaveIndex([...saveIndex, index]);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    return true;
  };

  const handleClickSearch = (condition) => {
    setParam(condition);
  };

  const handleClickAllSearch = () => {
    if (searchData) {
      list({
        dspYm: searchData.dspYm,
        managerName: searchData.managerName,
        custNo: searchData.custNo,
      });
    } else {
      props.toast.error("조회 결과가 없습니다.");
    }
  };

  const handleClick = (index) => {
    setSearchData(datas[index]);
    setViewBaseDate(datas[index].baseDate);
    setMinDpsBlnc(datas[index].minDpsBlnc);

    setSetlDate(datas[index].dspYm);
    setPlcfbCd(datas[index].plcfbName);
    setAbookSeq(datas[index].plcfbSeqNo);
  };

  const isDuplicate = () => {
    let arr = [];
    let note1Chk = false;

    if (datas) {
      datas.forEach((item) => {
        if (item.div === "office") {
          if (item.dpsNote1) {
            arr.push(item.dpsNote1);
          } else note1Chk = true;
          if (item.dpsNote2) arr.push(item.dpsNote2);
          if (item.dpsNote3) arr.push(item.dpsNote3);
          if (item.dpsNote4) arr.push(item.dpsNote4);
          if (item.dpsNote5) arr.push(item.dpsNote5);
        }
      });
    }

    if (note1Chk) {
      alert("입금적요1은 필수입니다.");
      return false;
    }

    const isDup = arr.some((item) => {
      return arr.indexOf(item) !== arr.lastIndexOf(item);
    });

    if (isDup) {
      alert("중복된 입금적요가 있습니다.");
      return false;
    }

    return true;
  };

  const isSaveData = () => {
    let ret = true;

    if (saveIndex.length === 0) {
      props.toast.error("저장할 내용이 없습니다.");
      ret = false;
    }

    return ret;
  };

  const handleClickSave = async () => {
    let ret = false;
    let msg = "";

    if (isDuplicate() && isSaveData()) {
      let saveData = datas.filter((item, index) => saveIndex.includes(index));
      ret = await edit(API.UPDATE, { plcfbInfoList: saveData });
      msg = "수정저장되었습니다.";
    }

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };

  const handleClickSaveBaseDate = async () => {
    let ret = false;
    let msg = "";

    if (baseDate) {
      ret = await edit(API.UPDATECOMM, {
        baseDate: props.formatter(props.formatter(baseDate, "date"), "toValue"),
      });
      msg = "기준일저장되었습니다.";
    }

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };

  //list(param);

  return (
    <div className="PlcfbAbookTxn">

        {cmpnCd==="01" && (
          <div style={{marginBottom: '2.250em', fontSize: '16px'}}>
            총 장부 : {props.formatter(states.all_cnt, "amount")}개&nbsp;&nbsp;&nbsp;
            영업소 장부 : {props.formatter(states.office_cnt, "amount")}개&nbsp;&nbsp;&nbsp;
            차량 장부 : {props.formatter(states.car_cnt, "amount")}개
          </div>
        )}
      
      
      <PlcfbAbookTxnConditionBox
        onClickSearch={handleClickSearch}
        {...props}
        cretFlag={cretFlag}
        deptCd={deptCd}
        deptNm={deptNm}
        cmpnCd={cmpnCd}        
      />
      <ControlBox>
        {cretFlag && (
          <Button className="save" onClick={handleClickSave}>
            저장
          </Button>
        )}
        <Button className="all" onClick={handleClickAllSearch}>
          전체보기
        </Button>
      </ControlBox>
      <article className="grid_data first">
        <div className="thead">
          <table>
            <colgroup>
              {[
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                {[
                  "월",
                  "영업소명",
                  "법인구분",
                  "이름",
                  "영업소 코드",
                  "한도금액",
                  "계좌번호",
                  "입금적요1",
                  "입금적요2",
                  "입금적요3",
                  "입금적요4",
                  "입금적요5",
                  "연락처1",
                  "연락처2",
                  "연락처3",
                  "연락처4",
                  "연락처5",
                ].map((i, key) => {
                  return <th key={key}>{i}</th>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbody NoreSize">
          <table>
            <colgroup>
              {[
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "5.7%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "6%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!datas || datas.length === 0 ? (
                <tr>
                  <td className="NoData" colSpan={17}>
                    조회 결과가 없습니다.
                  </td>
                </tr>
              ) : (
                datas.map((item, key) => {
                  return (
                    <tr
                      key={key}
                      onClick={() => handleClick(key)}
                      className={"hand " + (item.mktYn === "N" ? "nouse" : "")}
                      style={setlDate === item.dspYm && plcfbCd === item.plcfbName && abookSeq === item.plcfbSeqNo ? {background : "#abd0f5"} : {background : ""}}
                    >
                      <td>{props.formatter(item.dspYm, "dateYm")}</td>
                      <td>{item.plcfbName}</td>
                      <td>{item.phydsCorpName}</td>
                      <td>
                        {props.formatter(item.managerName, "name", false)}
                      </td>
                      <td>{item.orgnCd}</td>
                      <td className="amount">
                        {props.formatter(item.limitAmt, "amount")}
                      </td>
                      <td>
                        {item.acntBankName}{" "}
                        {props.formatter(item.acntNo, "account", false)}
                      </td>
                      {item.div === "office" ? (
                        <>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <CustomInput
                                name="dpsNote1"
                                type="text"
                                placeholder="입금적요 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    dpsNote1: e.target.value,
                                  })
                                }
                                value={item.dpsNote1}
                                maxLength={20}
                              />
                            ) : (
                              item.dpsNote1
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <CustomInput
                                name="dpsNote2"
                                type="text"
                                placeholder="입금적요 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    dpsNote2: e.target.value,
                                  })
                                }
                                value={item.dpsNote2}
                                maxLength={20}
                              />
                            ) : (
                              item.dpsNote2
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <CustomInput
                                name="dpsNote3"
                                type="text"
                                placeholder="입금적요 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    dpsNote3: e.target.value,
                                  })
                                }
                                value={item.dpsNote3}
                                maxLength={20}
                              />
                            ) : (
                              item.dpsNote3
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <CustomInput
                                name="dpsNote4"
                                type="text"
                                placeholder="입금적요 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    dpsNote4: e.target.value,
                                  })
                                }
                                value={item.dpsNote4}
                                maxLength={20}
                              />
                            ) : (
                              item.dpsNote4
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <CustomInput
                                name="dpsNote5"
                                type="text"
                                placeholder="입금적요 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    dpsNote5: e.target.value,
                                  })
                                }
                                value={item.dpsNote5}
                                maxLength={20}
                              />
                            ) : (
                              item.dpsNote5
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <FormatInput
                                formatType="phone"
                                name="apdCntcNo1"
                                placeholder="연락처 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    apdCntcNo1: e.target.value,
                                  })
                                }
                                value={props.formatter(
                                  item.apdCntcNo1,
                                  "phone",
                                )}
                              />
                            ) : (
                              props.formatter(item.apdCntcNo1, "phone", false)
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <FormatInput
                                formatType="phone"
                                name="apdCntcNo2"
                                placeholder="연락처 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    apdCntcNo2: e.target.value,
                                  })
                                }
                                value={props.formatter(
                                  item.apdCntcNo2,
                                  "phone",
                                )}
                              />
                            ) : (
                              props.formatter(item.apdCntcNo2, "phone", false)
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <FormatInput
                                formatType="phone"
                                name="apdCntcNo3"
                                placeholder="연락처 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    apdCntcNo3: e.target.value,
                                  })
                                }
                                value={props.formatter(
                                  item.apdCntcNo3,
                                  "phone",
                                )}
                              />
                            ) : (
                              props.formatter(item.apdCntcNo3, "phone", false)
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <FormatInput
                                formatType="phone"
                                name="apdCntcNo4"
                                placeholder="연락처 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    apdCntcNo4: e.target.value,
                                  })
                                }
                                value={props.formatter(
                                  item.apdCntcNo4,
                                  "phone",
                                )}
                              />
                            ) : (
                              props.formatter(item.apdCntcNo4, "phone", false)
                            )}
                          </td>
                          <td>
                            {cretFlag && item.mktYn === "Y" ? (
                              <FormatInput
                                formatType="phone"
                                name="apdCntcNo5"
                                placeholder="연락처 입력"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    apdCntcNo5: e.target.value,
                                  })
                                }
                                value={props.formatter(
                                  item.apdCntcNo5,
                                  "phone",
                                )}
                              />
                            ) : (
                              props.formatter(item.apdCntcNo5, "phone", false)
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </article>
      {cretFlag && (
        <article className="baseDateArea">
          기준일 :
          <CustomDatePicker
            name="baseDate"
            onChange={(e) => {
              setBaseDate(e);
            }}
            date={baseDate}
            changeDate={baseDate}
          />
          <Button className="save" onClick={handleClickSaveBaseDate}>
            저장
          </Button>
        </article>
      )}
      <article className="text_info">
        {viewBaseDate && (
          <p>
            {props.formatter(viewBaseDate, "date")} 기준 최소입금잔액{" "}
            <span>{props.formatter(minDpsBlnc, "amount")}</span>원 입니다.
          </p>
        )}
        <p>
          최소입금액 계산법 : 이월잔액 + 영업소정산금액 합계 - 본사입금액 - 전일
          영업소 정산금액 - 한도금액
        </p>
        <p>입금계좌번호[농협 301-0127-5223-41, 예금주: 평해전산용역정산㈜]</p>
      </article>
      <CustomTab
        tabs={[
          {
            name: "장부현황",
            component: (
              <PlcfbAbookTxnLedgerStatus
                searchData={searchData}
                cretFlag={cretFlag}
              />
            ),
          },
          {
            name: "입금리스트",
            component: <PlcfbAbookTxnDepositList searchData={searchData} />,
          },
          {
            name: "출금리스트",
            component: <PlcfbAbookTxnWithdrawalList searchData={searchData} />,
          },
          {
            name: "입금자 불분명리스트",
            component: <PlcfbAbookTxnImpossList searchData={searchData} />,
          }
        ]}
      />
    </div>
  );
};

PlcfbAbookTxn.propTypes = {};

export default withBase(PlcfbAbookTxn);
