import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withBase from "../Hoc/withBase";

const API = {
  GET: {
    url: "api/v1/comn/cache/info",
    method: "get",
  },
};

const CacheSelect = (props) => {
  const { cache, name, onChange, onBlur, onMap, placeholder, value } = props;
  const [items, setItems] = useState([]);
  const getCache = async () => {
    const { HttpRequest } = props;
    const { url, method } = API.GET;
    const response = await HttpRequest.request(`${url}/${cache}`, method);
    if (HttpRequest.hasError(response, true)) {
      return [];
    }
    return response;
  };
  useEffect(() => {
    async function fetch() {
      const data = await getCache();
      if (data) {
        setItems(data.map(onMap));
      }
    }
    cache ? fetch() : setItems([]);
  }, [cache]);
  return (
    <select
      name={name}
      onChange={(e) => {
        let item;
        if (e && e.target.value) {
          item = items.find((element) => element.code === e.target.value);
        }
        onChange(e, item);
      }}
      onBlur={onBlur}
      className="select"
      value={value}
    >
      <option value={""} key={-1}>
        {placeholder}
      </option>
      {items &&
        items.map((item, key) => {
          return (
            <option value={item.code} key={key}>
              {item.name}
            </option>
          );
        })}
    </select>
  );
};

CacheSelect.propTypes = {};

export default withBase(CacheSelect);
