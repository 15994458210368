import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./RoleMgt.css";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  withBase,
  ControlBox,
  CustomCheckBox,
  Button,
  CacheSelect,
} from "components";
import { useSelector } from "react-redux";

const API = {
  LIST: {
    url: "api/v1/comn/roleMgt/list",
    method: "post",
  },
  DELETE: {
    url: "api/v1/comn/roleMgt/delete",
    method: "post",
  },
  REFRESH: {
    url: "api/v1/comn/cache/refresh/roleInfo",
    method: "get",
  },
  REFRESH2: {
    url: "api/v1/comn/cache/refresh/roleRiHst",
    method: "get",
  },
};

const RoleMgt = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({});
  const [datas, setDatas] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [cacheCode, setCacheCode] = useState("roleInfo");
  let upperMenu = "";

  useEffect(() => {
    setCacheCode("");
    refresh();
  }, []);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      searching(false);
      return;
    }
    setDatas(response.roleRiHstList);
    searching(false);
  };

  const del = async () => {
    const { HttpRequest } = props;
    const { url, method } = API.DELETE;

    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    setCacheCode("");
    refresh();
    return true;
  };

  const refresh = async () => {
    const { HttpRequest } = props;

    const response = await HttpRequest.request(
      API.REFRESH.url,
      API.REFRESH.method
    );
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    const response2 = await HttpRequest.request(
      API.REFRESH2.url,
      API.REFRESH2.method
    );
    if (HttpRequest.hasError(response2, true)) {
      return false;
    }

    setRoleId("");
    setCacheCode("roleInfo");
    setParam({});
  };

  const handleChangeSearch = (roleId) => {
    setParam({
      roleId: roleId.code,
      roleIdName: roleId.name,
    });
  };

  const chkRoleId = () => {
    let ret = false;

    param && param.roleId
      ? (ret = true)
      : props.toast.error("권한을 선택하세요.");

    return ret;
  };

  const handleClickEdit = () => {
    if (chkRoleId()) {
      props.history.push(
        "/comn/roleMgt/" + param.roleId + "/" + param.roleIdName
      );
    }
  };

  const handleClickSave = async () => {
    if (chkRoleId()) {
      if (window.confirm("해당 권한을 삭제 하시겠습니까?")) {
        let ret = false;
        let msg = "";

        ret = await del();
        msg = "삭제되었습니다.";

        if (ret) {
          await refresh();

          if (msg) props.toast.error(msg);
        }
      }
    }
  };

  return (
    <div className="RoleMgt">
      <ControlBox
        InputTitle={<h6>권한명</h6>}
        left={
          <CacheSelect
            cache={cacheCode}
            onMap={(item, key) => {
              return {
                code: item.roleId,
                name: item.roleNm,
              };
            }}
            placeholder={"권한 선택"}
            onChange={(e, item) => {
              if (item) {
                setRoleId(item.code);
                handleChangeSearch(item);
              } else {
                setRoleId("");
                handleChangeSearch("");
              }
            }}
            value={roleId}
          />
        }
      >
        {cretFlag && (
          <>
            <Link to="/comn/roleMgt/new/">
              <Button className="insert">신규등록</Button>
            </Link>
            <Button className="update" onClick={handleClickEdit}>
              수정
            </Button>
            <Button className="delete" onClick={handleClickSave}>
              삭제
            </Button>
          </>
        )}
      </ControlBox>
      <article className="grid_data">
        <div className="">
          <div className="thead">
            <table>
              <colgroup>
                {["*", "8.6%", "14%"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {["메뉴", "조회", "등록/수정/삭제"].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody NoreSize HasRowsTH">
            <table>
              <colgroup>
                {["*", "8.6%", "14%"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={3}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    let gridTr =
                      upperMenu === item.riIdUpName ? (
                        <tr key={key}>
                          <td className="cnt">{item.riIdName}</td>
                          <td>
                            <CustomCheckBox
                              checked={item.readFlag === "1" ? true : false}
                              disabled={true}
                            />
                          </td>
                          <td>
                            <CustomCheckBox
                              checked={item.cretFlag === "1" ? true : false}
                              disabled={true}
                            />
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <th>{item.riIdUpName}</th>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr key={key}>
                            <td className="cnt">{item.riIdName}</td>
                            <td>
                              <CustomCheckBox
                                checked={item.readFlag === "1" ? true : false}
                                disabled={true}
                              />
                            </td>
                            <td>
                              <CustomCheckBox
                                checked={item.cretFlag === "1" ? true : false}
                                disabled={true}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    upperMenu = item.riIdUpName;
                    return gridTr;
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  );
};

RoleMgt.propTypes = {};

export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({})
)(withRouter(withBase(RoleMgt)));
