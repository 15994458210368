import React from "react";
import PropTypes from "prop-types";
import "./ReadyMoneyTxnWithdrawalTable.css";
import { FormatInput, CustomInput, CustomDatePicker } from "components";

const ReadyMoneyTxnWithdrawalTable = (props) => {
  const { datas, totalData, addRows, formatter, onChange, editFlg } = props;

  return (
    <>
      <div className="ReadyMoneyTxnWithdrawalTable">
        <article className="grid_data">
          <div className="thead HasTD">
            <table>
              <colgroup>
                {[
                  "*",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  <th rowSpan="2">거래일자</th>
                  <th colSpan="5">입금</th>
                  <th colSpan="6">출금</th>
                  <th rowSpan="2">계좌잔액</th>
                </tr>
                <tr>
                  <td>계좌 입금액</td>
                  <td>차입금</td>
                  <td>차입계좌(계좌명)</td>
                  <td>기타입금</td>
                  <td>기타입금 내용</td>
                  <td>송금(펌뱅킹)</td>
                  <td>개별송금</td>
                  <td>기타출금</td>
                  <td>기타출금 내용</td>
                  <td>차입금 반제</td>
                  <td>차입계좌(계좌명)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {[
                  "*",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {(!datas || datas.length === 0) &&
                (!addRows || addRows.length === 0) ? (
                  <tr>
                    <td className="NoData" colSpan={10}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas &&
                  datas.map((item, key) => {
                    return (
                      <>
                        <tr key={key}>
                          {item.rdymnyDate === "이월잔고" ? (
                            <>
                              <th>{item.rdymnyDate}</th>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="amount">
                                {formatter(item.acntBlnc, "amount")}
                              </td>
                            </>
                          ) : (
                            <>
                              <th>
                                {editFlg ? (
                                  <CustomDatePicker
                                    name="rdymnyDate2"
                                    onChange={onChange("chgDate", item.rowNum)}
                                    date={
                                      new Date(
                                        formatter(item.rdymnyDate2, "dateDash"),
                                      )
                                    }
                                  />
                                ) : (
                                  formatter(item.rdymnyDate2, "date")
                                )}
                              </th>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="plcfbDpsAmt"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.plcfbDpsAmt}
                                  />
                                ) : (
                                  formatter(item.plcfbDpsAmt, "amount")
                                )}
                              </td>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="loanAmt"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.loanAmt}
                                  />
                                ) : (
                                  formatter(item.loanAmt, "amount")
                                )}
                              </td>
                              <td className="tal">
                                {editFlg ? (
                                  <CustomInput
                                    name="loanAcntSbst"
                                    type="text"
                                    placeholder="입력"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.loanAcntSbst}
                                    maxLength={50}
                                  />
                                ) : (
                                  item.loanAcntSbst
                                )}
                              </td>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="etcDpsAmt"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.etcDpsAmt}
                                  />
                                ) : (
                                  formatter(item.etcDpsAmt, "amount")
                                )}
                              </td>
                              <td className="tal">
                                {editFlg ? (
                                  <CustomInput
                                    name="etcDpsAcntSbst"
                                    type="text"
                                    placeholder="입력"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.etcDpsAcntSbst}
                                    maxLength={50}
                                  />
                                ) : (
                                  item.etcDpsAcntSbst
                                )}
                              </td>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="acntOtpayAmt"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.acntOtpayAmt}
                                  />
                                ) : (
                                  formatter(item.acntOtpayAmt, "amount")
                                )}
                              </td>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="indivOtpayAmt"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.indivOtpayAmt}
                                  />
                                ) : (
                                  formatter(item.indivOtpayAmt, "amount")
                                )}
                              </td>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="etcOtpayAmt"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.etcOtpayAmt}
                                  />
                                ) : (
                                  formatter(item.etcOtpayAmt, "amount")
                                )}
                              </td>
                              <td className="tal">
                                {editFlg ? (
                                  <CustomInput
                                    name="etcOtpaySbst"
                                    type="text"
                                    placeholder="입력"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.etcOtpaySbst}
                                    maxLength={50}
                                  />
                                ) : (
                                  item.etcOtpaySbst
                                )}
                              </td>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="rfndAmt"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.rfndAmt}
                                  />
                                ) : (
                                  formatter(item.rfndAmt, "amount")
                                )}
                              </td>
                              <td className="tal">
                                {editFlg ? (
                                  <CustomInput
                                    name="rfndAcntSbst"
                                    type="text"
                                    placeholder="입력"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.rfndAcntSbst}
                                    maxLength={50}
                                  />
                                ) : (
                                  item.rfndAcntSbst
                                )}
                              </td>
                              <td className="amount">
                                {editFlg ? (
                                  <FormatInput
                                    formatType="amount"
                                    name="acntBlnc"
                                    onChange={onChange("update", item.rowNum)}
                                    value={item.acntBlnc}
                                  />
                                ) : (
                                  formatter(item.acntBlnc, "amount")
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      </>
                    );
                  })
                )}
                {addRows &&
                  addRows.map((item) => {
                    return (
                      <>
                        <tr key={item.rowNum}>
                          <th>
                            <CustomDatePicker
                              name="rdymnyDate"
                              onChange={onChange("date", item.rowNum)}
                              date={new Date()}
                            />
                          </th>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="plcfbDpsAmt"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.plcfbDpsAmt}
                            />
                          </td>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="loanAmt"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.loanAmt}
                            />
                          </td>
                          <td className="tal">
                            <CustomInput
                              name="loanAcntSbst"
                              type="text"
                              placeholder="입력"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.loanAcntSbst}
                              maxLength={50}
                            />
                          </td>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="etcDpsAmt"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.etcDpsAmt}
                            />
                          </td>
                          <td>
                            <CustomInput
                              name="etcDpsAcntSbst"
                              type="text"
                              placeholder="입력"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.etcDpsAcntSbst}
                              maxLength={50}
                            />
                          </td>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="acntOtpayAmt"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.acntOtpayAmt}
                            />
                          </td>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="indivOtpayAmt"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.indivOtpayAmt}
                            />
                          </td>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="etcOtpayAmt"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.etcOtpayAmt}
                            />
                          </td>
                          <td>
                            <CustomInput
                              name="etcOtpaySbst"
                              type="text"
                              placeholder="입력"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.etcOtpaySbst}
                              maxLength={50}
                            />
                          </td>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="rfndAmt"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.rfndAmt}
                            />
                          </td>
                          <td>
                            <CustomInput
                              name="rfndAcntSbst"
                              type="text"
                              placeholder="입력"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.rfndAcntSbst}
                              maxLength={50}
                            />
                          </td>
                          <td>
                            <FormatInput
                              formatType="amount"
                              name="acntBlnc"
                              onChange={onChange("addRow", item.rowNum)}
                              value={item.acntBlnc}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="tfoot">
            <table>
              <colgroup>
                {[
                  "*",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                  "7.6%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!totalData || totalData.length === 0 ? (
                  <></>
                ) : (
                  totalData.map((item, key) => {
                    return (
                      <tr key={key}>
                        <th>합계</th>
                        <td className="amount">
                          {formatter(item.plcfbDpsAmt, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.loanAmt, "amount")}
                        </td>
                        <td></td>
                        <td className="amount">
                          {formatter(item.etcDpsAmt, "amount")}
                        </td>
                        <td></td>
                        <td className="amount">
                          {formatter(item.acntOtpayAmt, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.indivOtpayAmt, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.etcOtpayAmt, "amount")}
                        </td>
                        <td></td>
                        <td className="amount">
                          {formatter(item.rfndAmt, "amount")}
                        </td>
                        <td></td>
                        <td className="amount">
                          {formatter(item.acntBlnc, "amount")}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </article>
      </div>
    </>
  );
};

ReadyMoneyTxnWithdrawalTable.propTypes = {};

export default ReadyMoneyTxnWithdrawalTable;
