import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Login.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTitle, withBase } from "components";
import { SIGNIN } from "stores/apis";
import { TOKEN, TOKEN_TYPE } from "variables/constants";
import LoginLogo from "assets/images/login/logo.svg";
import { removeStorage } from "variables/common";

const LoginSaveCheckBox = (props) => {
  const [checked, setChecked] = useState("");
  const handleClick = () => {
    setChecked(checked === "on" ? "" : "on");
  };
  return (
    <li className="LoginSave">
      <span className={checked}>
        <input type="checkbox" onClick={handleClick} />
      </span>
      로그인 상태 유지
    </li>
  );
};

LoginSaveCheckBox.propTypes = {};

const Login = (props) => {
  const titleUpdater = useTitle("로그인");
  const [failed, setFailed] = useState(false);
  const handleLogin = async (values) => {
    const {
      HttpRequest,
      history, // change url, like window.history
    } = props;

    removeStorage();

    const { url, method } = SIGNIN;
    const data = await HttpRequest.request(url, method, values);
    if (!HttpRequest.hasError(data, true)) {
      sessionStorage.setItem(TOKEN, data.accessToken);
      sessionStorage.setItem(TOKEN_TYPE, data.tokenType);
      titleUpdater("경동택배ㆍ합동택배 FTAS");
      setFailed(false);
      history.push("/main");
    } else {
      setFailed(true);
    }
  };
  return (
    <Formik
      initialValues={{ userId: "", pwd: "" }}
      validationSchema={Yup.object().shape({
        userId: Yup.string().required("아이디를 입력하세요."),
        pwd: Yup.string().required("비밀번호를 입력하세요."),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleLogin(values);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div className="LoginWrap">
            <div className="area">
              <h1 className="LoginLogo">
                <img src={LoginLogo} alt="경동택배 합동택배 로고" />
              </h1>
              <form onSubmit={handleSubmit}>
                <ul>
                  <li className="LoginInput">
                    <input
                      name="userId"
                      type="text"
                      placeholder="아이디"
                      onChange={handleChange}
                    />
                    <div className="error">
                      {errors.userId && touched.userId && errors.userId}
                    </div>
                  </li>
                  <li className="LoginInput">
                    <input
                      name="pwd"
                      type="password"
                      placeholder="비밀번호"
                      onChange={handleChange}
                    />
                    <div className="error">
                      {errors.pwd && touched.pwd && errors.pwd}
                    </div>
                  </li>
                  <li
                    className="LoginCheck"
                    style={{ display: failed ? "block" : "none" }}
                  >
                    가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.
                  </li>
                  <li className="LoginButton">
                    <button type="submit" disabled={isSubmitting}>
                      로그인
                    </button>
                  </li>
                  <li className="LoginLost">
                    아이디, 비밀번호 분실은 관리자에게 문의해주세요.
                  </li>
                </ul>
              </form>
            </div>
            <div className="FooterText roboto">
              Financial Transaction Accounting System
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

Login.propTypes = {};

export default withBase(Login);
