import React from "react";
import PropTypes from "prop-types";
import "./CustomTextArea.css";

const CustomTextArea = (props) => {
  const { placeholder, children, onChange, value, name } = props;
  return (
    <textarea
      name={name}
      className="CustomTextArea"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    >
      {children}
    </textarea>
  );
};

CustomTextArea.propTypes = {};

export default CustomTextArea;
