import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./CustomTab.css";
import Cols from "./Cols";
import TabOn from "assets/images/icon_tab_on.png";

const CustomTab = (props) => {
  // const { tabs, children } = props;
  const { tabs } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    setSelectedItem(tabs[0]);
  }, [tabs]);
  const handleClick = (item) => {
    setSelectedItem(item);
  };
  return (
    <>
      <article className="tab_area">
        <table>
          <colgroup>
            <Cols tabs={tabs} />
          </colgroup>
          <tbody>
            <tr>
              {tabs.map((item, key) => {
                return (
                  <td
                    key={key}
                    onClick={() => {
                      handleClick(item);
                    }}
                    className={item === selectedItem ? "on" : ""}
                  >
                    <span>
                      <img src={TabOn} alt="tab_image" />
                      {item.name}
                    </span>
                  </td>
                );
              })}
              <td></td>
            </tr>
          </tbody>
        </table>
      </article>
      {selectedItem ? selectedItem.component : null}
      {/* {children && children} */}
    </>
  );
};

CustomTab.propTypes = {};

export default CustomTab;
