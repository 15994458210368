import React from "react";
import PropTypes from "prop-types";
import "./CmsListPopupBatch.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, DatePickerField, CustomInput } from "components";
import moment from "moment";

const CmsListPopupBatch = (props) => {
  const { layerType, viewData, onClose, onSave, formatter, cretFlag } = props;

  const getSum = (list) => {
    if(list && list.length !== 0)
      return props.formatter(list.reduce((acc, curr) => acc + curr.fee, 0), "amount");
  };

  return (
    <Formik
      initialValues={{
        otpayDate: moment(),
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        let data = viewData.map((item) => ({
          ...item,
        }));
        onSave("remit", data);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <Form>
            <div className="CmsListPopupBatch">
              <h5>필수 입력</h5>
              <article className="popup_grid first">
                <div className="first">
                  <table>
                    <colgroup>
                      <col width="10.4%" />
                      <col width="5%" />
                      <col width="10.7%" />
                      <col width="10.2%" />
                      <col width="10.3%" />
                      <col width="10.5%" />
                      <col width="12.1%" />
                      <col width="12.9%" />
                      <col width="5%" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>발송일</th>
                        <th>상태</th>
                        <th>영업소명</th>
                        <th>수수료</th>
                        <th>차량번호</th>
                        <th>예금주명</th>
                        <th>계좌번호</th>
                        <th>통장기재</th>
                        <th>구분</th>
                        <th>사유</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewData &&
                        viewData.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{formatter(item.dspDate, "date")}</td>
                              <td>{item.otpaySttus}</td>
                              <td>{item.plcfbName}</td>
                              <td className="amount">
                                {formatter(item.fee, "amount")}
                              </td>
                              <td>{item.carNo}</td>
                              <td>{formatter(item.dposr, "name", false)}</td>
                              <td>
                                {formatter(item.acntNo, "account", false)}
                              </td>
                              <td>{item.bnkbkSttm}</td>
                              <td>{item.degreeDiv}</td>
                              <td>{item.reqMemo}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td></td>
                          <td colSpan="2">정지 합계금액<i></i></td>
                          <td className="amount">{getSum(viewData)}</td>
                          <td colSpan="6"></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </article>
              <div className="PopupButton">
                {cretFlag && (
                  <Button
                    type="submit"
                    className="insert"
                    disabled={isSubmitting}
                  >
                    정지
                  </Button>
                )}
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CmsListPopupBatch.propTypes = {};

export default CmsListPopupBatch;
