import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "components";

const UserInfoViewTable = (props) => {
  const { viewData, onClose, onEdit, formatter, cretFlag } = props;
  let data = [];
  if (viewData) {
    data = [
      {
        name: "아이디",
        value: viewData.userId,
      },
      {
        name: "이름",
        value: props.formatter(viewData.userNm, "name", true),
      },
      {
        name: "소속구분",
        value: viewData.posCmpnNm,
      },
      {
        name: "소속",
        value: viewData.posDeptNm,
      },
      {
        name: "직급",
        value: viewData.clposNm,
      },
      {
        name: "직책",
        value: viewData.rspofNm,
      },
      {
        name: "권한",
        value: viewData.roleNm,
      },
      {
        name: "핸드폰번호",
        value: formatter(viewData.athnMphonNo, "phone", true),
      },
      {
        name: "전화번호",
        value: formatter(viewData.pponNo, "phone", true),
      },
      {
        name: "이메일",
        value: viewData.email,
      },
      {
        name: "재직여부",
        value: viewData.inofficeSttusCd === "Y" ? "재직중" : "퇴사",
      },
      {
        name: "사용여부",
        value: viewData.delYn === "Y" ? "사용중" : "미사용",
      },
    ];
  }
  return (
    <>
      <article className="popup_grid">
        <table>
          <colgroup>
            <col width="200" />
            <col width="*" />
          </colgroup>
          <tbody>
            {data &&
              data.map((item, key) => {
                return (
                  <tr key={key}>
                    <th>{item.name}</th>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </article>
      <div className="PopupButton">
        {cretFlag && (
          <Button className="insert" onClick={onEdit}>
            수정
          </Button>
        )}
        <Button className="close" onClick={onClose}>
          닫기
        </Button>
      </div>
    </>
  );
};

UserInfoViewTable.propTypes = {};

export default UserInfoViewTable;
