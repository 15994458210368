import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Sample.css";
import { Formik, Form } from "formik";
import { Button, withBase, Address } from "components";
import axios from "axios";

const Sample = (props) => {
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(null);
  return (
    <>
      <div>
        <Button onClick={() => setOpen(true)}>주소 검색</Button>
      </div>
      {address ? (
        <>
          <div>{address.zipNo}</div>
          <div>{address.roadAddr}</div>
        </>
      ) : null}
      <Address open={open} handleOpen={setOpen} setAddress={setAddress} />
    </>
  );
};

Sample.propTypes = {};

export default withBase(Sample);
