import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./CodeAutocomplete.css";
import ReactAutocomplete from "react-autocomplete";
import { connect } from "react-redux";
import { findGroupCode } from "variables/common";

const CodeAutocomplete = (props) => {
  const {
    code,
    codeId,
    name,
    setFieldValue,
    value,
    placeholder,
    name2,
  } = props;
  const [items, setItems] = useState([]);
  useEffect(() => {
    console.log(codeId);
    codeId ? setItems(findGroupCode(code, codeId)) : setItems([]);
  }, [codeId]);
  const handleFieldValue = (value) => {
    setFieldValue(name, value);
    const item = items.find((element) => element.name === value);
    setFieldValue(name2, item ? item.code : "");
  };
  return (
    <ReactAutocomplete
      items={items ? items : []}
      shouldItemRender={(item, value) =>
        value && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      }
      getItemValue={(item) => item.name}
      renderItem={(item, highlighted) => (
        <div
          key={item.code}
          style={{ background: highlighted ? "lightgray" : "white" }}
        >
          {item.name}
        </div>
      )}
      value={value}
      onChange={(e) => handleFieldValue(e.target.value)}
      onSelect={(value) => handleFieldValue(value)}
      inputProps={{ placeholder: placeholder }}
    />
  );
};

CodeAutocomplete.propTypes = {};

export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({})
)(CodeAutocomplete);
