import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import {
  CacheAutocomplete,
  CodeSelect,
  Button,
  ConditionBox,
  CustomInput,
} from "components";

const CarInfoCondition = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            plcfbCd: "",
            plcfbNm: "",
            carDiv: "",
            carName: "",
            carNo: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { plcfbNm, ...rest } = values;
            setSubmitting(true);
            onClickSearch({ ...rest });
            setSubmitting(false);
          }}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>영업소명</dt>
                            <dd>
                              <CacheAutocomplete
                                cache="plcfbInfo"
                                onMap={(item, key) => {
                                  return {
                                    code: item.plcfbCd,
                                    name: item.plcfbName,
                                  };
                                }}
                                name="plcfbNm"
                                value={values.plcfbNm}
                                callBack={(item) => {
                                  setFieldValue(
                                    "plcfbCd",
                                    item ? item.code : ""
                                  );
                                }}
                                setFieldValue={setFieldValue}
                                placeholder="영업소 입력"
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>차량구분</dt>
                            <dd>
                              <CodeSelect
                                codeId="CAR_DIV"
                                name="carDiv"
                                onChange={handleChange}
                                placeholder="차량구분 선택"
                                value={values.carDiv}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>차량명</dt>
                            <dd>
                              <CustomInput
                                name="carName"
                                type="text"
                                placeholder="차량명 입력"
                                onChange={handleChange}
                                value={values.carName}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>차량번호</dt>
                            <dd>
                              <CustomInput
                                name="carNo"
                                type="text"
                                placeholder="차량번호 입력"
                                onChange={handleChange}
                                value={values.carNo}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>운행여부</dt>
                            <dd>
                              <CodeSelect
                                codeId="MVMN_YN"
                                name="mvmnYn"
                                onChange={handleChange}
                                placeholder="운행여부 선택"
                                value={values.mvmnYn}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

CarInfoCondition.propTypes = {};

export default CarInfoCondition;
