import React from "react";
import PropTypes from "prop-types";
import "./Button.css";
import DelIcon from "assets/images/del_icon.svg";
import PrevIcon from "assets/images/prev_icon.svg";
import RequestIcon from "assets/images/request_icon.svg";
import AddFileIcon from "assets/images/icon_file_add.png";
import FeeFileUploadIcon from "assets/images/icon_fee_file_upload.png";
import FeeFileUploadDelIcon from "assets/images/icon_file_del.png";
import FeeClcltIcon from "assets/images/icon_fee.png";
import FTASClcltIcon from "assets/images/icon_ftas_clclt.png";
import PrintIcon from "assets/images/print.png";
import InquireIcon from "assets/images/icon_search.png";
import SetlTxnIcon from "assets/images/icon_data.png";
import DataGnrIcon from "assets/images/icon_data_gnr.png";
import SendIcon from "assets/images/icon_send.png";
import DashboardIcon from "assets/images/icon_btn_dashboard.png";
import CalcIcon from "assets/images/icon_calc.png";

const Button = (props) => {
  const { children, className, onClick, value, type, disabled } = props;

  return (
    <button
      type={type ? type : "button"}
      className={"button " + className}
      onClick={onClick}
      value={value}
      disabled={disabled}
    >
      {className === "delete" ? <img src={DelIcon} alt="delete" /> : null}
      {className === "prev" ? <img src={PrevIcon} alt="prev" /> : null}
      {className === "delete_row" ? (
        <img src={DelIcon} alt="delete row" />
      ) : null}
      {className === "withdrawl_rquest" ? (
        <img src={RequestIcon} alt="Withdrawl Rquest" />
      ) : null}
      {className === "add_file" ? (
        <img src={AddFileIcon} alt="Add File" />
      ) : null}
      {className === "fee_file_upload" ? (
        <img src={FeeFileUploadIcon} alt="Fee File Upload" />
      ) : null}
      {className === "fee_file_del" ? (
        <img src={FeeFileUploadDelIcon} alt="Fee File Delete" />
      ) : null}
      {className === "fee_clclt" ? (
        <img src={FeeClcltIcon} alt="Fee Clclt" />
      ) : null}
      {className === "ftas_clclt" ? (
        <img src={FTASClcltIcon} alt="FTAS Calculate" />
      ) : null}
      {className === "print" ? <img src={PrintIcon} alt="Print" /> : null}
      {className === "inquire" ? <img src={InquireIcon} alt="Inquire" /> : null}
      {className === "data_armt" ? (
        <img src={SetlTxnIcon} alt="Data Arrangement" />
      ) : null}
      {className === "data_gnr" ? (
        <img src={DataGnrIcon} alt="Data Generation" />
      ) : null}
      {className === "send" ? <img src={SendIcon} alt="Send" /> : null}
      {className === "dashboard" ? <img src={DashboardIcon} alt="" /> : null}
      {className === "calc" ? <img src={CalcIcon} alt="" /> : null}
      {children}
    </button>
  );
};

Button.propTypes = {};

export default Button;
