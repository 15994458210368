import React from "react";
import PropTypes from "prop-types";
import "./CmsListPopupWrapper.css";
import { Title } from "components";

const CmsListPopupWrapper = (props) => {
  const {
    layerType,
    className,
    title,
    batchTable,
    individualTable,
    downTable,
  } = props;
  const determineView = () => {
    let ret = null;
    switch (layerType) {
      case "batch":
        ret = batchTable;
        break;
      case "individual":
        ret = individualTable;
        break;
      case "down":
        ret = downTable;
        break;
      default:
        break;
    }
    return ret;
  };
  return (
    <div className={className ? className : "Popup"}>
      <Title
        title={title}
        upperMenus={[
          title,
          layerType === "batch"
            ? "정지"
            : layerType === "individual"
            ? "개별송금"
            : "송금요청서",
        ]}
      />
      {determineView()}
    </div>
  );
};

CmsListPopupWrapper.propTypes = {};

export default CmsListPopupWrapper;
