import React from "react";
import PropTypes from "prop-types";
import "./AcntDpsTxnListTable.css";
import { Pagination } from "components";

const AcntDpsTxnListTable = (props) => {
  const {
    datas,
    totalData,
    page,
    total,
    row,
    pageCount,
    fetchData,
    onDoubleClick,
    formatter,
  } = props;
  return (
    <>
      <article className="grid_data first">
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {["25%", "25%", "25%", "*"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {["입금일", "합계", "정상(일치)", "불능(불일치)"].map(
                    (i, key) => {
                      return <th key={key}>{i}</th>;
                    },
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody">
            <table>
              <colgroup>
                {["25%", "25%", "25%", "*"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    {totalData ? formatter(totalData.dealDate, "date") : ""}
                  </td>
                  <td className="amount">
                    {totalData ? formatter(totalData.totSum, "amount") : ""}
                  </td>
                  <td className="amount">
                    {totalData ? formatter(totalData.mtchAmt, "amount") : ""}
                  </td>
                  <td className="amount">
                    {totalData ? formatter(totalData.notMtchAmt, "amount") : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
      <article className="grid_data">
        
        <div className="thead">
          <table>
            <colgroup>
              {[
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                {[
                  "거래일자",
                  "거래시간",
                  "수수료",
                  "거래기록사항",
                  "적요",
                  "영업소명",
                  "소장명",
                  "입금확인수행시간",
                  "입금확인수행자",
                  "수정일시",
                  "수정자명",
                ].map((i, key) => {
                  return <th key={key}>{i}</th>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="holdTable">
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {[
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={11}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        onDoubleClick={() =>
                          item.notesMtchYn === "N" ? onDoubleClick(key) : ""
                        }
                        className={item.notesMtchYn === "N" ? "hand" : ""}
                      >
                        <th>{formatter(item.dealDate, "date")}</th>
                        <td>{formatter(item.dealTime, "time")}</td>
                        <td className="amount">
                          {formatter(item.dealAmt, "amount")}
                        </td>
                        <td>{item.notesMtchYnNm}</td>
                        <td>{item.notes}</td>
                        <td className="tal">{item.plcfbName}</td>
                        <td>{formatter(item.managerName, "name", false)}</td>
                        <td>{formatter(item.dpsConfDt, "datetime")}</td>
                        <td>{item.dpsConfName}</td>
                        <td>{formatter(item.amdDate, "datetime")}</td>
                        <td>{item.amdrId}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        {datas && datas.length > 0 && (
          <Pagination
            page={page}
            total={total}
            row={row}
            pageCount={pageCount}
            fetchData={fetchData}
          />
        )}
      </article>
    </>
  );
};

AcntDpsTxnListTable.propTypes = {};

export default AcntDpsTxnListTable;
