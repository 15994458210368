import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./UserInfoEditTable.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  CustomInput,
  FormatInput,
  Button,
  CodeSelect,
  CacheSelect,
  CheckBox as Checkbox,
  CacheAutocomplete,
} from "components";

const UserInfoEditTable = (props) => {
  const { layerType, viewData, onClose, onSave, formatter } = props;
  const [grpIdPosDept, setGrpIdPosDeptCd] = useState("");
  const [grpIdClpos, setGrpIdClpos] = useState("");
  const [grpIdRspof, setGrpIdRspof] = useState("");
  const [posCmpnNm, setPosCmpnNm] = useState("");
  // const [posDeptNm, setPosDeptNm] = useState("");
  const [clposNm, setClposNm] = useState("");
  const [rspofNm, setRspofNm] = useState("");
  const [pwdChg, setPwdChg] = useState(layerType === "edit" ? true : false);
  return (
    <>
      <Formik
        initialValues={{
          userId: viewData ? viewData.userId : "",
          pwd: viewData ? "**********1aA" : "",
          confirmPwd: viewData ? "**********1aA" : "",
          pwdChgFlg: false,
          userNm: viewData ? viewData.userNm : "",
          posCmpnCd: "",
          posDeptNm: "",
          posDeptCd: "",
          clposCd: "",
          rspofCd: "",
          roleId: viewData ? viewData.roleId : "",
          athnMphonNo: viewData ? formatter(viewData.athnMphonNo, "phone") : "",
          pponNo: viewData ? formatter(viewData.pponNo, "phone") : "",
          email: viewData ? viewData.email : "",
          inofficeSttusCd: viewData
            ? viewData.inofficeSttusCd === "Y"
              ? true
              : false
            : true,
          delYn: viewData ? (viewData.delYn === "Y" ? true : false) : true,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const {
            pwd,
            confirmPwd,
            pwdChgFlg,
            clposCd,
            rspofCd,
            inofficeSttusCd,
            delYn,
            pponNo,
            athnMphonNo,
            ...rest
          } = values;
          onSave(layerType, {
            ...rest,
            pwd: layerType === "edit" && pwdChgFlg === false ? null : pwd,
            posCmpnNm,
            // posDeptNm,
            clposNm,
            rspofNm,
            inofficeSttusCd: inofficeSttusCd ? "Y" : "N",
            delYn: delYn ? "Y" : "N",
            pponNo: formatter(pponNo, "toValue"),
            athnMphonNo: formatter(athnMphonNo, "toValue"),
          });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          userId: Yup.string().required().min(5).max(15),
          pwd: Yup.string()
            .required("비밀번호를 입력하세요.")
            .min(8, "비밀번호는 최소 8자리 이상 입력하세요.")
            .max(20, "비밀번호는 최대 20자리 이하 입력하세요.")
            .matches(
              /[0-9]/,
              "영문소문자, 영문대문자, 숫자, 특수문자를 포함해야합니다."
            )
            .matches(
              /[a-z]/,
              "영문소문자, 영문대문자, 숫자, 특수문자를 포함해야합니다."
            )
            .matches(
              /[A-Z]/,
              "영문소문자, 영문대문자, 숫자, 특수문자를 포함해야합니다."
            )
            .matches(
              /[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/,
              "영문소문자, 영문대문자, 숫자, 특수문자를 포함해야합니다."
            ),
          confirmPwd: Yup.string()
            .required("비밀번호를 확인을 입력하세요.")
            .oneOf([Yup.ref("pwd"), null], "비밀번호가 일치하지 않습니다."),
          userNm: Yup.string().required(),
          posCmpnCd: Yup.string().required(),
          posDeptCd: Yup.string().required(),
          clposCd: Yup.string().required(),
          rspofCd: Yup.string().required(),
          roleId: Yup.string().required(),
          athnMphonNo: Yup.string().required(),
          pponNo: Yup.string().required(),
          email: Yup.string().email().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <Form className="UserInfoEditTable">
              <h5>필수 입력</h5>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="200" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        아이디<i></i>
                        <span>
                          {errors.userId && touched.userId && errors.userId}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="userId"
                          type="text"
                          placeholder="아이디 입력"
                          onChange={handleChange}
                          value={values.userId}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        비밀번호<i></i>
                        <span>
                          {errors.pwd && touched.pwd
                            ? errors.pwd
                            : errors.confirmPwd && touched.confirmPwd
                            ? errors.confirmPwd
                            : ""}
                        </span>
                      </th>
                      <td>
                        <div style={{ marginBottom: "10px" }}>
                          <CustomInput
                            name="pwd"
                            type="password"
                            placeholder="비밀번호 입력"
                            onChange={handleChange}
                            value={values.pwd}
                            disabled={pwdChg}
                          />
                          {layerType === "edit" ? (
                            <span className="left">
                              <Checkbox
                                name="pwdChgFlg"
                                onChange={(e) => {
                                  if (pwdChg) {
                                    values.pwd = "";
                                    values.confirmPwd = "";
                                  } else {
                                    values.pwd = "**********1aA";
                                    values.confirmPwd = "**********1aA";
                                  }
                                  setPwdChg(!pwdChg);
                                  handleChange(e);
                                }}
                                checked={values.pwdChgFlg}
                              />{" "}
                              비밀번호변경
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <CustomInput
                            name="confirmPwd"
                            type="password"
                            placeholder="비밀번호 확인"
                            onChange={handleChange}
                            value={values.confirmPwd}
                            disabled={pwdChg}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        이름<i></i>
                        <span>
                          {errors.userNm && touched.userNm && errors.userNm}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="userNm"
                          type="text"
                          placeholder="이름 입력"
                          onChange={handleChange}
                          value={values.userNm}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        소속구분<i></i>
                        <span>
                          {errors.posCmpnCd &&
                            touched.posCmpnCd &&
                            errors.posCmpnCd}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="POS_DIV"
                          name="posCmpnCd"
                          onChange={(e, item) => {
                            setPosCmpnNm(item ? item.name : "");
                            values.posDeptCd = "";
                            values.posDeptNm = "";
                            values.clposCd = "";
                            values.rspofCd = "";
                            setGrpIdPosDeptCd(item ? item.strVal1 : "");
                            setGrpIdClpos(item ? item.strVal2 : "");
                            setGrpIdRspof(item ? item.strVal3 : "");
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          placeholder="소속구분 선택"
                          value={values.posCmpnCd}
                        />
                        {viewData && viewData.posCmpnNm && (
                          <span className="left">{viewData.posCmpnNm}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        소속<i></i>
                        <span>
                          {errors.posDeptCd &&
                            touched.posDeptCd &&
                            errors.posDeptCd}
                        </span>
                      </th>
                      <td>
                        {values.posCmpnCd === "02" ? (
                          <CacheAutocomplete
                            cache="plcfbInfo"
                            onMap={(item, key) => {
                              return {
                                code: item.plcfbCd,
                                name: item.plcfbName,
                              };
                            }}
                            name="posDeptNm"
                            value={values.posDeptNm}
                            callBack={(item) => {
                              values.posDeptNm = item ? item.name : "";
                              setFieldValue("posDeptCd", item ? item.code : "");
                            }}
                            setFieldValue={setFieldValue}
                            placeholder="소속 선택"
                          />
                        ) : (
                          <CodeSelect
                            codeId={grpIdPosDept}
                            name="posDeptCd"
                            onChange={(e, item) => {
                              values.posDeptNm = item ? item.name : "";
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            placeholder="소속 선택"
                            value={values.posDeptCd}
                          />
                        )}
                        {viewData && viewData.posDeptNm && (
                          <span className="left">{viewData.posDeptNm}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        직급<i></i>
                        <span>
                          {errors.clposCd && touched.clposCd && errors.clposCd}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId={grpIdClpos}
                          name="clposCd"
                          onChange={(e, item) => {
                            setClposNm(item ? item.name : "");
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          placeholder="직급 선택"
                          value={values.clposCd}
                        />
                        {viewData && viewData.clposNm && (
                          <span className="left">{viewData.clposNm}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        직책<i></i>
                        <span>
                          {errors.rspofCd && touched.rspofCd && errors.rspofCd}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId={grpIdRspof}
                          name="rspofCd"
                          onChange={(e, item) => {
                            setRspofNm(item ? item.name : "");
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          placeholder="직책 선택"
                          value={values.rspofCd}
                        />
                        {viewData && viewData.rspofNm && (
                          <span className="left">{viewData.rspofNm}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        권한<i></i>
                        <span>
                          {errors.roleId && touched.roleId && errors.roleId}
                        </span>
                      </th>
                      <td>
                        <CacheSelect
                          cache="roleInfo"
                          onMap={(item, key) => {
                            return {
                              code: item.roleId,
                              name: item.roleNm,
                            };
                          }}
                          name="roleId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="권한 선택"
                          value={values.roleId}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        핸드폰번호<i></i>
                        <span>
                          {errors.athnMphonNo &&
                            touched.athnMphonNo &&
                            errors.athnMphonNo}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="athnMphonNo"
                          placeholder="핸드폰번호 입력"
                          onChange={handleChange}
                          value={values.athnMphonNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        전화번호<i></i>
                        <span>
                          {errors.pponNo && touched.pponNo && errors.pponNo}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="phone"
                          name="pponNo"
                          placeholder="전화번호 입력"
                          onChange={handleChange}
                          value={values.pponNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        이메일<i></i>
                        <span>
                          {errors.email && touched.email && errors.email}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="email"
                          type="text"
                          placeholder="이메일 입력"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>재직상태</th>
                      <td>
                        <Checkbox
                          name="inofficeSttusCd"
                          onChange={handleChange}
                          checked={values.inofficeSttusCd}
                        />{" "}
                        재직중
                      </td>
                    </tr>
                    <tr>
                      <th>사용여부</th>
                      <td>
                        <Checkbox
                          name="delYn"
                          onChange={handleChange}
                          checked={values.delYn}
                        />{" "}
                        사용중
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="PopupButton">
                <Button
                  type="submit"
                  className="insert"
                  disabled={isSubmitting}
                >
                  저장
                </Button>
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

UserInfoEditTable.propTypes = {};

export default UserInfoEditTable;
