import React from "react";
import { CustomLoading } from "components";

const Searching = (props) => {
  const { searchState } = props;
  return (
    <div>
      <CustomLoading layerType={searchState.displayFlg ? "loading" : ""}>
        {searchState.msg ? searchState.msg : "조회중입니다."}
      </CustomLoading>
    </div>
  );
};

Searching.propTypes = {};

export default Searching;
