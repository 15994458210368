import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./AcntDpsTxnUpload.css";
import {
  withBase,
  Button,
  LayerPopup,
  PopupWrapper,
  CustomMessage,
  LinearProgressWithLabel,
} from "components";
import FileUploadIcon from "assets/images/icon_file_upload.png";
import { useDropzone } from "react-dropzone";
import AcntDpsTxnUploadList from "./AcntDpsTxnUploadList";
import { properties } from "variables/properties";
import axios from "axios";
import { useSelector } from "react-redux";

const AcntDpsTxnUpload = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [layerType, setLayerType] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [step, setStep] = useState(1);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: ({}, ".xlsx"),
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 314572800,
    onDrop: (acceptedFiles, rejectedFiles) => {
      console.log(acceptedFiles, rejectedFiles);
      if (acceptedFiles && acceptedFiles.length > 0) {
        setFiles([...acceptedFiles]);
        setStep(2);
      }
    },
  });

  useEffect(() => {
    if (files.length === 0) setStep(1);
  }, [files]);

  const handleClickClose = () => {
    setLayerType("");
  };

  const handleClickFileUpload = async () => {
    props.searching(true, "파일업로드 및 입금리스트 반영중입니다.");
    setIsUploading(true);
    await handleFileUpload();
    setIsUploading(false);
    props.searching(false);
  };

  const handleClickRemoveFile = (file) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFiles([...acceptedFiles]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("name", "파일업로드");
    formData.append("fileTypeCd", "FT005");

    files.forEach((file) => {
      formData.append("files", file);
    });

    let url = "api/v1/comn/file/upload";
    if (properties && properties.contextRoot) {
      url = `${properties.contextRoot}/${url}`;
    }

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded} kb of ${total} kb | ${percent}%`);

        if (percent < 100) {
          setUploadPercent(percent);
        }
      },
    };

    await axios
      .post(url, formData, options)
      .then((res) => {
        setUploadPercent(100);
        setTimeout(() => {
          setUploadPercent(0);
        }, 1000);
        if (res instanceof Error) {
          console.log(res.response);
          if (res.response.status === 409) {
            let data = res.response.data;
            if (data.message) {
              setMessage(data.message);
            } else {
              let indexS = data.indexOf("<body>");
              let indexE = data.indexOf("</body>");

              if (indexS > 0 && indexE > 0) {
                setMessage(data.slice(indexS + 6, indexE));
              } else {
                setMessage(
                  <>
                    파일 업로드를 실패 하였습니다.
                    <br />
                    다시 업로드 해주세요.
                  </>,
                );
              }
            }
          } else {
            setMessage(
              <>
                파일 업로드를 실패 하였습니다.
                <br />
                다시 업로드 해주세요.
              </>,
            );
          }
          setLayerType("message");
        } else {
          console.log(res);
          setStep(3);
          setMessage(<>파일 업로드 및 입금리스트 반영이 완료 됐습니다.</>);
          setLayerType("message");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setMessage("파일 업로드 예외 발생했습니다.");
        setLayerType("message");
      });
  };

  return (
    <div className="AcntDpsTxnUpload">
      <div className="UploadArea">
        <div
          className={
            step === 1
              ? "drop"
              : step === 2
              ? "drop SecondStep"
              : "drop ThirdStep"
          }
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <img src={FileUploadIcon} className="icon" alt="fileUpload" />
          <Button className="add_file" onClick={open}>
            파일첨부
          </Button>
          {step === 1 ? (
            <h4>
              <span>파일첨부</span> 버튼을 눌러 파일을 첨부해 주세요.(파일은
              1개만 올라갑니다.)
              <br />
              <br />
              파일 업로드가 완료되면 바로 <span>입금리스트에 반영</span>
              됩니다.
            </h4>
          ) : step === 2 ? (
            cretFlag && (
              <Button
                className="fee_file_upload"
                onClick={handleClickFileUpload}
                disabled={isUploading}
              >
                파일 업로드
              </Button>
            )
          ) : (
            <>
              <h4>파일 업로드 및 입금리스트 반영이 완료 됐습니다.</h4>
            </>
          )}
        </div>
        {step === 2 ? (
          <AcntDpsTxnUploadList
            files={files}
            onClickRemoveFile={handleClickRemoveFile}
          />
        ) : null}
        {uploadPercent > 0 && <LinearProgressWithLabel value={uploadPercent} />}
      </div>
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={""}
          message={
            <CustomMessage onClose={handleClickClose}>{message}</CustomMessage>
          }
        />
      </LayerPopup>
    </div>
  );
};

AcntDpsTxnUpload.propTypes = {};

export default withBase(AcntDpsTxnUpload);
