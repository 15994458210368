import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Pagination.css";
import Prev from "./Prev";
import Next from "./Next";
import _ from "lodash";

const Pagination = (props) => {
  const { page, total, row, pageCount, fetchData } = props;
  const [pages, setPages] = useState([]);
  const [visiblePrev, setVisiblePrev] = useState(false);
  const [visibleNext, setVisibleNext] = useState(false);

  useEffect(() => {
    buildPages(page, total, row, pageCount);
  }, [page, total, row, pageCount]);

  const buildPages = (pagination) => {
    if (total <= 0) return;
    const start = calcStart(page, pageCount);
    const [end, last] = calcEnd(start, row, total, pageCount);
    const pages = _.range(start, end + 1).map((children, key) => {
      return {
        children,
        onClick: handleClick,
        active: children === page,
      };
    });
    if (start > pageCount) {
      setVisiblePrev(true);
    } else {
      setVisiblePrev(false);
    }
    if (end < last) {
      setVisibleNext(true);
    } else {
      setVisibleNext(false);
    }
    setPages(pages);
  };

  const calcStart = (page, pageCount) => {
    return pageCount * Math.floor((page - 1) / pageCount) + 1;
  };

  const calcEnd = (start, row, total, pageCount) => {
    let end = start + (pageCount - 1);
    const last = Math.ceil(total / row);
    if (end > last) end = last;
    return [end, last];
  };

  const handleClick = (e, page) => {
    fetchData(page);
  };

  const handlePrev = (e) => {
    fetchData(calcStart(page, pageCount) - 1);
  };

  const handleNext = (e) => {
    const [end] = calcEnd(calcStart(page, pageCount), row, total, pageCount);
    fetchData(end + 1);
  };

  return (
    <div className="paging">
      <ul>
        <Prev onClick={handlePrev} visible={visiblePrev} />
        {pages.map((item, key) => {
          return (
            <li key={key} className={page === item.children ? "on" : null}>
              <span onClick={(e) => item.onClick(e, item.children)}>
                {item.children}
              </span>
            </li>
          );
        })}
        <Next onClick={handleNext} visible={visibleNext} />
      </ul>
    </div>
  );
};

Pagination.propTypes = {};

export default Pagination;
