import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  CustomInput,
  FormatInput,
  Button,
  CodeSelect,
  CheckBox as Checkbox,
} from "components";

const CorpInfoEditTable = (props) => {
  const { layerType, viewData, onClose, onSave, formatter } = props;

  return (
    <>
      <Formik
        initialValues={{
          corpCd: viewData ? viewData.corpCd : "",
          corpDiv: viewData ? viewData.corpDiv : "",
          corpName: viewData ? viewData.corpName : "",
          bizno: viewData ? formatter(viewData.bizno, "bizno") : "",
          acntName: viewData ? viewData.acntName : "",
          acntBankCd: viewData ? viewData.acntBankCd : "",
          acntNo: viewData ? formatter(viewData.acntNo, "account") : "",
          firstBlnc: viewData ? formatter(viewData.firstBlnc, "amount") : "",
          ladvhFlag: viewData
            ? viewData.ladvhFlag === 1
              ? true
              : false
            : true,
          mktYn: viewData ? (viewData.mktYn === "Y" ? true : false) : true,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const {
            bizno,
            acntNo,
            firstBlnc,
            ladvhFlag,
            mktYn,
            ...rest
          } = values;
          onSave(layerType, {
            ...rest,
            bizno: formatter(bizno, "toValue"),
            acntNo: formatter(acntNo, "toValue"),
            firstBlnc: formatter(firstBlnc, "toValue"),
            ladvhFlag: ladvhFlag ? 1 : 0,
            mktYn: mktYn ? "Y" : "N",
          });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          corpDiv: Yup.string().required(),
          corpName: Yup.string().required().max(20),
          bizno: Yup.string().required(),
          acntName: Yup.string().required().max(20),
          acntBankCd: Yup.string().required(),
          acntNo: Yup.string().required(),
          firstBlnc: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form>
              <h5>필수 입력</h5>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="200" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        법인구분<i></i>
                        <span>
                          {errors.corpDiv && touched.corpDiv && errors.corpDiv}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="CORP_DIV"
                          name="corpDiv"
                          onChange={handleChange}
                          onBlur={handleChange}
                          placeholder={"법인구분 선택"}
                          value={values.corpDiv}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        법인명<i></i>
                        <span>
                          {errors.corpName &&
                            touched.corpName &&
                            errors.corpName}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="corpName"
                          type="text"
                          placeholder="법인명 입력"
                          onChange={handleChange}
                          value={values.corpName}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        사업자번호<i></i>
                        <span>
                          {errors.bizno && touched.bizno && errors.bizno}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="bizno"
                          name="bizno"
                          placeholder="사업자번호 입력"
                          onChange={handleChange}
                          value={values.bizno}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        예금주<i></i>
                        <span>
                          {errors.acntName &&
                            touched.acntName &&
                            errors.acntName}
                        </span>
                      </th>
                      <td>
                        <CustomInput
                          name="acntName"
                          type="text"
                          placeholder="계좌명 입력"
                          onChange={handleChange}
                          value={values.acntName}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        계좌번호<i></i>
                        <span>
                          {errors.acntBankCd && touched.acntBankCd
                            ? errors.acntBankCd
                            : errors.acntNo && touched.acntNo
                            ? errors.acntNo
                            : ""}
                        </span>
                      </th>
                      <td>
                        <CodeSelect
                          codeId="ACNT_BANK_CD"
                          name="acntBankCd"
                          onChange={handleChange}
                          onBlur={handleChange}
                          placeholder={"은행 선택"}
                          value={values.acntBankCd}
                        />{" "}
                        <FormatInput
                          formatType="account"
                          name="acntNo"
                          placeholder="계좌번호 입력"
                          onChange={handleChange}
                          value={values.acntNo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        기초잔액<i></i>
                        <span>
                          {errors.firstBlnc &&
                            touched.firstBlnc &&
                            errors.firstBlnc}
                        </span>
                      </th>
                      <td>
                        <FormatInput
                          formatType="amount"
                          name="firstBlnc"
                          placeholder="기초잔액 입력"
                          onChange={handleChange}
                          value={values.firstBlnc}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>지입차량여부</th>
                      <td>
                        <Checkbox
                          name="ladvhFlag"
                          onChange={handleChange}
                          checked={values.ladvhFlag}
                        />{" "}
                        지입차량
                      </td>
                    </tr>
                    <tr>
                      <th>영업여부</th>
                      <td>
                        <Checkbox
                          name="mktYn"
                          onChange={handleChange}
                          checked={values.mktYn}
                        />{" "}
                        영업중
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="PopupButton">
                <Button
                  type="submit"
                  className="insert"
                  disabled={isSubmitting}
                >
                  저장
                </Button>
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

CorpInfoEditTable.propTypes = {};

export default CorpInfoEditTable;
