import React, { useState } from "react";
import PropTypes from "prop-types";
import "./FeeInfo.css";
import { CustomTab } from "components";
import Tab1 from "./FeeInfoKD";
import Tab2 from "./FeeInfoHD";

const FeeInfo = (props) => {
  const [tabs, setTabs] = useState([
    { name: "경동", path: "./FeeInfoKD", component: <Tab1 /> },
    { name: "합동", path: "./FeeInfoHD", component: <Tab2 /> },
  ]);

  return (
    <div className="FeeInfo">
      <CustomTab tabs={tabs} />
    </div>
  );
};

FeeInfo.propTypes = {};

export default FeeInfo;
