import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./TrmFee.css";
import { withBase, Button, ControlBox } from "components";
import moment from "moment";
import TrmFeeConditionBox from "./TrmFeeConditionBox";
import TrmFeeTable from "./TrmFeeTable";

const API = {
  LIST: {
    url: "api/v1/report/trmFee/list",
    method: "post",
  },
  DOWN: {
    url: "api/v1/report/trmFee/down",
    method: "post",
  },
};

const TrmFee = (props) => {
  const [param, setParam] = useState({
    setlDate: moment().add(-1, "days").format("YYYYMM"),
  });
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState([]);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setTotalData([]);
      searching(false);
      return;
    }

    if (!response.trmSumSheetList || response.trmSumSheetList.length === 0) {
      setDatas([]);
      setTotalData([]);
    } else {
      setDatas(response.trmSumSheetList.slice(0, -2));
      setTotalData(response.trmSumSheetList.slice(-2));
    }
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam(condition);
  };

  const handleClickDown = async () => {
    const { url } = API.DOWN;

    await props.downExcel.request(url, param);
  };
  return (
    <div className="TrmFee">
      <TrmFeeConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      <TrmFeeTable
        datas={datas}
        totalData={totalData}
        formatter={props.formatter}
      />
    </div>
  );
};

TrmFee.propTypes = {};

export default withBase(TrmFee);
