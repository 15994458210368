import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./SetlTxnStats.css";
import {
  withBase,
  ControlBox,
  Button,
  Pagination,
  LayerPopup,
  PopupWrapper,
  CustomLoading,
  CustomMessage,
} from "components";
import moment from "moment";
import SetlTxnStatsConditionBox from "./SetlTxnStatsConditionBox";
import SetlTxnStatsTable from "./SetlTxnStatsTable";
import axios from "axios";
import { properties } from "variables/properties";

const API = {
  LIST: {
    url: "api/v1/calc/setlTxnStats/list",
    method: "post",
  },
  DOWN: {
    url: "api/v1/calc/setlTxnStats/downjufile",
    method: "post",
  },
};

const SetlTxnStats = (props) => {
  const [param, setParam] = useState();
  const [total, setTotal] = useState(0);
  const [datas, setDatas] = useState([]);
  const [layerType, setLayerType] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (param) list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.setlTxnList);
    searching(false);
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...condition,
      pageNo: 1,
    });
  };

  const handleClickDown = async () => {
    if (!param) {
      props.toast.error("조회후 사용가능합니다.");
      return;
    }

    setLayerType("loading");
    let { url } = API.DOWN;
    let downParam = { cretDate: param.cretDate };

    if (properties && properties.contextRoot) {
      url = `${properties.contextRoot}/${url}`;
    }

    await axios
      .post(url, downParam, { responseType: "arraybuffer" })
      .then((res) => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: "application/octet-stream" }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/octet-stream" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        props.toast.error("DBF 다운로드 실패");
        console.log(error.response);
      });
    setLayerType("");
  };
  return (
    <div className="SetlTxnStats">
      <SetlTxnStatsConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        <Button className="dbf_down" onClick={handleClickDown}>
          DBF 다운
        </Button>
      </ControlBox>
      <SetlTxnStatsTable
        widths={[
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "2.2%",
          "1.5%",
          "1.5%",
          //"1.5%",
          "*",
        ]}
        columns={[
          "발송일",
          "원천구분",
          "no1s",
          "temi",
          "junsan2",
          "spa1",
          "spa5",
          "spa6",
          "spa7",
          "spa3",
          "spa4(no63s)",
          "no16s",
          "charang",
          "yunno",
          "yunno2",
          "yunke",
          "junsan",
          "susu",
          "chulgo",
          "susuy",
          "susuc",
          "chuly",
          "chulc",
          "testsu",
          "발송연계차",
          "발송법인",
          "도착연계차",
          "도착법인",
          "no19s",
          "no13s",
          "carno1",
          "노선1구분",
          "no21s",
          "no22s",
          "no23s",
          "지원susu1",
          "t1_gubun",
          "t2_gubun",
          "no0s",
          "sbr_zone",
          "rcode",
          "innib",
          "sbr_lgubun",
          "no2s",
          "ebr_zone",
          "gubun",
          "innid",
          "ebr_lgubun",
          "sbr_rank",
          "ebr_rank",
          //"per"
        ]}
        datas={
          datas &&
          datas.map((item, key) => {
            return (
                <>
                    <td>{item.cretDate}</td>
                    <td>{item.srcfileName}</td>
                    <td>{item.no1s}</td>
                    <td>{item.temi}</td>
                    <td>{item.junsan2}</td>
                    <td>{item.spa1}</td>
                    <td>{item.spa5}</td>
                    <td>{item.spa6}</td>
                    <td>{item.spa7}</td>
                    <td>{item.spa3}</td>
                    <td>{item.spa4}</td>
                    <td>{item.no16s}</td>
                    <td>{item.charang}</td>
                    <td>{item.yunno}</td>
                    <td>{item.yunno2}</td>
                    <td>{item.yunke}</td>
                    <td>{item.junsan}</td>
                    <td>{item.susu}</td>
                    <td>{item.chulgo}</td>
                    <td>{item.susuy}</td>
                    <td>{item.susuc}</td>
                    <td>{item.chuly}</td>
                    <td>{item.chulc}</td>
                    <td>{item.testsu}</td>
                    <td>{item.dsp_lnk_car}</td>
                    <td>{item.dsp_corp}</td>
                    <td>{item.arvl_lnk_car}</td>
                    <td>{item.arvl_corp}</td>
                    <td>{item.no19s}</td>
                    <td>{item.no13s}</td>
                    <td>{item.carno1}</td>
                    <td>{item.route_div1}</td>
                    <td>{item.no21s}</td>
                    <td>{item.no22s}</td>
                    <td>{item.no23s}</td>
                    <td>{item.suprt_fee1}</td>
                    <td>{item.t1Gubun}</td>
                    <td>{item.t2Gubun}</td>
                    <td>{item.no0s}</td>
                    <td>{item.sbrZone}</td>
                    <td>{item.rcode}</td>
                    <td>{item.innib}</td>
                    <td>{item.sbrLgubun}</td>
                    <td>{item.no2s}</td>
                    <td>{item.ebrZone}</td>
                    <td>{item.gubun}</td>
                    <td>{item.innid}</td>
                    <td>{item.ebrLgubun}</td>
                    <td>{item.sbrRank}</td>
                    <td>{item.ebrRank}</td>
                    {/* <td>{item.per}</td> */}
                </>
            );
          })
        }
      />
      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}

      <PopupWrapper
        layerType={layerType}
        title={""}
        loading={
          <CustomLoading layerType={layerType}>DBF 다운중입니다.</CustomLoading>
        }
      />
    </div>
  );
};

SetlTxnStats.propTypes = {};

export default withBase(SetlTxnStats);
