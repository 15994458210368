import React, { useState, useEffect, useRef } from "react";
import { LayerPopup, PopupWrapper } from "components";
import PrevBtn from "assets/images/notice_prev.png";
import NextBtn from "assets/images/notice_next.png";
import NotificationViewTable from "views/Board/Notification/NotificationViewTable";

function Notice(props) {
  const { noticeData, formatter } = props;
  const [notiSeq, setNotiSeq] = useState(0);
  const [notiData, setNotiData] = useState({ ntfTitle: "", regDt: "" });
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);
  const [delay, setDelay] = useState(10000);

  useEffect(() => {
    if (noticeData.length < 1) return;

    setNotiData(noticeData[0]);
  }, [noticeData]);

  useEffect(() => {
    if (noticeData.length < 1) return;
    setNotiData(noticeData[notiSeq]);
    setDelay(5000);
  }, [notiSeq]);

  useInterval(() => {
    handleNotiSeq("NEXT");
  }, delay);

  const prevNoti = (e) => {
    e.preventDefault();
    handleNotiSeq("PREV");
  };

  const nextNoti = (e) => {
    e.preventDefault();
    handleNotiSeq("NEXT");
  };

  const handleNotiSeq = (type) => {
    let newSeq = 0;
    switch (type) {
      case "PREV":
        newSeq = notiSeq === 0 ? noticeData.length - 1 : notiSeq - 1;
        break;
      case "NEXT":
        newSeq = notiSeq >= noticeData.length - 1 ? 0 : notiSeq + 1;
        break;
      default:
        break;
    }

    setNotiSeq(newSeq);
  };

  const handleClick = () => {
    setLayerType("dashboard");
    setViewData(notiData);
  };

  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };

  return (
    noticeData &&
    noticeData.length > 0 && (
      <div className="Notice">
        <ul className="pointer" onClick={handleClick}>
          <li className="type">[공지]</li>
          <li className="cnt">{notiData.ntfTitle}</li>
          <li className="writingDate">{formatter(notiData.regDt, "date")}</li>
        </ul>
        <div className="control">
          <a href="#" className="noticePrev" onClick={prevNoti}>
            <img src={PrevBtn} />
          </a>
          <a href="#" className="noticeNext" onClick={nextNoti}>
            <img src={NextBtn} />
          </a>
        </div>
        <LayerPopup layerType={layerType} onClose={handleClickClose}>
          <PopupWrapper
            layerType={layerType}
            title={"공지사항"}
            viewTable={
              <NotificationViewTable
                layerType={layerType}
                viewData={viewData}
                onClose={handleClickClose}
                formatter={props.formatter}
              />
            }
          />
        </LayerPopup>
      </div>
    )
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default Notice;
