import React from "react";
import PropTypes from "prop-types";
import "./TrmFeeTable.css";
import IconTotalPlus from "assets/images/icon_plus.png";

const TrmFeeTable = (props) => {
  const { datas, totalData, formatter } = props;
  return (
    <div className="TrmFeeTable">
      <article className="grid_data">
        <div className="thead">
          <table>
            <colgroup>
              {[
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              <tr>
                {[
                  "일자",
                  "구분",
                  "고촌",
                  "군포",
                  "칠곡",
                  "장성",
                  "양산",
                  "화성",
                  "인천",
                  "평택",
                  "합계",
                ].map((i, key) => {
                  return <th key={key}>{i}</th>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="holdTable">
          <div className="tbody HasTH">
            <table>
              <colgroup>
                {[
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "9%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={9}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas &&
                  datas.map((item, key) => {
                    return (
                      <>
                        <tr key={key}>
                          <th>{formatter(item.setlDate, "date")}</th>
                          <td>{item.feeDiv}</td>
                          <td className="amount">
                            {formatter(item.koChon, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.kunPoo, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.chilKok, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.jangSung, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.yangSan, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.hwaSung, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.inCheon, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.pyeongTaek, "amount")}
                          </td>
                          <td className="amount">
                            {formatter(item.sumFee, "amount")}
                          </td>
                        </tr>
                      </>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>  
        <div className="tfoot">
          <table>
            <colgroup>
              {[
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "9%",
                "*",
              ].map((i, key) => {
                return <col width={i} key={key} />;
              })}
            </colgroup>
            <tbody>
              {!totalData || totalData.length === 0 ? (
                <></>
              ) : (
                totalData.map((item, key) => {
                  return (
                    <tr key={key}>
                      {item.feeDiv === "정상수수료" ? (
                        <th rowSpan="2">
                          <img src={IconTotalPlus} alt="" />
                          {item.setlDate}
                        </th>
                      ) : (
                        <></>
                      )}
                      <td>{item.feeDiv}</td>
                      <td className="amount">
                        {formatter(item.koChon, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.kunPoo, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.chilKok, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.jangSung, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.yangSan, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.hwaSung, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.inCheon, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.pyeongTaek, "amount")}
                      </td>
                      <td className="amount">
                        {formatter(item.sumFee, "amount")}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </article>
    </div>
  );
};

TrmFeeTable.propTypes = {};

export default TrmFeeTable;
