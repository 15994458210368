import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./FeeInfoSpecial.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  withBase,
  Button,
  ControlBox,
  CustomInput,
  FormatInput,
} from "components";
import { useSelector } from "react-redux";

const API = {
  LIST: {
    url: "api/v1/comn/feeInfo/readSpc",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/comn/feeInfo/updateSpc",
    method: "post",
  },
  CONDLIST: {
    url: "api/v1/comn/feeInfo/readCond",
    method: "post",
  },
  CONDCREATE: {
    url: "api/v1/comn/feeInfo/createCond",
    method: "post",
  },
  CONDUPDATE: {
    url: "api/v1/comn/feeInfo/updateCond",
    method: "post",
  },
};

const FeeInfoSpecial = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const { phydsCorpDiv, feeCd } = props.match.params;
  const [param, setParam] = useState({
    phydsCorpDiv: phydsCorpDiv,
    feeCd: feeCd,
  });
  const [datas, setDatas] = useState([]);
  const [condDatas, setCondDatas] = useState([]);
  const [saveIndex, setSaveIndex] = useState([]);
  const [condValue, setCondValue] = useState();
  const [condTitle, setCondTitle] = useState();
  const [condAmdPosblYn, setAmdPosblYn] = useState("N"); // 추가 조건 입력 가능 여부
  const [twoFeeRate, setTwoFeeRate] = useState("1"); // 수수료 입력칸 개수 - 1개 : 0, 2개 : 1
  const [hapCheck, setHapCheck] = useState("1"); // 합체크 여부 - pass시 : 0,  체크시 : 1

  const titleArr = [
    ["물류법인", "수수료구분", "순번", "수수료항목", "수수료", "조건"],
    [
      "물류법인",
      "수수료구분",
      "순번",
      "수수료항목",
      "택배수수료율",
      "화물수수료율",
      "조건",
    ],
  ];
  const colArr = [
    ["10%", "26%", "7%", "11%", "14%", "*"],
    ["10%", "26%", "7%", "11%", "14%", "14%", "*"],
  ];

  useEffect(() => {
    list();
    condList();
  }, []);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;

    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setSaveIndex([]);
      searching(false);
      return;
    }
    setDatas(response.spcFeeInfoList);
    setSaveIndex([]);

    if (!response.spcFeeInfoList || response.spcFeeInfoList.length === 0) {
      setAmdPosblYn("N");
      setTwoFeeRate("1");
      setHapCheck("1");
    } else {
      setAmdPosblYn(response.spcFeeInfoList[0].condAmdPosblYn);
      setTwoFeeRate(response.spcFeeInfoList[0].twoFeeRate);
      setHapCheck(response.spcFeeInfoList[0].hapCheck);
    }

    searching(false);
  };

  const condList = async () => {
    const { HttpRequest } = props;
    const { url, method } = API.CONDLIST;

    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setCondDatas([]);
      return;
    }
    setCondDatas(response.spcFeeCondInfoList);

    !response.spcFeeCondInfoList || response.spcFeeCondInfoList.length === 0
      ? setCondTitle("")
      : setCondTitle(response.spcFeeCondInfoList[0].remarks);
  };

  const handleChange = (index, rowNum, value) => {
    setDatas(
      datas.map((item) =>
        item.rowNum === rowNum ? { ...item, ...value } : item
      )
    );

    setSaveIndex([...saveIndex, index]);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    return true;
  };

  const validCheckData = () => {
    let ret = true;

    if (hapCheck === "0") return ret;

    let homdlvFeeRate = 0;
    let frgtFeeRate = 0;
    let seq = 0;

    for (let i = 0; i < datas.length; i++) {
      let arr = datas[i];

      if (arr.amdPosblYn === "Y") {
        if (seq === arr.seq) {
          homdlvFeeRate = homdlvFeeRate + Number(arr.homdlvFeeRate);
          if (twoFeeRate === "1")
            frgtFeeRate = frgtFeeRate + Number(arr.frgtFeeRate);
        } else {
          seq = arr.seq;
          homdlvFeeRate = Number(arr.homdlvFeeRate);
          if (twoFeeRate === "1") frgtFeeRate = Number(arr.frgtFeeRate);
        }
      }

      if (homdlvFeeRate > 1 || frgtFeeRate > 1) {
        ret = false;
        break;
      }
    }

    if (!ret) {
      props.toast.error(
        "순번 " +
          seq +
          "의 " +
          (twoFeeRate === "1"
            ? homdlvFeeRate > 1
              ? "택배수수료율"
              : "화물수수료율"
            : "수수료") +
          "의 합이 1이 넘습니다."
      );
    }

    return ret;
  };

  const handleClickSave = async (saveType, index) => {
    let ret = false;
    let refresh = false;
    let msg = "";
    let saveData = "";

    switch (saveType) {
      case "update":
        if (validCheckData(saveType)) {
          saveData = datas.filter((item, index) => saveIndex.includes(index));
          ret = await edit(API.UPDATE, { spcFeeInfoList: saveData });
          msg = "수정저장되었습니다.";
        }
        break;
      case "insert":
        saveData = [
          {
            ...param,
            condValue: condValue,
            amdPosblYn: "Y",
            remarks: condTitle,
            useYn: "Y",
          },
        ];
        ret = await edit(API.CONDCREATE, { spcFeeCondInfoList: saveData });
        refresh = true;
        msg = "추가되었습니다.";
        break;
      case "delete":
        if (window.confirm("해당 조건을 삭제 하시겠습니까?")) {
          saveData = [{ ...condDatas[index], useYn: "N" }];
          ret = await edit(API.CONDUPDATE, { spcFeeCondInfoList: saveData });
          refresh = true;
          msg = "삭제되었습니다.";
        }
        break;
      default:
        break;
    }

    if (ret) {
      if (refresh) condList(); list();
      if (msg) props.toast.error(msg);
    }
  };

  const replaceStr = (str) => {
    // ie에서 replaceAll 오류로 for문으로 작성
    const replaceArr = ["&gt;", "&lt;", "&amp;", "&quot;"];
    const changeArr = [">", "<", "&", '"'];

    if (str) {
      for (let i = 0; replaceArr.length > i; i++) {
        for (let j = 0; str.length > j; j++) {
          if (str.indexOf(replaceArr[i]) === -1) break;
          str = str.replace(replaceArr[i], changeArr[i]);
        }
      }
    }

    return str;
  };

  return (
    <div className="FeeInfoSpecial">
      <Formik
        initialValues={{
          condValue: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleClickSave("insert");
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          condValue: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form>
              <ControlBox
                InputTitle={
                  condAmdPosblYn === "Y" &&
                  cretFlag && (
                    <h6>
                      <>
                        {condTitle} 추가
                        <i></i>
                        <span>
                          {errors.condValue &&
                            touched.condValue &&
                            errors.condValue}
                        </span>
                      </>
                    </h6>
                  )
                }
                left={
                  condAmdPosblYn === "Y" &&
                  cretFlag && (
                    <CustomInput
                      name="condValue"
                      type="text"
                      placeholder="입력"
                      onChange={(e) => {
                        setCondValue(e.target.value);
                        handleChange(e);
                      }}
                      value={values.condValue}
                    />
                  )
                }
                leftbtn={
                  condAmdPosblYn === "Y" &&
                  cretFlag && (
                    <Button
                      type="submit"
                      className="add"
                      disabled={isSubmitting}
                    >
                      추가
                    </Button>
                  )
                }
              >
                {cretFlag && (
                  <Button
                    className="save"
                    onClick={() => handleClickSave("update")}
                  >
                    저장
                  </Button>
                )}
                <Link to="/comn/feeInfo">
                  <Button className="prev">이전화면</Button>
                </Link>
              </ControlBox>
            </Form>
          );
        }}
      </Formik>
      <div className="left_tb">
        <article className="grid_data">
          <div className="thead">
            <table>
              <colgroup>
                {["100%"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {[condTitle ? condTitle : "조건"].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody">
            <table>
              <colgroup>
                {["50%", "50%"].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!condDatas || condDatas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={2}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  condDatas.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td
                          className="cnt"
                          colSpan={item.amdPosblYn === "Y" ? 1 : 2}
                        >
                          {item.condValue}
                        </td>
                        {item.amdPosblYn === "Y" && cretFlag ? (
                          <td className="btn">
                            <Button
                              className="delete"
                              onClick={() => handleClickSave("delete", key)}
                            >
                              삭제
                            </Button>
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </article>
      </div>
      <div className="right_tb">
        <article className="grid_data">
          <div className="thead">
            <table>
              <colgroup>
                {colArr[twoFeeRate].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {titleArr[twoFeeRate].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody">
            <table>
              <colgroup>
                {colArr[twoFeeRate].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={colArr[twoFeeRate].length}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.phydsCorpName}</td>
                        <td>{item.feeCdName}</td>
                        <td>{item.rowNum}</td>
                        <td>{item.feeName}</td>
                        <td>
                          {item.amdPosblYn === "Y" && cretFlag ? (
                            <FormatInput
                              formatType="fee"
                              name="homdlvFeeRate"
                              onChange={(e) => {
                                handleChange(key, item.rowNum, {
                                  homdlvFeeRate: e.target.value,
                                });
                              }}
                              value={item.homdlvFeeRate}
                            />
                          ) : (
                            item.homdlvFeeRate
                          )}
                        </td>
                        {twoFeeRate === "1" && (
                          <td>
                            {item.amdPosblYn === "Y" && cretFlag ? (
                              <FormatInput
                                formatType="fee"
                                name="frgtFeeRate"
                                onChange={(e) =>
                                  handleChange(key, item.rowNum, {
                                    frgtFeeRate: e.target.value,
                                  })
                                }
                                value={item.frgtFeeRate}
                              />
                            ) : (
                              item.frgtFeeRate
                            )}
                          </td>
                        )}
                        <td className="tal">
                          {item.no23sMod === "0" ? (
                            replaceStr(item.seqText)
                          ) : (
                            <CustomInput
                              name="seqText"
                              type="text"
                              placeholder="입력"
                              onChange={(e) => {
                                handleChange(key, item.rowNum, {
                                  seqText: e.target.value,
                                });
                              }}
                              value={replaceStr(item.seqText)}
                              maxLength={200}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </article>
      </div>
    </div>
  );
};

FeeInfoSpecial.propTypes = {};

export default withBase(FeeInfoSpecial);
