import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DsblTxn.css";
import { CustomTab } from "components";
import DsblTxnList from "./DsblTxnList";
import DsblTxnDetails from "./DsblTxnDetails";

const DsblTxn = (props) => {
  return (
    <div className="DsblTxn">
      <CustomTab
        tabs={[
          {
            name: "정지 및 펌뱅킹불능내역",
            component: <DsblTxnList />,
          },
          {
            name: "상세보기",
            component: <DsblTxnDetails />,
          },
        ]}
      />
    </div>
  );
};

DsblTxn.propTypes = {};

export default DsblTxn;
