import React, { useState } from "react";
import PropTypes from "prop-types";
import "./FtasSetl.css";
import CaculateIcon from "assets/images/icon_calculate.png";
import {
  withBase,
  Button,
  LayerPopup,
  PopupWrapper,
  CustomLoading,
  CustomMessage,
} from "components";

const API = {
  CREATE: {
    url: "api/v1/calc/deliveryTxn/calcFtas",
    method: "post",
  },
};

const FtasSetl = (props) => {
  const [layerType, setLayerType] = useState("");
  const [message, setMessage] = useState("");

  const handleClickFtasClclt = async () => {
    setLayerType("loading");
    const { HttpRequest } = props;
    const { url, method } = API.CREATE;

    const response = await HttpRequest.request(url, method, {});
    if (HttpRequest.hasError(response, true)) {
      setMessage("FTAS 정산중 오류가 발생했습니다.");
      setLayerType("message");
      return;
    }
    console.log(response);
    setMessage("FTAS 정산이 완료되었습니다.");
    setLayerType("message");
  };

  const handleClickClose = () => {
    setLayerType("");
  };

  return (
    <div className="FtasSetl">
      <form className="CalculateArea">
        <div className="drop">
          <img src={CaculateIcon} className="icon" />
          <Button className="ftas_clclt" onClick={handleClickFtasClclt}>
            FTAS 정산
          </Button>
          <h4>
            자료정리가 완료되면 <span>FTAS 프로그램 실행</span> 버튼을
            눌러주세요.
          </h4>
        </div>
      </form>

      <PopupWrapper
        layerType={layerType}
        title={""}
        loading={
          <CustomLoading layerType={layerType}>
            FTAS 정산중입니다.
          </CustomLoading>
        }
      />
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={""}
          message={
            <CustomMessage onClose={handleClickClose}>{message}</CustomMessage>
          }
        />
      </LayerPopup>
    </div>
  );
};

FtasSetl.propTypes = {};

export default withBase(FtasSetl);
