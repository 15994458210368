import { createAction, handleActions } from "redux-actions";

// import { Map, fromJS } from "immutable";
import { fromJS } from "immutable";
// import { pender } from "redux-pender";
// import * as api from "../apis";

// action types
const INITIALIZE = "modal/INITIALIZE";
const OPEN = "modal/OPEN";
const CLOSE = "modal/CLOSE";

// action creators
export const initialize = createAction(INITIALIZE);
export const openModal = createAction(OPEN);
export const closeModal = createAction(CLOSE);

// initial state
const initialState = fromJS({
  classicModal: false,
  classicModalTitle: "",
  classicModalMessage: "",
  classicModalAction: null,
  noticeModal: false,
  noticeModalTitle: "",
  noticeModalMessage: "",
  smallModal: false,
  smallModalTitle: "", // Not used
  smallModalMessage: "",
  smallModalAction: null,
  formModal: false,
  formModalTitle: "",
  formModalMessage: ""
});

// reducer
export default handleActions(
  {
    [INITIALIZE]: () => initialState,
    [OPEN]: (state, action) => {
      const { modal, title, message, modalAction } = action.payload;
      return state
        .set(modal, true)
        .set(modal + "Title", title)
        .set(modal + "Message", message)
        .set(modal + "Action", modalAction);
    },
    [CLOSE]: (state, action) => {
      const { modal } = action.payload;
      return state
        .set(modal, false)
        .set(modal + "Title", "")
        .set(modal + "Message", "")
        .set(modal + "Action", null);
    }
  },
  initialState
);
