import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./UserInfo.css";
import { connect } from "react-redux";
import {
  withBase,
  ControlBox,
  Button,
  Table,
  Pagination,
  LayerPopup,
  PopupWrapper,
} from "components";
import { useSelector } from "react-redux";
import UserInfoConditionBox from "./UserInfoConditionBox";
import UserInfoViewTable from "./UserInfoViewTable";
import UserInfoEditTable from "./UserInfoEditTable";

const API = {
  LIST: {
    url: "api/v1/comn/user/list",
    method: "post",
  },
  READ: {
    url: "api/v1/comn/user/read",
    method: "post",
  },
  CREATE: {
    url: "api/v1/comn/user/create",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/comn/user/update",
    method: "post",
  },
  DELETE: {
    url: "api/v1/comn/user/delete",
    method: "post",
  },
};

const UserInfo = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState({
    pageNo: 1,
  });
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setDatas([]);
      searching(false);
      return;
    }
    setTotal(response.totalRowCnt);
    setDatas(response.userInfoList);
    searching(false);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    setParam({
      ...param,
      pageNo: 1,
    });
    return true;
  };

  const handleClickSearch = (condition) => {
    setParam({
      ...param,
      ...condition,
    });
  };
  const handleDoubleClick = (index) => {
    setLayerType("view");
    setViewData(datas[index]);
  };
  const handleClickNew = () => {
    setLayerType("new");
  };
  const handleClickEdit = () => {
    setLayerType("edit");
  };
  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const handleClickSave = async (layerType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "new":
        ret = await edit(API.CREATE, data);
        msg = "신규등록되었습니다.";
        break;
      case "edit":
        ret = await edit(API.UPDATE, data);
        msg = "수정저장되었습니다.";
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };
  return (
    <div className="UserInfo">
      <UserInfoConditionBox onClickSearch={handleClickSearch} {...props} />
      {cretFlag && (
        <ControlBox>
          <Button className="insert" value="new" onClick={handleClickNew}>
            신규등록
          </Button>
        </ControlBox>
      )}
      <article className="grid_data">
        <div className="TableArea">
          <Table
            widths={[
              "4.8%",
              "10.8%",
              "11%",
              "6.5%",
              "7.5%",
              "4.5%",
              "6%",
              "9%",
              "*",
              "10%",
              "11%",
            ]}
            columns={[
              "구분",
              "업무별 권한",
              "아이디",
              "이름",
              "소속",
              "부서",
              "직급",
              "전화번호",
              "이메일",
              "생성 일시",
              "사용여부",
            ]}
            datas={
              datas &&
              datas.map((item, key) => {
                return (
                  <>
                    <td>{item.rowNum}</td>
                    <td>{item.roleNm}</td>
                    <td>{item.userId}</td>
                    <td>{props.formatter(item.userNm, "name", false)}</td>
                    <td>{item.posCmpnNm}</td>
                    <td className="tal">{item.posDeptNm}</td>
                    <td>{item.clposNm}</td>
                    <td>{props.formatter(item.athnMphonNo, "phone", false)}</td>
                    <td className="tal">{item.email}</td>
                    <td>{props.formatter(item.cretDt, "datetime")}</td>
                    {item.delYn === "Y" ? (
                      <td className="TextColorRed">사용중</td>
                    ) : (
                      <td>미사용</td>
                    )}
                  </>
                );
              })
            }
            onDoubleClick={handleDoubleClick}
          />
        </div>
      </article>

      {datas && datas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              ...param,
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"사용자관리"}
          viewTable={
            <UserInfoViewTable
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onEdit={handleClickEdit}
              formatter={props.formatter}
              cretFlag={cretFlag}
            />
          }
          editTable={
            <UserInfoEditTable
              viewData={viewData}
              layerType={layerType}
              onClose={handleClickClose}
              onSave={handleClickSave}
              formatter={props.formatter}
            />
          }
        />
      </LayerPopup>
    </div>
  );
};

UserInfo.propTypes = {};

export default connect(
  (state) => ({
    code: state.code.get("code"),
  }),
  (dispatch) => ({}),
)(withBase(UserInfo));
