import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ReadyMoneyTxn.css";
import { withBase, Button, ControlBox } from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import ReadyMoneyTxnConditionBox from "./ReadyMoneyTxnConditionBox";
import ReadyMoneyTxnDepositTable from "./ReadyMoneyTxnDepositTable";
import ReadyMoneyTxnWithdrawalTable from "./ReadyMoneyTxnWithdrawalTable";
import ReadyMoneyTxnOtherTable from "./ReadyMoneyTxnOtherTable";

const API = {
  LIST: {
    url: "api/v1/report/readyMoneyTxn/list",
    method: "post",
  },
  CREATE: {
    url: "api/v1/report/readyMoneyTxn/create",
    method: "post",
  },
  DELETE: {
    url: "api/v1/report/readyMoneyTxn/delete",
    method: "post",
  },
  DOWN1: {
    url: "api/v1/report/readyMoneyTxn/downrdymny1",
    method: "post",
  },
  DOWN2: {
    url: "api/v1/report/readyMoneyTxn/downrdymny2",
    method: "post",
  },
  DOWN3: {
    url: "api/v1/report/readyMoneyTxn/downrdymny3",
    method: "post",
  },
};

const ReadyMoneyTxn = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [param, setParam] = useState();
  const [datas, setDatas] = useState([]);
  const [totalData, setTotalData] = useState();
  const [acntNoCd, setAcntNoCd] = useState("INIT");

  const [saveIndex, setSaveIndex] = useState([]);
  const [editFlg, setEditFlg] = useState(false);
  const [addRowSeq, setAddRowSeq] = useState(0);
  const [addRows, setAddRows] = useState([]);

  useEffect(() => {
    if (param) list();
  }, [param]);

  const initState = () => {
    setDatas([]);
    setTotalData("");
    setAcntNoCd(param.acntNo);
    setSaveIndex([]);
    setEditFlg(false);
    setAddRowSeq(0);
    setAddRows([]);
  };

  const list = async () => {
    const { HttpRequest, searching } = props;
    searching(true);
    initState();

    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      searching(false);
      return;
    }

    if (!response.readyMoneyTxnList || response.readyMoneyTxnList.length < 2) {
      setDatas([]);
      setTotalData("");
    } else {
      setDatas(response.readyMoneyTxnList.slice(0, -1));
      setTotalData(response.readyMoneyTxnList.slice(-1));
    }
    searching(false);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    list();
    return true;
  };

  const handleClickSearch = (condition) => {
    setAcntNoCd(condition.acntNo);
    setParam(condition);
  };

  const handleClickDown = async () => {
    if (acntNoCd === "INIT") {
      props.toast.error("조회후 사용가능합니다.");
      return;
    }

    let api;

    if (acntNoCd === "01") {
      api = API.DOWN1;
    } else if (acntNoCd === "02") {
      api = API.DOWN2;
    } else {
      api = API.DOWN3;
    }

    await props.downExcel.request(api.url, param);
  };

  const validCheckData = () => {
    let err = false;
    let arr = [];
    let item;

    for (let i = 0; addRows.length > i; i++) {
      item = Number(addRows[i].rdymnyDate);

      if (
        Number(param.rdymnyDateFrom) > item ||
        Number(param.rdymnyDateTo) < item
      ) {
        err = true;
        break;
      }

      arr.push(item);
    }

    if (datas) {
      for (let i = 0; datas.length > i; i++) {
        item = Number(datas[i].rdymnyDate2);

        if (item) {
          if (
            Number(param.rdymnyDateFrom) > item ||
            Number(param.rdymnyDateTo) < item
          ) {
            err = true;
            break;
          }

          arr.push(item);
        }
      }
    }

    if (err) {
      props.toast.error("조회기간에 해당하는 날자만 입력가능합니다.");
      return false;
    }

    // 날자 중복 체크 제거
    // err = arr.some((item) => {
    //   return arr.indexOf(item) !== arr.lastIndexOf(item);
    // });

    // if (err) {
    //   props.toast.error("중복된 날자가 있습니다.");
    //   return false;
    // }

    return true;
  };

  const handleClickSave = async () => {
    const { formatter } = props;

    let ret = false;
    let msg = "";

    if (validCheckData()) {
      let saveData = [
        ...datas.filter((item, index) => saveIndex.includes(item.rowNum)),
        ...addRows,
      ];

      saveData = saveData.map((item) => {
        return {
          ...item,
          plcfbDpsAmt: formatter(String(item.plcfbDpsAmt), "toValue"),
          indivDpsAmt: formatter(String(item.indivDpsAmt), "toValue"),
          loanAmt: formatter(String(item.loanAmt), "toValue"),
          etcDpsAmt: formatter(String(item.etcDpsAmt), "toValue"),
          acntOtpayAmt: formatter(String(item.acntOtpayAmt), "toValue"),
          indivOtpayAmt: formatter(String(item.indivOtpayAmt), "toValue"),
          etcOtpayAmt: formatter(String(item.etcOtpayAmt), "toValue"),
          rfndAmt: formatter(String(item.rfndAmt), "toValue"),
          acntBlnc: formatter(String(item.acntBlnc), "toValue"),
        };
      });
      ret = await edit(API.CREATE, { readyMoneyTxnList: saveData });
      msg = "저장되었습니다.";
    }

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };

  const handleClickEdit = () => {
    if (param) {
      setEditFlg(true);
    } else {
      props.toast.error("조회후 사용가능합니다.");
    }
  };

  const handleClickAddRow = () => {
    let newRow = {
      rowNum: addRowSeq,
      rdymnyDate: moment().format("YYYYMMDD"),
      acntNo: param.acntNo,
      plcfbDpsAmt: 0,
      indivDpsAmt: 0,
      loanAmt: 0,
      loanAcntSbst: "",
      etcDpsAmt: 0,
      etcDpsAcntSbst: "",
      acntOtpayAmt: 0,
      indivOtpayAmt: 0,
      etcOtpayAmt: 0,
      etcOtpaySbst: "",
      rfndAmt: 0,
      rfndAcntSbst: "",
      acntBlnc: 0,
    };

    setAddRowSeq(addRowSeq + 1);
    setAddRows([...addRows, newRow]);
  };

  const handleClickDelRow = () => {
    setAddRows(addRows.slice(0, -1));
  };

  const handleChange = (type, rowNum) => (e) => {
    let tempRows;
    if (type === "date") {
      tempRows = addRows.map((row) => {
        if (row.rowNum === rowNum) {
          row.rdymnyDate = moment(e).format("YYYYMMDD");
        }
        return row;
      });

      setAddRows(tempRows);
    } else if (type === "addRow") {
      const {
        target: { name, value },
      } = e;

      tempRows = addRows.map((row) => {
        if (row.rowNum === rowNum) {
          row[name] = value;
        }
        return row;
      });

      setAddRows(tempRows);
    } else if (type === "chgDate") {
      tempRows = datas.map((row) => {
        if (row.rowNum === rowNum) {
          row.rdymnyDate2 = moment(e).format("YYYYMMDD");
        }
        return row;
      });

      setDatas(tempRows);

      setSaveIndex([...saveIndex, rowNum]);
    } else {
      const {
        target: { name, value },
      } = e;

      tempRows = datas.map((row) => {
        if (row.rowNum === rowNum) {
          row[name] = value;
        }
        return row;
      });

      setDatas(tempRows);

      setSaveIndex([...saveIndex, rowNum]);
    }
  };

  const determineTable = () => {
    let ret = null;
    switch (acntNoCd) {
      case "INIT":
      case "01":
        ret = (
          <ReadyMoneyTxnDepositTable
            param={param}
            datas={datas}
            totalData={totalData}
            addRows={addRows}
            formatter={props.formatter}
            onChange={handleChange}
            editFlg={editFlg}
          />
        );
        break;
      case "02":
        ret = (
          <ReadyMoneyTxnWithdrawalTable
            param={param}
            datas={datas}
            totalData={totalData}
            addRows={addRows}
            formatter={props.formatter}
            onChange={handleChange}
            editFlg={editFlg}
          />
        );
        break;
      case "03":
      case "04":
      case "05":
        ret = (
          <ReadyMoneyTxnOtherTable
            param={param}
            datas={datas}
            totalData={totalData}
            addRows={addRows}
            formatter={props.formatter}
            onChange={handleChange}
            editFlg={editFlg}
          />
        );
        break;
      default:
        break;
    }
    return ret;
  };

  return (
    <div className="ReadyMoneyTxn">
      <ReadyMoneyTxnConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        {cretFlag ? (
          editFlg ? (
            <>
              <Button className="add" onClick={handleClickAddRow}>
                추가
              </Button>
              <Button className="delete" onClick={handleClickDelRow}>
                삭제
              </Button>
              <Button className="insert" onClick={handleClickSave}>
                저장
              </Button>
            </>
          ) : (
            <>
              <Button className="insert" onClick={handleClickEdit}>
                수정
              </Button>
            </>
          )
        ) : (
          <></>
        )}
        <Button className="excel" onClick={handleClickDown}>
          엑셀 다운로드
        </Button>
      </ControlBox>
      {determineTable()}
    </div>
  );
};

ReadyMoneyTxn.propTypes = {};

export default withBase(ReadyMoneyTxn);
