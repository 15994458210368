import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./FeeInfoBasic.css";
import { withBase, Button, ControlBox, FormatInput } from "components";
import { useSelector } from "react-redux";
import FeeInfoBasicConditionBox from "./FeeInfoBasicConditionBox";

const API = {
  LIST: {
    url: "api/v1/comn/feeInfo/readBasic",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/comn/feeInfo/updateBasic",
    method: "post",
  },
};

const FeeInfoBasic = (props) => {
  const cretFlag = useSelector((state) => state.user.get("cretFlag"));
  const [datas, setDatas] = useState([]);
  const [saveIndex, setSaveIndex] = useState([]);

  const list = async (param) => {
    const { HttpRequest, searching } = props;
    searching(true);

    const { url, method } = API.LIST;

    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setDatas([]);
      setSaveIndex([]);
      searching(false);
      return;
    }
    setDatas(response.basicFeeInfoList);
    setSaveIndex([]);
    searching(false);
  };

  const handleChange = (index, rowNum, value) => {
    setDatas(
      datas.map((item) =>
        item.rowNum === rowNum ? { ...item, ...value } : item
      )
    );

    setSaveIndex([...saveIndex, index]);
  };

  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    console.log(data);
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }

    setSaveIndex([]);
    return true;
  };
  const handleClickSearch = (condition) => {
    list(condition);
  };

  const validCheckData = () => {
    let ret = true;

    let routeName = "";
    let sbrLgubunName = "";
    let ebrLgubunName = "";
    let homdlvFeeRate = 0;
    let frgtFeeRate = 0;

    for (let i = 0; i < datas.length; i++) {
      let arr = datas[i];

      if (arr.amdPosblYn === "Y") {
        if (
          routeName === arr.routeName &&
          sbrLgubunName === arr.sbrLgubunName &&
          ebrLgubunName === arr.ebrLgubunName
        ) {
          homdlvFeeRate = homdlvFeeRate + Number(arr.homdlvFeeRate);
          frgtFeeRate = frgtFeeRate + Number(arr.frgtFeeRate);
        } else {
          routeName = arr.routeName;
          sbrLgubunName = arr.sbrLgubunName;
          ebrLgubunName = arr.ebrLgubunName;
          homdlvFeeRate = Number(arr.homdlvFeeRate);
          frgtFeeRate = Number(arr.frgtFeeRate);
        }
      }

      if (homdlvFeeRate > 1 || frgtFeeRate > 1) {
        ret = false;
        break;
      }
    }
    if (!ret) {
      // props.toast.error(
      alert(
        "노선1구분 : " +
          routeName +
          "\n발송지구역 : " +
          sbrLgubunName +
          "\n도착지구역 : " +
          ebrLgubunName +
          (homdlvFeeRate > 1 ? "\n택배" : "\n화물") +
          "수수료율의 합이 1이 넘습니다."
      );
    }

    return ret;
  };

  const handleClickSave = async () => {
    let ret = false;
    let msg = "";

    let saveData = datas.filter((item, index) => saveIndex.includes(index));

    if (validCheckData()) {
      ret = await edit(API.UPDATE, { basicFeeInfoList: saveData });
      msg = "수정저장되었습니다.";
    }

    if (ret) {
      if (msg) props.toast.error(msg);
    }
  };
  return (
    <div className="FeeInfoBasic">
      <FeeInfoBasicConditionBox onClickSearch={handleClickSearch} {...props} />
      <ControlBox>
        {cretFlag && (
          <Button className="save" onClick={handleClickSave}>
            저장
          </Button>
        )}
        <Link to="/comn/feeInfo">
          <Button className="prev">이전화면</Button>
        </Link>
      </ControlBox>
      <article className="grid_data">
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {[
                  "8.4%",
                  "8.5%",
                  "8.4%",
                  "8.4%",
                  "8.4%",
                  "13.9%",
                  "14%",
                  "6.1%",
                  "6.1%",
                  "6.1%",
                  "6.1%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  {[
                    "물류법인",
                    "노선1구분",
                    "발송지구역",
                    "도착지구역",
                    "수수료항목",
                    "택배수수료율",
                    "화물수수료율",
                    "no43s",
                    "no22s",
                    "no23s",
                    "carno2",
                    "carno3",
                  ].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody NoreSize HasRowsTH">
            <table>
              <colgroup>
                {[
                  "8.4%",
                  "8.5%",
                  "8.4%",
                  "8.4%",
                  "8.4%",
                  "13.9%",
                  "14%",
                  "6.1%",
                  "6.1%",
                  "6.1%",
                  "6.1%",
                  "*",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!datas || datas.length === 0 ? (
                  <tr>
                    <td className="NoData" colSpan={12}>
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                ) : (
                  datas.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.phydsCorpName}</td>
                        <td>{item.routeName}</td>
                        <td>{item.sbrLgubunName}</td>
                        <td>{item.ebrLgubunName}</td>
                        <td>{item.feeName}</td>
                        <td>
                          {item.amdPosblYn === "Y" && cretFlag ? (
                            <FormatInput
                              formatType="fee"
                              name="homdlvFeeRate"
                              placeholder="택배수수료율"
                              onChange={(e) => {
                                handleChange(key, item.rowNum, {
                                  homdlvFeeRate: e.target.value,
                                });
                              }}
                              value={item.homdlvFeeRate}
                            />
                          ) : (
                            item.homdlvFeeRate
                          )}
                        </td>
                        <td>
                          {item.amdPosblYn === "Y" && cretFlag ? (
                            <FormatInput
                              formatType="fee"
                              name="frgtFeeRate"
                              placeholder="화물수수료율"
                              onChange={(e) =>
                                handleChange(key, item.rowNum, {
                                  frgtFeeRate: e.target.value,
                                })
                              }
                              value={item.frgtFeeRate}
                            />
                          ) : (
                            item.frgtFeeRate
                          )}
                        </td>
                        <td>{item.no43s}</td>
                        <td>{item.no22s}</td>
                        <td>{item.no23s}</td>
                        <td>{item.carno2}</td>
                        <td>{item.carno3}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  );
};

FeeInfoBasic.propTypes = {};

export default withBase(FeeInfoBasic);
