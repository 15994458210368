import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withBase, LayerPopup, PopupWrapper, Pagination } from "components";
import SetlTxnPopupBasicFee from "./SetlTxnPopupBasicFee";
import moment from "moment";

const API = {
  LIST: {
    url: "api/v1/calc/deliveryTxn/listVerifyFare",
    method: "post",
  },
  READ: {
    url: "api/v1/calc/deliveryTxn/readTmp",
    method: "post",
  },
  UPDATE: {
    url: "api/v1/calc/deliveryTxn/updateTmp",
    method: "post",
  },
};

const SetlTxnCorrectionContentFeeTable = (props) => {
  const [param, setParam] = useState({ pageNo: 1 });
  const [feeDatas, setFeeDatas] = useState(props.feeDatas);
  const [total, setTotal] = useState(props.total);
  const [layerType, setLayerType] = useState("");
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    list();
  }, [param]);

  const list = async () => {
    const { HttpRequest } = props;
    const { url, method } = API.LIST;
    console.log(param);
    const response = await HttpRequest.request(url, method, param);
    if (HttpRequest.hasError(response, true)) {
      setTotal(0);
      setFeeDatas([]);
      return;
    }
    setTotal(response.totalRowCnt);
    setFeeDatas(response.vrfBatchWrkRsltTxnList3);
  };
  const read = async (readParam, readName) => {
    const { HttpRequest } = props;
    const { url, method } = API.READ;
    console.log(readParam);
    const response = await HttpRequest.request(url, method, readParam);
    if (HttpRequest.hasError(response, true)) {
      setViewData([]);
      return;
    }
    setViewData({ ...response.setlTxnList[0], readName });
    setLayerType("edit");
  };
  const edit = async ({ url, method }, data) => {
    const { HttpRequest } = props;
    const response = await HttpRequest.request(url, method, data);
    if (HttpRequest.hasError(response, true)) {
      return false;
    }
    return true;
  };

  const handleDoubleClick = (index) => {
    let readParam = {
      cretDate: moment(feeDatas[index].wrkDate).format("YYYYMMDD"),
      srcfileCd: feeDatas[index].reviewDatName3,
      no1s: feeDatas[index].reviewDatName4,
    };
    read(readParam, feeDatas[index].reviewDatName2);
  };
  const handleClickClose = () => {
    setLayerType("");
    setViewData(null);
  };
  const handleClickSave = async (layerType, data) => {
    let ret = false;
    let msg = "";
    console.log(data);
    switch (layerType) {
      case "edit":
        ret = await edit(API.UPDATE, data);
        msg = "수정저장되었습니다.";
        break;
      default:
        console.log(layerType);
        break;
    }
    if (ret) {
      setLayerType("");
      setViewData(null);

      if (msg) props.toast.error(msg);
    }
  };

  return (
    <>
      <article className="grid_data">
        <h4 className="title">기타 오류내용</h4>
        <div className="TableArea">
          <div className="thead">
            <table>
              <colgroup>
                {["5%", "10%", "14%", "10%", "20%", "20%", "*"].map(
                  (i, key) => {
                    return <col width={i} key={key} />;
                  }
                )}
              </colgroup>
              <tbody>
                <tr>
                  {[
                    "순번",
                    "수탁번호",
                    "검증결과",
                    "정산가능여부",
                    "사유1",
                    "사유2",
                    "사유3",
                  ].map((i, key) => {
                    return <th key={key}>{i}</th>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tbody ScrollNone ">
            <table>
              <colgroup>
                {["5%", "10%", "14%", "10%", "20%", "20%", "*"].map(
                  (i, key) => {
                    return <col width={i} key={key} />;
                  }
                )}
              </colgroup>
              <tbody>
                {feeDatas &&
                  feeDatas.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        onDoubleClick={() => handleDoubleClick(key)}
                        className="hand"
                      >
                        <td>{item.rowNum}</td>
                        <td>{item.reviewDatName4}</td>
                        <td className="tal">{item.reviewDatName2}</td>
                        <td className="impossible">{item.reviewDatName1}</td>
                        <td className="tal">{item.reviewDatName5}</td>
                        <td className="tal">{item.reviewDatName6}</td>
                        <td className="tal">{item.reviewDatName7}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {feeDatas && feeDatas.length > 0 && (
        <Pagination
          page={param.pageNo}
          total={total}
          row={15}
          pageCount={10}
          fetchData={(page) => {
            setParam({
              pageNo: page,
            });
          }}
        />
      )}
      <LayerPopup layerType={layerType} onClose={handleClickClose}>
        <PopupWrapper
          layerType={layerType}
          title={"기타오류내용"}
          className={"CorrectionPopup"}
          editTable={
            <SetlTxnPopupBasicFee
              layerType={layerType}
              viewData={viewData}
              onClose={handleClickClose}
              onSave={handleClickSave}
            />
          }
        />
      </LayerPopup>
    </>
  );
};

SetlTxnCorrectionContentFeeTable.propTypes = {};

export default withBase(SetlTxnCorrectionContentFeeTable);
