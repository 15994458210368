import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  ConditionBox,
  CustomInput,
  DatePickerField,
  CacheAutocomplete,
  CacheSelect,
  CodeSelect,
  FormatInput,
} from "components";
import moment from "moment";

const FareFeeTxnConditionBox = (props) => {
  const { onClickSearch, formatter } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
          	//dspDate: moment().add(-1, "days"),
            dspSDate: moment().add(-1, "days"),
            dspEDate: moment().add(-1, "days"),
            plcfbName: "",
            carNo: "",
            dposr: "",
            corpName: "",
            otpaySttus: "",
            setlItem: "",
            fee: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const {  dspSDate,dspEDate, fee, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              //dspDate: moment(dspDate).format("YYYYMMDD"),
              dspSDate: moment(dspSDate).format("YYYYMMDD"),
              dspEDate: moment(dspEDate).format("YYYYMMDD"),
              fee: formatter(fee, "toValue"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            //dspDate: Yup.date().required().nullable(),
            dspSDate: Yup.date().required().nullable(),
            dspEDate: Yup.date().required().nullable(),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              발송일<i></i>
                              <span>
                                {errors.dspDate &&
                                  touched.dspDate &&
                                  errors.dspDate}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerField
                                  name="dspSDate"
                                  date={values.dspSDate}
                                />
                              </div>
                            </dd>
                            <dd>
                              <div className="HasDate">
                                <DatePickerField
                                  name="dspEDate"
                                  date={values.dspEDate}
                                />
                              </div>
                            </dd>
                          </dl>
                          <dl>
                            <dt>영업소</dt>
                            <dd>
                              <CacheAutocomplete
                                cache="plcfbInfo"
                                onMap={(item, key) => {
                                  return {
                                    code: item.plcfbCd,
                                    name: item.plcfbName,
                                  };
                                }}
                                name="plcfbName"
                                value={values.plcfbName}
                                setFieldValue={setFieldValue}
                                placeholder="영업소 입력"
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>차량번호</dt>
                            <dd>
                              <CustomInput
                                name="carNo"
                                type="text"
                                placeholder="차량번호 입력"
                                onChange={handleChange}
                                value={values.carNo}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>예금주</dt>
                            <dd>
                              <CustomInput
                                name="dposr"
                                type="text"
                                placeholder="예금주 입력"
                                onChange={handleChange}
                                value={values.dposr}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>법인명</dt>
                            <dd>
                              <CacheSelect
                                cache="corpInfo"
                                onMap={(item, key) => {
                                  return {
                                    code: item.corpName,
                                    name: item.corpName,
                                  };
                                }}
                                name="corpName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="법인명 선택"
                                value={values.corpName}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>처리결과</dt>
                            <dd>
                              <CodeSelect
                                codeId="OTPAY_STTUS"
                                name="otpaySttus"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"처리결과 선택"}
                                value={values.otpaySttus}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>수수료종류</dt>
                            <dd>
                              <CodeSelect
                                codeId="FEE_TYPE"
                                name="setlItem"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"수수료종류 선택"}
                                value={values.setlItem}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>수수료</dt>
                            <dd>
                              <FormatInput
                                formatType="amt"
                                name="fee"
                                placeholder="수수료 입력"
                                onChange={handleChange}
                                onBlur={handleChange}
                                value={values.fee}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

FareFeeTxnConditionBox.propTypes = {};

export default FareFeeTxnConditionBox;
