import React from "react";
import PropTypes from "prop-types";

const Cols = (props) => {
  if (props.tabs) {
    const cols = props.tabs.map((tab, key) => {
      return <col width={"14.2%"} key={key} />;
    });
    cols.push(<col width={"*"} key={cols.length} />);
    return cols;
  }
};

export default Cols;
