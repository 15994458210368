import React from "react";
import PropTypes from "prop-types";
import "./SalesSumTxnTable.css";
import IconTotalPlus from "assets/images/icon_plus.png";
import IconTotalPercent from "assets/images/icon_percent.png";

const SalesSumTxnTable = (props) => {
  const { datas, totalData, formatter } = props;
  return (
    <div className="SalesSumTxnTable">
      <article className="grid_data">
        <div className="TableArea">
          <div className="thead HasTD">
            <table>
              <colgroup>
                {[
                  "7%",
                  "*",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                <tr>
                  <th>발송일</th>
                  <th>총운임</th>
                  <th>마이너스운임</th>
                  <th>물류법인</th>
                  <th>차량법인</th>
                  <th>지입법인</th>
                  <th>하역법인</th>
                  <th>보험(백변)</th>
                  <th>보험(고변)</th>
                  <th>보험(용변)</th>
                  <th>영업소수수료</th>
                  <th>연계차수수료</th>
                  <th>노선차수수료</th>
                  <th>용차수수료</th>
                </tr> 
              </tbody>
            </table> 
          </div>          
          <div class="holdTable">
            <div className="tbody HasTH">
              <table>
                <colgroup>
                  {[
                    "7%",
                    "*",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                    "7.15%",
                  ].map((i, key) => {
                    return <col width={i} key={key} />;
                  })}
                </colgroup>
                <tbody>
                  {!datas || datas.length === 0 ? (
                    <tr>
                      <td className="NoData" colSpan={14}>
                        조회 결과가 없습니다.
                      </td>
                    </tr>
                  ) : (
                    datas &&
                    datas.map((item, key) => {
                      let layer = false;

                      if (item.corpCd) layer = true;
                      return (
                        <>
                          <tr key={key}>
                            <th>{formatter(item.setlDate, "date")}</th>
                            <td className="amount">
                              {formatter(item.totFare, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.minusFare, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.phydsCorp, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.carCorp, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.ladvhCorp, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.loadCorp, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.jundInsur, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.highInsur, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.carInsur, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.mktFee, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.linkcarFee, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.routecarFee, "amount")}
                            </td>
                            <td className="amount">
                              {formatter(item.dlvrcarFee, "amount")}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="tfoot">
            <table>
              <colgroup>
                {[
                  "7%",
                  "*",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                  "7.15%",
                ].map((i, key) => {
                  return <col width={i} key={key} />;
                })}
              </colgroup>
              <tbody>
                {!totalData || totalData.length === 0 ? (
                  <></>
                ) : (
                  totalData.map((item, key) => {
                    return key === 0 ? (
                      <tr key={key}>
                        <th>
                          <img src={IconTotalPlus} alt="" />
                          {item.setlDate}
                        </th>
                        <td className="amount">
                          {formatter(item.totFare, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.minusFare, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.phydsCorp, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.carCorp, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.ladvhCorp, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.loadCorp, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.jundInsur, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.highInsur, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.carInsur, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.mktFee, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.linkcarFee, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.routecarFee, "amount")}
                        </td>
                        <td className="amount">
                          {formatter(item.dlvrcarFee, "amount")}
                        </td>
                      </tr>
                    ) : (
                      <tr key={key}>
                        <th>
                          <img src={IconTotalPercent} alt="" />
                          {item.setlDate}
                        </th>
                        <td>{item.totFare}%</td>
                        <td>{item.minusFare}%</td>
                        <td>{item.phydsCorp}%</td>
                        <td>{item.carCorp}%</td>
                        <td>{item.ladvhCorp}%</td>
                        <td>{item.loadCorp}%</td>
                        <td>{item.jundInsur}%</td>
                        <td>{item.highInsur}%</td>
                        <td>{item.carInsur}%</td>
                        <td>{item.mktFee}%</td>
                        <td>{item.linkcarFee}%</td>
                        <td>{item.routecarFee}%</td>
                        <td>{item.dlvrcarFee}%</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  );
};

SalesSumTxnTable.propTypes = {};

export default SalesSumTxnTable;
