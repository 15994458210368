import React from "react";
import PropTypes from "prop-types";
import "./AcntDpsTxnListPopup.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, CacheAutocomplete } from "components";

const AcntDpsTxnListPopup = (props) => {
  const { layerType, viewData, onClose, onSave, formatter } = props;

  return (
    <div className="AcntDpsTxnListPopup">
      <Formik
        initialValues={{
          plcfbCd: viewData ? viewData.plcfbCd : "",
          plcfbName: viewData ? viewData.plcfbName : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSave(layerType, {
            ...viewData,
            plcfbCd: values.plcfbCd,
          });
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          plcfbCd: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <Form>
              <article className="popup_grid">
                <table>
                  <colgroup>
                    <col width="170" />
                    <col width="170" />
                    <col width="170" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>입금일자</th>
                      <th>입금액</th>
                      <th>적요</th>
                      <th>
                        영업소명<i></i>
                        <span>
                          {errors.plcfbCd && touched.plcfbCd && errors.plcfbCd}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {viewData ? formatter(viewData.dealDate, "date") : ""}
                      </td>
                      <td className="amount">
                        {viewData ? formatter(viewData.dealAmt, "amount") : ""}
                      </td>
                      <td>{viewData ? viewData.notes : ""}</td>
                      <td>
                        <CacheAutocomplete
                          cache="plcfbInfo"
                          onMap={(item, key) => {
                            return {
                              code: item.plcfbCd,
                              name: item.plcfbName,
                            };
                          }}
                          name="plcfbName"
                          value={values.plcfbName}
                          callBack={(item) => {
                            setFieldValue("plcfbCd", item ? item.code : "");
                          }}
                          setFieldValue={setFieldValue}
                          placeholder="영업소 입력"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
              <div className="PopupButton">
                <Button
                  type="submit"
                  className="insert"
                  disabled={isSubmitting}
                >
                  저장
                </Button>
                <Button className="close" onClick={onClose}>
                  닫기
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AcntDpsTxnListPopup.propTypes = {};

export default AcntDpsTxnListPopup;
