import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PopupWrapper.css";
import { Title, Button } from "components";

const PopupWrapper = (props) => {
  const {
    layerType,
    className,
    title,
    viewTable,
    editTable,
    loading,
    message,
  } = props;

  const determineView = () => {
    let ret = null;
    switch (layerType) {
      case "dashboard":
      case "view":
        ret = viewTable;
        break;
      case "input":
      case "edit":
      case "new":
        ret = editTable;
        break;
      case "loading":
        ret = loading;
        break;
      case "message":
        ret = message;
        break;
      default:
        break;
    }
    return ret;
  };

  return (
    <div className={className ? className : "Popup"}>
      <Title
        title={title}
        upperMenus={[
          title,
          layerType === "new"
            ? "신규등록"
            : layerType === "edit"
            ? "수정"
            : layerType === "input"
            ? "수기입력"
            : "조회",
        ]}
      />
      {determineView()}
    </div>
  );
};

PopupWrapper.propTypes = {};

export default PopupWrapper;
