import React, { Component } from "react";
import { Bar, ChartComponentProps } from "react-chartjs-2";

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    mode: "label",
    callbacks: {
      label: function (tooltipItem, data) {
        var value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        if (parseInt(value) >= 1000) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            " : " +
            value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        } else {
          return data.datasets[tooltipItem.datasetIndex].label + " : " + value;
        }
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback(value) {
            return Number(value).toLocaleString("ko-KR");
          },
        },
      },
    ],
  },
};

const plugins = [
  {
    afterDraw: (chartInstance, easing) => {
      const datasets = chartInstance.chart.config.data.datasets;

      if (datasets) {
        const ctx = chartInstance.chart.ctx;
        ctx.font = "9px Verdana";
        ctx.fillStyle = "#333";
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";

        datasets.forEach(function (dataset) {
          console.log(dataset);
          const dataArray = dataset.data;
          dataset._meta[0].data.forEach(function (bar, index) {
            ctx.fillText(dataArray[index], bar._view.x, bar._view.y);
          });
        });
      }
    },
  },
];

export default function SetlSumChrt(props) {
  const { chrtData } = props;
  const chart = React.useRef();

  const data = {
    labels: chrtData.labels,
    datasets: [
      {
        label: "영업소입금예정금액",
        fill: false,
        backgroundColor: "#3E64F4",
        borderColor: "#3E64F4",
        hoverBackgroundColor: "#3E64F4",
        hoverBorderColor: "#3E64F4",
        data: chrtData.kdCnt,
      },
      {
        label: "영업소입금액",
        fill: false,
        borderColor: "#34BCA1",
        backgroundColor: "#34BCA1",
        hoverBackgroundColor: "#34BCA1",
        hoverBorderColor: "#34BCA1",
        data: chrtData.hdCnt,
      },
    ],
  };

  return (
    <div className="chart">
      <Bar ref={chart} data={data} options={options} />
    </div>
  );
}
