import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  ConditionBox,
  CodeSelect,
  CustomInput,
  DatePickerField,
} from "components";
import moment from "moment";

const AcntDpsTxnListConditionBox = (props) => {
  const { onClickSearch } = props;

  return (
    <>
      <ConditionBox>
        <Formik
          initialValues={{
            dealDate: moment().add(-1, "days"),
            notes: "",
            notesMtchYn: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { dealDate, ...rest } = values;
            setSubmitting(true);
            onClickSearch({
              ...rest,
              dealDate: moment(dealDate).format("YYYYMMDD"),
            });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            dealDate: Yup.date().required().nullable(),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className="HasTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <dl>
                            <dt>
                              입금일<i></i>
                              <span>
                                {errors.dealDate &&
                                  touched.dealDate &&
                                  errors.dealDate}
                              </span>
                            </dt>
                            <dd>
                              <div className="HasDate">
                                <DatePickerField
                                  name="dealDate"
                                  date={values.dealDate}
                                />
                              </div>
                            </dd>
                          </dl>
                          <dl>
                            <dt>적요</dt>
                            <dd>
                              <CustomInput
                                name="notes"
                                type="text"
                                placeholder="적요 입력"
                                onChange={handleChange}
                                value={values.notes}
                              />
                            </dd>
                          </dl>
                          <dl>
                            <dt>거래기록사항</dt>
                            <dd>
                              <CodeSelect
                                codeId="NOTES_MTCH_YN"
                                name="notesMtchYn"
                                onChange={handleChange}
                                onBlur={handleChange}
                                placeholder={"거래기록사항 선택"}
                                value={values.notesMtchYn}
                              />
                            </dd>
                          </dl>
                        </td>
                        <td>
                          <Button
                            type="submit"
                            className="inquire"
                            disabled={isSubmitting}
                          >
                            조회하기
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ConditionBox>
    </>
  );
};

AcntDpsTxnListConditionBox.propTypes = {};

export default AcntDpsTxnListConditionBox;
